import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Table extends Component {
  render() { console.log(this.props)
    return (
      <div>
        <table cellSpacing="0" className="myTable">
          <thead>
          <tr>
            <th className="myTable__title-left myTable__headTitle-small"><FormattedMessage id="id"/></th>
            <th className="myTable__title-left  myTable__headTitle-small"><FormattedMessage id="category"/></th>
            <th className="myTable__title-left  myTable__headTitle-small"><FormattedMessage id="title"/></th>
            <th className="myTable__title-left  myTable__headTitle-small"><FormattedMessage id="tax"/></th>
            <th className="myTable__title-left  myTable__headTitle-small"><FormattedMessage id="price"/></th>
          </tr>
          </thead>
          <tbody>
          <tr className="myTable__tr">
            <td>ID1</td>
            <td>Kat1</td>
            <td>Titel</td>
            <td>20% - 30 €</td>
            <td>€ 150.00</td>
          </tr>
          <tr className="myTable__tr">
            <td>ID1</td>
            <td>Kat1</td>
            <td>Titel</td>
            <td>20% - 30 €</td>
            <td>€ 150.00</td>
          </tr>
          </tbody>
        </table>
        <div className="myTable__footer">
          <div>
            {/* <a href="#" className="button__textBtn">Payment overview</a> */}
            </div>
          <div><a href="#empty" className="button__textBtn"><FormattedMessage id="download"/></a></div>
        </div>
      </div>
    );
  }
}

export default Table;

