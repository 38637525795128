import React, { useRef, useCallback, useEffect } from "react";
import BaseFormio from "./BaseFormio";
import { APIButtons } from "./APIButton";

// HOOKS
import useMounted from "../../hooks/useMounted";
import useSafeAxios from "../../hooks/useSafeAxios";
import useSafeDelay from "../../hooks/useSafeDelay";

// FUNCTIONS
import { axiosWithAuth } from "../../axiosSetup";
import { debounce } from "../../functions/utils";

export default function ReviewForm({
  info,
  form,
  afterSaved,
  onFailed,
  blockFormSubmission,
  onSubmission,
  disableForm,
  rerender,
}) {
  let starRenderTimeout1;
  let starRenderTimeout2;
  const mounted = useMounted();
  const safeAxios = useSafeAxios(axiosWithAuth, mounted);
  const safeDelay = useSafeDelay(mounted);

  const formOptions = useRef({ ...info.default_formio_options }).current;

  const onHandleAction = useCallback(
    ({ formio, action, autoSave, isText }) => {
      if (blockFormSubmission) {
        return false;
      }

      const sendForm = () =>
        safeDelay(350)
          .then(_ => BaseFormio.apiRequest(safeAxios, action.api, formio.data))
          .then(r => {
            if (mounted()) {
              if (onSubmission) onSubmission(r);
              formio.setRespAlert(r);
              //isText || formio.checkAndShowErrors();
            }
            return r.isOK;
          })
          .catch(error => {
            if (mounted()) {
              formio.setRespAlert(error.response);
              if (onFailed) onFailed(error);
            }
            // The APIButton needs to know the request failed.
            if (!autoSave) throw error;
          });

      return { save: sendForm }[action.key]();
    },
    [mounted, safeAxios, safeDelay, onFailed]
  );

  const onDebouncedAction = useCallback(debounce(onHandleAction, 500), [onHandleAction]);

  // Auto-save when an input's value has changed.
  const onAutoSave = useCallback(
    ({ changed }) => {
      if (changed && !changed.flags.fromSubmission) {
        const isText = changed.component.inputType === "text";
        const handle = isText ? onDebouncedAction : onHandleAction;

        if (afterSaved) afterSaved();

        onFormRender("save");

        handle({
          isText,
          formio: changed.instance._currentForm,
          action: form.save_api,
          autoSave: true,
        });
      }
    },
    [form, onHandleAction, onDebouncedAction]
  );

  // add star rating
  useEffect(() => {}, []);

  const addStars = () => {
    const starRatins = document.querySelectorAll(".star-rating");

    starRatins.forEach(starRating => {
      const inputGroup = starRating.querySelectorAll(".form-radio label");
      let checked = false;

      // iterate backwords trough star set

      for (var i = inputGroup.length - 1; i >= 0; i--) {
        const input = inputGroup[i].getElementsByTagName("input")[0];

        const label = inputGroup[i].getElementsByTagName("span")[0];

        // if iterated input is checked
        if (input.checked) {
          checked = true;
        }

        // if last input checked, clear stars
        if (inputGroup[inputGroup.length - 1].querySelector("input").checked) {
          label.classList.remove("add_star_full");
        } else if (checked && i !== inputGroup.length - 1) {
          label.classList.remove("add_star_line");
          label.classList.add("add_star_full");
        } else {
          label.classList.add("add_star_line");
          label.classList.remove("add_star_full");
        }
      }
    });
  };

  const onFormRender = onWhat => {
    const allStars = document.querySelectorAll(".star-rating label > input");

    if (allStars.length !== 0) {
      starRenderTimeout1 = setTimeout(() => {
        addStars();
      }, 500);

      // // prevent click on selected start - remove toggle effect
      // allLabels.forEach(label => {
      //   label.addEventListener("mousedown", function (e) {
      //     const input = label.querySelector("input");
      //     // if there is a input in this label
      //     if (input && input.checked) {
      //       console.log("input", input, input.checked);
      //       e.stopPropagation();
      //       e.preventDefault();

      //       //@todo this is not working check to see uf you can precvent default in formio
      //     }
      //   });
      // });

      allStars.forEach(star => {
        star.addEventListener("click", function (e) {
          starRenderTimeout2 = setTimeout(() => {
            addStars();
          }, 500);
        });
      });
    }
  };

  // on unmount
  useEffect(() => {
    return () => {
      clearTimeout(starRenderTimeout1);
      clearTimeout(starRenderTimeout2);
    };
  }, []);

  return (
    <BaseFormio
      schema={form.schema}
      content={form.content}
      renderCallback={onFormRender}
      onChange={form.auto_save && onAutoSave}
      options={formOptions}
      disableForm={disableForm || blockFormSubmission}
    >
      {!blockFormSubmission && <APIButtons list={[form.actions, ctxt => onHandleAction(ctxt)]} />}
    </BaseFormio>
  );
}
