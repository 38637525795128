import React, { Component } from "react";
import { injectIntl } from "react-intl";

// HOC
import AppHOC from "../hoc/AppHOC";

// REDUX
import { connect } from "react-redux";
import { clearEntrySteps, clearEntryStepActive } from "../actions/entryActions";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import AlphaTable from "../components/tables/AlphaTable";
import ColorAlerter from "../components/alerts/ColorAlerter";
import ContentContainer from "../components/layout/ContentContainer";
import ParseHTML from "../functions/ParseHTML";

// HOC
import AnimateHOC from "../hoc/AnimateHOC";

// FUNCTIONS
import AJAX from "../functions/ajax";

export class Dashboard extends Component {
  _id = Math.random();

  state = {};

  UNSAFE_componentWillMount() {
    this.getTables(this.props);

    // clear active entry steps
    if (this.props.clearEntrySteps) this.props.clearEntrySteps();

    // clear active entry step
    if (this.props.clearEntryStepActive) this.props.clearEntryStepActive();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getTables(nextProps);
  }

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  getTables = props => {
    // make just one request
    if (!this._getTabels) {
      if (props.user && props.user.appModule) {
        this._getTabels = true;

        AJAX()
          .getEntryTableGroup(this._id, props)
          .then(res => {
            if (res && res.data) {
              // if there are not entry data
              if (res.data.length > 0) {
                this.setState({ tables: res.data });
              } else {
                AJAX()
                  .getDashboard(this._id, props)
                  .then(res => {
                    const data = res.data.welcome_text;
                    this.setState({ submitterDashboardMsg: data });
                  });
              }
            }
          });
      }
    }
  };

  renderTables = tables =>
    tables.map(table => <AlphaTable titleType="h1" key={table.id} data={table} />);

  isTicketTool = () => this.props.info.apps[this.props.info.default_app].slug === "aat2020";

  renderNoEntriesAlert = data => {
    if (data.format === "json") return false
    else return (
    <ContentContainer title={data.title}>
      {data.format === "html" ? <ParseHTML html={data.body} /> : data.body}
    </ContentContainer>)
  };

  render() {
    const { tables } = this.state;

    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin" id="main_content">
            <div className="dashboardMain">
              <AnimateHOC animate={true}>
                {this.state.submitterDashboardMsg &&
                  this.renderNoEntriesAlert(this.state.submitterDashboardMsg)}
                {tables && this.renderTables(tables)}
              </AnimateHOC>
            </div>
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

const mapStateToProps = state => ({
  info: state.app.info,
  user: state.user,
});

export default injectIntl(
  connect(mapStateToProps, { clearEntrySteps, clearEntryStepActive })(Dashboard)
);
