import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector } from "react-redux";

// COMPONENTS
import Layout from "../layout/Layout";
import Select from "../inputs/Select";
import Icon from "../icons/Icon";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

const HeaderNav = ({ history }) => {
  const info = useSelector(state => state.app.info);
  const user = useSelector(state => state.user);
  const isMainMenuVisible = useSelector(state => state.app.isMainMenuVisible);
  const [userRoleMenu, setUserRoleMenu] = useState();
  const [selectedRoleItem, setSelectedRoleItem] = useState();

  useEffect(() => {
    setTheUserRoleMenu();
  }, []);

  const setTheUserRoleMenu = () => {
    const modules = info.apps[info.default_app].modules;
    const { labels, roles, role_selected } = user;

    // only display those for which a module exists
    const rolesMenu = roles.filter(r => modules[r]);

    let selectedRoleItem;
    const formattedMenu = rolesMenu.map((roleKey, index) => {
      const item = { id: index, key: roleKey, label: labels[roleKey] };
      if (roleKey === role_selected) selectedRoleItem = item;
      return item;
    });

    setUserRoleMenu(formattedMenu);
    setSelectedRoleItem(selectedRoleItem);
  };

  return (
    <nav
      className="__he_m bc12"
      data-name="HeaderNav"
      aria-label={renderLangString({ id: "$_c1" })}
    >
      <Layout flex spaceBetween>
        <div>{!isMainMenuVisible && <Icon iconId="globalNavLogo" addClass="__he_i" />}</div>
        {userRoleMenu && userRoleMenu.length > 1 && (
          <Select
            options={userRoleMenu}
            blockOptions={true}
            select={[selectedRoleItem]}
            getValues={val => AA_GLOBAL.switchUserRole(val[0].key, { history })}
          />
        )}
      </Layout>
    </nav>
  );
};

export default withRouter(HeaderNav);
