import { axiosWithAuth } from "../axiosSetup";
import { between, showBackendErrorPage } from "./utils";

//@todo https://andrewmmc.com/blog/2020/handle-fetch-state-in-react-hooks/ new ajax
// Important: You need to check if res is returned in each [then] function of the axios promise,
// because it is returned conditionaly. This was done to prevent memory leak

/**
 * Keeps track of axios requests using a string ID.
 */
const runningRequests = {
  table: new Map(),
  store(context) {
    this.table.set(context.requestID);
  },
  has(requestID) {
    return this.table.has(requestID);
  },
  maybeCancel(requestID, reason) {
    const context = this.table.get(requestID);
    context && context.cancelRequest(reason);
    return this.clear(requestID);
  },
  clear(requestID) {
    return this.table.delete(requestID);
  },
  clearContext(oldContext) {
    const context = this.table.get(oldContext.requestID);
    return context === oldContext ? this.clear(oldContext.requestID) : false;
  },
};

const axiosRequest = (requestID, method, url, data, headers) => {
  // Always cancel previous request first.
  runningRequests.maybeCancel(requestID, "automatic");

  let cancelRequest;
  const cancelToken = new axiosWithAuth.CancelToken(fn => (cancelRequest = fn));

  let request = axiosWithAuth.request({ method, url, data, headers, cancelToken });

  const context = { requestID, request, cancelRequest };

  runningRequests.store(context);

  return (
    request
      // .then(res => {
      //   return res;
      //   // @todo fix this later
      //   // if (res.data && runningRequests.has(requestID) && between(res.status, 200, 299)) {
      //   //   return res;
      //   // } else {
      //   //   return null;
      //   // }
      // })
      // .catch(err => {
      //   // Print server error directly to the bottom of the page.
      //   // Messes up the page's styles, but the point is to be instantly shown the issue.
      //   if (
      //     process.env.NODE_ENV === "development" &&
      //     err.response &&
      //     between(err.response.status, 500, 599)
      //   ) {
      //     document.body.innerHTML += `<object type="text/html">${err.response.data}</object>`;
      //   }

      //   return null;
      // })
      .then(res =>
        res.data && runningRequests.has(requestID) && between(res.status, 200, 299) ? res : res
      )
      .catch(err => showBackendErrorPage(err, { url }))
      .finally(() => runningRequests.clearContext(context))
  );
};

//@todo refactor

const defaultApp = ({ info }) => info.apps[info.default_app];
const addParams = (url, params) =>
  url + (url.includes("?") ? "&" : "?") + (Array.isArray(params) ? params : [params]).join("&");
const moduleAsParam = ({
  match: {
    params: { module },
  },
}) => (module ? `module=${module}` : "");

// prettier-ignore
const AJAX = {
  cancelRequest: (requestID, reason) => runningRequests.maybeCancel(requestID, reason || "explicit"),
  cancelRequests: (requestIDs, reason) => requestIDs.map(rID => AJAX.cancelRequest(rID, reason)),
  defaultApp: defaultApp,
  getAppInfo: (requestID) => axiosRequest(requestID, 'GET', '/api/info'),
  getLoginData: (requestID, props) => axiosRequest(requestID, 'GET', addParams(defaultApp(props).modules.auth.api_url), moduleAsParam(props)),
  // defaultApp(props).modules.session.api_url
  getSession: (requestID, props) => axiosRequest(requestID, 'GET', addParams('/api/session', moduleAsParam(props))),
  register: (requestID, props, data) => axiosRequest(requestID, 'POST', defaultApp(props).modules.registration.api_url, data),
  login: (requestID, props, data) => axiosRequest(requestID, 'POST', defaultApp(props).modules.login.api_url, data),
  resetPassword: (requestID, props, data) => axiosRequest(requestID, 'POST', `/api/password/reset/apply/${props.match.params.id}`, data),
  forgotPassword: (requestID, props, data) => axiosRequest(requestID, 'POST', `/api/password/reset/request`, data),
  changePassword: (requestID, props, data) => axiosRequest(requestID, 'POST', defaultApp(props).modules.change_password.api_url, data),
  getEntryStepList: (requestID, props, id) => axiosRequest(requestID, 'GET', `${props.user.appModule.submissions.api_url}/${id}/edit`),
  getEntryTable: (requestID, props) => axiosRequest(requestID, 'POST', props.user.appModule.submissions.index),
  getReviewCasesTable: (requestID, props) => axiosRequest(requestID, 'POST', `${props.user.appModule.review.api_url}/admin/cases/index`),
  getReviewResultsTable: (requestID, props) => axiosRequest(requestID, 'POST', `${props.user.appModule.review.api_url}/admin/results/index`),
  getReviewStages: (requestID, props) => axiosRequest(requestID, 'GET', `${props.user.appModule.api_url}/dashboard`),
  getReviewStage: (requestID, props, id) => axiosRequest(requestID, 'GET', `${props.user.appModule.review.api_url}/stages/${id}`),
  getReviewCollection: (requestID, props, id) => axiosRequest(requestID, 'GET', `${props.user.appModule.review.api_url}/collections/${id}`),
  getReviewCase: (requestID, props, id) => axiosRequest(requestID, 'GET', `${props.user.appModule.review.api_url}/ccases/${id}`),
  getEntryTableGroup: (requestID, props) => axiosRequest(requestID, 'POST', props.user.appModule.submissions.grouped),
  getDashboard: (requestID, props) => axiosRequest(requestID, 'GET', `${props.user.appModule.api_url}/dashboard`),
  getUsersTable: (requestID, props) => axiosRequest(requestID, 'GET', props.user.appModule.users.index),
  deleteEntry: (requestID, props, id) => axiosRequest(requestID, 'DELETE', `${props.user.appModule.submissions.index}${id}`),
  submitEntryParentStep: (requestID, props, data) => axiosRequest(requestID, 'POST', props.stepActive.data_set_url, data),
  getEntryStepsDisplayData: (requestID, props) => axiosRequest(requestID, 'GET', `${props.user.appModule.submissions.api_url}/${props.match.params.id}/requirements/${props.match.params.displayId}/with_data_set`),
  getUserData: (requestID, props) => axiosRequest(requestID, 'GET', `${props.user.appModule.users.api_url}/${props.match.params.id}`),
  getProfiles: (requestID, props) => axiosRequest(requestID, 'GET', props.user.appModule.profiles.api_url),
  contentGeneratorConfimModal: (requestID, method, url, data) => axiosRequest(requestID, method, url, data),

  // generic request
  head: (requestID, url) => axiosRequest(requestID, 'HEAD', url),
  options: (requestID, url) => axiosRequest(requestID, 'OPTIONS', url),
  get: (requestID, url) => axiosRequest(requestID, 'GET', url),
  delete: (requestID, url) => axiosRequest(requestID, 'DELETE', url),
  post: (requestID, url, data) => axiosRequest(requestID, 'POST', url, data),
  put: (requestID, url, data) => axiosRequest(requestID, 'PUT', url, data),
  patch: (requestID, url, data) => axiosRequest(requestID, 'PATCH', url, data),
  send: (requestID, method, url, data) => axiosRequest(requestID, method, url, data),
  sendAPI: (requestID, api) => axiosRequest(requestID, api.method, api.url, api.data, api.headers)
}

//@todo change to simply AJAX. exported as lambda as it was used like this from the beginning
export default () => AJAX;
