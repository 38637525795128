import getDateAndTime from "../functions/getDateAndTime";

const calculateDaysLeft = (starts, ends) => {
  const startsDateDate = getDateAndTime({ date: starts });

  // calculate days
  const calculateDays = date => {
    // To set two dates to two variables
    const date1 = new Date(date);
    const date2 = new Date();

    // To calculate the time difference of two dates
    const Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return parseInt((Math.abs(Difference_In_Days) + "").split(".")[0]);
  };

  //check if starts in future
  if (startsDateDate.isDateInFuture) {
    return calculateDays(starts);
  } else {
    const endDateData = getDateAndTime({ date: ends });
    //check if starts in future
    if (endDateData.isDateInFuture) {
      return calculateDays(ends);
    } else {
      return 0;
    }
  }
};

export default calculateDaysLeft;
