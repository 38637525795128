import React from "react";
import { withRouter, Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { FaEdit } from "react-icons/fa";

// REDUX
import { useSelector, useDispatch } from "react-redux";

// COMPONENTS
import ContentContainer from "../layout/ContentContainer";
import ContentGenerator from "../layout/ContentGenerator";
import FieldList from "../layout/FieldList";
import Popover from "../popovers/Popover";
import ProgressBar from "../statistics/ProgressBar";
import BigGallery from "../gallery/BigGallery";
import IconAria from "../icons/IconAria";

const Overview = ({ overviewData, readonly, match, intl }) => {
  const info = useSelector(state => state.app.info);
  const user = useSelector(state => state.user);

  const projectOverviewDataWasRedered = () => {
    // // scroll on page load
    // if (window.location.hash.slice(0, 7) === "#slide-") {
    //   this.waitForAnimation = setTimeout(() => {
    //     const id = window.location.hash.slice(7);
    //     const el = document.querySelector("#link-" + id);
    //     const title = document.getElementById("title-" + id);
    //     // mark title
    //     if (title) {
    //       title.classList.add("projectOverview__title-mark");
    //     }
    //     if (el) {
    //       const elOffsetTop = getElPosition(el).y;
    //       window.scrollTo({
    //         top: elOffsetTop,
    //         behavior: "smooth",
    //       });
    //     }
    //   }, 300);
    // }
  };

  return (
    <ContentContainer
      test="overviewCon"
      contentRedered={projectOverviewDataWasRedered}
      title={intl.formatMessage({ id: "projectdetails" })}
      alerterData={overviewData.alerts || []}
    >
      <div className="projectOverview__header">
        <span className={`projectOverview__idMarker`} id={`link-${match.params.id}`}></span>
        <h2 className="projectOverview__title" id={`title-${match.params.id}`}>
          {overviewData.code ? `${overviewData.code} - ` : ""}
          {overviewData.title}
          {!overviewData.locked && (
            <Popover msg={intl.formatMessage({ id: "edit" }) + " " + overviewData.title}>
              <Link
                to={`/ui/${user.role_selected}/edit-entry/${match.params.id}/${match.params.id}`}
                aria-label={overviewData.title}
              >
                <FaEdit className="projectOverview__title-icon" />
              </Link>
            </Popover>
          )}
        </h2>
        <h3 className="projectOverview__subTitle">
          {overviewData.categories ? overviewData.categories.join(", ") : ""}
        </h3>
      </div>

      {overviewData?.progress && (
        <ProgressBar
          value={overviewData.progress.value}
          text={overviewData.progress.text}
          style={{ margin: "-1em 0 0.75em" }}
          ttip={overviewData.progress.ttip}
        />
      )}

      {overviewData?.gallery?.length > 0 && (
        <BigGallery
          blockEditing={overviewData.locked}
          title={overviewData.title + (overviewData.code ? " - " + overviewData.code : "")}
          data={overviewData.gallery}
        />
      )}

      {overviewData && (
        <div>
          <div className="printHeaderData">
            <img
              src={info.apps[info.default_app].logo?.src}
              className="projectOverview__printLogo"
              alt="Company Logo"
            />
            <span className="projectOverview__titleCode">{overviewData.code}</span>
            <h2 className="projectOverview__printTitle">
              {overviewData.title}
              <hr style={{ margin: "2rem 0 3rem" }} />
            </h2>
          </div>

          <StepsOverview steps={overviewData.data} opts={{ intl, user, match, readonly }} />
        </div>
      )}
    </ContentContainer>
  );
};

const EditStepLink = ({ step, opts: { intl, user, match } }) => (
  <Popover msg={`${intl.formatMessage({ id: "edit" })}: ${step.title}`}>
    <Link
      aria-label={intl.formatMessage({ id: "edit" })}
      to={`/ui/${user.role_selected}/edit-entry/${match.params.id}/${step.req_id || step.id}`}
      className="projectOverview__editIcon"
    >
      <IconAria iconId="edit" />
    </Link>
  </Popover>
);

const FieldsSection = ({ model, prefix, opts }) => (
  <div className={`${prefix}__box`}>
    <span className={`${prefix}__idMarker`} id={`link-${model.id}`}></span>
    <div className={`${prefix}__labelInfo`}>
      <div className={`${prefix}__labelInfo__titleCon`}>
        <h3 className={`title title--medium form__title ${prefix}Title`} id={`title-${model.id}`}>
          {/* No need to set innerHTML. HTML is not supported in model titles. */}
          {model.title}
        </h3>
        {!opts?.readonly && !model.locked && <EditStepLink step={model} opts={opts || {}} />}
      </div>

      {model.display_content?.data && <ContentGenerator data={model.display_content} />}

      <FieldList model={model} prefix={prefix} />
    </div>
  </div>
);

// NB: assign `req_id` to `id` for backwards compatibility. `req_id` was added in the back end in July 21.
// Remove this function once the whole entry step system (EditEntry & MainSideNav) works with the data set's id
// instead of its requirement id. Eventually a requirement (Formio form) may have multiple data sets.
const patchStepID = step => {
  step.id = step.req_id || step.id;
  return step;
};

const StepsOverview = ({ steps, opts }) => (
  <div className="projectOverview">
    {Array.isArray(steps) &&
      steps.map(step => (
        <FieldsSection
          key={step.id}
          model={patchStepID(step)}
          opts={opts}
          prefix={"projectOverview"}
        />
      ))}
  </div>
);

export default injectIntl(withRouter(Overview));
export { StepsOverview };
