import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import RootReducer from './reducers';

const initialState = {};

const middleware = [ReduxThunk];

const store = createStore(
  RootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
)

export default store;
