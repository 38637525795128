import {
  SHOW_MOB_MENU,
  HIDE_MOB_MENU,
  TOGGLE_MODAL,
  RESET_STORE_PROPS,
  GET_INFO,
  GET_LOGIN_DATA,
  SET_PAGE_WIDTH,
  GALLERY_FULLSCREEN_TOGGLE,
  SET_LANGUAGE,
  SET_MESSAGES,
} from "./types";

export const showMobMenu = () => ({
  type: SHOW_MOB_MENU,
});

export const getInfo = data => dispatch => {
  dispatch({ type: GET_INFO, payload: data });
};

export const getLoginData = data => dispatch => {
  dispatch({ type: GET_LOGIN_DATA, payload: data });
};

export const hideMenu = () => ({ type: HIDE_MOB_MENU });

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});

export const resetStoreProps = () => ({
  type: RESET_STORE_PROPS,
});
export const setPageWidth = pageWidth => ({
  type: SET_PAGE_WIDTH,
  payload: pageWidth,
});

export const toggleGalleryFullscreen = () => ({
  type: GALLERY_FULLSCREEN_TOGGLE,
});

export const setLanguage = lang => ({
  type: SET_LANGUAGE,
  payload: lang,
});

export const setMessages = messages => ({
  type: SET_MESSAGES,
  payload: messages,
});
