import { useState, useEffect, useRef } from "react";

const useTimer = () => {
  const [dayTime, setDayTime] = useState();
  const [timer, setTimer] = useState();
  const interval = useRef();

  const tick = () => {
    let counterDone = false;

    //Set expire date
    const date = new Date(dayTime).getTime();

    // Get today's date and convert it to times
    let now = new Date().getTime();

    // Find the time difference between now and given date
    let timeDifference = date - now;

    // Calculate days, hours, minutes and seconds
    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // add 0 in front of the single decimal
    minutes = (minutes + "").length === 1 ? `0${minutes}` : minutes;
    seconds = (seconds + "").length === 1 ? `0${seconds}` : seconds;

    // If the count down is over, clear interval
    if (timeDifference < 0) {
      clearInterval(interval);
      counterDone = true;
    }

    setTimer({
      counterWithDays: `${days}:${hours}:${minutes}:${seconds}`,
      counterWithoutDays: `${hours}:${minutes}:${seconds}`,
      days,
      hours,
      minutes,
      seconds,
      counterDone,
    });

    // if counder ends stop it
    if (days < 0) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    tick();

    interval.current = setInterval(tick, 1000);

    return () => {
      clearInterval(interval.current);
    };
  }, [dayTime]);

  return [timer, setDayTime];
};

export default useTimer;
