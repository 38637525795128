import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Btn = props => {
  return (
    <button
      id={props.id}
      type={props.type}
      form={props.form}
      onClick={props.clicked}
      style={props.style}
      className={"button " + (props.boxBtn ? ' button__boxBtn ' : ' button__main-form ') + (props.addClass ? props.addClass : '')}>
        <div className={"circleLoader-small " + (props.boxBtn ? ' button__main-form-loader-b ' : ' button__main-form-loader-w ') + (props.loading ? '' : ' u-hide ')}></div>
        {props.intlText ?
            <div className={props.loading ? ' u-invisible': ''}><FormattedMessage id={props.intlText}/></div>
        :   <div className={props.loading ? ' u-invisible': ''}>{props.text}</div>}
    </button>
  );
};

Btn.propTypes = {
  type: PropTypes.string,
  clicked: PropTypes.func,
  addClass: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  boxBtn: PropTypes.bool,
  form: PropTypes.string
};

export default Btn;