import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import {
  FaEdit,
  FaEye,
  // FaCopy,
  FaTrash,
  FaPrint,
} from "react-icons/fa";

// COMPONENTS
import Popover from "../popovers/Popover";
import IconAria from "../icons/IconAria";

// REDUX
import { connect } from "react-redux";
import { hideMenu } from "../../actions/appActions";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

class OperationsNav extends Component {
  state = {
    modalVisiblity: false,
  };

  UNSAFE_componentWillMount() {
    this.setPageInUrl(this.props);

    // set the first unconfirmd step as active is displayId is not set
    if (this.props.match.params.displayId) {
      // if displayId exists redirect with it
      const editEntryURL = `/ui/${this.props.user.role_selected}/edit-entry/${this.props.match.params.id}/${this.props.match.params.displayId}`;
      this.setState({ firstUnconfirmedStepUrl: editEntryURL });
    } else {
      // if displayId does not enxists, find first unconfimed step id
      const editEntryURL = AA_GLOBAL.makeEditEntryUrl(this.props.match.params.id, this.props.data);
      this.setState({ firstUnconfirmedStepUrl: editEntryURL });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setPageInUrl(nextProps);
  }

  setPageInUrl = props => {
    this._page = props.match.params.role;
  };

  removeEntry = () => {
    this.props.hideMenu();
    if (!this.props.disableDelete) this.props.removeBtnClick();
  };

  copyEntry = () => {};

  handlePrint = () => {
    if (!window.print) return false;
    this.props.hideMenu() 
    setTimeout(() => {
      this.props.print();
    }, 500);
  };

  render() {
    return (
      <div className="operationsNav">
        <nav className="operationsNav__menu" role="complementary">
          <Popover msg={this.props.intl.formatMessage({ id: "projectOverview" })}>
            <NavLink
              aria-label={this.props.intl.formatMessage({ id: "projectOverview" })}
              to={`/ui/${this._page}/project-overview/${this.props.match.params.id}`}
              // activeClassName={this._page === ''? " operationsNav__item-active" : null}
              className={
                "operationsNav__item " +
                (this.props.location.pathname.split("/")[3] === `project-overview`
                  ? "operationsNav__item-active"
                  : "")
              }
            >
              <IconAria iconId="eye" />
            </NavLink>
          </Popover>
          <Popover msg={this.props.intl.formatMessage({ id: "editEntry" })}>
            <NavLink
              aria-label={this.props.intl.formatMessage({ id: "editEntry" })}
              to={this.props.disableEdit ? "#" : this.state.firstUnconfirmedStepUrl}
              className={
                "operationsNav__item " +
                (this.props.location.pathname.split("/")[3] === `edit-entry` &&
                !this.props.disableEdit
                  ? " operationsNav__item-active"
                  : "") +
                (this.props.disableEdit ? " operationsNav__item-disabled" : "")
              }
            >
              <IconAria iconId="edit" />
            </NavLink>
          </Popover>
          {this.props.data.copy_url && (
            <Popover msg={this.props.intl.formatMessage({ id: "copy" })}>
              <NavLink
                aria-label={this.props.intl.formatMessage({ id: "copy" })}
                to={`/ui/${this._page}/copy-entry/${this.props.match.params.id}`}
                className={
                  "operationsNav__item " +
                  (this.props.location.pathname.split("/")[3] === `copy-entry`
                    ? " operationsNav__item-active"
                    : "")
                }
              >
                <IconAria iconId="copy" />
              </NavLink>
            </Popover>
          )}
          <Popover msg={this.props.intl.formatMessage({ id: "delete" })}>
            <div
              tabIndex="0"
              aria-label={this.props.intl.formatMessage({ id: "delete" })}
              className={
                "operationsNav__item " +
                (this.props.disableDelete ? " operationsNav__item-disabled" : "")
              }
              onClick={this.removeEntry}
              onKeyDown={e => e.key === "Enter" && this.removeEntry()}
            >
              <IconAria iconId="delete" />
            </div>
          </Popover>
          {/* <Popover msg={this.props.intl.formatMessage({id: 'copy'})}>
            <div
              className="operationsNav__item operationsNav__item-disabled">
                <FaCopy/>
            </div>
          </Popover> */}
          <Popover
            msg={
              window.print
                ? this.props.intl.formatMessage({ id: "print" })
                : this.props.intl.formatMessage({ id: "printNotSupported" })
            }
          >
            <div
              tabIndex="0"
              aria-label={this.props.intl.formatMessage({ id: "delete" })}
              className={
                "operationsNav__item " + (!window.print ? " operationsNav__item-disabled" : "")
              }
              onClick={this.handlePrint}
              onKeyDown={e => e.key === "Enter" && this.handlePrint()}
            >
              <IconAria iconId="print" />
            </div>
          </Popover>
        </nav>
      </div>
    );
  }
}

OperationsNav.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default injectIntl(withRouter(connect(mapStateToProps, { hideMenu })(OperationsNav)));
