export function isHidden(el) {
  return el.offsetParent === null;
}

// if el is fixed position
// function isHidden(el) {
//   var style = window.getComputedStyle(el);
//   return (style.display === 'none')
// }

// function isHidden(el) {
//   var style = window.getComputedStyle(el);
//   return false;
// }

export function isVisible(e) {
  return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}
