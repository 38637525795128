import { useCallback } from "react";

/** Avoids calling the callback when the component isn't mounted anymore.
 */
export function useSafeTimeout(mounted) {
  return (fn, delay) => setTimeout(() => mounted() && fn(), delay);
}

export function safeDelay(ms, mounted) {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      // Only resolve when mounted. Do nothing otherwise.
      if (mounted()) resolve();
    }, ms);
  });
}

export default function useSafeDelay(mounted) {
  return useCallback(ms => safeDelay(ms, mounted), [mounted]);
}
