import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import RemoveWrapDiv from './ReduxWrapper';

export class ToggleRenderHOC extends Component {

  state = {
    display: true
  }

  UNSAFE_componentWillMount() {
    const path = this.props.location.pathname;

    if(this.props.hideOnRoutes) {

      // check if app route is one of the routs added in hideOnRoutes props
      // if true, that component will not be renderd
      const renderState = this.props.hideOnRoutes.some(route => {
        return path.includes(route);
      })
      if (renderState) { this.setState({display: false})}
    }

    if(this.props.hideOnRoute) {
      const renderState = this.props.hideOnRoute.some(route => {
        return route === path;
      })
      if (renderState) { this.setState({display: false})}
    }

    if(this.props.showOnRoutes) {

      // check if app route is one of the routs added in hideOnRoutes props
      // if true, that component will not be renderd
      const renderState = this.props.showOnRoutes.some(route => {
        return !path.includes(route);
      })
      if (renderState) { this.setState({display: false})}
    }
  }

  render() {
    return (
      <RemoveWrapDiv>
        {this.state.display ? <RemoveWrapDiv>{this.props.children}</RemoveWrapDiv> : <div className="emptyDiv-125"></div>}
      </RemoveWrapDiv>
    );
  }
}

export default withRouter(ToggleRenderHOC);