import axios from "axios";
import { extractLanguageFromLocale } from "./utils";

const localeMap = {
  de: "de-DE",
  en: "en-US",
  pt: "pt-PT",
  fr: "fr-FR",
};

// Supported locales. See: /public/json/formio/*.json
const supported = ["de-AT", "de-DE", "en-GB", "en-US", "pt-PT", "pt-BR", "fr-FR", "fr-LU"];

const fetchAndSetFormioLocale = formioOpts => {
  formioOpts.i18n = formioOpts.i18n || {};
  if (formioOpts.i18n[formioOpts.language]) return;

  const langOrLocale = formioOpts.language || "en";

  const parseLang = locale => (/^[^-]+/.exec(locale) || [])[0];

  const locale =
    (new Set(supported).has(langOrLocale) ? langOrLocale : localeMap[parseLang(langOrLocale)]) ||
    "en-US";

  return axios.get(`/ui/json/formio/${locale}.json`).then(res => {
    const lang = extractLanguageFromLocale(locale);
    formioOpts.language = lang;
    formioOpts.i18n[lang] = res.data;
    return res;
  });
};

export default fetchAndSetFormioLocale;
