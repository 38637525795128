//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import validateData from "../../functions/validateData";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import MainNav from "../../components/navigations/MainNav";
import Main from "../../components/main/Main";
import DisplayScore from "../../components/display/DisplayScore";
import Overview from "../../components/display/Overview";
import ReviewForm from "../../components/forms/ReviewForm";
import AnimateLayout from "../../components/layout/AnimateLayout";
import AlphaTable from "../../components/tables/AlphaTable";
import PriviewOverview from "../../components/display/PriviewOverview";

// DATA MODEL
import caseDataModel from "../../data_model/review_admin/case/case_data";

function Case({ match, info, history }) {
  const isInitialMount = useRef(false);
  const _id = 3589204823904809;
  const dispatch = useDispatch();

  const [counterData, setCounterData] = useState();
  const [cases, setCases] = useState([]);
  const [reviewers, setReviewers] = useState();
  const [formDataWithContent, setFormDataWithContent] = useState();
  const [formData, setFormData] = useState();
  const [score, setScore] = useState();
  const [overview, setOverview] = useState();

  const reduxState = useSelector(state => state);
  const reviewer = useSelector(state => state.reviewer);
  const sizeOfTheScreen = useSelector(state => state.app.sizeOfTheScreen);

  const init = useCallback(() => {
    getMainData();
  }, []);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  const handleTableLinkClick = linkID => {
    //get form data
    AJAX()
      .get(_id, linkID)
      .then(res => {
        formData.content = res.data.result.content;
        setFormDataWithContent(formData);

        setScore(res.data.result.score);

        // If on mobile size
        if (sizeOfTheScreen && sizeOfTheScreen === "mobile") {
          dispatch({ type: TYPES.SHOW_HELPER_NAV_ON_TOP });
        } else if (sizeOfTheScreen && sizeOfTheScreen === "desktop") {
          dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
        }
        dispatch({ type: TYPES.SET_HELPER_NAV_AS_PRESENT });
      });
  };

  // // set the counter with data from judge module
  // useEffect(() => {
  //   if (reduxState.reviewer && reduxState.reviewer.expiresAt) {
  //     setCounterData(reduxState.reviewer.expiresAt);
  //   }
  // }, [reduxState]);

  const getMainData = () => {
    AJAX()
      .getReviewCase(_id, reduxState, match.params.id)
      .then(res => {
        const isDataValid = validateData(
          "Reviewer > Case > AJAX().getReviewCase",
          res,
          caseDataModel
        );

        if (isDataValid) {
          setCases(res.data.case.nav.cases);
          const formData = res.data.case.form;

          formData.actions = {};
          setFormData(formData);
          setOverview(res.data.case.overview);

          dispatch({
            type: TYPES.SET_PAGE_IDS,
            payload: { stage: res.data.case.stage_id, collection: res.data.case.collection_id },
          });

          AJAX()
            .get(_id, res.data.case.assignments_url)
            .then(res => setReviewers(res.data));
        }
      });
  };

  const handleNavItemClick = () => {
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
    dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
  };

  // ON UNMOUNT
  useEffect(() => {
    return () => {
      dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      dispatch({ type: TYPES.SET_HELPER_NAV_AS_NOT_PRESENT });
    };
  }, []);

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={cases}>
          {cases && (
            <MainNav
              clicked={handleNavItemClick}
              title={renderLangString({ id: "$_c3" })}
              titleTag="h1"
              data={cases}
              linkTo="/ui/review_admin/case"
              activeItem={match.params.id}
            />
          )}
        </AnimateLayout>
      </MainAsideNav>

      <Main counter={counterData}>
        {reviewers && (
          <div>
            <PriviewOverview data={overview}>
              <Overview overviewData={overview} readonly={true} />
            </PriviewOverview>
            <AlphaTable linkCallBack={handleTableLinkClick} data={reviewers} />
          </div>
        )}
      </Main>
      <HelperAsideNav>
        {formDataWithContent && (
          <div>
            <DisplayScore style={{ marginBottom: "3rem" }} addClass="c10 bc1" score={score} />
            <ReviewForm
              blockFormSubmission={true}
              disableForm={true}
              info={info}
              form={formDataWithContent}
            />
          </div>
        )}
      </HelperAsideNav>
    </NewAppHOC>
  );
}

export default withRouter(Case);
