// MADE ONLY FOR OLD TEMPLATE TO FIX ARIA PROBLEMS
import React, { Component } from "react";
import {
  MdEmail,
  MdLock,
  MdInfo,
  MdLockMdInfo,
  MdKeyboardArrowDown,
  MdCardTravel,
  MdAddCircle,
  MdLibraryAdd,
  MdVpnKey,
  MdPerson,
  MdAccessTime,
  MdChevronLeft,
  MdChevronRight,
  MdVideocam,
  MdContentPaste,
  MdDashboard,
  MdZoomOutMap,
  MdZoomIn,
  MdSettings,
  MdClose,
  MdMenu,
  MdInsertDriveFile,
  MdLibraryMusic,
  MdSave,
  MdVideoLibrary,
  MdArrowDropDown,
  MdPanoramaFishEye,
  MdInsertPhoto,
  MdWarning,
  MdImage,
  MdAddBox,
  MdSearch,
  MdFileDownload,
  MdArrowDropUp,
  MdCheckCircle,
  MdLabel,
  MdLabelOutline,
  MdDateRange,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
} from "react-icons/md";

import { TiDelete } from "react-icons/ti";
import { FaEye, FaEdit, FaUserCog, FaCopy, FaTrash, FaPrint } from "react-icons/fa";
import { FiCheckSquare, FiExternalLink, FiEdit, FiGrid } from "react-icons/fi";
import { BsDash } from "react-icons/bs";

const oldLibIcons = {
  email: <MdEmail />,
  xlink: <FiExternalLink />,
  password: <MdLock />,
  info: <MdInfo />,
  arrowDown: <MdKeyboardArrowDown />,
  adminDashboard: <MdSettings />,
  admin: <FaUserCog />,
  user: <MdPerson />,
  profile: <MdCardTravel />,
  logout: <MdVpnKey />,
  zoomOut: <MdZoomOutMap />,
  zoomIn: <MdZoomIn />,
  left: <MdChevronLeft />,
  right: <MdChevronRight />,
  videoCam: <MdVideocam />,
  close: <TiDelete />,
  add: <MdLibraryAdd />,
  menu: <MdMenu />,
  dashboard: <MdDashboard />,
  download: <MdFileDownload />,
  clock: <MdAccessTime />,
  arrowDropDown: <MdArrowDropDown />,
  arrowDropUp: <MdArrowDropUp />,
  search: <MdSearch />,
  close1: <MdClose />,
  image: <MdImage />,
  file: <MdInsertDriveFile />,
  music: <MdLibraryMusic />,
  video: <MdVideoLibrary />,
  photo: <MdInsertPhoto />,
  copy: <FaCopy />,
  delete: <FaTrash />,
  print: <FaPrint />,
  checkCircle: <MdCheckCircle />,
  save: <MdSave />,
  drafts: <FiEdit />,
  published: <FiExternalLink />,
  submitted: <FiCheckSquare />,
  error: <MdAddCircle />,
  warning: <MdWarning />,
  total: <FiGrid />,
  edit: <FaEdit />,
  dash: <BsDash />,
  status: <MdContentPaste />,
  addBox: <MdAddBox />,
  circle: <MdPanoramaFishEye />,
  eye: <FaEye />,
  label: <MdLabel />,
  labelOutline: <MdLabelOutline />,
  date: <MdDateRange />,
  time: <MdAccessTime />,
  radioChecked: <MdRadioButtonChecked />,
  radioUnchecked: <MdRadioButtonUnchecked />,
};

class IconAria extends Component {
  id = Math.random();

  renderIcon = () => {
    const { iconId, clicked, ...props } = this.props;

    // set icon props
    const defaultProps = {
      id: this.id,
      onClick: clicked,
      "aria-hidden": true,
      role: "presentation",
      tabIndex: "-1",
      width: "inherit",
      height: "inherit",
    };

    const newProps = { ...defaultProps, ...props };

    // add props to the react element
    return React.cloneElement(oldLibIcons[iconId], newProps);
  };

  render() {
    return this.renderIcon();
  }
}

export default IconAria;
