import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const TableBtn = (props) => {
  return (
    props.link ?
    <Link to="#empty" className={'button button__actionSmall ' + props.addClass}>
      <span className="button__actionSmall-icon">{props.children}</span>
      <span><FormattedMessage id={props.text}/></span>
    </Link>
    :
    <button onClick={props.clicked}  className={'button button__actionSmall ' + props.addClass}>
      <span className="button__actionSmall-icon">{props.children}</span>
      <span><FormattedMessage id={props.text}/></span>
      <span className={"button__actionSmall-loadIcon " + (props.loading ? '' : 'u-hide')}>
        <div className="circleLoader-small"></div>
      </span>
    </button>
  );
};

TableBtn.propTypes = {
  addClass: PropTypes.string,
  text: PropTypes.string
}

export default TableBtn;