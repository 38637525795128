import { useState, useEffect } from "react";

const useScroll = () => {
  const [screenSize, setScreenSize] = useState();

  const handleScreenSize = e => {
    setScreenSize({ top: window.scrollY, bottom: window.scrollY + window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScreenSize);
    return () => window.removeEventListener("scroll", handleScreenSize);
  }, []);

  return screenSize;
};

export default useScroll;
