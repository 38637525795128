
import {
  GET_PROJECT_OVERVIEW_DATA,
  CLEAR_ENTRY_STEPS,
  CHECK_LIST_STEP,
  FORM_SUBMITED,
  CLEAR_ACTIVE_ENTRY_STEP,
  SAVE_FORM_SUBMISSION,
  CLEAR_ENTRY_STEP_DATA,
  SAVE_UPLOAD_DATA,
  CLEAR_UPLOAD_DATA,
  GET_ENTRY_STEPS,
  GET_CATEGORIES,
  DISPLAY_ENTRY_STEP,
  GET_OPENED_ENTRY_REQUIREMENTS,
  ADD_ENTRIES,
  SUBMISSION_DEADLINE_EXPIRED } from './types';

export const addEntryList = (entryList, tableId) => ({
  type: ADD_ENTRIES,
  payload: { data: entryList, tableId }
})

export const getOpenedEntryRequirements = (res) => ({type: GET_OPENED_ENTRY_REQUIREMENTS, payload: res.data})

export const getProjectOverview = (data) => ({
  type: GET_PROJECT_OVERVIEW_DATA,
  payload: data
})

export const saveUploadData = (id, data) => ({
  type: SAVE_UPLOAD_DATA,
  payload: {id, data}
})

export const clearUppyData = (id) => ({
  type: CLEAR_UPLOAD_DATA,
  payload: id
})

export const checkEntryStep = list => ({
  type: CHECK_LIST_STEP,
  payload: list
})

export const setEntryStepActive = entry => ({
  type: DISPLAY_ENTRY_STEP,
  payload: entry
})

export const clearEntryStepActive = () => ({
  type: CLEAR_ACTIVE_ENTRY_STEP
})

export const getEntrySteps = entrySteps => ({
  type: GET_ENTRY_STEPS,
  payload: entrySteps
})

export const getCategories = categories => ({
  type: GET_CATEGORIES,
  payload: categories
})

export const saveFormSubmission = submissin => ({
  type: SAVE_FORM_SUBMISSION,
  payload: submissin
})

export const clearEntrySteps = () => ({
  type: CLEAR_ENTRY_STEPS
})

export const onFormioSubmitDone = formSubmitRes => ({
  type: FORM_SUBMITED,
  payload: formSubmitRes
})

export const clearEntryStepData = () => ({
  type: CLEAR_ENTRY_STEP_DATA
})

export const setDeadlineExpired = () => ({
  type: SUBMISSION_DEADLINE_EXPIRED
})



