import React, { useState, useEffect, useRef } from "react";

// COMPONENTS
import LoadingSpinner from "../loaders/LoadingSpinner";

// Important: is animate is async, its first value needs to be undefined

const AnimateLayout = ({ id, children, animate, type, animationTime, addClass }) => {
  const defaultAnimationTime = animationTime || 500;
  let isAsync = useRef(false);
  let timer1 = null;
  let timer2 = null;
  let timer3 = null;
  const wrapper = useRef();
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    // check if data is async
    if (!isAsync.current && animate === undefined) isAsync.current = true;

    // if data is async
    if (isAsync.current) {
      // wait for 500 ms
      timer1 = setTimeout(() => {
        // if data didnt come
        if (!animate) {
          // show the loader
          setLoaderVisibility(true);
        } else {
          // hide the loader and animate
          showWithAnimation();
        }
      }, 500);

      if (animate) {
        clearTimeout(timer1);
        showWithAnimation();
      }
    }
    // if data is not async
    else {
      showWithAnimation();
    }
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  });

  const showWithAnimation = () => {
    setRender(true);
    timer2 = setTimeout(() => {
      if (wrapper.current) displayAnimation(wrapper.current);
    }, 100);
  };

  const displayAnimation = wrapper => {
    // add after render
    switch (type) {
      case "fadeIn":
        wrapper.style.opacity = "1";
        break;

      default:
        wrapper.style.opacity = "1";
        break;
    }
  };

  if (render)
    return (
      <div
        className={addClass ? addClass : ""}
        ref={wrapper}
        id={id}
        style={{
          opacity: "0",
          transition: `all ${defaultAnimationTime}ms ease-in-out`,
        }}
      >
        {children}
      </div>
    );
  if (loaderVisibility) return <LoadingSpinner />;
  return null;
};

export default AnimateLayout;
