import React, { useEffect, useRef } from "react";

// REDUX
import { useSelector } from "react-redux";

const ExpandableDivAnimation = ({ children }) => {
  let timeout;
  const container = useRef();
  const contentWrap = useRef();
  const app = useSelector(state => state.app);

  useEffect(() => {
    if (container && app.sizeOfTheScreen === "desktop") {
      const conDimensions = container.current.getBoundingClientRect();
      const conWrapDimensions = contentWrap.current.getBoundingClientRect();

      if (conWrapDimensions.height && conWrapDimensions.height !== 0) {
        // on load - if content wrap does not have style
        if (!contentWrap.current.getAttribute("style")) {
          container.current.style.height = `${conWrapDimensions.height}px`;
          contentWrap.current.style.height = `${conWrapDimensions.height}px`;
        } else {
          contentWrap.current.style.height = `auto`;
          setTimeout(() => {
            container.current.style.height = `${conWrapDimensions.height}px`;
          }, 100);
        }
      }
    }
  });

  return (
    <div ref={container} style={{ transition: "height .5s", overflow: "hidden" }}>
      <div ref={contentWrap}>{children}</div>
    </div>
  );
};

export default ExpandableDivAnimation;
