import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// COMPONENTS
import ContentGenerator from '../components/layout/ContentGenerator';
import MainHeaderNav from '../components/navigations/MainHeaderNav';
import MainBoxNav from '../components/navigations/MainBoxNav';
import FooterMain from '../components/footers/FooterMain';

// HOC
import AppHOC from '../hoc/AppHOC';

export class Info extends Component {

  render() {
    return (
    <AppHOC>
    <div id="page">
      <MainHeaderNav/>
      <MainBoxNav/>
      <div className="rowCon rowCon__withMargin" id="main_content">
        <div className="dashboardMain">
          <ContentGenerator url="/api/pages/info"/>
        </div>
      </div>
      <FooterMain/>
    </div>
    </AppHOC>
    );
  }
}

Info.propTypes = {
  intl: PropTypes.object
}


export default injectIntl(Info);
