import React, { useState, useEffect } from "react";

// COMPONENTS
import Layout from "./Layout";
import Icon from "../icons/Icon";
import Title from "../text/Title";

// HOOKS
import useLocalStorage from "../../hooks/useLocalStorage";

// FUNCTIONS
import ParseHTML from "../../functions/ParseHTML";

const ToggleLayout = ({ children, initialLayout, id, title, hideBtns, getClickedItem }) => {
  const _id = "layoutStyle_" + id;
  const defaultValue = initialLayout || "cards";
  const style = {
    cards: { id: "cards", grid: true, gridList: true },
    list: { id: "list", flex1: true, marginBottomSmall: true },
  };
  const [layoutStyle, setLayoutStyle] = useState(style[defaultValue]);
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(_id, defaultValue);

  // set layout on load based on localStorage value or initialLayout value
  useEffect(() => {
    if (!id) {
      console.error("Please add a unique id to ToggleLayout component!");
    } else {
      setLayoutStyle(style[localStorageValue]);
    }
  }, [id, setLayoutStyle, localStorageValue]);

  const handleLayoutStyleIconClick = type => {
    setLocalStorageValue(type);
    setLayoutStyle(style[type]);

    if (getClickedItem) getClickedItem(type);
  };

  return (
    <div className="__o">
      <Layout flex spaceBetween>
        <Title addClass="__ot">{title || <ParseHTML html="&nbsp;" />}</Title>
        {!hideBtns && (
          <Layout flex addClass="__om">
            <button className="__ob" onClick={() => handleLayoutStyleIconClick("cards")}>
              <Icon
                iconId="cards"
                addClass={`__oi ${layoutStyle.id === "cards" ? "c1 __oa" : ""}`}
              />
            </button>
            <button className="__ob" onClick={() => handleLayoutStyleIconClick("list")}>
              <Icon iconId="list" addClass={`__oi ${layoutStyle.id === "list" ? "c1 __oa" : ""}`} />
            </button>
          </Layout>
        )}
      </Layout>
      <Layout {...layoutStyle}>{children}</Layout>
    </div>
  );
};

export default ToggleLayout;
