import React, { useEffect, useRef } from "react";

// functions
import generateUniqueID from "../../functions/generateUniqueID";

const ToggleVisibility = ({ children, toggle, animationTime, slide, addClass }) => {
  const isMounted = useRef(false);
  const _id = generateUniqueID();
  let timeout1 = null;
  let timeout2 = null;
  let timeout3 = null;
  const wrapper = useRef();
  const getAnimationTime = animationTime || 300;

  // toggle
  useEffect(() => {
    if (isMounted.current) {
      if (toggle) {
        if (slide) slideDown();
        else show();
      }
      if (!toggle) {
        if (slide) slideUp();
        else hide();
      }
    }
    isMounted.current = true;
  }, [toggle]);

  const show = () => {
    wrapper.current.style.display = "block";

    timeout1 = setTimeout(() => {
      wrapper.current.style.opacity = "1";
    }, 100);
  };

  const hide = () => {
    wrapper.current.style.opacity = "0";

    timeout2 = setTimeout(() => {
      wrapper.current.style.display = "none";
    }, getAnimationTime);
  };

  const slideDown = () => {
    wrapper.current.style.display = "block";

    timeout1 = setTimeout(() => {
      wrapper.current.style.opacity = "1";
      wrapper.current.style.maxHeight = "1000px";
    }, 100);
    timeout3 = setTimeout(() => {
      wrapper.current.style.maxHeight = "initial";
      wrapper.current.style.height = "auto";
    }, getAnimationTime);
  };

  const slideUp = () => {
    wrapper.current.style.opacity = "0";
    wrapper.current.style.maxHeight = "0px";

    timeout2 = setTimeout(() => {
      wrapper.current.style.display = "none";
    }, getAnimationTime);
  };

  // clear timeouts
  useEffect(() => {
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
    };
  });

  return (
    <div
      ref={wrapper}
      data-name="ToggleVisibility"
      id={`toggleDivContainer_${_id}`}
      className={addClass}
      style={{
        transition: `all ${getAnimationTime}ms ease-in-out`,
        display: "none",
        opacity: "0",
        maxHeight: slide ? 0 : "none",
      }}
    >
      {children}
    </div>
  );
};

export default ToggleVisibility;
