import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import AlphaLogo from "../../img/logo.png";

// REDUX
import { connect } from "react-redux";
import { setLanguage } from "../../actions/appActions";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

export class FooterMain extends Component {
  state = {
    currentLocale: this.props.currentApp.chosen_locale,
    locales: this.props.currentApp.locales,
  };

  componentDidMount() {}

  changeLanguage = newLocale => {
    this.setState({ currentLocale: newLocale }, () => {
      AA_GLOBAL.cookies.set("lang", newLocale, { path: "/" });
      window.location.reload();
      // this.props.history.push(window.location.pathname);
      // this.props.setLanguage(newLocale);
    });
  };

  render() {
    const { currentLocale, locales } = this.state;

    return (
      <footer className="footer">
        <div className="footer__right">
          <a
            href="https://www.alpha-awards.com/"
            className="footer__right-link"
            data-test="AA_link"
            target="_black"
          >
            <span>
              <FormattedMessage id="powerdBy" />
            </span>
            <img
              src={AlphaLogo}
              alt="alpha awards"
              className="footer__right-logo"
              data-test="AA_logo"
            />
          </a>
        </div>
        <div className="footer__left">
          {/* HIDE THIS FOR PRESENTATION */}
          <div>
            {/* TODO: not ready yet
            <div className="u-hide">
              <a href="#empty" className="footer__left-a" data-test="FAQ"><FormattedMessage id="faq"/></a> -
              <a href="#empty" className="footer__left-a" data-test="Imprint"><FormattedMessage id="imprint"/></a>
            </div>
            */}
            <div className="footer__lang">
              {locales &&
                locales
                  .map(locale => ({
                    locale,
                    label: locale.split("-")[0].toUpperCase(),
                    isChosen: currentLocale === locale,
                  }))
                  .map((item, i) => (
                    <span key={item.locale}>
                      {item.isChosen ? (
                        <span style={{ cursor: "default" }} className="footer__lang-btnActive">
                          {item.label}
                        </span>
                      ) : (
                        <span
                          tabIndex="0"
                          onClick={() => this.changeLanguage(item.locale)}
                          onKeyDown={e => e.key === "Enter" && this.changeLanguage(item.locale)}
                        >
                          {item.label}
                        </span>
                      )}
                      {i + 1 !== locales.length && " | "}
                    </span>
                  ))}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  currentApp: state.app.info.apps[state.app.info.default_app],
});

export default connect(mapStateToProps, { setLanguage })(FooterMain);
