import React from 'react';
import PropTypes from 'prop-types';

const MainBtn = (props) => {
  return (
    <button
      className={'button button__main button--mainClr ' + (props.addClass ? props.addClass : '')}
      onClick={props.clicked}
      form={props.form}
      type={props.type}>
      {props.children}
    </button>
  );
};

MainBtn.propTypes = {
  addClass: PropTypes.string,
  type: PropTypes.string
}

export default MainBtn;