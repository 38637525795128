// new global object
import store from "../store";
import Cookies from "universal-cookie";

const GLOBALS = {
  cookies: new Cookies(),
  reduxStore: store,
  screenSizeChangedFor: 0,
};

export default GLOBALS;
