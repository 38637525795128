//** NEW TEMPLATE
import React, { useEffect, useState } from "react";
import { renderLangString } from "../../languageStrings";

// COMPONENTS
import Icon from "../icons/Icon";

const DisplayScore = ({ id, score, addClass, style, savedStatus }) => {
  const [state, setState] = useState(0);

  useEffect(() => {
    if (savedStatus === "saving") {
      setState(1);
    } else if (savedStatus === "saved") {
      setState(2);
    }

    const timeout = setTimeout(() => {
      setState(0);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [savedStatus]);

  return (
    <div id={id} className={`__z ${addClass}`} style={style || {}} data-name="DisplayScore">
      {state === 1 && <span className="__zs">{renderLangString({ id: "$_d2" })}</span>}
      {state === 2 && (
        <span className="__zs">
          <Icon iconId="checkLine" addClass="__zi" />
          {renderLangString({ id: "$_d3" })}
        </span>
      )}
      {state === 0 && (
        <span className="__zs">
          <Icon iconId="cup" addClass="__zi" />
          {score || "- "}
        </span>
      )}
    </div>
  );
};

export default DisplayScore;
