import {
  SHOW_MOB_MENU,
  HIDE_MOB_MENU,
  TOGGLE_MODAL,
  SUBMISSION_DEADLINE_EXPIRED,
  RESET_STORE_PROPS,
  GET_LOGIN_DATA,
  GET_INFO,
  SAVE_SESSION_AND_INFO,
  SET_PAGE_WIDTH,
  HIDE_MAIN_MENU,
  SHOW_MAIN_MENU,
  SET_SCREEN_SIZE,
  GALLERY_FULLSCREEN_TOGGLE,
  HIDE_MAIN_MENU_ON_TOP,
  SHOW_MAIN_MENU_ON_TOP,
  SET_HELPER_NAV_AS_PRESENT,
  SET_HELPER_NAV_AS_NOT_PRESENT,
  SHOW_HELPER_NAV_ON_TOP,
  HIDE_HELPER_NAV_ON_TOP,
  TOGGLE_HELLPER_SIDE_NAV,
  LOGOUT,
  SET_LANGUAGE,
} from "../actions/types";

const initialState = {
  // lang: "de-DE",
  mobMenuDisplay: false,
  isMainMenuVisible: true,
  isMainMenuVisibleOnTop: false,
  isHelperMenuPresent: false,
  isHelperMenuVisibleOnTop: false,
  modalBackVisibility: false,
  loaderVisiblity: false,
  gallery: {
    fullScreen: false,
  },
  //helperSideNavToggle: true, // remove this when helper nav is done
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_SCREEN_SIZE:
      return {
        ...state,
        sizeOfTheScreen: action.payload,
      };
    case SAVE_SESSION_AND_INFO:
      return {
        ...state,
        info: action.info,
      };
    case GET_INFO:
      return {
        ...state,
        info: action.payload,
        EndsAt: action.payload.apps[action.payload.default_app].modules.submitter.end_at,
        counterActive: action.payload.apps[action.payload.default_app].modules.submitter.active,
      };
    case GET_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case SUBMISSION_DEADLINE_EXPIRED:
      return {
        ...state,
        counterActive: false,
      };
    case SHOW_MOB_MENU:
      return {
        ...state,
        mobMenuDisplay: true,
        modalBackVisibility: true,
      };
    case HIDE_MOB_MENU:
      return {
        ...state,
        mobMenuDisplay: false,
        modalBackVisibility: false,
      };
    case SET_PAGE_WIDTH:
      return {
        ...state,
        screenSize: action.payload,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modalBackVisibility: !state.modalVisiblity ? true : false,
        modalVisiblity: !state.modalVisiblity,
        mobMenuDisplay: false,
      };
    case TOGGLE_HELLPER_SIDE_NAV:
      return {
        ...state,
        helperSideNavToggle: !state.helperSideNavToggle,
      };
    case "SHOW_HELLPER_SIDE_NAV":
      return {
        ...state,
        helperSideNavToggle: true,
      };
    case "HIDE_HELLPER_SIDE_NAV":
      return {
        ...state,
        helperSideNavToggle: false,
      };
    case "PUT_HELLPER_SIDE_NAV_ON_TOP":
      return {
        ...state,
        helperSideNavOnTop: true,
      };
    case RESET_STORE_PROPS:
      return {
        ...state,
        mobMenuDisplay: false,
        modalBackVisibility: false,
        colorAlert: false,
        loaderVisiblity: false,
      };
    case GALLERY_FULLSCREEN_TOGGLE:
      return {
        ...state,
        gallery: {
          fullScreen: !state.gallery.fullScreen,
        },
      };
    case "SET_APP_TITLE":
      return {
        ...state,
        appTitle: action.payload,
      };
    case "HIDE_APP_TITLE":
      return {
        ...state,
        appTitle: null,
      };
    case "SET_COUNTER":
      return {
        ...state,
        counterEndsAt: action.payload,
      };

    case "ERROR_POPUP":
      return {
        ...state,
        errorPopup: true,
      };

    // new template
    case HIDE_MAIN_MENU:
      return {
        ...state,
        isMainMenuVisible: false,
      };
    case SHOW_MAIN_MENU:
      return {
        ...state,
        isMainMenuVisible: true,
      };
    case SHOW_MAIN_MENU_ON_TOP:
      return {
        ...state,
        isMainMenuVisibleOnTop: true,
      };
    case HIDE_MAIN_MENU_ON_TOP:
      return {
        ...state,
        isMainMenuVisibleOnTop: false,
      };
    case SET_HELPER_NAV_AS_PRESENT:
      return {
        ...state,
        isHelperMenuPresent: true,
      };
    case SET_HELPER_NAV_AS_NOT_PRESENT:
      return {
        ...state,
        isHelperMenuPresent: false,
      };
    case SHOW_HELPER_NAV_ON_TOP:
      return {
        ...state,
        isHelperMenuVisibleOnTop: true,
      };
    case HIDE_HELPER_NAV_ON_TOP:
      return {
        ...state,
        isHelperMenuVisibleOnTop: false,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
