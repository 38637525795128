import React from 'react';

const ScrollableContent = (props) => {
  return (
    <div className={(props.height ? "scrollableContent " : ' nonScrollableContent')} style={props.height ? {height: props.height} : null}>
      {props.children}
    </div>
  );
};

export default ScrollableContent;