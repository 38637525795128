//** NEW TEMPLATE
import React, { useState, useEffect } from "react";
import renderLangString from '../../languageStrings'

// COMPONENTS
import AllIcons from "./allIcons";

const Icon = ({ iconId, addClass, id, style, clicked }) => {
  const noIconMsg =
    process.env.NODE_ENV === "development" ? (
      <span
        title={"Icon name [" + iconId + "] does not exist in allIcons.js!"}
        style={{ color: "#000" }}
      >
        IC
      </span>
    ) : (
      ""
    );

  const [icon, setIcon] = useState(noIconMsg);

  // set icon on render
  useEffect(() => {
    if (!AllIcons[iconId]) return;

    // set icon props
    const props = {
      children: AllIcons[iconId].props.children,
      className: `ic ${addClass}`,
      id: id ? id : "",
      "data-id": iconId,
      focusable: clicked ? "true" : "false",
      tabIndex: clicked ? "0" : "-1",
      onClick: clicked,
      "aria-label": renderLangString({id: '$_1'}),
      viewBox: AllIcons[iconId].props.viewBox,
      xmlns: "http://www.w3.org/2000/svg",
      style: style ? style : {},
    };

    // add props to the react element
    const createdIcon = React.cloneElement(AllIcons[iconId], props);

    setIcon(createdIcon);
  }, [iconId, addClass, id, style]);

  return icon;
};

export default Icon;
