import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdContentPaste /*, MdMoodBad, MdMood*/ } from "react-icons/md";

// COMPONENTS
import IconAria from "../icons/IconAria";

class Status extends Component {
  state = { showAs: "normal" };

  static map = {
    // "created": 0,
    // "updated": 0
    deleted: 1,
    undeleted: 1,
    published: 2,
    confirmed: 2,
  };

  static list = ["normal", "alert", "success"];

  UNSAFE_componentWillReceiveProps(nextProps) {
    const showAs = Status.list[Status.map[nextProps.status.code] || 0];
    this.setState({ showAs });
  }

  render() {
    return (
      <div className={`statusDisplay statusDisplay--${this.state.showAs}`}>
        <IconAria iconId="status" data-test="icon" className="statusDisplay__icon" />
        <div className="statusDisplay__name" data-test="statusDisplay">
          {this.props.status.label}
        </div>
      </div>
    );
  }
}

Status.propTypes = {
  status: PropTypes.object.isRequired,
};

export default Status;
