import React, { Component } from "react";
import PropTypes from "prop-types";

// COMPONENTS
import ContentLoader from "../components/loaders/ContentLoader";

class AnimateHOC extends Component {
  state = {
    animate: false,
    loader: false,
    animationDone: false,
  };

  _hasResponse = false;

  componentDidMount() {
    const $this = this;
    $this._isMounted = true;

    if (this.props.animate) {
      this._hasResponse = true;

      if ($this._isMounted) this.setState({ animate: true, loader: false });

      this.timeoutRemoveAnimation = setTimeout(() => {
        if ($this._isMounted) {
          $this.setState({ animationDone: true }, () => {
            clearTimeout($this.timeoutRemoveAnimation);
          });
        }
      }, 1000);
    }
    // wait for response for 500ms
    // if response is not given in 500ms show the content loader
    this.timeout = setTimeout(() => {
      if (!$this._hasResponse && $this._isMounted) {
        $this.setState({ loader: $this.props.skipAnimation ? false : true }, () => {
          clearTimeout($this.timeout);
        });
      } else {
        clearTimeout($this.timeout);
      }
    }, 500);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const $this = this;

    if (nextProps.animate) {
      $this._hasResponse = true;

      if ($this._isMounted) $this.setState({ animate: true, loader: false });

      $this.timeoutRemoveAnimation1 = setTimeout(() => {
        if ($this._isMounted) $this.setState({ animationDone: true });
        clearTimeout($this.timeoutRemoveAnimation);
      }, 1000);
    }
  }

  generateClass = animate => {
    let generatedClass = "";

    if (!this.props.skipAnimation) {
      animate ? (generatedClass = "a-slideUp") : (generatedClass = "u-hide");
    }

    return generatedClass;
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutRemoveAnimation);
    clearTimeout(this.timeoutRemoveAnimation1);
    this._isMounted = false;
  }

  render() {
    if (!this.props.animate) return null;
    return (
      <div className={this.props.addClass ? this.props.addClass : ""}>
        {this.state.loader ? <ContentLoader full /> : null}
        <div className={!this.state.animationDone ? this.generateClass(this.state.animate) : ""}>
          <div style={this.state.loader ? { opacity: 0 } : { opacity: 1 }}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

AnimateHOC.propTypes = {
  animate: PropTypes.any,
  skipAnimation: PropTypes.any,
  addClass: PropTypes.string,
};

export default AnimateHOC;
