import React from "react";

// COMPONENTS
import LoginCompanyInfo from "../components/display/LoginCompanyInfo";
import FooterMain from "../components/footers/FooterMain";
import ResetPasswordForm from "../containers/ResetPasswordForm";

// HOC
import AppHOC from "../hoc/AppHOC";

const ResetPassword = ({ loginData }) => (
  <AppHOC>
    <div className="LoginPage">
      <div>
        <div className="loginMain">
          {loginData && loginData.company_info && (
            <LoginCompanyInfo data={loginData.company_info} />
          )}
          <ResetPasswordForm />
        </div>
      </div>
      <FooterMain />
    </div>
  </AppHOC>
);

export default ResetPassword;
