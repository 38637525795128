import React, { Component } from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import { withRouter } from "react-router-dom";

// COMPONENTS
import ContentContainer from "../layout/ContentContainer";
import Btn from "../buttons/Btn";
import Modal from "../modals/Modal";

// HOC
import AnimateHOC from "../../hoc/AnimateHOC";

// VENDORS
import fadeInOut from "../../vendors/fadeInOut";

// FUNCTIONS
import AJAX from "../../functions/ajax";

class ContentGenerator extends Component {
  _id = Math.random();

  state = {
    loader: false,
  };

  UNSAFE_componentWillMount() {
    this.getData(this.props.data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getData(nextProps.data);
  }

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  getData = data => {
    // if there is no data get it from the server
    if (!data) {
      // show loader
      this.setState({ loader: true });

      AJAX()
        .get(this._id, this.props.url)
        .then(res => {
          if (!res || !res.data || !res.data.page) return;
          const { page } = res.data;
          page.visible = true;
          page.data = page.body;
          delete page.body;
          this.setState({ data: page, loader: false });
        });
    } else {
      // if there is data passed to this component
      this.setState({ data: data, loader: false });
    }
  };

  handleButton = btn => {
    this.setState({ modalData: btn.modal, btnClicked: btn }, () => {
      if (btn.enabled) {
        const el = document.getElementById("a23r2t24e5yhy353rawf");
        if (el) {
          fadeInOut(el, "500", true);
        }
      }
    });
  };

  confirmModal = () => {
    if (this.state.btnClicked && this.state.btnClicked.enabled) {
      AJAX()
        .contentGeneratorConfimModal(
          this._id,
          this.state.btnClicked.method,
          this.state.btnClicked.api_url,
          {}
        )
        .then(function (res) {
          if (res) window.location.reload();
        });
    }
  };

  renderContent = content => {
    let { format, data } = content;

    switch (format) {
      case "md":
      case "markdown":
        format = "md";
        content = <Markdown source={data} />;
        break;
      case "html":
        // NB: cannot use the object tag because Chrome doesn't render it correctly.
        // return <object type="text/html"><div dangerouslySetInnerHTML...></div></object>;
        content = <div dangerouslySetInnerHTML={{ __html: data }}></div>;
        break;
      default:
        format = "unknown";
        content = "Unknown content format: " + data;
    }

    return <div className={`cc_${format}`}>{content}</div>;
  };

  rendersBtns = () => this.state.data.actions.map((btn, i) => this.renderButton(btn, i));

  renderButton = (btn, i) => {
    return (
      <Btn
        key={i}
        clicked={() => this.handleButton(btn)}
        addClass={
          "button button__main-form dashboardMain__stepForms-btns " +
          (!btn.enabled ? "button__main-form-disabled" : "") +
          (btn.key === "unpublish" || btn.key === "unsubmit" ? " BDanger " : "")
        }
        text={btn.label}
      />
    );
    // <button
    //         key={i}
    //         onClick={() => this.handleClick(btn)}
    //         className={"btn btn__main-form dashboardMain__stepForms-btns " + (!btn.enabled ? "btn__main-form-disabled" : '')}>{btn.label}</button>
  };

  handleClick = btn => {};

  render() {
    // if(this.state.loader) { return <ContentLoader full hide={this.props.hideLoader}/> }
    const body = this.state.data;

    if (body && body.visible) {
      return (
        <AnimateHOC skipAnimation={!body.title} animate={!this.state.loader}>
          <div className={"contentGen " + (!body.title ? "contentGen__noTitle" : "")}>
            <ContentContainer
              contentAlerter={body.alert}
              title={body.title}
              titleType={this.props.titleType}
            >
              {body && this.renderContent(body)}
              {body.actions && body.actions.length > 0 && (
                <div className="contentGen__btns">{this.rendersBtns()}</div>
              )}
            </ContentContainer>
          </div>

          {this.state.modalData ? (
            <Modal
              id="a23r2t24e5yhy353rawf"
              promptModal
              yesMsg={this.state.modalData.yes ? this.state.modalData.yes : "default"}
              noMsg={this.state.modalData.no ? this.state.modalData.no : "default"}
              yes={this.confirmModal}
              msg={this.state.modalData.content ? this.state.modalData.content : "default"}
            />
          ) : null}
        </AnimateHOC>
      );
    } else {
      return null;
    }
  }
}

ContentGenerator.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
};

export default withRouter(ContentGenerator);
