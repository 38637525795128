import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

// FUNCTIONS
import AJAX from "../functions/ajax";

// REDUX
import { connect } from "react-redux";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";

import { NewEntryForm, onNewEntryAdd } from "../views/NewEntry";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

export class CopyEntry extends Component {
  _id = Math.random();
  state = {};

  componentDidMount() {
    AJAX()
      .getEntryStepList(this._id + "/menu", this.props, this.props.match.params.id)
      .then(res => res?.data?.copy_url && this.setState({ copyURL: res.data.copy_url }));
  }

  render() {
    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain">
              <AnimateHOC animate={this.props.user}>
                <NewEntryForm
                  key={this.state.copyURL}
                  {...this.props}
                  url={this.state.copyURL}
                  onSuccess={res => onNewEntryAdd(res, this.props)}
                />
              </AnimateHOC>
            </div>
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

CopyEntry.propTypes = {
  user: PropTypes.object,
  info: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.user,
  info: state.app.info,
});

export default injectIntl(connect(mapStateToProps)(CopyEntry));
