import React, { Component } from "react";
import { Player, ControlBar, ClosedCaptionButton } from "video-react";
import { Link, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import ReactAudioPlayer from "react-audio-player";

//COMPONENTS
import Popover from "../popovers/Popover";
import IconAria from "../icons/IconAria";

import imagePholderJPG from "../../img/placeholders/image_placeholder.jpg";
import videoPholderJPG from "../../img/placeholders/video_placeholder.jpg";
import audioPholderJPG from "../../img/placeholders/audio_placeholder.jpg";
import filePholderJPG from "../../img/placeholders/file_placeholder.jpg";

// FUNCTIONS
import { classes } from "../../functions/utils";

// REDUX
import { connect } from "react-redux";
// this function records the state of the full screen gallery
// so you can isolate the swipe event if there are multiple swipe event on your app
import { toggleGalleryFullscreen } from "../../actions/appActions";

class BigGallery extends Component {
  state = {
    gallery: [],
    display: {},
    thumbnailSlider: 0,
    galleryMouseOver: false,
    thumbnailsContainerWidth: 0,
    allThumbnailsWidth: 0,
    thumbnailsScrollOffset: 0,
    contentType: null,
    expand: false,
    numOfItems: 0,
    itemPosition: 0,
    touchStart: 0,
    zoomImageToggle: false,
    magnifierZoom: 3,
    swipeLength: 30,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      gallery: this.props.data,
      display: this.props.data[0],
      numOfItems: this.props.data.length,
      stopId: this.props.data[0].requirement_id,
    });
  }

  componentDidMount() {
    document.addEventListener("keydown", this.slideImageOnKeyPress, true);
    window.addEventListener("scroll", this.handleScroll);
    this.addAccessibilityToTheVideo();
  }

  componentDidUpdate() {
    this.addAccessibilityToTheVideo();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.slideImageOnKeyPress, true);
    window.removeEventListener("scroll", this.handleScroll);
  }

  addAccessibilityToTheVideo() {
    const video = document.querySelector(".video-react-video");
    if (video) {
      video.setAttribute("aria-label", "video");
    }
  }

  imageMagnifier = () => {
    const imgID = "gallImage738";

    this._img = document.getElementById(imgID);

    // create magnifier glass
    this._glass = document.createElement("DIV");
    this._glass.setAttribute("class", "img-magnifier-glass");
    this._glass.setAttribute("id", "img-magnifier-643");
    this._img.parentElement.insertBefore(this._glass, this._img);

    this._glass.style.backgroundImage = "url('" + this._img.src + "')";
    this._glass.style.backgroundRepeat = "no-repeat";
    this._glass.style.backgroundSize =
      this._img.clientWidth * this.state.magnifierZoom +
      "px " +
      this._img.height * this.state.magnifierZoom +
      "px";
    this._bw = 3;
    this._w = this._glass.offsetWidth / 2;
    this._h = this._glass.offsetHeight / 2;

    this._glass.addEventListener("mousemove", this.moveMagnifier);
    this._img.addEventListener("mousemove", this.moveMagnifier);
  };
  moveMagnifier = e => {
    let pos, x, y;
    // prevent any other actions that may occur when moving over the image
    e.preventDefault();

    // get the cursor's x and y
    pos = this.getCursorPos(e);
    x = pos.x;
    y = pos.y;

    // prevent the magnifier glass from being positioned outside the image
    if (x > this._img.width - this._w / this.state.magnifierZoom) {
      x = this._img.width - this._w / this.state.magnifierZoom;
    }
    if (x < this._w / this.state.magnifierZoom) {
      x = this._w / this.state.magnifierZoom;
    }
    if (y > this._img.height - this._h / this.state.magnifierZoom) {
      y = this._img.height - this._h / this.state.magnifierZoom;
    }
    if (y < this._h / this.state.magnifierZoom) {
      y = this._h / this.state.magnifierZoom;
    }

    // set the position of the magnifier glass
    // this._glass.style.left = (x - this._w) + "px";
    // this._glass.style.top = (y - this._h) + "px";
    this._glass.style.left = 0 + "px";
    this._glass.style.bottom = 0 + "px";

    // display what the magnifier glass sees
    this._glass.style.backgroundPosition =
      "-" +
      (x * this.state.magnifierZoom - this._w + this._bw) +
      "px -" +
      (y * this.state.magnifierZoom - this._h + this._bw) +
      "px";
  };
  getCursorPos = e => {
    var a,
      x = 0,
      y = 0;
    e = e || window.event;

    // get the x and y positions of the image
    a = this._img.getBoundingClientRect();

    // calculate the cursor x and y coordinates, relative to the image
    x = e.pageX - a.left;
    y = e.pageY - a.top;

    // consider any page scrolling
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  };

  displayItem = item => {
    this.removeManifier();
    const index = this.state.gallery.findIndex(obj => obj.id === item.id);

    this.setState({
      display: item,
      itemPosition: index,
      contentType: item.type,
      stopId: item.requirement_id,
      zoomImageToggle: false,
    });
  };

  expand = () => {
    this.setState({ expand: !this.state.expand }, () => {
      // if the gallery is expended - add some height to the body
      // so scroll event on thumbnails will have enough scroll
      // to move the thumbnail container to the end
      if (this.state.expand) {
        // put page scoller to the top
        window.scrollTo(0, 0);

        // set thubnail container width
        const thumbnailsContainerWidth = document.getElementById("galleryThumbnails").offsetWidth;

        // calculate the width of all thumbnails
        const thumbnails = document.querySelectorAll(".bigGallery__item");
        let allThumbnailsWidth = 0;

        for (let i = 0; i < thumbnails.length; i++) {
          allThumbnailsWidth += thumbnails[i].offsetWidth + 1;
        }

        document.getElementsByTagName("body")[0].style.height = allThumbnailsWidth + "px";

        this.setState({
          thumbnailsContainerWidth,
          allThumbnailsWidth,
          thumbnailsScrollOffset: allThumbnailsWidth - thumbnailsContainerWidth,
        });
      } else {
        document.getElementsByTagName("body")[0].style.height = "auto";
      }
    });
  };

  slideImage = direction => {
    this.removeManifier();

    if (direction === "right") {
      this.setState(
        {
          itemPosition:
            (this.state.itemPosition === this.state.numOfItems - 1 ? -1 : this.state.itemPosition) +
            1,
        },
        () => {
          this.setState({
            display: this.state.gallery[this.state.itemPosition],
            contentType: this.state.gallery[this.state.itemPosition].type,
            zoomImageToggle: false,
          });
        }
      );
    } else {
      this.setState(
        {
          itemPosition:
            (this.state.itemPosition === 0 ? this.state.numOfItems : this.state.itemPosition) - 1,
        },
        () => {
          this.setState({
            display: this.state.gallery[this.state.itemPosition],
            contentType: this.state.gallery[this.state.itemPosition].type,
            zoomImageToggle: false,
          });
        }
      );
    }
  };

  imgDoubleClick = () => {
    if (this.state.display.type === "image") {
      this.setState({ expand: !this.state.expand });
    }
  };

  slideImageOnKeyPress = e => {
    if (e.keyCode === 37) {
      this.slideImage("left");
    } else if (e.keyCode === 39) {
      this.slideImage("right");
    } else if (e.keyCode === 27) {
      this.setState({ expand: false, zoomImageToggle: false });
      this.removeManifier();
      document.getElementsByTagName("body")[0].style.height = "auto";
      this.props.toggleGalleryFullscreen();
    }
  };

  toggleZoom = () => {
    this.setState({ zoomImageToggle: !this.state.zoomImageToggle }, () => {
      this.removeManifier();
      if (this.state.zoomImageToggle && this.state.display.type === "image") this.imageMagnifier();
    });
  };

  removeManifier = () => {
    var elem = document.querySelector("#img-magnifier-643");
    if (elem) elem.parentNode.removeChild(elem);
  };

  // handleImageMouseEnter = (e) => {
  //   console.log(e.target)
  //   console.log('mouse enter')
  // }

  // handleImageMouseLeave = () => {
  //   console.log('mouse leave')
  // }

  handleImageZoomIconClick = () => {};

  setSwipeStartPosition = e => {
    this.setState({ touchStart: e.touches[0].clientX });
  };

  swipeToggleMobMenu = e => {
    if (this.state.expand) {
      let touchEndDone = e.changedTouches[0].clientX;
      if (this.state.touchStart > touchEndDone + this.state.swipeLength) {
        this.slideImage("right");
      } else if (this.state.touchStart < touchEndDone - this.state.swipeLength) {
        this.slideImage("left");
      }
    }
  };

  galleryMouseOver = () => {
    this.setState({ galleryMouseOver: true });
  };
  galleryMouseOut = () => {
    this.setState({ galleryMouseOver: false });
  };

  handleScroll = () => {
    if (this.state.expand) {
      if (this.state.galleryMouseOver) {
        if (window.pageYOffset < this.state.thumbnailsScrollOffset) {
          document.getElementById("galleryThumbnails").style.left = -window.pageYOffset + "px";
        }
      }
    }
  };

  thumbnailPlaceholder = item =>
    ({
      audio: audioPholderJPG,
      video: videoPholderJPG,
      image: imagePholderJPG,
    }[item.type] || filePholderJPG);

  static MediaIcon = type => {
    const Icon = {
      audio: "audio",
      video: "video",
    }[type];

    return Icon ? <IconAria iconId={Icon} className={"bigGallery__item-imgLink-i"} /> : null;
  };

  renderThubnails = () =>
    (this.state.gallery || []).map(item => (
      <Popover msg={item.title} key={item.id}>
        <div
          className={classes(
            "bigGallery__item",
            item.id === this.state.display.id && "bigGallery__item--active"
          )}
        >
          <div
            className="bigGallery__item-imgLink"
            onClick={() => this.displayItem(item)}
            style={{
              backgroundImage: [
                `url(${item.thumbnail_url})`,
                `url(${this.thumbnailPlaceholder(item)})`,
              ],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <BigGallery.MediaIcon type={item.type} />
          </div>
        </div>
      </Popover>
    ));

  renderDisplay = item => {
    if (item.type === "image") {
      return (
        <img
          id="gallImage738"
          src={item.detail_url}
          alt={item.title}
          className={
            "bigGallery__display-content " +
            (this.state.expand ? " bigGallery__display-content-extended" : "")
          }
        />
      );
    } else if (item.type === "video") {
      return (
        <div
          className={
            "bigGallery__videoContainer " +
            (this.state.expand ? " bigGallery__videoContainer-expended " : "")
          }
        >
          <Player playsInline poster={item.preview_url} src={item.detail_url}>
            <track
              kind="captions"
              src={`${process.env.PUBLIC_URL}/defaultCaption.vtt`}
              srcLang="en"
              label="English"
              default
            />

            <ControlBar autoHide={false}>
              <ClosedCaptionButton order={7} />
            </ControlBar>
          </Player>
        </div>
      );
    } else if (item.type === "audio") {
      return (
        <ReactAudioPlayer src={item.detail_url} controls className="bigGallery__audioPlayer" />
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.display ? (
          <div
            className={"bigGallery " + (this.state.expand ? " bigGallery--extend " : "")}
            onMouseEnter={this.galleryMouseOver}
            onMouseLeave={this.galleryMouseOut}
          >
            {/* <div className="bigGallery__title">{this.state.display.title}</div> */}
            <div
              className={
                "bigGallery__DHeader " + (this.state.expand ? "" : "bigGallery__DHeader-hide")
              }
            >
              <div className="bigGallery__DTitle">
                {this.state.expand ? <span>{this.props.title}</span> : null}
              </div>
              <div className="bigGallery__DMenu">
                {this.state.display.type === "image" ? (
                  <Popover
                    msg={this.props.intl.formatMessage({ id: "expand" })}
                    addClass="bigGallery__DOptions-zoom"
                  >
                    <div
                      className={
                        "bigGallery__DOptions " +
                        (this.state.expand ? " " : " bigGallery__DOptions-notExpanded ") +
                        (this.state.zoomImageToggle ? " bigGallery__DOptions-zoomAcitve " : "")
                      }
                      onClick={this.toggleZoom}
                    >
                      <span className="bigGallery__DOptions-expand">
                        <IconAria iconId="zoomIn" />
                      </span>
                    </div>
                  </Popover>
                ) : null}

                {!this.state.expand ? (
                  <Link
                    to={`#${this.state.stopId}`}
                    aria-label={"-" + this.props.intl.formatMessage({ id: "projectOverview" })}
                    className={
                      "bigGallery__DOptions bigGallery__DOptions-2 " +
                      (this.state.expand ? " " : "bigGallery__DOptions-notExpanded")
                    }
                  >
                    <Popover msg={this.props.intl.formatMessage({ id: "projectOverview" })}>
                      <span className="bigGallery__DOptions-expand bigGallery__DOptions-eye">
                        <IconAria iconId="eye" />
                      </span>
                    </Popover>
                  </Link>
                ) : null}

                {!this.state.expand && !this.props.blockEditing ? (
                  <Link
                    to={`/ui/submitter/edit-entry/${this.props.match.params.id}/${this.state.stopId}`}
                    aria-label={`-${this.props.intl.formatMessage({ id: "edit" })}`}
                    className={
                      "bigGallery__DOptions bigGallery__DOptions-3 " +
                      (this.state.expand ? " " : "bigGallery__DOptions-notExpanded")
                    }
                  >
                    <Popover msg={`${this.props.intl.formatMessage({ id: "edit" })}`}>
                      <span className="bigGallery__DOptions-expand bigGallery__DOptions-eye">
                        <IconAria iconId="edit" />
                      </span>
                    </Popover>
                  </Link>
                ) : null}
                <Popover msg={this.props.intl.formatMessage({ id: "expand" })}>
                  <div
                    onClick={this.props.toggleGalleryFullscreen}
                    className={
                      "bigGallery__DOptions " +
                      (this.state.expand ? " " : "bigGallery__DOptions-notExpanded")
                    }
                  >
                    <span className="bigGallery__DOptions-expand" onClick={this.expand}>
                      <IconAria iconId="zoomOut" />
                    </span>
                  </div>
                </Popover>
              </div>
            </div>
            <div
              onTouchStart={this.setSwipeStartPosition}
              onTouchEnd={this.swipeToggleMobMenu}
              onDoubleClick={this.imgDoubleClick}
              className="bigGallery__display"
            >
              {/* <div className="bigGallery__display-zoom" id="gallZoomImage">

              </div> */}

              {this.renderDisplay(this.state.display)}
              {!this.state.expand ? (
                <div className="bigGallery__fileName bigGallery__fileName-small">
                  {this.state.display.title}
                </div>
              ) : null}

              <div className="bigGallery__slider">
                <div
                  className={
                    "bigGallery__slider-right " +
                    (this.state.contentType === "audio" ? " bigGallery__slider--audio-mob " : "")
                  }
                  onClick={() => this.slideImage("right")}
                >
                  <IconAria iconId="right" />
                </div>
                <div
                  className={
                    "bigGallery__slider-left " +
                    (this.state.contentType === "audio" ? " bigGallery__slider--audio-mob " : "")
                  }
                  onClick={() => this.slideImage("left")}
                >
                  <IconAria iconId="left" />
                </div>
              </div>
            </div>
            <div className="bigGallery__items-container" id="galleryThumbnails">
              <div className={"bigGallery__fileName " + (this.state.expand ? "" : "u-hide")}>
                {this.state.display.title}
              </div>
              {this.renderThubnails()}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(withRouter(connect(null, { toggleGalleryFullscreen })(BigGallery)));
