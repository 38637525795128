import {
   LOGOUT,
   SAVE_SESSION_AND_INFO,
   SET_USER_ROLE,
   GET_SESSION  } from './types';

export const getSession = (session) => ({
  type: GET_SESSION,
  payload: session
})

export const saveSessionAndInfo = (data) => ({
  type: SAVE_SESSION_AND_INFO,
  session: data.session,
  info: data.info,
  appModule: data.appModule
})

export const logout = () => ({
  type: LOGOUT
})

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: role
})

