//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { SET_PAGE_IDS, SHOW_HELLPER_SIDE_NAV, HIDE_HELLPER_SIDE_NAV } from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";
import ErrorHOC from "../../hoc/ErrorHOC";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import Main from "../../components/main/Main";
import UserNav from "../../components/navigations/UserNav";
import AlphaTable from "../../components/tables/AlphaTable";
import AnimateLayout from "../../components/layout/AnimateLayout";
import Title from "../../components/text/Title";
import ReviewForm from "../../components/forms/ReviewForm";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import validateData from "../../functions/validateData";

// DATA MODEL
import collectionDataModel from "../../data_model/reviewer/collection/collection";
import stageCollectionsDataModel from "../../data_model/reviewer/collection/all_collections";

function Reviewer({ match, info, history }) {
  const isInitialMount = useRef(false);
  const _id = 12908319203891;
  const dispatch = useDispatch();

  const [counterData, setCounterData] = useState();
  const [collectionData, setCollectionData] = useState({});
  const [tableData, setTableData] = useState();
  const [formData, setFormData] = useState();
  const [reviewStageCollections, setReviewStageCollections] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const reduxState = useSelector(state => state);

  const init = useCallback(() => {
    getMainData();
  }, [getMainData]);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  useEffect(() => {
    if (reduxState.reviewer && reduxState.reviewer.expiresAt) {
      setCounterData(reduxState.reviewer.expiresAt);
    }
  }, [reduxState]);

  const getMainData = () => {
    AJAX()
      // .getReviewCollection(_id, reduxState, match.params.id)
      .get(_id, "https://alphaawards-aca8b.firebaseio.com/app/admin_collection.json")
      .then(res => {
        const isDataValid = validateData(
          "ReviewAdmin > Reviewer > AJAX().getMainData",
          res,
          collectionDataModel
        );

        if (isDataValid) {
          const data = res.data.collection;

          getMenuData(data.stage_id);

          setCollectionData(data);

          //@todo remove this when you get the real data
          data.cases_table.filters = [];
          setTableData(data.cases_table);
          setIsConfirmed(data.confirmed);
          dispatch({
            type: SET_PAGE_IDS,
            payload: { stage: data.stage_id, collection: data.id },
          });
        }
      });
  };

  const getMenuData = id => {
    AJAX()
      // .getReviewStage(_id, reduxState, id)
      .get(_id, "https://alphaawards-aca8b.firebaseio.com/app/admin_collection_menu.json")
      .then(res => {
        const isDataValid = validateData(
          "ReviewAdmin > Reviewer >  AJAX().getReviewStage",
          res,
          stageCollectionsDataModel
        );

        if (isDataValid) {
          setReviewStageCollections(res.data.stage.collections);
        }
      });
  };

  const handleTableLinkClick = linkID => {
    history.push(`${Routes.reviewAdminReviewer().path}/${linkID}`);
    dispatch({ type: SHOW_HELLPER_SIDE_NAV });

    //get form data
    AJAX()
      .get(_id, "https://alphaawards-aca8b.firebaseio.com/app/form_data.json")
      .then(res => {
        setFormData(res.data);
        //setReviewers(res.data);
      });
  };

  // const handleTableLinkClick = (linkID) => {
  //   //@todo remove this function when server role is fixed
  //   history.push(`${Routes.reviewAdminCase().path}/${linkID}`)
  // }

  // run on unmount
  useEffect(() => {
    return () => {
      AJAX().cancelRequest(_id);
      dispatch({ type: HIDE_HELLPER_SIDE_NAV });
    };
  }, [_id]);

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={collectionData} addClass="__n_bs">
          <Title tagName="h1" style={{ marginBottom: "3.5rem" }}>
            Reviewers
          </Title>
          {/* <ParseHTML html={collectionData.description} /> */}
          <UserNav />
          {/* <MainNav
            title={intlStringCheck("evaluationCategories")}
            data={reviewStageCollections}
            linkTo={Routes.reviewAdminCollection().path}
            activeItem={match.params.id}
          /> */}
        </AnimateLayout>
      </MainAsideNav>
      <Main counter={counterData}>
        <AnimateLayout animate={collectionData}>
          {tableData && (
            <ErrorHOC crashPage>
              <AlphaTable data={tableData} linkCallBack={handleTableLinkClick} />
            </ErrorHOC>
          )}
        </AnimateLayout>
      </Main>
      <HelperAsideNav>
        {formData && (
          <ReviewForm blockFormSubmission={true} disableForm={true} info={info} form={formData} />
        )}
      </HelperAsideNav>
    </NewAppHOC>
  );
}

export default injectIntl(withRouter(Reviewer));
