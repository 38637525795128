import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import fadeInOut from "../../vendors/fadeInOut";

// COMPONENTS
import CheckLoader from "../loaders/CheckLoader";

const slideDown = { top: "50%" };

export class Modal extends Component {
  state = {
    hideContent: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if there is loader prop and its true
    // hide modal content
    if (nextProps.loader) {
      this.setState({ hideContent: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  loaderDone = () => {
    // wait for animation to end render back the modal content
    this.timeout = setTimeout(() => {
      this.setState({ hideContent: false });
    }, 500);
    this.props.loaderDone();
  };

  hideModal = e => {
    if (e.target.getAttribute("data-modal")) {
      this.hide();
    }
  };

  hide = () => {
    if (this.props.no) {
      this.props.no();
    }
    const el = document.getElementById(this.props.id);
    fadeInOut(el, "500", false);
  };

  renderPrompt = () => {
    return (
      <div data-test="propmptModal" id={`modal_${this.props.id}`}>
        <div className="modal__msg" data-test="propmptModal_msg">
          {this.props.intl ? <FormattedMessage id={this.props.msg} /> : this.props.msg}
        </div>

        <div className="modal__btnCon">
          <button
            data-test="propmptModal_confirmBtn"
            className="button button__main-form"
            id={`yes${this.props.id}`}
            onClick={this.props.yes}
          >
            {this.props.intl ? <FormattedMessage id={this.props.yesMsg} /> : this.props.yesMsg}
          </button>
          <button
            data-test="propmptModal_cancelBtn"
            className="button button__main-form"
            onClick={this.hide}
          >
            {this.props.intl ? <FormattedMessage id={this.props.noMsg} /> : this.props.noMsg}
          </button>
        </div>
      </div>
    );
  };

  sppiner = done => {
    return <CheckLoader done={done} loaderDone={this.loaderDone} />;
  };

  render() {
    return (
      <div
        id={this.props.id}
        ref={this.props.myRef}
        data-modal={this.props.id}
        className="modal__Modalback modal--defaultCol"
        onClick={this.hideModal}
        data-test="modalBack"
      >
        <div
          className="modal"
          style={this.props.visibility ? slideDown : slideDown}
          data-test="modal"
        >
          {this.props.promptModal && !this.state.hideContent ? this.renderPrompt() : null}
          {this.props.loader ? this.sppiner(this.props.loader) : this.sppiner(this.props.loader)}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  visibility: PropTypes.bool,
  loader: PropTypes.bool,
  intl: PropTypes.bool,
  yes: PropTypes.func,
  yesMsg: PropTypes.string,
  no: PropTypes.func,
  noMsg: PropTypes.string,
  msg: PropTypes.string,
  promptModal: PropTypes.bool, // defines what do you whant to see in modal
};

export default Modal;
