import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import AlphaLogo from "../../img/logo.png";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import NewButton from "../buttons/NewButton";
import ToggleVisibility from "../features/ToggleVisibility";
import Prompter from "../prompt/Prompter";

const ErrorPopup = ({ logoutBtn, iconId, reloadPageBtn, title, text, history }) => {
  const [isLogoutMenuVisible, setIsLogoutMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.user.role_selected);

  useEffect(() => {
    // remove the app on load
    document.getElementById("root").innerHTML = "";
  }, []);

  const handleReloadBtnClick = () => {
    window.location.reload();
  };

  const handleLogOutClick = () => {
    setIsLogoutMenuVisible(!isLogoutMenuVisible);
  };

  const handleLogoutModalEvents = which => {
    console.log("which", which);
    if (which === "close") setIsLogoutMenuVisible(false);
    if (which === "confirm") logout();
  };

  const logout = () => {
    //@todo add this 3 lines into reducer and only dispatch here
    AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
    dispatch({ type: TYPES.LOGOUT });
    history.push(`/ui/auth/${userRole}`);
    document.querySelector("body").classList.remove("theme-1");
    window.location.reload();
  };

  return (
    <div className="__v bc1 c10">
      <Layout flex flexColumn fullHeight lastItemBottom>
        <Layout flex spaceBetween addClass="__v_h">
          <div>
            <Icon iconId="globalNavLogo" addClass="c12 __v_hi" />
          </div>
          <div>
            {logoutBtn && (
              <button onClick={handleLogOutClick} className="__b">
                <Icon iconId="logoutFull" addClass="__v_hl c15" />
              </button>
            )}
            <ToggleVisibility toggle={isLogoutMenuVisible}>
              <div className="__v_hm">
                <Prompter
                  id="029548209475029341290"
                  title={renderLangString({ id: "$_b5" })}
                  msg={renderLangString({ id: "$_b6" })}
                  handleEvents={handleLogoutModalEvents}
                />
              </div>
            </ToggleVisibility>
          </div>
        </Layout>
        <Layout flex fullHeight alignItemsCenter justifyContentCenter>
          <div className="__v_m">
            <Layout flex>
              <div>
                <Icon iconId={iconId} addClass="__v_mi" />
              </div>
              <Layout flex1 addClass="__v_mt">
                <h1>{title}</h1>
                <p>{text}</p>
                {reloadPageBtn && (
                  <NewButton
                    value={renderLangString({ id: "$_d1" })}
                    addClass="__v_mb"
                    clicked={handleReloadBtnClick}
                  />
                )}
              </Layout>
            </Layout>
          </div>
        </Layout>
        <Layout flex right addClass="__v_f">
          <div>
            <a
              href="https://www.alpha-awards.com/"
              className="__fo_bl c12"
              data-test="AA_link"
              target="_black"
            >
              <span>
                <FormattedMessage id="powerdBy" />
              </span>
              <img src={AlphaLogo} alt="alpha awards" />
            </a>
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default withRouter(ErrorPopup);
