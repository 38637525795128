import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import Sticky from "react-stickynode";

// FUNCTIONS
import AJAX from "../functions/ajax";
import { safeDelay } from "../hooks/useSafeDelay";

// REDUX
import { connect } from "react-redux";
import { hideMenu } from "../actions/appActions";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import MobileNav from "../components/navigations/MobileNav";
import Modal from "../components/modals/Modal";
import MainSideNav from "../components/navigations/MainSideNav";
import EntryStepDisplay from "../components/display/EntryStepDisplay";
import ContentContainer from "../components/layout/ContentContainer";
import FieldList from "../components/layout/FieldList";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// VENDORS
import fadeInOut from "../vendors/fadeInOut";
import { isHidden } from "../vendors/checkForVisiblity";

export class EditEntry extends Component {
  _id = Math.random();

  menuRequest = this._id + "/entry_menu";
  deleteRequest = this._id + "/entry_delete";

  modalElemRef = React.createRef();

  state = {
    mainSideNavSticky: false,
  };

  componentDidMount() {
    this.mounted = true;

    this.fetchEntryStepList().then(res => {
      const { id, displayId } = this.props.match.params;
      displayId || this.props.history.push(AA_GLOBAL.makeEditEntryUrl(id, res.data));
    });
  }

  componentWillUnmount() {
    this.mounted = false;
    AJAX().cancelRequests([this.menuRequest, this.deleteRequest]);
  }

  fetchEntryStepList = () =>
    AJAX()
      .getEntryStepList(this.menuRequest, this.props, this.props.match.params.id)
      .then(res =>
        lastArg(this.mounted && res?.data && this.setState({ entryMenu: res.data }), res)
      );

  onStepSave() {
    const prevStatus = this.state.entryMenu.status;

    return this.fetchEntryStepList().then(res => {
      if (!this.mounted || !res?.data) return;

      // is menu is locked redirect to project overview
      if (prevStatus !== "confirmed" && res.data.status === "confirmed")
        safeDelay(1000, () => this.mounted).then(this.pushOverviewHistory);

      return res;
    });
  }

  showRemoveEntryModal = () => {
    // hide mob menu if visible
    if (isHidden(document.getElementById("mobileNav"))) this.props.hideMenu();

    fadeInOut(this.modalElemRef.current, "500", true);
  };

  stepChanged = () => this.props.hideMenu?.();

  confirmDelete = () => {
    // show modal loader
    this.setState({ deleteModalLoader: true });

    // set end point url
    const params = encodeURI(`?ids=["${this.props.match.params.id}"]`);

    return AJAX()
      .deleteEntry(this.deleteRequest, this.props, params)
      .finally(() => this.setState({ deleteModalLoader: false }));
  };

  loaderDone = () => {
    // hide modal
    fadeInOut(this.modalElemRef.current, "500", false);
    this.setState({ deleteModalLoader: false });
    this.props.history.push(`/ui/${this.props.user.role_selected}/dashboard`);
  };

  handleStateChange = status =>
    status.status === Sticky.STATUS_FIXED &&
    this.setState({ mainSideNavSticky: status.status !== 0 });

  overviewPath = () =>
    `/ui/${this.props.user.role_selected}/project-overview/${this.props.match.params.id}`;

  printEntry = () =>
    this.props.history.push(`${this.overviewPath()}/${this.props.match.params.displayId}#print`);

  pushOverviewHistory = () => this.props.history.push(this.overviewPath());

  setActiveStep = step => this.setState({ activeStep: step });

  viewUser = user =>
    this.props.history.push(`/ui/${this.props.user.role_selected}/user/${user.id}`);

  render() {
    const { entryMenu, activeStep } = this.state;

    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain dashboardMain__multiple ">
              <main className="column-md u-order-1">
                {activeStep && entryMenu && (
                  <EntryStepDisplay
                    menu={entryMenu}
                    step={activeStep}
                    onStepSave={() => this.onStepSave()}
                  />
                )}
              </main>

              {entryMenu && (
                <MobileNav>
                  <Sticky onStateChange={this.handleStateChange} enableTransforms={false}>
                    <AnimateHOC animate={entryMenu}>
                      <MainSideNav
                        menu={entryMenu}
                        sticky={this.state.mainSideNavSticky}
                        onPrintClick={this.printEntry}
                        onRemoveClick={this.showRemoveEntryModal}
                        onStepChange={this.setActiveStep}
                        onStepClick={this.stepChanged}
                        operationsNav
                      />
                      {entryMenu.created_by?.user &&
                        this.props.user.role_selected === "submission_admin" && (
                          <ContentContainer
                            title={entryMenu.created_by.user.title}
                            btn
                            btnText={"view"}
                            btnOnClick={() => this.viewUser(entryMenu.created_by.user)}
                          >
                            <FieldList
                              model={entryMenu.created_by.user}
                              // @todo add proper CSS class (added projectOverview as a temp solution.)
                              prefix="projectOverview"
                              addClass="projectOverview"
                            />
                          </ContentContainer>
                        )}
                    </AnimateHOC>
                  </Sticky>
                </MobileNav>
              )}
            </div>
          </div>
          <FooterMain />
        </div>
        <Modal
          id="dw302lf04kf23t405240f05"
          myRef={this.modalElemRef}
          loaderDone={this.loaderDone}
          loader={this.state.deleteModalLoader}
          promptModal
          intl
          yesMsg="yes"
          noMsg="no"
          yes={this.confirmDelete}
          msg="areYouSureDeleteEntries"
        />
      </AppHOC>
    );
  }
}

const lastArg = (...args) => args[args.length - 1];

EditEntry.propTypes = {};

const mapStateToProps = store => ({
  info: store.app.info,
  user: store.user,
  app: store.app,
});

export default injectIntl(connect(mapStateToProps, { hideMenu })(withRouter(EditEntry)));
