import React from "react";
import renderLangString from "../../languageStrings";

//COMPONENTS
import NewButton from "../buttons/NewButton";
import Icon from "../icons/Icon";
import Layout from "../layout/Layout";

// FUNCTIONS
import S from "../../functions/select";

const Prompter = ({ id, msg, title, addClass, handleEvents }) => {
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      handleEvents("close");
      if (S("#logoutBtn")[0]) S("#logoutBtn")[0].focus();
    }
  };

  return (
    <div className={`__e  ${addClass || "bc12 c16"}`} data-name="Prompter">
      <Layout flex spaceBetween addClass="__e_h">
        <div>
          <strong>{title}</strong>
        </div>
        <span
          id={`x_${id}`}
          tabIndex={0}
          className="__b"
          onClick={() => handleEvents("close")}
          onKeyPress={handleKeyPress}
        >
          <Icon iconId="x" addClass="__e_i" />
        </span>
      </Layout>
      <div className="__e_b">{msg}</div>
      <Layout flex addClass="__e_f">
        <NewButton
          btnStyle="flat"
          colStyle="primary"
          value={renderLangString({ id: "$_b5" })}
          clicked={() => handleEvents("confirm")}
        />
        <NewButton
          btnStyle="flat"
          value={renderLangString({ id: "$_b7" })}
          clicked={() => handleEvents("close")}
          onKeyPress={handleKeyPress}
        />
      </Layout>
    </div>
  );
};

export default Prompter;
