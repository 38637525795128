import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from 'react-icons/md';

class ToggleContainerBtn extends Component {

  state = {
    toggleIconsState: true
  }

  toggleIcons = () => {
    this.setState({toggleIconsState: !this.state.toggleIconsState})
    if(this.props.toggleContent) this.props.toggleContent();
  }

  UNSAFE_componentWillMount() {
    if(this.props.closed) { this.setState({toggleIconsState: false}) }
  }

  render() {
    return (
      this.props.hidden ? null :
      <div
        onClick={this.toggleIcons}
        data-test="toggleBtn">
        <MdKeyboardArrowDown
          className={'button__plus-minus ' + (this.state.toggleIconsState ? ' ' : ' u-hide ')}
          test="arrowDownBtn"/>
        <MdKeyboardArrowLeft
          className={'button__plus-minus ' + (this.state.toggleIconsState ? ' u-hide ' : ' ')}
          test="arrowLeftBtn"/>
      </div>
    );
  }
}

ToggleContainerBtn.propTypes = {
  toggleContent: PropTypes.func.isRequired,
  closed: PropTypes.bool,
  hidden: PropTypes.bool
}

export default ToggleContainerBtn;