import React from "react";
import { useRef, useState, useLayoutEffect, useCallback } from "react";

// COMPONENTS
import ContentContainer from "../components/layout/ContentContainer";
// import Alerter from "../components/alerts/Alerter";
import BaseFormio from "../components/forms/BaseFormio";
import { APIButtons } from "../components/forms/APIButton";

// FUNCTIONS
import { axiosWithAuth } from "../axiosSetup";
import AJAX from "../functions/ajax";

// HOOKS
import useMounted from "../hooks/useMounted";
import useSafeAxios from "../hooks/useSafeAxios";
import useSafeDelay from "../hooks/useSafeDelay";

import AnimateHOC from "../hoc/AnimateHOC";

// REDUX
import { connect } from "react-redux";
import { getSession } from "../actions/userActions";

const ProfilesCreateEdit = props => {
  const { user, info } = props;
  const mounted = useMounted();
  const safeAxios = useSafeAxios(axiosWithAuth, mounted);
  const safeDelay = useSafeDelay(mounted);

  const [page, setPage] = useState({ profiles: [], forms: {} });
  const [newProfileFormio, setNewProfileFormio] = useState();
  const [selectedForm, setSelectedForm] = useState();

  const [triggerReload, setTriggerReload] = useState(0);
  const reloadPage = () => setTriggerReload(x => ++x);

  const formioOptions = useRef(info.default_formio_options).current;

  // Load profiles index.
  useLayoutEffect(() => {
    safeAxios({ url: user.appModule.profiles.api_url }).then(({ data: { body: page } }) => {
      if (page.forms.requirements.length === 1)
        page.forms.actions = page.forms.actions.filter(a => a.key !== "cancel");

      setPage(page);
      setSelectedForm(preselectIfOnlyOneForm(page));

      if (
        // If we need to enforce again.
        (!user.enforce_profile && page.enforce_profile) ||
        // If we don't need to enforce anymore.
        (user.enforce_profile && !page.enforce_profile)
      ) {
        user.enforce_profile = page.enforce_profile;

        const urlRole = props.location.pathname.split("/")[2];
        const match = { match: { params: { module: urlRole } } };

        AJAX()
          .getSession("reload_session", match)
          // Store in Redux with getSession().
          .then(res => {
            props.getSession(res.data);
            if (props.onSaveCallback) props.onSaveCallback(!res.data.data.enforce_profile);
          });
      }
    });
  }, [safeAxios, user, props, triggerReload]);

  // When selecting a form from the list.
  const onProfileFormsChanged = useCallback(
    ({ changed }) => {
      // There's only a select input, no need to check changed.component.key
      const fieldsetValue = changed && changed.value;
      setSelectedForm(makeSelectedFormContext(page, fieldsetValue));
    },
    [page]
  );

  const onCreateProfileButton = useCallback(
    ({ formio, action }) => {
      const resetNewProfileForm = () => {
        newProfileFormio && newProfileFormio.clearData();
        setSelectedForm(null);
      };

      const runAction = {
        reset: () => formio.clearData(),
        cancel: resetNewProfileForm,
        create: () => {
          return safeDelay(250)
            .then(_ => apiRequest(safeAxios, action.api, formio.data))
            .then(r => {
              formio.setRespAlert(r);
              if (r.isOK) safeDelay(750).then(resetNewProfileForm).then(reloadPage);
              return r.isOK;
            })
            .catch(error => {
              mounted() && formio.setRespAlert(error.response);
              throw error;
            });
        },
      }[action.key];

      return runAction && runAction();
    },
    [safeDelay, safeAxios, newProfileFormio, mounted]
  );

  const onEditProfileButton = useCallback(
    ({ formio, action }, profile) => {
      const sendAndReloadOnSuccess = () => {
        return safeDelay(250)
          .then(_ => apiRequest(safeAxios, action.api, formio.data))
          .then(r => {
            formio.clearHasChanges();

            if (r.isOK && r.changed) safeDelay(500).then(reloadPage);

            if (action.key === "save") formio.checkAndShowErrors();

            formio.setRespAlert(r);
            return r.isOK;
          })
          .catch(error => {
            mounted() && formio.setRespAlert(error.response);
            throw error;
          });
      };

      const runAction = {
        reset: () => formio.resetData(),
        // TODO: ask for confirmation
        delete: sendAndReloadOnSuccess,
        save: sendAndReloadOnSuccess,
      }[action.key];

      return runAction && runAction();
    },
    [safeDelay, safeAxios, mounted]
  );

  const { requirements } = page.forms;
  const totalFormsCount = (requirements && requirements.length) || 0;

  return (
    <>
      {/* One container for creating a new profile */}
      {/* Don't show if no 'selectable' forms are available. */}
      {page.forms.selectable > 0 && (
        <ContentContainer title={page.forms.title} titleType={props.titleType}>
          {/* Show select if the dashboard has more than one form. */}
          {totalFormsCount > 1 && (
            <BaseFormio
              schema={page.forms.schema}
              onCreate={setNewProfileFormio}
              onChange={onProfileFormsChanged}
              options={formioOptions}
            >
              {selectedForm && <div style={{ marginBottom: "1em" }}></div>}
            </BaseFormio>
          )}

          {selectedForm && (
            <AnimateHOC animate={true} key={selectedForm.id}>
              <BaseFormio schema={selectedForm.schema} options={formioOptions}>
                <APIButtons list={[selectedForm.actions, ctxt => onCreateProfileButton(ctxt)]} />
              </BaseFormio>
            </AnimateHOC>
          )}
        </ContentContainer>
      )}

      {/* One container for editing each existing profile */}
      {page.profiles.map(p => (
        <ContentContainer key={p.id} title={p.title}>
          {/* @todo enable when the Backend is ready*/}
          {/*<Alerter list={p.alerts} />*/}
          <BaseFormio
            content={p.content}
            schema={getProfileSchema(page, p)}
            options={formioOptions}
          >
            <APIButtons list={[p.actions, ctxt => onEditProfileButton(ctxt, p)]} />
          </BaseFormio>
        </ContentContainer>
      ))}
    </>
  );
};

/**
 * Executes an authenticated request with parameters defined by the backend.
 *
 * @param api
 *  Supplied by the backend.
 * @param data
 *  Supplied by the frontend (e.g. a form.)
 */
function apiRequest(safeAxios, api, data) {
  const { url, method, headers } = api || {};
  return safeAxios({ url, method, headers, data: { data, api: api.data } });
}

function makeSelectedFormContext(page, requirement_id) {
  const { id, schema } = getRequirement(page, requirement_id) || {};
  return id && { id, schema, actions: page.forms.actions };
}

function preselectIfOnlyOneForm(page) {
  const {
    forms: { requirements },
  } = page;

  return (
    (requirements &&
      requirements.length === 1 &&
      makeSelectedFormContext(page, requirements[0].id)) ||
    null
  );
}

const getRequirement = ({ forms }, requirement_id) =>
  forms.requirements[forms.index_by_id[requirement_id]];

const getProfileSchema = (page, profile) => getRequirement(page, profile.requirement_id).schema;

const mapStateToProps = store => ({
  info: store.app.info,
  user: store.user,
});

export default connect(mapStateToProps, { getSession })(ProfilesCreateEdit);
