import React, { Component } from "react";

// COMPONENTS
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import AuthInfo from "../components/display/AuthInfo";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// VENDORS
import getUrlQueryParam from "../vendors/getUrlQueryParam";

// FUCTIONS
import { getWidth } from "../functions/getPageDimesions";

class Auth extends Component {
  // posible auth views: register, login
  state = {
    authView: "login",
  };

  changeAuth = auth => {
    this.setState({ authView: auth });
  };

  hideInfoOnResize = () => {
    const pageWidth = getWidth();

    if (pageWidth > 980) {
      this.setState({ authView: "login" });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.hideInfoOnResize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.hideInfoOnResize);
    const pageWidth = getWidth();

    // if there is page parameter in the url take a authView from there
    if (window.location.search && window.location.search.length > 0) {
      this.setState({ authView: getUrlQueryParam("page", window.location.search) });
      return;
    }
    // on small screens show info first
    else if (pageWidth <= AA_GLOBAL.breakExtraSmall) {
      this.setState({ authView: "info" });
    } else {
      // if localStorage is set with login prop
      // if (localStorage.getItem("login")) {
      // }
      // show login first
      this.setState({ authView: "login" });
    }
  }

  renderAuth = () => {
    let authView = this.state.authView;
    const { data } = this.props;

    if (!data) {
      return null;
    } else if (authView === "register" && data.forms && !data.forms.hide_registration) {
      return <Registration changeAuth={this.changeAuth} data={data} />;
    } else if (authView === "retrieve") {
      return <ForgotPassword changeAuth={this.changeAuth} data={data} />;
    } else if (authView === "info") {
      return <AuthInfo changeAuth={this.changeAuth} data={data} />;
    } else {
      return <Login changeAuth={this.changeAuth} data={data} />;
    }
  };

  render() {
    return (
      <div className="loginMain__auth">
        <div className={"container container__wsLarge container__boxS a-slideLeft"}>
          {this.renderAuth()}
        </div>
      </div>
    );
  }
}

export default Auth;
