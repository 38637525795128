import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

const ParseHTML = ({ html, history }) => {
  const wrapper = useRef();

  useEffect(() => {
    // select all link routs
    const links = wrapper.current.querySelectorAll('a[href^="/ui"]');

    // if there are links in the html
    if (links.length > 0) {
      links.forEach(link => {
        // change route on click
        link.addEventListener("click", e => {
          e.preventDefault();

          const uiIndex = link.href.split("/").indexOf("ui");
          const route = "/" + link.href.split("/").splice(uiIndex).join("/");

          history.push(route);
        });
      });
    }
  }, []);

  return (
    <div
      data-name="ParseHTML"
      ref={wrapper}
      dangerouslySetInnerHTML={{ __html: html }}
      className="__1"
    ></div>
  );
};

export default withRouter(ParseHTML);
