import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// HOC
import RemoveWrapDiv from "../../hoc/RemoveWrapDiv";

const BoxBtnLarge = props => {
  const renderContent = () => (
    <RemoveWrapDiv>
      <div className="button__superlarge--iconCon">{props.children}</div>
      <div className="button__superlarge--data">
        <div className="button__superlarge--data-title">{props.title}</div>
        <div className="button__superlarge--data-subtitle">{props.subtitle}</div>
      </div>
    </RemoveWrapDiv>
  );

  if (props.disabled) {
    return (
      <div
        className={"button button__superlarge u-disabled " + (props.addClass ? props.addClass : "")}
      >
        {renderContent()}
      </div>
    );
  }
  if (props.link) {
    return (
      <NavLink
        to={`/ui${props.link}`}
        activeClassName={"button__superlarge--active"}
        aria-label={props.title}
        exact={true}
        className={"button button__superlarge " + (props.addClass ? props.addClass : "")}
      >
        {renderContent()}
      </NavLink>
    );
  }
  if (props.linkA) {
    return (
      <a
        href={`/ui${props.linkA}`}
        aria-label={props.title}
        className={"button button__superlarge " + (props.addClass ? props.addClass : "")}
      >
        {renderContent()}
      </a>
    );
  }
  return (
    <div
      data-test="slBtn"
      className={
        "button button__superlarge " +
        (props.active ? " button__superlarge--active " : "") +
        (props.addClass ? props.addClass : "")
      }
      onClick={props.clicked}
    >
      {renderContent()}
    </div>
  );
};

BoxBtnLarge.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.any,
  active: PropTypes.bool,
  addClass: PropTypes.string,
  clicked: PropTypes.func,
};

export default BoxBtnLarge;
