import React, { useRef } from "react";
import BaseFormio from "../forms/BaseFormio";
import { APIButtons } from "../forms/APIButton";

// HOOKS
import useMounted from "../../hooks/useMounted";
import useSafeAxios from "../../hooks/useSafeAxios";
import useSafeDelay from "../../hooks/useSafeDelay";

// FUNCTIONS
import { axiosWithAuth } from "../../axiosSetup";

export default function TableForm({ info, form }) {
  const mounted = useMounted();
  const safeAxios = useSafeAxios(axiosWithAuth, mounted);
  const safeDelay = useSafeDelay(mounted);

  const formOptions = useRef({ ...info.default_formio_options }).current;

  function onFormButton({ formio, action }) {
    const sendForm = () =>
      safeDelay(250)
        .then(_ => BaseFormio.apiRequest(safeAxios, action.api, formio.data))
        .then(r => {
          formio.setRespAlert(r);
          return r.isOK;
        })
        .catch(error => {
          mounted() && formio.setRespAlert(error.response);
          throw error;
        });

    const runAction = { save: sendForm }[action.key];
    return runAction && runAction();
  }

  return (
    <BaseFormio schema={form.schema} content={form.content} options={formOptions}>
      <APIButtons list={[form.actions, ctxt => onFormButton(ctxt)]} />
    </BaseFormio>
  );
}
