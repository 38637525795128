import React, { Component } from "react";

// COMPONENTS
import ContentGenerator from "../components/layout/ContentGenerator";

export class PublicPage extends Component {
  render() {
    return (
      <div className="rowCon rowCon__withMargin" id="main_content">
        <div className="dashboardMain">
          <ContentGenerator url={`/api/pages/${this.props.match.params.slug}`} />
        </div>
      </div>
    );
  }
}

export default PublicPage;
