import React from "react";

// ICONS
import Icon from "../icons/Icon";

const MobToggleMenuBtn = ({ toggle, iconID, clicked }) => {
  const handleClick = () => {
    clicked();
  };

  return (
    <button onClick={handleClick} className="__mb bc12">
      {toggle ? <Icon iconId="x" /> : <Icon iconId={iconID} />}
    </button>
  );
};

export default MobToggleMenuBtn;
