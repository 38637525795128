// For combining CSS class names, leaving out values that aren't strings.
function classes() {
  return [...arguments].filter(x => typeof x === "string").join(" ");
}

const wrapArray = x => (Array.isArray(x) ? x : [x]);

function loadJS(url, tagID, onLoaded, onFailed) {
  let d = document,
    scriptTag = d.getElementById(tagID);

  if (scriptTag) {
    onLoaded && onLoaded(scriptTag);
    return scriptTag;
  } else {
    scriptTag = d.createElement("script");
    scriptTag.src = url;
    scriptTag.id = tagID;
    scriptTag.onload = () => {
      onLoaded && onLoaded(scriptTag);
    };
    scriptTag.onerror = () => {
      onFailed && onFailed(scriptTag);
    };
    return d.head.appendChild(scriptTag);
  }
}

function debounce(func, wait) {
  var tid;
  return function () {
    var context = this,
      args = arguments,
      clear = () => clearTimeout(tid);
    tid = clear() || setTimeout(() => func.apply(context, args), wait);
    return clear;
  };
}

// NB: react doesn't run code in script tags using e.g.:
//   <div dangerouslySetInnerHTML={{ __html: htmlWithScriptTags }}></div>
//
// This function is needed to be able to embed HTML and execute script tags.
function setHTMLWithScripts(el, html) {
  if (el && typeof html === "string") {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.deleteContents();
    el.appendChild(range.createContextualFragment(html));
  }
  return el;
}

export const between = (x, min, max) => min <= x && x <= max;

export function showBackendErrorPage(err, _api) {
  if (
    process.env.NODE_ENV === "development" &&
    err &&
    err.response &&
    between(err.response.status, 500, 599)
  ) {
    const doc = document.open("text/html");
    doc.write(err.response.data);
    doc.close();

    // if (api) window.history.pushState({}, "", api.url);
  }
}

export const extractLanguageFromLocale = locale => locale.slice(0, 2).toLowerCase();

export { classes, wrapArray, debounce, loadJS, setHTMLWithScripts };
