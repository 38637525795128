import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { classes } from '../../functions/utils'

// COMPONENTS
import ContentGenerator from '../layout/ContentGenerator';

class LoginCompanyInfo extends Component {
  render() {
    const { logo, body } = this.props.data;

    return (
      <div
        id="loginConpanyInfo"
        className="loginMain__conpanyInfo a-slideRight"
        data-test="loginCompanyInfo"
      >
        <div className="container container__wsLarge container__boxS" style={{height: '100%'}}>
          {// TODO: remove logo.url eventually when backend setups only use src.
            logo && (logo.src || logo.url) && (
            <img
              src={logo.src || logo.url}
              title={logo.title}
              alt={logo.alt || "Company Logo"}
              style={logo.style}
              className={classes("loginMain__conpanyInfo-logo", logo.class)}
              width={logo.width}
              height={logo.height}
              srcSet={logo.srcset}
              data-test="companyImg"
            />
          )}
          <ContentGenerator data={body} />
        </div>
      </div>
    );
  }
}

LoginCompanyInfo.propTypes = {
  animate: PropTypes.bool
}

export default LoginCompanyInfo;
