import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// HOC
import RemoveWrapDiv from '../../hoc/RemoveWrapDiv';

class ActionBtn extends Component {

  state = {
    classes: ''
  }

  UNSAFE_componentWillMount() {
    let classes = '';
    if (this.props.disabled) { classes += ' button--disabled ' }

    this.setState({
      classes
    })
  }

  renderBtn = () => (
    <button
      onClick={this.props.clicked}
      className={'button button__action ' + this.state.classes}>
      {this.props.children}
      <span className="button__action-txt">{this.props.text}</span>
    </button>
  )

  renderLink = () => (
    <Link
      to={'/ui' + this.props.link}
      className={'button button__action ' + this.state.classes}>
      {this.props.children}
      <span className="button__action-txt">{this.props.text}</span>
    </Link >
  )

  render() {
    return (
      <RemoveWrapDiv>
        {this.props.clicked ? this.renderBtn() : this.renderLink() }
      </RemoveWrapDiv>
    )
  }
};

ActionBtn.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  link: PropTypes.string,
  clicked: PropTypes.func
}

export default ActionBtn;


