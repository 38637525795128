import React, { useState, useLayoutEffect, useRef, useContext } from "react";

import { classes, showBackendErrorPage } from "../../functions/utils";

import useMounted from "../../hooks/useMounted";
import useSafeDelay from "../../hooks/useSafeDelay";

import BaseFormio from "./BaseFormio";

const APIButton = ({ type, label, onClick, addClass, api }) => {
  const mounted = useMounted();
  const safeDelay = useSafeDelay(mounted);
  const formio = useContext(BaseFormio.Context);
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [successClass, setSuccessClass] = useState(false);

  const typeRef = useRef(type);
  const elemRef = useRef();

  useLayoutEffect(() => {
    if (formio) {
      // Execute save/create when pressing Ctrl+Enter in formio elements.
      if (["save", "create"].indexOf(typeRef.current) !== -1)
        formio.element.addEventListener("keyup", event => {
          event.ctrlKey && event.code === "Enter" && elemRef.current.click();
          event.preventDefault();
        });

      formio.ready.then(_ => setLoaded(true));
    }

    return () => {
      // TODO: ?
      // if (formio)
      //   formio.element.removeEventListener()
    };
  }, [formio]);

  const onClickWithContext = e => {
    // Automatically disable and enable button using a Promise.
    setDisabled(true);
    // TODO: there's no way to cancel the previous timer yet.
    setSuccessClass("");

    let promiseOrOther = onClick({ event: e, formio });

    const onSubmitDone = (isOK, err) => {
      if (!mounted() || !{ save: 1, create: 1 }[typeRef.current]) return;
      setSuccessClass(isOK === false ? "button--error" : "button--success");
      safeDelay(5000).then(() => setSuccessClass(""));
      showBackendErrorPage(err, api);
    };

    Promise.resolve(promiseOrOther)
      .then(isOK => onSubmitDone(isOK))
      .catch(err => onSubmitDone(false, err))
      .finally(() => mounted() && setDisabled(false));
  };

  return (
    loaded && (
      <button
        disabled={disabled}
        className={classes(
          "button",
          disabled && "button--disabled",
          type && `button--type-${type}`,
          addClass,
          successClass
        )}
        onClick={onClickWithContext}
        ref={elemRef}
      >
        {label}
      </button>
    )
  );
};

const cssClasses = {
  save: "button--primary",
  // confirm: "button--primary",
  create: "button--primary",
  // cancel: "button--secondary",
  // reset: "button--secondary",
  // delete: "button--secondary",
};

const renderMany = (buttons, onClick) =>
  buttons.map(b => (
    <APIButton
      key={b.id || b.key}
      type={b.key}
      label={b.label}
      addClass={classes(b.classes, cssClasses[b.key] || "button--secondary")}
      onClick={ctxt => onClick({ action: b, ...ctxt })}
      api={b.api}
    />
  ));

export const APIButtons = ({ list: [buttons, onClick] }) => renderMany(buttons, onClick);

export default APIButton;
