const judgeGuideTextDataModel = {
  guide_text: {
    type: "object",
    contains: {
      body: { type: "string" },
      format: { type: "string" },
    },
  },
};

export default judgeGuideTextDataModel;
