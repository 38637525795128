import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import {FaCcMastercard, FaCcAmex, FaPaypal} from 'react-icons/fa';

// COMPONENTS
import ContentContainer from '../components/layout/ContentContainer';
import MainBoxNav from '../components/navigations/MainBoxNav';
import FooterMain from '../components/footers/FooterMain';
import ActionBtn from '../components/buttons/ActionBtn';
import MainHeaderNav from '../components/navigations/MainHeaderNav';
import MobileNav from '../components/navigations/MobileNav';
import PaymentTable from '../components/tables/PaymentTable';
import FieldList from '../components/layout/FieldList';
import AlphaForm from '../components/forms/AlphaForm';

// REDUX
import { connect } from 'react-redux';
import { showMobMenu } from '../actions/appActions';
import * as entryActions from '../actions/entryActions';

// HOC
import AppHOC from '../hoc/AppHOC';


export class Cart extends Component {

  state = {
    toggleEditState: false
  }

  toggleEdit = () => {
    this.setState({toggleEditState: !this.state.toggleEditState})
  }

  changeTable = () => {
    this.props.togglePaymentTables();
  }

  makeABillingForm = (billingAddress) => {

    let form = { components: []};

    if(billingAddress) {
      for(let i = 0; i < billingAddress.length; i++) {
        let formObj = {
          id: Math.random(),
          type: 'text',
          name: billingAddress[i].langTitle,
          value: billingAddress[i].text,
          additional: {
            label: billingAddress[i].langTitle
          },
          validate: {
            valid: false,
            errMsg: '',
            errMsgs: {
              fieldRequiredOrEmpty: 'pleaseFillOut'
            }
          }
        }

        formObj.id = billingAddress[i].id;
        formObj.name = billingAddress[i].langTitle;
        formObj.value = billingAddress[i].text;
        form.components.push(formObj)
      }

      return <AlphaForm form={form} getData={this.editBillingAddress} submitBtnText="save"/>
    }
  }

  editBillingAddress = (formData) => {
    const form = []

    this.props.billingAddress.list.forEach(item => {
      for(var key in formData) {
        if(item.langTitle === key) {
          item.text = formData[key];
          form.push(item);
        }
      }
    })

    this.props.editBillingAddress(form)
  }

  UNSAFE_componentWillMount() {
    if(!this.props.billingAddress.list) {
      this.props.getPaymentData();
    }
  }

  render() {
    return (
      <AppHOC>
      <div id="page">
        <MainHeaderNav/>
        <div className="mainHeaderSubnav"></div>
        <div className="rowCon">
          <MainBoxNav/>
          <div className="dashboardMain">
            <MobileNav>
              <ContentContainer
                title={this.props.intl.formatMessage({id: 'billingAddress'})}
                btn
                btnText={this.props.toggleEditState ? this.props.intl.formatMessage({id: 'close'}) : this.props.intl.formatMessage({id: 'edit'})}
                btnOnClick={this.props.toggleBillingEditForm}
                >
                {/* <BillingAddress showEditForm={this.state.toggleEditState}/> */}
                <div className="dashboardMain__billingAddress">
                  {this.props.billingAddress && !this.props.toggleEditState  ?
                    <FieldList model={this.props.billingAddress}/>
                    : null}
                  {this.props.toggleEditState ?
                    <div className="dashboardMain__billingAddress-form">
                      {this.makeABillingForm(this.props.billingAddress.list)}
                      <div className={"dashboardMain__billingAddress-loader " + (this.props.editBillingAddressLoader ? '' : 'u-hide')}>
                        <div className="circleLoader-extraLarge dashboardMain__billingAddress-loader-i"></div>
                      </div>
                    </div>
                  : null}
                </div>
              </ContentContainer>
              <div className="r-showOnSmall">
              <ActionBtn
                clicked={() => this.props.payWithBankAcc(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithBank'})}
                test="payWithBankaccontBtn"><FaCcAmex/></ActionBtn>
              <ActionBtn
                clicked={() => this.props.payWithCreditCard(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithCard'})}
                test="payWithCardBtn"><FaCcMastercard/></ActionBtn>
              <ActionBtn
                clicked={() => this.props.payWithPaypal(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithPaypal'})}
                test="payWithPaypalBtn"><FaPaypal/></ActionBtn>
              </div>
            </MobileNav>

            <ContentContainer
              sizeClass="column-md"
              title={this.props.intl.formatMessage({id: 'payments'})}>
              {this.props.notPayed ?
                <div>
                  <div className={this.props.toggleTable ? 'u-hide' : ''}>
                  <h3 className="title title--medium form__title">{this.props.intl.formatMessage({id: 'pay'})}</h3>
                  <PaymentTable
                    data={this.props.notPayed}
                    changeTable={this.changeTable}
                    paymentOverview={this.props.payed.length !== 0}/>
                </div>
                <div className={this.props.toggleTable ? '' : 'u-hide'}>
                  <h3 className="title title--medium form__title"><FormattedMessage id="payedEntries"/></h3>
                  <PaymentTable
                    data={this.props.payed}
                    changeTable={this.changeTable}
                    payEntries={this.props.notPayed.length !== 0}/>
                </div>
                </div>
                : null}

            </ContentContainer>
            <div className="column-exsm r-visibilityNoneOnSmall">
              <ActionBtn
                clicked={() => this.props.payWithBankAcc(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithBank'})}
                test="payWithBankaccontBtn"><FaCcAmex/></ActionBtn>
              <ActionBtn
                clicked={() => this.props.payWithCreditCard(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithCard'})}
                test="payWithCardBtn"><FaCcMastercard/></ActionBtn>
              <ActionBtn
                clicked={() => this.props.payWithPaypal(this.props.notPayed)}
                text={this.props.intl.formatMessage({id: 'payWithPaypal'})}
                test="payWithPaypalBtn"><FaPaypal/></ActionBtn>
            </div>
          </div>
        </div>
        <FooterMain/>
      </div>
      </AppHOC>
    );
  }
};

Cart.propTypes = {
  showMobMenu: PropTypes.func.isRequired,
  payWithBankAcc: PropTypes.func.isRequired,
  payWithCreditCard: PropTypes.func.isRequired,
  payWithPaypal: PropTypes.func.isRequired,

}

const mapStateToProps = (state) => ({
  toggleTable: state.entry.payment.entries.toggleTable,
  payed: state.entry.payment.entries.payed,
  notPayed: state.entry.payment.entries.notPayed,
  billingAddress: state.entry.payment.billingAddress ,
  toggleEditState: state.entry.payment.billingAddress.toggleEditState,
  editBillingAddressLoader: state.entry.payment.billingAddress.loader
})

export default connect(mapStateToProps, { showMobMenu, ...entryActions })(injectIntl(Cart));