import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

class CheckBox extends Component {
  render() {
    const id = Math.random();

    return (
      <span className="invisibleCheckbox" data-test="checkBox">
        <label
          htmlFor={id}
          tabIndex={this.props.tabindex || "0"}
          onKeyUp={e => e.key === "Enter" && this.props.toggleIcons()}
        >
          <MdCheckBoxOutlineBlank
            className={
              "invisibleCheckbox__unchecked " +
              (this.props.addClass ? "form__checkbox " : " ") +
              (this.props.checked ? " u-hide" : "")
            }
            aria-label="Icon"
            test="uncheckedIcon"
            onClick={this.props.toggleIcons}
          />
          <MdCheckBox
            className={
              "invisibleCheckbox__checked " +
              (this.props.addClass ? "form__checkbox " : " ") +
              (this.props.checked ? "" : " u-hide")
            }
            aria-label="Icon"
            test="checkedIcon"
            onClick={this.props.toggleIcons}
          />
        </label>
        <input
          type="checkbox"
          aria-labelledby={this.props.labeldBy}
          id={id}
          name={this.props.name}
          defaultChecked={this.props.checked}
          className="u-hide"
          data-test="checkbox"
        />
      </span>
    );
  }
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  toggleIcons: PropTypes.func,
  name: PropTypes.string,
  addClass: PropTypes.string,
};

export default CheckBox;
