
import {
  LOGOUT,
  SAVE_SESSION_AND_INFO,
  SET_USER_ROLE} from '../actions/types';

const initialState = {
}

export default function(state = initialState, action) {

  switch(action.type) {

    case SAVE_SESSION_AND_INFO:
      return {
        ...state,
        ...action.session.data,
        role_selected: action.session.data.role_selected,
        appModule: action.appModule
      }
    case SET_USER_ROLE:
      return {
        ...state,
        role_selected: action.payload.role_selected,
        appModule: action.payload.appModule
      }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}