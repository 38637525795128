import store from "../store";
import Cookies from "universal-cookie";
import serverBaseURL from "./serverSetup";

const AA_GLOBAL = {
  baseURL: serverBaseURL(),
  cookies: new Cookies(),
  breakExtraSmall: 736,
  breakSuperSmall: 460,
  $audioThumbnailCol: "#737373",
  $alertCol: "#ff6464",
  $mainTextColor: "#737373",
  authCookieExpireData: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365), // 1 year
  emailRegex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  // @todo: other Unicode alphabetical letters should also be accepted.
  userPassRegex: /^(?=.*[a-zäöü])(?=.*\d).{8,}$/i,
  loaderLoadingDuration: 1500,
};

AA_GLOBAL.globalFormioOpts = { baseUrl: "/", i18n: {}, language: "en", noAlerts: true };

AA_GLOBAL.makeEditEntryUrl = (entryID, entryMenu) => {
  const { user } = store.getState();
  const chosenStep = AA_GLOBAL.getUnfinishedStep(entryMenu.requirement_steps);
  return `/ui/${user.role_selected}/edit-entry/${entryID}/${chosenStep.id}`;
};

AA_GLOBAL.firstUnfinishedStep = steps =>
  (steps || []).reduce(
    (acc, step) =>
      acc || AA_GLOBAL.firstUnfinishedStep(step.requirement_steps) || (!step.confirmed && step),
    null
  );

AA_GLOBAL.getUnfinishedStep = steps => AA_GLOBAL.firstUnfinishedStep(steps) || steps.slice(-1)[0];

AA_GLOBAL.switchUserRole = (selectedRole, { history }) =>
  history.push(`/ui/${selectedRole}/dashboard`);

export default AA_GLOBAL;
