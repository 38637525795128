import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Routes from "../Routes";
import renderLangString from "../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../actions/types";

// FUNCTIONS
import S from "../functions/select";

// HOOKS
import useResizeScreen from "../hooks/useResizeScreen";

// COMPONENTS
import ModalPortal from "../components/modals/ModalPortal";
import ErrorPopup from "../components/popups/ErrorPopup";

const NewAppHOC = ({ children }) => {
  const [isConnectionLost, setIsConnectionLost] = useState();
  const dispatch = useDispatch();
  const screenSize = useResizeScreen();
  const app = useSelector(state => state.app);
  const isMainMenuVisible = useSelector(store => store.app.isMainMenuVisible);

  // useEffect(() => {
  //   document.getElementsByTagName("body")[0].onload = function () {
  //     alert("done");
  //   };
  // }, []);

  // add new styles to the new template
  useEffect(() => {
    const body = S("body")[0];
    // check for app theme and add the class `theme-judge` if it is a new theme
    let role = Routes.getData().module;
    const isReviewer = role => ["judge", "reviewer"].includes(role);
    const isReviewAdmin = role => ["judge_admin", "review_admin"].includes(role);

    // @todo work in progress
    if (isReviewer(role)) {
      body.classList.add("theme-judge", "style-fix");
    } else if (isReviewAdmin(role)) {
      body.classList.add("theme-judge", "style-fix");
    }
  }, []);

  useEffect(() => {
    if (screenSize.x < 980) {
      dispatch({ type: TYPES.SET_SCREEN_SIZE, payload: "mobile" });
    } else {
      dispatch({ type: TYPES.SET_SCREEN_SIZE, payload: "desktop" });
    }
  }, [screenSize]);

  useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  const handleConnectionChange = () => {
    setIsConnectionLost(true);
  };

  // on unmount
  useEffect(() => {
    return () => {
      dispatch({ type: TYPES.HIDE_HELPER_NAV_ON_TOP });
      dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
    };
  }, []);

  return (
    <>
      <div>{children}</div>
      {isConnectionLost && (
        <ModalPortal>
          <ErrorPopup
            text={renderLangString({ id: "$_c9" })}
            iconId="noConnection"
            reloadPageBtn
            title={renderLangString({ id: "$_c8" })}
          />
        </ModalPortal>
      )}
      {app.errorPopup && (
        <ModalPortal>
          <ErrorPopup
            title={renderLangString({ id: "$_b1" })}
            text={renderLangString({ id: "$_b2" })}
            logoutBtn
            msg={app.errorPopup.msg}
            iconId="infoFull"
            reloadPageBtn
          />
        </ModalPortal>
      )}
    </>
  );
};

export default withRouter(NewAppHOC);
