//** NEW TEMPLATE
import React, { useEffect, useRef, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// COMPONENT
import GlobalNav from "./GlobalNav";
import Layout from "../layout/Layout";
import TogglePageScroller from "../features/TogglePageScroller";
import NavSeperator from "../navigations/NavSeperator";
import ModalBackground from "../modals/ModalBackground";
import ModalPortal from "../modals/ModalPortal";

// HOOKS
import useResizeScreen from "../../hooks/useResizeScreen";

const MainAsideNav = ({ children, header, footer, onScroll }) => {
  const screenSize = useResizeScreen();
  const isMainMenuVisible = useSelector(store => store.app.isMainMenuVisible);
  const sizeOfTheScreen = useSelector(store => store.app.sizeOfTheScreen);
  const isMainMenuVisibleOnTop = useSelector(store => store.app.isMainMenuVisibleOnTop);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sizeOfTheScreen === "mobile") {
      dispatch({ type: TYPES.HIDE_MAIN_MENU });
    } else if (sizeOfTheScreen === "desktop") {
      dispatch({ type: TYPES.SHOW_MAIN_MENU });
    }
  }, [sizeOfTheScreen]);

  const handleBlackModalBackgroundClick = () => {
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  return (
    <aside
      className={`__mn bc12 ${isMainMenuVisible ? "" : "__mn_h"} ${
        isMainMenuVisibleOnTop ? "__mn_t" : ""
      }`}
      role="main"
      data-name="MainAsideNav"
    >
      <GlobalNav />
      <div className="__n">
        <NavSeperator header={header} footer={footer} onScroll={onScroll}>
          {children}
        </NavSeperator>
      </div>
      {isMainMenuVisibleOnTop && (
        <ModalPortal>
          <ModalBackground clicked={handleBlackModalBackgroundClick} />
        </ModalPortal>
      )}
    </aside>
  );
};

export default MainAsideNav;
