import { useRef, useEffect, useCallback } from "react";

export default function useMounted() {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  // Return a stored callback instead of mounted itself,
  // so we can write mounted() outside to get the value.
  return useCallback(() => mounted.current, []);
}

// NB: just an idea to explore if useful.
export function useOnMounted(onMounted, onUnmounted) {
  const ref = useRef({ flag: false });

  ref.onMounted = onMounted;
  ref.onUnmounted = onUnmounted;

  useEffect(() => {
    ref.current.flag = true;

    if (ref.current.onMounted) ref.current.onMounted();

    return () => {
      ref.current = false;

      if (ref.current.onUnmounted) ref.current.onUnmounted();
    };
  }, []);

  return () => ref.current;
}
