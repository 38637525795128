//** NEW TEMPLATE
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";
import { renderLangString } from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";

// COMPONENT
import Layout from "../layout/Layout";
import Popover from "../popovers/Popover";
import NewButton from "../buttons/NewButton";
import ProgressBar from "../display/ProgressBar";

const CarouselNav = ({ data, history, match, onBtnClick }) => {
  const [dotClasses, setDotClasses] = useState("");
  const dispatch = useDispatch();

  const handleDotClick = id => {
    history.push(`${Routes.reviewerCase().path}/${id}`);
    if (onBtnClick) onBtnClick();
  };

  const generateDotClass = item => {
    let dotClass = "";

    if (item.id === match.params.id) dotClass += "__u--a ";
    if (item.excluded) dotClass += "bc1 ";
    else if (item.confirmed) dotClass += "bc1 ";
    else if (item.id === match.params.id) dotClass += "bc16 ";
    else if (!item.confirmed) dotClass += "bc15 ";

    return dotClass;
  };

  const generateDots = () =>
    data.cases.map(caseItem => (
      <Popover
        key={Math.random()}
        msg={caseItem.title}
        style={{ flex: "1", position: "relative", zIndex: 3 }}
      >
        <div
          onClick={() => handleDotClick(caseItem.id)}
          className={`__ud ${generateDotClass(caseItem)}`}
        ></div>
      </Popover>
    ));

  const handleNextAndBack = (data, clicked) => {
    let activeCaseIndex = data.cases.findIndex(x => x.id === match.params.id);

    // prettier-ignore
    if (clicked === "next") {
      activeCaseIndex = activeCaseIndex + 1;
      if (activeCaseIndex > data.cases.length - 1) {
        activeCaseIndex = 0;
      }
    }
    else if (clicked === "back") {
      activeCaseIndex = activeCaseIndex - 1;
      if(activeCaseIndex < 0) { activeCaseIndex = data.cases.length - 1 }
    }

    history.push(`${Routes.reviewerCase().path}/${data.cases[activeCaseIndex].id}`);
    if (onBtnClick) onBtnClick();
  };

  return (
    <div className="__u" data-name="CarouselNav">
      <Layout flex spaceBetween lastItemRight addClass="__uh">
        <div className="__uc"></div>
        <div className="__ut">
          {data.current.code} - {data.current.title}
        </div>
        <div className="__us">
          {data.stats.done}/{data.stats.total}
        </div>
      </Layout>
      <div className="__un">
        {data && data.cases.length < 100 ? (
          <Layout flex spaceBetween alignItemsCenter addClass="__ul">
            {generateDots()}
          </Layout>
        ) : (
          <ProgressBar
            width={(data.stats.done / (data.stats.total || NaN)) * 100 + "%"}
            color="c1"
            addClass="__ul"
          />
        )}
      </div>
      <Layout flex spaceBetween>
        <NewButton
          btnStyle="flat"
          addClass="__ub"
          colStyle="gray"
          style={{ width: "49%" }}
          clicked={() => handleNextAndBack(data, "back")}
        >
          {renderLangString({ id: "$_x" })}
        </NewButton>
        <NewButton
          btnStyle="flat"
          addClass="__ub"
          colStyle="gray"
          style={{ width: "49%" }}
          clicked={() => handleNextAndBack(data, "next")}
        >
          {renderLangString({ id: "$_y" })}
        </NewButton>
      </Layout>
    </div>
  );
};

export default withRouter(CarouselNav);
