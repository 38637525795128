//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";
import ErrorHOC from "../../hoc/ErrorHOC";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import Main from "../../components/main/Main";
import MainNav from "../../components/navigations/MainNav";
import UserNav from "../../components/navigations/UserNav";
import AlphaTable from "../../components/tables/AlphaTable";
import AnimateLayout from "../../components/layout/AnimateLayout";
import Title from "../../components/text/Title";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import ParseHTML from "../../functions/ParseHTML";
import validateData from "../../functions/validateData";

// DATA MODEL
import collectionDataModel from "../../data_model/review_admin/collection/collection";
import stageCollectionsDataModel from "../../data_model/review_admin/collection/all_collections";

function Collection({ match, history }) {
  const isInitialMount = useRef(false);
  const _id = 4902384203480;
  const dispatch = useDispatch();

  const [counterData, setCounterData] = useState();
  const [collectionData, setCollectionData] = useState({});
  const [tableData, setTableData] = useState();
  const [reviewStageCollections, setReviewStageCollections] = useState([]);
  const [reviewers, setReviewers] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const reduxState = useSelector(state => state);

  const init = useCallback(() => {
    getMainData();
    dispatch({ type: TYPES.SET_HELPER_NAV_AS_PRESENT });
    dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
  }, [getMainData]);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  useEffect(() => {
    if (reduxState.reviewer && reduxState.reviewer.expiresAt) {
      setCounterData(reduxState.reviewer.expiresAt);
    }
  }, [reduxState]);

  const getMainData = () => {
    AJAX()
      .getReviewCollection(_id, reduxState, match.params.id)
      //.get(_id, "https://alphaawards-aca8b.firebaseio.com/app/admin_collection.json")
      .then(res => {
        const isDataValid = validateData(
          "ReviewAdmin > Collection > AJAX().getReviewCollection",
          res,
          collectionDataModel
        );

        if (isDataValid) {
          const data = res.data.collection;

          getMenuData(data.stage_id);

          setCollectionData(data);

          setTableData(data.cases_table);
          setIsConfirmed(data.confirmed);
          dispatch({
            type: TYPES.SET_PAGE_IDS,
            payload: { stage: data.stage_id, collection: data.id },
          });

          // get reviewers
          AJAX()
            .get(_id, data.assignments_url)
            .then(res => {
              if (res.data.data.length !== 0) {
                if (reduxState.app.sizeOfTheScreen === "desktop") {
                  dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
                }
                setReviewers(res.data.data);
              } else {
                dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
              }
            });
        }
      });
  };

  const getMenuData = id => {
    AJAX()
      .getReviewStage(_id, reduxState, id)
      .then(res => {
        const isDataValid = validateData(
          "ReviewAdmin > Collection >  AJAX().getReviewStage",
          res,
          stageCollectionsDataModel
        );

        if (isDataValid) {
          setReviewStageCollections(res.data.stage.collections);
        }
      });
  };

  const handleNavItemClick = () => {
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  // run on unmount
  useEffect(() => {
    return () => {
      AJAX().cancelRequest(_id);
      dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      dispatch({ type: TYPES.SET_HELPER_NAV_AS_NOT_PRESENT });
    };
  }, [_id]);

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={collectionData} addClass="__n_bs">
          <Title tagName="h1">{collectionData.title}</Title>
          <ParseHTML html={collectionData.description} />
          <MainNav
            clicked={handleNavItemClick}
            title={renderLangString({ id: "$_m" })}
            data={reviewStageCollections}
            linkTo={Routes.reviewAdminCollection().path}
            activeItem={match.params.id}
          />
        </AnimateLayout>
      </MainAsideNav>
      <Main counter={counterData}>
        <AnimateLayout animate={collectionData}>
          {tableData && (
            <ErrorHOC crashPage>
              <AlphaTable data={tableData} />
            </ErrorHOC>
          )}
        </AnimateLayout>
      </Main>
      <HelperAsideNav preventFromClosing>
        <AnimateLayout animate={reviewers}>
          <Title style={{ marginBottom: "3.5rem" }}>{renderLangString({ id: "$_b4" })}</Title>
          {reviewers && <UserNav list={reviewers} popoverStatsFor="judges" />}
        </AnimateLayout>
      </HelperAsideNav>
    </NewAppHOC>
  );
}

export default injectIntl(withRouter(Collection));
