import { combineReducers } from "redux";
import appReducer from "../reducers/appReducer";
import entryReducer from "../reducers/entryReducer";
import reviewerReducer from "../reducers/reviewerReducer";
import reviewAdminReducer from "../reducers/reviewAdminReducer";
import userReducer from "../reducers/userReducer";
import { LOGOUT } from "../actions/types";
import persistReducer from "./persistReducer";

// export default combineReducers({
//   app: appReducer,
//   entry: entryReducer,
//   user: userReducer
// })

const reducer = combineReducers({
  app: appReducer,
  entry: entryReducer,
  reviewer: reviewerReducer,
  reviewAdmin: reviewAdminReducer,
  user: userReducer,
  persist: persistReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = { persist: state.persist };
  }

  return reducer(state, action);
};

export default rootReducer;
