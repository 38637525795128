import React from 'react';
import PropTypes from 'prop-types';

const ContentLoader = (props) => {

  if(props.full) {
    return (
      <div className={"containerLoader " + (props.hide ? 'u-hide' : '') + (props.addClass ? props.addClass : '')}>
        <div className="containerLoader__title"></div>
        <div className="containerLoader__content">
          <div className="containerLoader__loader circleLoader-large"></div>
        </div>
      </div>
    );
  }else {
    return (
      <div className={"containerLoader__content " + (props.hide ? 'u-hide' : '') + (props.addClass ? props.addClass : '')}>
        <div className="containerLoader__loader circleLoader-large"></div>
      </div>
    )
  }
};

ContentLoader.propTypes = {
  full: PropTypes.bool
};

export default ContentLoader;
