import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Sortable from "sortablejs";
import { FaTrash, FaUndoAlt, FaFile } from "react-icons/fa";

// COMPONENTS
import ListItem from "../lists/ListItem";

class SortableList extends Component {
  items = this.props.items;
  deletedItems = [];
  state = { items: this.items, deletedItems: [] };
  sortablejsElem = React.createRef();

  componentDidMount() {
    Sortable.create(this.sortablejsElem.current, {
      easing: "cubic-bezier(1, 0, 0, 1)",
      animation: 100,
      handle: ".my-handle",
      filter: ".disable",
      onEnd: this.onSortEnd,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items)
      this.setState({ items: (this.items = this.props.items) });
  }

  listChanged = () => {
    this.props.onListChanged && this.props.onListChanged({ list: this.items });
    this.setState({ items: this.items, deletedItems: this.deletedItems });
  };

  onSortEnd = ({ newIndex, oldIndex }) => {
    if (newIndex === oldIndex) return;
    this.items.splice(newIndex, 0, this.items.splice(oldIndex, 1)[0]);
    this.listChanged();
  };

  handleRemoveItem = (id, index) => {
    const [item] = this.items.splice(index, 1);
    this.deletedItems.push(item);
    this.listChanged();
  };

  handleRestoreItem = (id, index) => {
    const [item] = this.deletedItems.splice(index, 1);
    this.items.push(item);
    this.listChanged();
  };

  handleRemoveAll = () => {
    this.deletedItems = this.deletedItems.concat(this.items);
    this.items.length = 0;
    this.listChanged();
  };

  handleRestoreAll = () => {
    this.items = this.items.concat(this.deletedItems);
    this.deletedItems.length = 0;
    this.listChanged();
  };

  handleChangeItem = (changedItemID, itemIndex, newName) => {
    const item = this.items[itemIndex];

    if (item && item.id === changedItemID && item.originalName !== newName) {
      item.originalName = newName;
      this.listChanged();
    }
  };

  renderItem = (item, index, deleted) => (
    <ListItem
      key={item.id}
      item={item}
      deleted={deleted}
      onChange={(id, name) => this.handleChangeItem(id, index, name)}
      onRestore={id => this.handleRestoreItem(id, index)}
      onRemove={id => this.handleRemoveItem(id, index)}
    />
  );

  renderItems = (items, deleted) =>
    items.map((item, index) => this.renderItem(item, index, deleted));

  render() {
    const { items, deletedItems } = this.state;
    const Icon = ({ i }) => <i style={{ height: "0.9em" }} />;

    return (
      <div>
        <div ref={this.sortablejsElem} className="sortableList">
          {this.renderItems(items, false)}
        </div>

        {items.length !== 0 && (
          <div className="sortableList__footer">
            <span>
              <span>
                {<Icon i={FaFile} />} {items.length}
              </span>
            </span>
            <span className="sortableList__footer-btn c_alert" onClick={this.handleRemoveAll}>
              {<Icon i={FaTrash} />} <FormattedMessage id="removeAll" />
            </span>
          </div>
        )}

        {deletedItems.length !== 0 && (
          <div className="sortableList__deleted">
            <div className="sortableList__deleted-msg">
              <FormattedMessage id="listIsRemovedAfterSave" />
            </div>
            {this.renderItems(deletedItems, true)}
            <div className="sortableList__footer">
              <span className="sortableList__footer-leftMenu">
                <span>
                  {<Icon i={FaTrash} />} {deletedItems.length}
                </span>
              </span>
              <span className="sortableList__footer-btn c_active" onClick={this.handleRestoreAll}>
                {<Icon i={FaUndoAlt} />} <FormattedMessage id="restoreAll" />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SortableList.propTypes = {
  items: PropTypes.array.isRequired,
  onListChanged: PropTypes.func,
};

export default SortableList;
