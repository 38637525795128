import { useState, useEffect } from "react";

const getScreenWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const getScreenHeight = () =>
  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

const useResizeScreen = () => {
  const [screenSize, setScreenSize] = useState(() => {
    try {
      return { x: getScreenWidth(), y: getScreenHeight() };
    } catch (err) {
      console.error(err);
    }
  });

  const handleScreenSize = () => {
    try {
      setScreenSize({ x: getScreenWidth(), y: getScreenHeight() });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleScreenSize);
    return () => window.removeEventListener("resize", handleScreenSize);
  }, []);

  return screenSize;
};

export default useResizeScreen;
