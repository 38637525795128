//** NEW TEMPLATE
import React, { useEffect, useState, useCallback } from "react";
import { injectIntl } from "react-intl";
import Routes from "../../Routes";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector } from "react-redux";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";

// FUNCTIONS
import validateData from "../../functions/validateData";

// DATA MODEL
import judgeGuideTextDataModel from "../../data_model/review_admin/dashboard/guide_text";
import caseDataModel from "../../data_model/review_admin/dashboard/stages";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import Main from "../../components/main/Main";
import ToggleLayout from "../../components/layout/ToggleLayout";
import ReviewCard from "../../components/display/ReviewCard";
import Message from "../../components/alerts/Message";
import AnimateLayout from "../../components/layout/AnimateLayout";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import ParseHTML from "../../functions/ParseHTML";
import getStateFromStageReviewData from "../../functions/getStateFromStageReviewData";

function Dashboard() {
  const _id = 432894072304982309;

  const [stageData, setStageData] = useState([]);
  const [reviewCardInfo, setReviewCardInfo] = useState({});

  const reduxState = useSelector(state => state);

  const init = useCallback(() => {
    getStages();
  }, [getStages]);

  // run once on page load
  useEffect(init, []);

  const getStages = () => {
    AJAX()
      .getReviewStages(_id, reduxState)
      .then(res => {
        // validate the stage data
        const isCaseValid = validateData(
          "ReviewAdmin > Dashboard > AJAX().getReviewStages",
          res,
          caseDataModel
        );

        if (isCaseValid) setStageData(res.data.stages);

        // validate the guide text data
        const isGuideTextValid = validateData(
          "ReviewAdmin > Dashboard > AJAX().getReviewStages",
          res,
          judgeGuideTextDataModel
        );

        if (isGuideTextValid) setReviewCardInfo(res.data.guide_text.body);
      });
  };

  const renderStages = data => {
    const allStages = [
      {
        title: `${renderLangString({ id: "$_6" })}`,
        list: [],
        id1: "489320482903489084",
        id2: "892034823904839480",
        id3: "095802938498302933",
        emptyListMsg: `${renderLangString({ id: "$_7" })}`,
      },
      {
        title: `${renderLangString({ id: "$_8" })}`,
        list: [],
        id1: "952038490328403213",
        id2: "873097910238782291",
        id3: "120938171892380980",
        emptyListMsg: `${renderLangString({ id: "$_9" })}`,
      },
      {
        title: `${renderLangString({ id: "$_a" })}`,
        list: [],
        id1: "127041283091283050",
        id2: "750193908901284902",
        id3: "670982072908392057",
        emptyListMsg: `${renderLangString({ id: "$_b" })}`,
      },
    ];

    data.forEach(stage => {
      // validate(stage);
      if (getStateFromStageReviewData(stage).status === "active") {
        allStages[0].list.push(stage);
      } else if (getStateFromStageReviewData(stage).status === "upcoming") {
        allStages[1].list.push(stage);
      } else if (getStateFromStageReviewData(stage).status === "inactive") {
        allStages[2].list.push(stage);
      }
    });

    return allStages.map(stage => {
      return (
        <ToggleLayout
          id={stage.id2}
          key={stage.id2}
          title={stage.title}
          hideBtns={stage.list.length === 0}
          initialLayout={stage.list.length === 0 && "list"}
        >
          {stage.list.length !== 0 ? (
            stage.list.map(item => {
              return (
                <ReviewCard
                  popoverStatsFor="judges"
                  key={item.id}
                  data={item}
                  clickTarget={Routes.reviewAdminStage().path}
                />
              );
            })
          ) : (
            <Message msg={stage.emptyListMsg} />
          )}
        </ToggleLayout>
      );
    });
  };

  // run on unmount
  useEffect(() => {
    return () => {
      AJAX().cancelRequest(_id);
    };
  }, [_id]);

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={reviewCardInfo}>
          <ParseHTML html={reviewCardInfo} />
        </AnimateLayout>
      </MainAsideNav>
      <Main>
        <AnimateLayout animate={stageData}>{renderStages(stageData)}</AnimateLayout>
      </Main>
      <HelperAsideNav />
    </NewAppHOC>
  );
}

export default injectIntl(Dashboard);
