import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { MdSearch } from "react-icons/md";

class SearchInput extends Component {
  // state = {
  //   timeout: null
  // }

  // componentDidMount() {
  //   if( document.getElementById(this.props.id) ) {
  //     document.getElementById(this.props.id).addEventListener('keyup', () => {

  //       clearTimeout(this.state.timeout)

  //       this.state.timeout = setTimeout(function () {
  //           console.log('Search:', this.props.value);
  //       }, 1500);
  //     })
  //   }
  // }

  render() {
    return (
      <div
        className={
          "searchInput " +
          (this.props.addClass ? this.props.addClass : "") +
          (this.props.value && this.props.value.length > 0 ? " searchInput--active" : "")
        }
        id={this.props.id}
        style={{ width: this.props.width }}
      >
        <MdSearch className="searchInput__icon" test="searchIcon" />
        <input
          className="searchInput__input"
          value={this.props.value}
          placeholder={this.props.intl.formatMessage({ id: "search" })}
          onChange={this.props.handleChange}
        />
      </div>
    );
  }
}

SearchInput.propTypes = {
  width: PropTypes.string,
  addClass: PropTypes.string,
  handleChange: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(SearchInput);
