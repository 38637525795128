import React, { useState, useEffect } from "react";
import { renderLangString } from "../../languageStrings";
import { useSelector } from "react-redux";
import ACTIONS from "../../actions/types";

// REDUX
import { useDispatch } from "react-redux";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";

// FUNCTIONS
import getDateAndTime from "../../functions/getDateAndTime";

// HOOKS
import useTimer from "../../hooks/useTimer";

const StageCounter = ({ data, hide, callback }) => {
  const dispatch = useDispatch();
  const [timer, setDayTime] = useTimer();
  const [isVisible, setIsVisible] = useState(true);
  const [timeIn, setTimeIn] = useState();
  const [status, setTheStatus] = useState();
  const userRole = useSelector(store => store.user.role_selected);

  // for testing ////////////////////////
  // var today = "Thu Nov 20 2020 19:38:19 GMT+0100 (Central European Standard Time)";
  // data.active = true;

  const dateInfo = getDateAndTime({
    date: data.end_at, // data.end_at
  }); //data.start_at, data.end_at, new Date().setSeconds(new Date().getSeconds() + 10)

  useEffect(() => {}, []);

  useEffect(() => {
    init(data);
  }, [data]);

  useEffect(() => {
    if (timer && timer.counterDone) {
      if (callback) callback();
      setTheStatus("expired");
    }
  }, [timer]);

  const init = data => {
    // if end_at is null hide the counter
    if (!data.end_at || hide) {
      setIsVisible(false);
    }

    // if active
    else if (data.active) {
      // check if in days
      if (dateInfo.timeLeft.in === "days" && timeIn !== "days") {
        setTimeIn("days");
      } else if (dateInfo.timeLeft.in === "hours" && timeIn !== "hours") {
        setTimeIn("hours");
      }

      setDayTime(data.end_at);
      setTheStatus("active");
    }

    // if expired
    if (!data.active) {
      setTheStatus("expired");
    }

    // if it starts in the future
    else if (!data.active) {
    }
  };

  const renderTime = () => {
    if (status === "expired") {
      return (
        <Layout flex1 flex justifyContentCenter alignItemsCenter>
          <Icon iconId="clockLine" addClass="__a_i c4" />
          {userRole === "reviewer" && renderLangString({ id: "$_b9" })}
        </Layout>
      );
    } else if (status === "active") {
      let pluralIndex;
      if (timer.days === 1) pluralIndex = 1;
      else if (timer.days > 1) {
        pluralIndex = 2;
      } else if (timer.days === 0) {
        pluralIndex = 0;
      }

      return (
        <Layout flex1 flex justifyContentCenter alignItemsCenter>
          <Icon iconId="clockLine" addClass="__a_i c1" />
          {renderLangString({
            id: "$_l",
            variables: {
              pluralizationIndex: pluralIndex,
              num: timeIn === "days" ? timer.days : timer.counterWithoutDays,
              toWhat: "Something",
              date: "(19.10.2020 - 01:59)",
            },
            html: true,
          })}
        </Layout>
      );
    }
  };

  if (!isVisible) return null;
  return (
    <div className="bc12 br3 __a">
      <Layout flex spaceBetween>
        {renderTime()}
      </Layout>
    </div>
  );
};

export default StageCounter;
