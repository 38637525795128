//** NEW TEMPLATE
import React, { useState, useEffect } from "react";

function Layout(props) {
  const [classes, setClasses] = useState("");

  const classNames = {
    flex: "__ly_f", // make it a flex container
    spaceBetween: "__ly_f-sb", // spread elements horizontally with space between the elements
    alignItemsCenter: "__ly_f-aic", // align items horizontally
    justifyContentCenter: "__ly_f-jcc", // align items horizontally
    flexWrap: "__ly_f-w", // allow element to fall below on window resize
    grid: "__ly_g", // make it a grid container
    gridList: "__ly_g-l", // make a countainer list of items that fall down on resize
    flexColumn: "__ly_f-dc", // make a flex container to columne
    fullHeight: "__ly_fh", // make the container full height
    screenHeight: "__ly_fvp", // make the container full screen height
    lastItemBottom: "__ly_f-mb", // push lest item to bottom of the container
    lastItemRight: "__ly_f-mr", // pull lest item to right side of the container
    marginBottomSmall: "__ly-mbs", // add 1rem as margin bottom
    flex1: "__ly_f1", // take full width
    right: "__ly_r",
  };

  useEffect(() => {
    // select class names based on prop names
    const classArr = [];
    Object.keys(props).forEach(name => {
      if (classNames[name]) {
        classArr.push(classNames[name]);
      }
    });

    // add the classes to the element
    setClasses(`${classArr.join(" ")} ${props.addClass ? props.addClass : ""}`);
  }, [classNames, props]);

  return (
    <div className={classes} style={props.styles} onClick={props.clicked}>
      {props.children}
    </div>
  );
}

export default Layout;
