import * as Sentry from "@sentry/browser";
import store from "../store";

// REDUX
import TYPES from "../actions/types";

// TYPES
import {
  isObject,
  isArray,
  isNumber,
  isString,
  isFunction,
  isBoolean,
  isDate,
  isRegExp,
  isNull,
} from "../types/checkTypes";

const validateData = (errLocation, axiosRes, validationData) => {
  const throwError = (errCausedBy, errorAt, propValue, valType, expectedType) => {
    // display error popup
    store.dispatch({ type: TYPES.ERROR_POPUP });

    const errMsg = createErrorMsg(errCausedBy, errorAt, propValue, valType, expectedType);
    console.error(errMsg, "color:blue");
    Sentry.captureException(new Error(errMsg));
  };

  const createErrorMsg = (errCausedBy, errorAt, propValue, valType, expectedType) => {
    const errorSpecificsMsg = () => {
      if (errCausedBy === "property") {
        return `Property [${errorAt}] does not exist!`;
      }

      if (errCausedBy === "type") {
        return `Invalid type of the property [${errorAt}]! [${errorAt}] value is: [${propValue}] and its type is: [${valType}]. The expected type is: [${expectedType}].`;
      }
    };

    const msg = `Error at: ${errLocation}, at propery: [${errorAt}].
    Request url is: ${axiosRes.request.responseURL}, request status is: ${axiosRes.status}
    ${errorSpecificsMsg()}
    '\n'
    VALIDATION MODEL DATA: ${JSON.stringify(validationData)}
    '\n'
    SEVER DATA: ${JSON.stringify(axiosRes.data)}`;

    return msg;
  };

  const validate = () => {
    let data = axiosRes.data;

    if (!data || !isObject(data)) {
      throwError("both", `data in axios res object`, data, typeof data, "object");
      return false;
    }

    // recursive function to check the types
    const validateProperties = (validationData, realData) => {
      // iterate trough each key in the validationData
      for (let key in validationData) {
        // if key exists in the real data
        if (key in realData) {
          // check the type of that key
          const isTypeValid = checkType(validationData[key].type, realData[key]);

          // if type is valid
          if (isTypeValid) {
            // check if property has its own data
            if (validationData[key].contains) {
              // check if the mocked property is array
              if (validationData[key].type === "array") {
                // if its array pass first object
                validateProperties(validationData[key].contains, realData[key][0]);
              } else {
                validateProperties(validationData[key].contains, realData[key]);
              }
            }
          }
          // if type is invalid
          else {
            throwError("type", key, realData[key], typeof realData[key], validationData[key].type);
          }
        }
        // if key does not exist in the real data
        else {
          throwError("property", key);
        }
      }
    };
    validateProperties(validationData, data);

    return true;
  };

  const checkType = (type, data) => {
    switch (type) {
      case "string":
        return isString(data);
      case "number":
        return isNumber(data);
      case "object":
        return isObject(data);
      case "array":
        return isArray(data);
      case "function":
        return isFunction(data);
      case "boolean":
        return isBoolean(data);
      case "date":
        return isDate(data);
      case "regex":
        return isRegExp(data);
      case "null":
        return isNull(data);
      case "any":
        return true;

      default:
        return false;
    }
  };

  return validate();
};

export default validateData;
