export default function (state = null, action) {
  switch (action.type) {
    case "SET_PAGE_IDS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_REVIEWER_ADMIN_EXPIRE_DATE":
      return {
        ...state,
        counterData: action.payload,
      };

    default:
      return state;
  }
}
