import React, { Component } from "react";

// COMPONENTS
import LoginCompanyInfo from "../components/display/LoginCompanyInfo";
import Auth from "../containers/Auth";
// import Slider from '../components/gallery/Slider';
import FooterMain from "../components/footers/FooterMain";

// REDUX
import { connect } from "react-redux";

// HOC
import AppHOC from "../hoc/AppHOC";

export class LoginPage extends Component {
  isReviewer = role => ["judge", "reviewer"].includes(role);
  isReviewAdmin = role => ["judge_admin", "review_admin"].includes(role);

  componentDidMount() {
    // add a judge theme colors
    const role = this.props.match.params.module;
    if (this.isReviewer(role) || this.isReviewAdmin(role))
      document.querySelector("body").classList.add("theme-1");
  }

  render() {
    const { loginData } = this.props;
    const role = this.props.match.params.module;

    if (!loginData) return null;
    return (
      <AppHOC>
        <div
          className={
            this.isReviewer(role) || this.isReviewAdmin(role)
              ? "LoginPage LoginPage__judge"
              : "LoginPage"
          }
        >
          <div className="loginContainer">
            <div
              className={
                "loginMain " +
                (!this.props.loginData.company_info ? "loginMain__noCompanyInfo" : "")
              }
            >
              {this.props.loginData.company_info ? (
                <LoginCompanyInfo data={this.props.loginData.company_info} />
              ) : null}
              <Auth data={this.props.loginData} />
            </div>
            {/* <Slider/> */}
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

const mapStateToProps = state => ({
  info: state.app.info,
  loginData: state.app.loginData,
});

export default connect(mapStateToProps)(LoginPage);
