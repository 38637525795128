
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { axiosWithAuth } from '../axiosSetup';

// REDUX
import { connect } from 'react-redux';
import { getEntrySteps, setEntryStepActive } from '../actions/entryActions';
import { hideMenu } from '../actions/appActions';

// COMPONENTS
import MainHeaderNav from '../components/navigations/MainHeaderNav';
import MainBoxNav from '../components/navigations/MainBoxNav';
import FooterMain from '../components/footers/FooterMain';

// HOC
import AppHOC from '../hoc/AppHOC';

// VENDORS
import { ContentContainer } from '../components/layout/ContentContainer';

export class AdminModerate extends Component {
  signal = axiosWithAuth.CancelToken.source();

  state = {
    toggle: false,
    toggle1: false

  }

  componentDidMount() {
    this.generateContainet();
  }


  toggle = () => {
    this.setState({toggle: !this.state.toggle})
  }
  toggle1 = () => {
    this.setState({toggle1: !this.state.toggle1})
  }

  aysincHeight = () => {
    return setTimeout(() => {
      return '400px';
    }, 1500);
  }

  generateContainet = () => {
    setTimeout(() => {
      this.setState({aysinc: true, text: `Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidatat voluptate irure aliqua quis commodo ut. Officia consectetur cillum ex non exercitation consectetur. Aliquip esse esse in ea laborum. Proident enim quis fugiat irure sit elit ullamco.
      Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidatat voluptate irure aliqua quis commodo ut. Officia consectetur cillum ex non exercitation consectetur. Aliquip esse esse in ea laborum. Proident enim quis fugiat irure sit elit ullamco.
      Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidata`})

    }, 3000);
  }

  render() {
    return (
    <AppHOC>
    <div id="page">
      <MainHeaderNav/>
      <MainBoxNav/>
      <div className="rowCon rowCon__withMargin">

        <div className="dashboardMain">

        <ContentContainer title="Title">

          <button onClick={this.toggle}>Toggle</button>
          {/* <ToggleSlideContent toggle={this.state.toggle}  setHeightOnAysinc={this.state.aysinc}>
            {this.state.text}
          </ToggleSlideContent>

          <button onClick={this.toggle1}>Toggle</button>
          <ToggleSlideContent toggle={this.state.toggle1}  setHeightOnAysinc={true}>
              Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidatat voluptate irure aliqua quis commodo ut. Officia consectetur cillum ex non exercitation consectetur. Aliquip esse esse in ea laborum. Proident enim quis fugiat irure sit elit ullamco.
              Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidatat voluptate irure aliqua quis commodo ut. Officia consectetur cillum ex non exercitation consectetur. Aliquip esse esse in ea laborum. Proident enim quis fugiat irure sit elit ullamco.
              Et ut consectetur aute velit. Ipsum do in mollit elit in ipsum magna. Nulla mollit sint cupidatat enim esse minim labore cupidatat voluptate irure aliqua quis commodo ut. Officia consectetur cillum ex non exercitation consectetur. Aliquip esse esse in ea laborum. Proident enim quis fugiat irure sit elit ullamco.
          </ToggleSlideContent> */}

        </ContentContainer>

        </div>
      </div>
      <FooterMain/>
    </div>

    </AppHOC>
    );
  }
}

AdminModerate.propTypes = {
}


const mapStateToProps = store => ({
  entryStepData: store.entry.steps,
  info: store.app.info
})


export default injectIntl(connect(mapStateToProps, {getEntrySteps, setEntryStepActive, hideMenu})(withRouter(AdminModerate)));
