const breakWords = (text, breakOn) => {
  const newWorsArr = [];
  const wordsArr = text.split(" ");

  wordsArr.forEach(word => {
    if (word.length > breakOn) {
      newWorsArr.push(`${word.slice(0, breakOn)}- \n`);
      newWorsArr.push(word.slice(breakOn));
    } else {
      newWorsArr.push(word);
    }
  });

  return newWorsArr.join(" ");
};

export default breakWords;
