const stageCollectionsDataModel = {
  stage: {
    type: "object",
    contains: {
      collections: {
        type: "array",
        contains: {
          id: { type: "string" },
          confirmed: { type: "boolean" },
          title: { type: "string" },
          stats: {
            type: "object",
            contains: {
              done: { type: "number" },
              todo: { type: "number" },
              total: { type: "number" },
            },
          },
        },
      },
    },
  },
};

export default stageCollectionsDataModel;
