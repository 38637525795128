import React from 'react';

// REDUX
import { Provider } from 'react-redux';
import store from '../store';

const ReduxWrapper = (props) => (
  <Provider store={store}>
    {props.children}
  </Provider>
)

export default ReduxWrapper;