import React, { Component } from "react";
import AlphaLogo from "../../img/alpha-logo-black-with-text.png";
// import AlphaLogoSmall from '../../img/logo-black.png';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  MdInfo,
  // MdEmail,
  // MdShoppingCart,
  MdKeyboardArrowDown,
  MdCardTravel,
  MdVpnKey,
  MdPerson,
  MdSettings,
} from "react-icons/md";
// import {FaBook} from 'react-icons/fa';
import { NavLink, Link, withRouter } from "react-router-dom";
import { classes } from "../../functions/utils";

// COMPONENTS
import Select from "../inputs/Select";
import IconAria from "../icons/IconAria";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

// REDUX
import { connect } from "react-redux";
import { logout, setUserRole } from "../../actions/userActions";

class MainHeaderNav extends Component {
  state = {
    userMenuVisible: false,
    blockOptions: false,
  };

  UNSAFE_componentWillMount() {
    if (this.props.user && this.props.user.roles) {
      this.setTheUserRoleMenu(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.roles) {
      this.setTheUserRoleMenu(nextProps);
    }
  }

  componentDidMount() {
    this.userMenuTag = document.getElementById("userMenu516");
    document.addEventListener("mouseup", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  setTheUserRoleMenu = props => {
    const modules = props.info.apps[this.props.info.default_app].modules;

    const { labels, roles, role_selected } = this.props.user;
    // only display those for which a module exists
    const rolesMenu = roles.filter(r => modules[r]);

    let selectedRoleItem;
    const formattedMenu = rolesMenu.map((roleKey, index) => {
      const item = { id: index, key: roleKey, label: labels[roleKey] };
      if (roleKey === role_selected) selectedRoleItem = item;
      return item;
    });

    this.setState({ userRoleMenu: formattedMenu, selectedRoleItem });
  };

  // add click (mousedown) event on the document
  // when documet is clicked - hide menu
  handleClickOutside = e => {
    // if menu is visible
    if (this.userMenuTag.offsetParent !== null) {
      e.stopPropagation();
      let target = e.target;
      // if user clicked on inner svg element - <path>
      // set target to svg because svg has data-menu attr
      if (target.hasAttribute("d")) target = target.parentNode;

      // if element has data-menu attr it menas
      // that menu has be clicked and dropmenu
      // should not be removed
      if (!target.hasAttribute("data-menu")) this.setState({ userMenuVisible: false });
    }
  };

  // show user dropdown navigation
  showMenu = () => {
    this.setState({ userMenuVisible: !this.state.userMenuVisible }, () => {
      if (this.state.userMenuVisible) {
        //window.addEventListener("keydown", this.hideMenu);
      }
    });
  };

  hideMenu = e => {
    if (e.key === "Escape") {
      this.setState({ userMenuVisible: false });
      //window.removeEventListener("keydown", this.hideMenu);
    }
  };

  logoutUser = () => {
    AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
    this.props.logout();
    this.props.history.push("/ui/auth");
    document.querySelector("body").classList.remove("theme-1");
  };

  render() {
    return (
      <div className="mainHeaderNav">
        <Link to="/ui/" test="logoLink">
          <img
            src={AlphaLogo}
            title="Home"
            alt="alpha awards logo"
            data-test="logo"
            className="mainHeaderNav__logo"
          />
          {/*<img src={AlphaLogoSmall} alt="alpha awards" className="mainHeaderNav__logoSmall"/>*/}
        </Link>

        {/* Print this info to avoid mistakes during development. */}
        {process.env.NODE_ENV === "development" && AA_GLOBAL.baseURL && (
          <span>Connected to backend: "{AA_GLOBAL.baseURL}"</span>
        )}

        <nav role="navigation" className="mainHeaderNav__nav">
          <ul className="mainHeaderNav__nav-items">
            {this.state.userRoleMenu && this.state.userRoleMenu.length > 1 && (
              <li>
                <Select
                  options={this.state.userRoleMenu}
                  blockOptions={true}
                  addClass="mainHeaderNav__nav-role"
                  select={[this.state.selectedRoleItem]}
                  getValues={val => AA_GLOBAL.switchUserRole(val[0].key, this.props)}
                />
              </li>
            )}

            <li>
              <NavLink
                to="/ui/info"
                aria-label={this.props.intl.formatMessage({ id: "info" })}
                className="button__nav"
                activeClassName="button__nav--active"
              >
                <IconAria iconId="info" className="mainHeaderNav__icon" />
              </NavLink>
            </li>
            {/* <li><a href="#empty" className="button button__nav button__nav--alert"><MdEmail className="mainHeaderNav__icon"/></a></li>
            <li>
              <NavLink
                to={`/ui/cart`}
                className="button__nav button__nav--alert"
                activeClassName="button__nav--active"
                test="cartLink"
                exact={true}>
                <MdShoppingCart className="mainHeaderNav__icon"/>
              </NavLink>
            </li> */}
            <li onClick={this.showMenu} data-test="userBtn" data-menu>
              <span
                onKeyDown={e => e.key === "Enter" && this.showMenu()}
                data-menu
                tabIndex="0"
                className="button__nav mainHeaderNav__user"
              >
                <IconAria
                  iconId="user"
                  className="mainHeaderNav__icon mainHeaderNav__user-icon"
                  data-menu
                />
                <span className="mainHeaderNav__user-name" data-menu></span>
                <span data-menu className="mainHeaderNav__user-arrow">
                  <IconAria iconId="arrowDown" data-menu />
                </span>
                <ul
                  id="userMenu516"
                  data-test="userMenuDropdown"
                  data-menu
                  className={classes(
                    "mainHeaderNav__user-menu",
                    this.state.userMenuVisible ? "" : "mainHeaderNav__user-menu-hide"
                  )}
                >
                  {this.props.user && this.props.user.rights === "admin" && (
                    <li>
                      <Link
                        to="/ui/submission_admin/dashboard"
                        data-menu
                        className="mainHeaderNav__user-menu-a"
                        test="adminLink"
                      >
                        <IconAria iconId="adminDashboard" />
                        <span className="mainHeaderNav__user-menu-text">
                          <FormattedMessage id="admin" />
                        </span>
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      to={`/ui/${this.props.user.role_selected}/profile/edit`}
                      data-menu
                      className="mainHeaderNav__user-menu-a"
                      test="profileLink"
                    >
                      <IconAria iconId="profile" />
                      <span className="mainHeaderNav__user-menu-text">
                        <FormattedMessage id="profile" />
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/ui/payments"
                      data-menu
                      className="mainHeaderNav__user-menu-a"
                      test="paymentsLink">
                      <FaBook/> <span className="mainHeaderNav__user-menu-text"><FormattedMessage id="viewInvoices"/></span>
                    </Link>
                  </li> */}
                  <li>
                    <button
                      data-menu
                      onClick={this.logoutUser}
                      className="mainHeaderNav__user-menu-a"
                      test="logoutLink"
                    >
                      <IconAria iconId="logout" />
                      <span className="mainHeaderNav__user-menu-text">
                        <FormattedMessage id="logout" />
                      </span>
                    </button>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user,
  info: store.app.info,
});

export default injectIntl(
  connect(mapStateToProps, { logout, setUserRole })(withRouter(MainHeaderNav))
);
