const stageDataModel = {
  stage: {
    type: "object",
    contains: {
      confirmed: { type: "boolean" },
      end_at: { type: "string" },
      id: { type: "string" },
      in_range: { type: "number" },
      start_at: { type: "string" },
      title: { type: "string" },
      stats: {
        type: "object",
        contains: {
          done: { type: "number" },
          todo: { type: "number" },
          total: { type: "number" },
        },
      },
    },
  },
};

export default stageDataModel;
