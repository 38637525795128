import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import AlphaLogo from "../../img/logo.png";

//  REDUX
import { useSelector } from "react-redux";

// COMPONENTS
import Layout from "../layout/Layout";

// GLOBALS
import AA_GLOBAL from "../../globals/globals";

// FUNCTIONS
import S from "../../functions/select";

const Footer = ({ addClass }) => {
  const selectedLang = useSelector(
    state => state.app.info.apps[state.app.info.default_app].chosen_locale
  );
  const languageList = useSelector(
    state => state.app.info.apps[state.app.info.default_app].locales
  );

  useEffect(() => {
    S("html")[0].setAttribute("lang", selectedLang.split("-")[0]);
  }, []);

  const changeLanguage = newLocale => {
    AA_GLOBAL.cookies.set("lang", newLocale, { path: "/" });

    window.location.reload();
  };

  const renderLangMenu = () => {
    const createMenuData = languageList.map(langName => ({
      langName,
      label: langName.split("-")[0].toUpperCase(),
      isChosen: langName === selectedLang,
    }));

    return createMenuData.map((item, index) => (
      <span key={item.label}>
        <span
          role="button"
          className={`__b ${item.isChosen && "__fo_ls"}`}
          tabIndex={item.langName === selectedLang ? "-1" : "0"}
          onClick={() => changeLanguage(item.langName)}
          onKeyDown={e => e.key === "Enter" && changeLanguage(item.langName)}
        >
          {item.label}
        </span>
        {index + 1 !== createMenuData.length && <span className="__fo_s"> | </span>}
      </span>
    ));
  };

  return (
    <footer className={`bc12 ${addClass}`}>
      <Layout flex spaceBetween>
        <div>{renderLangMenu()}</div>
        <div>
          <a
            href="https://www.alpha-awards.com/"
            className="__fo_bl c16"
            data-test="AA_link"
            target="_black"
          >
            <span>
              <FormattedMessage id="powerdBy" />
            </span>
            <img src={AlphaLogo} alt="alpha awards" className="__fo_i" data-test="AA_logo" />
          </a>
        </div>
      </Layout>
    </footer>
  );
};

export default Footer;
