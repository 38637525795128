//** NEW TEMPLATE
//@audit-issue save id into the cookie, put data into info.json

export default {
  id: 142436378472,
  reviewer: {
    dashboard: {
      activeItem: "-6px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/reviewer/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    stage: {
      activeItem: "46px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/reviewer/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/reviewer/stage",
            urlIdKey: "stage",
            ariaLabel: "$_4",
            iconProps: { iconId: "categoriesFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    collection: {
      activeItem: "97px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/reviewer/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/reviewer/stage",
            urlIdKey: "stage",
            ariaLabel: "$_4",
            iconProps: { iconId: "categoriesFull", addClass: "c12" },
          },
          {
            id: 3,
            url: "/ui/reviewer/collection",
            ariaLabel: "$_5",
            urlIdKey: "collection",
            iconProps: { iconId: "listFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    case: {
      body: {
        main: [
          {
            id: 1,
            url: "/ui/reviewer/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/reviewer/stage",
            ariaLabel: "$_4",
            urlIdKey: "stage",
            iconProps: { iconId: "categoriesFull", addClass: "c12" },
          },
          {
            id: 3,
            url: "/ui/reviewer/collection",
            ariaLabel: "$_5",
            urlIdKey: "collection",
            iconProps: { iconId: "listFull", addClass: "c12" },
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
  },
  review_admin: {
    dashboard: {
      activeItem: "-6px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/review_admin/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    stage: {
      activeItem: "46px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/review_admin/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/review_admin/stage",
            urlIdKey: "stage",
            ariaLabel: "$_4",
            iconProps: { iconId: "categoriesFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    collection: {
      activeItem: "97px",
      body: {
        main: [
          {
            id: 1,
            url: "/ui/review_admin/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/review_admin/stage",
            urlIdKey: "stage",
            ariaLabel: "$_4",
            iconProps: { iconId: "categoriesFull", addClass: "c12" },
          },
          {
            id: 3,
            url: "/ui/review_admin/collection",
            ariaLabel: "$_5",
            urlIdKey: "collection",
            iconProps: { iconId: "listFull", addClass: "c1" },
            active: true,
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    case: {
      body: {
        main: [
          {
            id: 1,
            url: "/ui/review_admin/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/review_admin/stage",
            ariaLabel: "$_4",
            urlIdKey: "stage",
            iconProps: { iconId: "categoriesFull", addClass: "c12" },
          },
          {
            id: 3,
            url: "/ui/review_admin/collection",
            ariaLabel: "$_5",
            urlIdKey: "collection",
            iconProps: { iconId: "listFull", addClass: "c12" },
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
    reviewer: {
      body: {
        main: [
          {
            id: 1,
            url: "/ui/review_admin/dashboard",
            ariaLabel: "$_2",
            iconProps: { iconId: "reviewCaseFull", addClass: "c12" },
          },
          {
            id: 2,
            url: "/ui/review_admin/stage",
            ariaLabel: "$_4",
            urlIdKey: "stage",
            iconProps: { iconId: "categoriesFull", addClass: "c12" },
          },
          {
            id: 3,
            url: "/ui/review_admin/collection",
            ariaLabel: "$_5",
            urlIdKey: "collection",
            iconProps: { iconId: "listFull", addClass: "c12" },
          },
        ],
        subMenu: [],
      },
      footer: [
        {
          id: 1,
          elID: "logoutBtn",
          button: "logout",
          ariaLabel: "$_3",
          iconProps: { iconId: "logoutFull", addClass: "c12" },
        },
      ],
    },
  },
};
