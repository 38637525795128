import React, { Component } from 'react';
import { FaTrash, FaUndoAlt, FaGripVertical, FaFileAlt } from 'react-icons/fa';
import { MdEdit, MdCheck, MdClose, MdPictureAsPdf, MdAudiotrack } from 'react-icons/md';
import ImageIconReplacement from '../../img/placeholders/imageIcon.jpg'
import VideoIconReplacement from '../../img/placeholders/videoIcon.jpg'

// COMPONENTS
import Popover from '../popovers/Popover';

class ListItem extends Component {
  originalInputValue = this.props.item.originalName;
  inputRef = React.createRef();

  state = {
    inputVal: this.originalInputValue,
    toggleItem: false,
    disable: false
  };

  handleInput = e => this.setState({ inputVal: e.target.value });

  toggleEditForm = (e) => {
    this.setState({toggleItem: !this.state.toggleItem, disable: !this.state.disable}, () => {
      if(this.state.toggleItem) this.inputRef.current.focus();
    })
  }

  hideForm = () =>
    this.setState({ toggleItem: false, inputVal: this.originalInputValue, disable: false });

  submit = (e) => {
    e.preventDefault();
    this.setState({ disable: false });
    this.props.onChange(this.props.item.id, this.state.inputVal);
    this.originalInputValue = this.state.inputVal;
    this.hideForm();
  }

  handleClick = e => e.target !== document.activeElement && this.inputRef.current.focus();

  handleMouseOver = e => this.setState({ disable: false });
  handleMouseOut = e => this.setState({ disable: true });

  selectText = e => e.target.select();

  generateThumbnail = (item) => {
    const type = (typeof item.type === "string" ? item.type : "undefined").split('/')[0];
    let thumbnail;

    if(type === "image") {
      thumbnail = <div img-url={`${item.url}/preview`} style={{backgroundImage: [`url(${item.url}/thumbnail)`, `url(${ImageIconReplacement})`]}} className="sortableList__item-img"></div>;
    }else if (type === 'video') {
      thumbnail = <div img-url={`${item.url}/preview`} style={{backgroundImage: [`url(${item.url}/thumbnail)`, `url(${VideoIconReplacement})`]}} className="sortableList__item-img"></div>;
    }else if (type === 'audio') {
      thumbnail = <div className="sortableList__item-img sortableList__item-thumbnail"><MdAudiotrack/></div>;
    }else if (item.type === 'application/pdf') {
      thumbnail = <div className="sortableList__item-img sortableList__item-thumbnail"><MdPictureAsPdf/></div>;
    }else {
      thumbnail = <div className="sortableList__item-img sortableList__item-thumbnail"><FaFileAlt/></div>;
    }
    // return <span><Popover imageInspector>{thumbnail}</Popover></span>;
    return thumbnail;
  }

  render() {
    const { item, deleted } = this.props;

    return (
      <div
        className={"sortableList__item-con " + (this.state.disable ? 'disable' : "")}
        key={item.id}>
        <div
        // onMouseDown={(e) => this.state.toggleItem ? e.preventDefault() : true}
        className={"sortableList__item " + (deleted ? 'sortableList__item-deleted' : '')}
        id={item.id}
        >
          {!deleted && <FaGripVertical className="sortableList__item-icon my-handle"/>}
          {this.generateThumbnail(item)}
        <div className="sortableList__text">
          <Popover removeMinusOnBreak msg={item.originalName}>
            {item.originalName}
          </Popover>
        </div>
        <div className="sortableList__menu">
          {!deleted && <MdEdit className="sortableList__menu-edit" onClick={this.toggleEditForm} />}
          <Popover intl msg={deleted ? "restore" : "delete"}>
            {deleted ?
              <FaUndoAlt className="sortableList__menu-undo" onClick={() => this.props.onRestore(item.id)}/>
            :
              <FaTrash className="sortableList__menu-del" onClick={() => this.props.onRemove(item.id)}/>
            }
          </Popover>
        </div>

      </div>
      <div className={"sortableList__menu-edit-con " + (this.state.toggleItem ? '' : 'u-hide')}>
        <form noValidate className="sortableList__form" onSubmit={this.submit}>
          <input
            ref={this.inputRef}
            onChange={this.handleInput}
            onClick={this.handleClick}
            onMouseOver={this.handleMouseOver}
            onMouseOut={this.handleMouseOut}
            onDoubleClick={this.selectText}
            type="text"
            className="sortableList__form-input"
            value={this.state.inputVal}/>
          <button type="submit" className="sortableList__form-btn"><MdCheck/></button>
          <button type="button" className="sortableList__form-btn" onClick={this.hideForm}><MdClose/></button>
        </form>
      </div>
    </div>

    );
  }
}

ListItem.propTypes = {
};

export default ListItem;
