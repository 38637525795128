const hasScheme = url => /^\s*(\w+:)?\/\//.test(url);

function validURL(str) {
  const a = document.createElement("a");
  a.href = hasScheme(str) ? str : `//${str.trimLeft()}`;
  return /\.[a-z]+$/i.test(a.hostname) && a.host !== window.location.host;
}

export default validURL;
export { hasScheme };
