//** NEW TEMPLATE
import React from "react";
import { injectIntl } from "react-intl";
import renderLangString from '../../languageStrings'


const ProgressBar = ({ width, textStats, intl, color, addClass }) => {
  return (
    <div className={`__p ${addClass}`} data-name="ProgressBar">
      <div className="__p_h"></div>
      <div className={`__p_b __p_s bc15`}>
        <div className={`__p_p bc1`} style={{ width: width }}></div>
      </div>
      {textStats && <div className="__p_f">{renderLangString({id: textStats})}</div>}
    </div>
  );
};

export default injectIntl(ProgressBar);
