import React from "react";

// COMPONENTS
import Layout from "../layout/Layout";

const LoadingSpinner = ({ style }) => {
  return (
    <Layout flex justifyContentCenter styles={style || { margin: "4rem 0" }}>
      <div className="__l"></div>
    </Layout>
  );
};

export default LoadingSpinner;
