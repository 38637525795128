//** NEW TEMPLATE
import React from "react";
import { withRouter } from "react-router-dom";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import NewButton from "../buttons/NewButton";

const TogglePrompt = ({
  iconID,
  title,
  history,
  msg,
  url,
  view,
  btnText,
  callback,
  confirmer,
  style,
}) => {
  const handleButtonClick = () => {
    if (url) {
      history.push(url);
    } else if (callback) {
      callback();
    }
  };

  // to use confirmer you need to add title, message, btn(object)
  if (confirmer)
    return (
      <div className={`__j bc1 c10`} style={style} data-name="TogglePrompt">
        <Layout flex>
          <div>{iconID && <Icon iconId={iconID} addClass="__j_i" />}</div>
          <div className="__j_c">
            <h2 className="__j_t">{!view ? title[0] : title[1]}</h2>
            <p>{!view ? msg[0] : msg[1]}</p>
            <div className="__j_b">
              <NewButton value={!view ? btnText[0] : btnText[1]} clicked={handleButtonClick} />
            </div>
          </div>
        </Layout>
      </div>
    );

  return (
    <div className="__r br2" style={style} data-name="TogglePrompt">
      <p className={`__rt ${view ? "c4" : ""}`}>{!view ? msg[0] : msg[1]}</p>
      <NewButton
        toggle
        btnStyle="flat"
        colStyle={view ? "default" : "danger"}
        addClass="__rb"
        clicked={handleButtonClick}
      >
        {!view ? btnText[0] : btnText[1]}
      </NewButton>
    </div>
  );
};

export default withRouter(TogglePrompt);

// const TogglePrompt = ({ action, excluded }) => {
//   console.log(action, excluded);
//   const mounted = useMounted();
//   const safeAxios = useSafeAxios(axiosWithAuth, mounted);
//   const safeDelay = useSafeDelay(mounted);

//   const onClick = useCallback(() => {
//     if (action) {
//       safeDelay(250)
//         .then(_ => BaseFormio.apiRequest(safeAxios, action.api, {}))
//         .then(r => {
//           return r.isOK;
//         })
//         .catch(error => {
//           mounted() && console.log("Error: @todo show error msg to user");
//           // throw error;
//         });
//     }
//   }, [action, mounted, safeAxios, safeDelay]);

//   return (
//     <div className="__r br2">
//       <p className="__rt">
//         If you should {excluded ? "" : "not"} vote, please confirm by clicking on the button below.
//       </p>
//       <button className="__rb c10 bc4" onClick={onClick}>
//         {action.label || "Exclude me"}
//       </button>
//     </div>
//   );
// };
