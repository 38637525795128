//** NEW TEMPLATE
import React, { useEffect, useRef } from "react";
import renderLangString from "../../languageStrings";
import Routes from "../../Routes";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES, { TOGGLE_HELLPER_SIDE_NAV, HIDE_HELPER_NAV_ON_TOP } from "../../actions/types";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import NewButton from "../buttons/NewButton";
import NavSeperator from "../navigations/NavSeperator";
import ModalPortal from "../modals/ModalPortal";
import ModalBackground from "../modals/ModalBackground";

// FUNCTIONS
import S from "../../functions/select";

// GLOBALS
import GLOBALS from "../../globals/index";

// HOOKS
import useResizeScreen from "../../hooks/useResizeScreen";

const HelperAsideNav = ({ children, header, footer, preventFromClosing }) => {
  const screenSize = useResizeScreen();
  const containerEl = useRef();
  const appStore = useSelector(state => state.app);
  const isHelperMenuVisibleOnTop = useSelector(store => store.app.isHelperMenuVisibleOnTop);
  const sizeOfTheScreen = useSelector(store => store.app.sizeOfTheScreen);
  const dispatch = useDispatch();

  useEffect(() => {
    GLOBALS.screenSizeChangedFor += 1;
  }, [screenSize]);

  useEffect(() => {
    // on rezise
    if (GLOBALS.screenSizeChangedFor && GLOBALS.screenSizeChangedFor > 5) {
      // GLOBALS.wasResizedToMobile = true;
      if (sizeOfTheScreen === "mobile") {
        dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      } else if (
        sizeOfTheScreen === "desktop" &&
        Routes.getData().module === "review_admin" &&
        (Routes.getData().page === "collection" || Routes.getData().page === "stage")
      ) {
        console.log("Routes.getData()", Routes.getData());
        dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
      }
    } else {
      if (sizeOfTheScreen === "mobile") {
        dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      }
    }
  }, [sizeOfTheScreen]);

  useEffect(() => {
    return () => {
      GLOBALS.screenSizeChangedFor = 0;
    };
  }, []);

  const hideHeperMenu = () => {
    dispatch({ type: TYPES.TOGGLE_HELLPER_SIDE_NAV });
  };

  const hideHeperMenuOnKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch({ type: TYPES.TOGGLE_HELLPER_SIDE_NAV });
      S("#btn-19004981")[0].focus();
    }
  };

  const handleBlackModalBackgroundClick = () => {
    dispatch({ type: TYPES.HIDE_HELPER_NAV_ON_TOP });
  };

  const renderHeader = () => (
    <div className="__sm_h">
      <div>
        {!preventFromClosing && (
          <NewButton
            ariaLabel={renderLangString({ id: "$_a9" })}
            id="btn-390481094"
            addClass="__sm_hb"
            btnStyle="noStyles"
            colStyle="noColor"
            clicked={hideHeperMenu}
            onKeyDown={hideHeperMenuOnKeyPress}
          >
            <Icon iconId="x" addClass="c17" style={{ display: "block" }} />
          </NewButton>
        )}
      </div>
    </div>
  );

  return (
    <div data-name="HelperAsideNav">
      <aside
        id="helper-aside-nav"
        className={`__sm __ha bc12 ${
          !appStore.helperSideNavToggle && !appStore.isHelperMenuVisibleOnTop ? "__sm-h" : ""
        }`}
      >
        <NavSeperator
          header={renderHeader()}
          footer={
            (footer && <div className="__sm_f">{footer}</div>) || <div className="__sm_f"></div>
          }
        >
          <div ref={containerEl}>
            {children}
            <div></div>
          </div>
        </NavSeperator>
      </aside>
      {isHelperMenuVisibleOnTop && (
        <ModalPortal>
          <ModalBackground clicked={handleBlackModalBackgroundClick} />
        </ModalPortal>
      )}
    </div>
  );
};

export default HelperAsideNav;
