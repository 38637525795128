// APP
export const APP_INIT = "APP_INIT";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SHOW_MOB_MENU = "SHOW_MOB_MENU";
export const HIDE_MOB_MENU = "HIDE_MOB_MENU";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const RESET_STORE_PROPS = "RESET_STORE_PROPS";
export const GALLERY_FULLSCREEN_TOGGLE = "GALLERY_FULLSCREEN_TOGGLE";
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";
export const GET_INFO = "GET_INFO";
export const SET_PAGE_WIDTH = "SET_PAGE_WIDTH";

// ENTRY
export const ADD_ENTRIES = "ADD_ENTRIES";
export const GET_ENTRY_REQUIREMENTS = "GET_ENTRY_REQUIREMENTS";
export const GET_PROJECT_OVERVIEW_DATA = "GET_PROJECT_OVERVIEW_DATA";
export const GET_OPENED_ENTRY_REQUIREMENTS = "GET_OPENED_ENTRY_REQUIREMENTS";
export const SAVE_UPLOAD_DATA = "SAVE_UPLOAD_DATA";
export const CLEAR_UPLOAD_DATA = "CLEAR_UPLOAD_DATA";
export const CLEAR_ENTRY_STEP_DATA = "CLEAR_ENTRY_STEP_DATA";
export const DISPLAY_ENTRY_STEP = "DISPLAY_ENTRY_STEP";
export const SAVE_FORM_SUBMISSION = "SAVE_FORM_SUBMISSION";
export const GET_ENTRY_STEPS = "GET_ENTRY_STEPS";
export const CLEAR_ENTRY_STEPS = "CLEAR_ENTRY_STEPS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const FORM_SUBMITED = "FORM_SUBMITED";
export const CHECK_LIST_STEP = "CHECK_LIST_STEP";
export const CLEAR_ACTIVE_ENTRY_STEP = "CLEAR_ACTIVE_ENTRY_STEP";
export const SUBMISSION_DEADLINE_EXPIRED = "SUBMISSION_DEADLINE_EXPIRED";

export const GET_SESSION = "GET_SESSION";
export const SAVE_SESSION_AND_INFO = "SAVE_SESSION_AND_INFO";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const LOGOUT = "LOGOUT";

// NEW TEMPLATE
export const SET_PAGE_IDS = "SET_PAGE_IDS";
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";
export const TOGGLE_HELLPER_SIDE_NAV = "TOGGLE_HELLPER_SIDE_NAV";
export const HIDE_HELLPER_SIDE_NAV = "HIDE_HELLPER_SIDE_NAV";
export const SHOW_HELLPER_SIDE_NAV = "SHOW_HELLPER_SIDE_NAV";
export const HIDE_MAIN_MENU = "HIDE_MAIN_MENU";
export const SHOW_MAIN_MENU = "SHOW_MAIN_MENU";
export const SET_REVIEWER_EXPIRE_DATE = "SET_REVIEWER_EXPIRE_DATE";
export const SHOW_MAIN_MENU_ON_TOP = "SHOW_MAIN_MENU_ON_TOP";
export const HIDE_MAIN_MENU_ON_TOP = "HIDE_MAIN_MENU_ON_TOP";
export const SET_HELPER_NAV_AS_PRESENT = "SET_HELPER_NAV_AS_PRESENT";
export const SET_HELPER_NAV_AS_NOT_PRESENT = "SET_HELPER_NAV_AS_NOT_PRESENT";
export const SHOW_HELPER_NAV_ON_TOP = "SHOW_HELPER_NAV_ON_TOP";
export const HIDE_HELPER_NAV_ON_TOP = "HIDE_HELPER_NAV_ON_TOP";

const TYPES = {
  SET_PAGE_IDS: "SET_PAGE_IDS",
  TOGGLE_HELLPER_SIDE_NAV: "TOGGLE_HELLPER_SIDE_NAV",
  SHOW_HELPER_NAV_ON_TOP: "SHOW_HELPER_NAV_ON_TOP",
  HIDE_HELPER_NAV_ON_TOP: "HIDE_HELPER_NAV_ON_TOP",
  SHOW_MAIN_MENU_ON_TOP: "SHOW_MAIN_MENU_ON_TOP",
  HIDE_MAIN_MENU_ON_TOP: "HIDE_MAIN_MENU_ON_TOP",
  HIDE_MAIN_MENU: "HIDE_MAIN_MENU",
  SHOW_MAIN_MENU: "SHOW_MAIN_MENU",
  HIDE_HELLPER_SIDE_NAV: "HIDE_HELLPER_SIDE_NAV",
  SHOW_HELLPER_SIDE_NAV: "SHOW_HELLPER_SIDE_NAV",
  SET_HELPER_NAV_AS_PRESENT: "SET_HELPER_NAV_AS_PRESENT",
  SET_HELPER_NAV_AS_NOT_PRESENT: "SET_HELPER_NAV_AS_NOT_PRESENT",
  SET_SCREEN_SIZE: "SET_SCREEN_SIZE",
  ERROR_POPUP: "ERROR_POPUP",
  LOGOUT: "LOGOUT",
};

export default TYPES;
