import React from "react";
import { Link } from "react-router-dom";

// COMPONENTS
import Icon from "../icons/Icon";
import Layout from "../layout/Layout";
import Title from "../text/Title";

// FUNCTIONS
import breakWords from "../../functions/breakWords";

const MainNav = ({ title, titleTag, data, activeItem, clicked, linkTo }) => {
  const handleLinkClick = () => {
    if (clicked) clicked();
  };

  return (
    <nav className="__k" role="navigation" aria-label="Voring rounds list" data-name="MainNav">
      <Title tagName={titleTag} addClass="__k_h">
        {title}
      </Title>
      <ul>
        {data.map(item => {
          return (
            <li key={item.id} onClick={clicked}>
              <Link
                onClick={handleLinkClick}
                className={`__k_i ${activeItem === item.id ? "bc1 c10" : "c16"}`}
                to={`${linkTo}/${item.id}`}
                aria-label={item.title}
              >
                <Layout flex spaceBetween lastItemRight>
                  <Icon
                    iconId="checkLine"
                    addClass={`ic-ch ${activeItem !== item.id && "c1"}
                    ${"confirmed" in item && item.confirmed ? "" : "__h-ho"}
                    ${!("confirmed" in item) ? "__h--m" : ""}`}
                  />
                  <div>{`${item.code ? item.code + " - " : ""} ${breakWords(item.title, 15)}`}</div>
                  {item.stats ? (
                    <div className="__k_s">
                      {item.stats.done}/{item.stats.total}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Layout>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNav;
