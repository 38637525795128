import React from "react";

import Popover from "../popovers/Popover";

import { classes } from "../../functions/utils";

const ProgressBar = ({ value, width, text, ttip, addClass, style }) => {
  width = width ? width : value === +value ? `${value}%` : "";
  return (
    <Popover msg={ttip || text || width}>
      <div
        className={classes("progress", addClass)}
        style={style}
        role="progressbar"
        aria-valuetext={ttip}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={value}
      >
        <div className="progress__bar" style={{ width }}></div>
        <div className="progress__text" style={{ mixBlendMode: "color-dodge" }}>
          {text}
        </div>
      </div>
    </Popover>
  );
};

export default ProgressBar;
