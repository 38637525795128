import React, { useRef, useState } from "react";
import { withRouter } from "react-router-dom";

// FUNCTIONS
import generateUniqueID from "../../functions/generateUniqueID";

const NewButton = ({
  id,
  value,
  children,
  btnStyle, //styles: flat, default, noStyles
  colStyle, //styles: danger, primary, default, noColor
  iconLeft, // { icon: "@", style: {} }
  iconRight, // { icon: "@", style: {} }
  addClass,
  style,
  toggle,
  ariaLabel,
  clicked,
  url,
  onKeyDown,
  onKeyPress,
  htmlTag,
  history,
}) => {
  const _id = id || `btn-${generateUniqueID()}`;
  const [pressed, setPressed] = useState(true);
  const CustomTag = htmlTag ? htmlTag : "button";
  const button = useRef("button");

  const handleClick = () => {
    if (url) {
      history.push(url);
      return false;
    }
    if (clicked) clicked();
    setPressed(!pressed);
  };

  // generate all css classes
  const generateClasses = () => {
    const btnStyleType =
      {
        noStyles: " ",
        default: "__bd",
        flat: "__bf",
      }[btnStyle] || "__bd";

    const btnColors =
      {
        noColor: " ",
        default: "bc15 c16",
        primary: "bc1 c10",
        danger: "bc4 c10",
      }[colStyle] || "bc15 c16";

    return ["__b __ly_f __ly_f-aic", btnStyleType, addClass, btnColors].join(" ");
  };

  // generate all tag properties
  const generateProperties = () => {
    const props = {
      "data-name": "NewButton",
      ref: button,
      tabIndex: "0",
      id: _id,
      className: generateClasses(),
      style: style,
      onClick: handleClick,
      onKeyDown: onKeyDown,
      onKeyPress: onKeyPress,
    };

    if (toggle) props["aria-pressed"] = pressed;
    if (ariaLabel) props["aria-label"] = ariaLabel;

    return props;
  };

  return (
    <CustomTag {...generateProperties()}>
      {iconLeft && iconLeft.icon}
      {value || children}
      {iconRight && iconRight.icon}
    </CustomTag>
  );
};

export default withRouter(NewButton);
