import { SET_MESSAGES } from "../actions/types";

const initialState = {
  messages: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };

    default:
      return state;
  }
}
