const caseDataModel = {
  case: {
    type: "object",
    contains: {
      apis: {
        type: "object",
        contains: {
          toggle_x: {
            type: "object",
            contains: {
              api: {
                type: "object",
                contains: {
                  url: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
      },
      coll_stats: {
        type: "object",
        contains: {
          confirmed: {
            type: "boolean",
          },
          stats: {
            type: "object",
            contains: {
              todo: {
                type: "number",
              },
            },
          },
        },
      },
      collection_id: {
        type: "string",
      },
      stage_id: {
        type: "string",
      },
      excluded: {
        type: "boolean",
      },
      form: {
        type: "object",
      },
      nav: {
        type: "object",
        contains: {
          cases: {
            type: "array",
            contains: {
              confirmed: { type: "boolean" },
              excluded: { type: "boolean" },
              id: { type: "string" },
              title: { type: "string" },
            },
          },
          stats: {
            type: "object",
            contains: {
              done: { type: "number" },
              todo: { type: "number" },
              total: { type: "number" },
            },
          },
        },
      },
      overview: {
        type: "object",
        contains: {
          alerts: { type: "array" },
          categories: { type: "array" },
          data: { type: "array" },
          locked: { type: "boolean" },
          title: { type: "string" },
        },
      },
      score: {
        type: "any",
      },
      title: {
        type: "string",
      },
      code: {
        type: "string",
      },
      top_cases: {
        type: "array",
        contains: {
          id: { type: "string" },
          title: { type: "string" },
        },
      },
    },
  },
};

export default caseDataModel;
