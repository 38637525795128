import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MdArrowDropDown } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";

// HOC
import RemoveWrapDiv from "../../hoc/RemoveWrapDiv";

// COMPONENTS
import IconAria from "../icons/IconAria";

class Select extends Component {
  _id = Math.random();

  state = {
    options: [],
    optionsSelected: [],
  };

  UNSAFE_componentWillMount() {
    // set the id
    const id = this.props.id || this._id;

    // add selected options
    const optionsSelected = this.props.select ? this.props.select : [];

    // add valuePropName
    const valuePropName = this.props.valuePropName || "label";

    // mark selected options
    if (!_isEmpty(optionsSelected)) {
      let options = this.props.options;
      options.forEach(option => {
        optionsSelected.forEach(sOption => {
          if (sOption.id === option.id) option.selected = true;
        });
      });
    }

    this.setState({
      options: this.props.options,
      valuePropName,
      id,
      optionsSelected,
    });
  }

  componentDidMount() {
    document.addEventListener("click", this.hideOptions);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.hideOptions);
  }

  // FUNCTIONS
  hideOptions = e => {
    let target = e.target;

    if (e.target && e.target.parentNode && e.target.nodeName === "svg") {
      target = e.target.parentNode;
    }

    if (e.target && e.target.parentNode && e.target.nodeName === "path") {
      target = e.target.parentNode.parentNode;
    }

    if (target === null || !target.hasAttribute("data-select")) {
      this.setState({ toggleOptions: false });
    }

    // get values callback - run on hide options
    if (this.props.multiple && !this.state.toggleOptions) {
      this.props.getValues(this.state.optionsSelected);
    }
  };

  hideWithKeyboard = () => {
    this.setState({ toggleOptions: false });
  };

  showOptions = e => {
    let target = e.target;

    if (e.target.nodeName === "svg") {
      target = e.target.parentNode;
    }

    if (e.target.nodeName === "path") {
      target = e.target.parentNode.parentNode;
    }

    if (target.hasAttribute("data-input")) {
      this.setState({ toggleOptions: true });
    }
  };

  handleOptionClick = option => {
    if (this.props.blockOptions) {
      this.props.getValues([option]);
      return false;
    }

    let optionsSelected = this.state.optionsSelected;
    let options = this.state.options;

    // select single option
    if (!this.props.multiple) {
      optionsSelected = [option];
      options.forEach(item => {
        if (item.id === option.id) item.selected = true;
        else item.selected = false;
      });
      option.name = this.props.propertyName;
      // get value callback
      this.props.getValues([option]);
    } else {
      // if selected options arr does not include clicked option, add it to the arr
      if (!optionsSelected.includes(option)) {
        option.selected = true;
        optionsSelected = [...optionsSelected, option];
      } else {
        // if selected options arr does have clicked optios, remove it from the arr
        optionsSelected = _filter(optionsSelected, function (o) {
          if (o.id !== option.id) return o;
        });
        // remove selection from it
        options.forEach(o => {
          if (o.id === option.id) o.selected = false;
        });
      }
    }

    this.setState({
      optionsSelected,
      options,
      toggleOptions: this.props.multiple ? true : false,
    });
  };

  removeFitler = () => {
    let options = this.state.options;
    options.forEach(option => (option.selected = false));
    this.setState({ optionsSelected: [], options, toggleOptions: false }, () => {
      this.props.getValues(this.state.optionsSelected);
    });
  };

  formatLable = () => {
    let inputVal = "";

    if (this.state.optionsSelected.length > 0) {
      inputVal = this.state.optionsSelected
        .map(option => option[this.state.valuePropName])
        .join(", ");
    }
    if (this.state.optionsSelected.length === 1) {
      inputVal = this.state.optionsSelected[0][this.state.valuePropName];
    }

    if (this.state.optionsSelected.length === 0 && this.props.placeholder) {
      inputVal = this.props.placeholder;
    }
    if (this.state.optionsSelected.length === 0 && this.props.intlPlaceholder) {
      inputVal = <FormattedMessage id={this.props.intlPlaceholder} />;
    }

    return inputVal;
  };

  displayInputValue = () => {
    if (this.props.multiple) {
      return (
        <RemoveWrapDiv>
          <div
            data-select="el"
            data-input="el"
            className={
              "select__input-text " + (this.state.optionsSelected.length === 0 ? "u-shadow" : "")
            }
          >
            {this.formatLable()}
          </div>
        </RemoveWrapDiv>
      );
    } else {
      return (
        <RemoveWrapDiv>
          <div
            data-select="el"
            data-input="el"
            className={
              "select__input-text " +
              (this.state.optionsSelected && this.state.optionsSelected.length > 0
                ? ""
                : "u-shadow")
            }
          >
            {this.formatLable()}
          </div>
        </RemoveWrapDiv>
      );
    }
  };

  renderResetOptionsBtn = () =>
    this.props.resetOptions ? (
      <span
        tabIndex="0"
        onKeyDown={e => e.key === "Enter" && this.removeFitler()}
        onClick={this.removeFitler}
      >
        <IconAria iconId="close" />
      </span>
    ) : (
      <IconAria iconId="arrowDropDown" />
    );

  render() {
    return (
      <div
        className={
          "select " +
          (this.props.addClass ? this.props.addClass : "") +
          (this.state.optionsSelected.length > 0 && this.props.addExtraStylesOnSelected
            ? " select__selected "
            : "") +
          (this.state.toggleOptions ? " u-focus" : "")
        }
        data-select="el"
        data-input="el"
        tabIndex="0"
        id={this.state.id}
        onClick={this.showOptions}
        onKeyDown={e => e.key === "Enter" && this.showOptions(e)}
        onKeyUp={e => e.key === "Escape" && this.hideWithKeyboard()}
      >
        <div
          data-select="el"
          data-input="el"
          className="select__input"
          id={`selectInput${this.state.id}`}
        >
          {this.displayInputValue()}
          <div data-select="el" data-input="el" className="select__input-icon">
            {this.state.optionsSelected.length === 0 ? (
              <IconAria iconId="arrowDropDown" />
            ) : (
              this.renderResetOptionsBtn()
            )}
          </div>
        </div>

        <div
          data-select="el"
          id={`options${this.state.id}`}
          className={
            "select__options " +
            (this.state.options.length > 5 ? "scrollableContent" : "") +
            (this.state.toggleOptions ? "" : " u-hide")
          }
        >
          <div data-select="el">
            {/* {this.props.firstOption ? <div className="select__option select__option-first" onClick={this.removeFitler}><FormattedMessage id={this.props.firstOption}/></div> : null} */}
            {this.state.options.map(option => (
              <div
                onClick={() => this.handleOptionClick(option)}
                onKeyDown={e => e.key === "Enter" && this.handleOptionClick(option)}
                data-select="el"
                key={Math.random()}
                tabIndex="0"
                className={"select__option " + (option.selected ? "select__option-active" : "")}
              >
                {option[this.state.valuePropName]}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  getValues: PropTypes.func.isRequired,
  filterName: PropTypes.string,
  valuePropName: PropTypes.string, // if value name is not 'label', you need to define it with valuePropName
  select: PropTypes.array,
  multiple: PropTypes.bool,
  resetOptions: PropTypes.bool,
  addExtraStylesOnSelected: PropTypes.bool,
  placeholder: PropTypes.string,
  intlPlaceholder: PropTypes.string,
};

export default Select;
