import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

// REDUX
import { connect } from "react-redux";
import { getCategories, setEntryStepActive } from "../actions/entryActions";
import { hideMenu } from "../actions/appActions";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import AlphaTable from "../components/tables/AlphaTable";
import ContentTopNav from "../components/navigations/ContentTopNav";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// FUNCTIONS
import AJAX from "../functions/ajax";

export class Admin extends Component {
  static actionsMap = {
    users: "getUsersTable",
    entries: "getEntryTable",
    cases: "getReviewCasesTable",
    review_results: "getReviewResultsTable",
  };

  _id = Math.random();

  state = {
    activeButtonID: Admin.defaultButtonID,
    mainSideNavSticky: false,
    adminMenu: [
      {
        id: "entries",
        labelID: "entries",
        styleType: { type: "box", color: "transparent" },
        active: true,
        showOn: ["submission_admin"],
      },
      {
        id: "users",
        labelID: "users",
        styleType: { type: "box", color: "transparent" },
        showOn: ["submission_admin", "review_admin", "judge_admin"],
      },
      {
        id: "cases",
        labelID: "cases",
        styleType: { type: "box", color: "transparent" },
        showOn: ["review_admin", "judge_admin"],
      },
      {
        id: "review_results",
        labelID: "review_results",
        styleType: { type: "box", color: "transparent" },
        showOn: ["review_admin", "judge_admin"],
      },
    ],
  };

  UNSAFE_componentWillMount() {
    // set default button id based on role selected
    Admin.defaultButtonID =
      this.props.user.role_selected === "submission_admin" ? "entries" : "users";
    this.fetchContent();
  }

  componentDidUpdate(prevProps) {
    const { catID } = this.props.match.params;
    const prevCatID = prevProps.match.params.catID;
    if (catID !== prevCatID) this.fetchContent();
  }

  componentWillUnmount() {
    AJAX().cancelRequest(this._id);
  }

  fetchContent = () => {
    const { catID } = this.props.match.params;
    const btnID = catID in Admin.actionsMap ? catID : Admin.defaultButtonID;
    this.setBtnActive(btnID, () => this.fetchTable(btnID));
  };

  fetchTable = btnID => {
    const functionName = Admin.actionsMap[btnID];
    AJAX()
      [functionName](this._id, this.props)
      .then(
        res => res && res.data && this.setState({ tables: wrap(res.data), activeButtonID: btnID })
      );
  };

  butnsClick = btn =>
    this.props.history.push(`/ui/${this.props.user.role_selected}/dashboard/${btn.id}`);

  setBtnActive = (btnID, onStateSet) => {
    // set buton active class
    const adminMenu = this.state.adminMenu.map(b => ({ ...b, active: b.id === btnID }));
    // remove button that should not be used on this page
    const filteredBtns = adminMenu.filter(i => i.showOn.includes(this.props.user.role_selected));

    this.setState({ adminMenu: filteredBtns }, onStateSet);
  };

  render() {
    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain">
              <div>
                <ContentTopNav buttons={this.state.adminMenu} butnsClick={this.butnsClick} />
                {(this.state.tables || []).map(
                  table =>
                    table && (
                      <AnimateHOC key={this.state.activeButtonID} animate={true}>
                        <AlphaTable data={table} titleType="h1" />
                      </AnimateHOC>
                    )
                )}
              </div>
            </div>
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

const wrap = x => (Array.isArray(x) ? x : [x]);

Admin.propTypes = {};

const mapStateToProps = store => ({
  entryStepData: store.entry.steps,
  categories: store.entry.categories,
  info: store.app.info,
  user: store.user,
});

export default injectIntl(
  connect(mapStateToProps, { setEntryStepActive, hideMenu, getCategories })(withRouter(Admin))
);
