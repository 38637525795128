//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";
import Sticky from "react-stickynode";
import { renderLangString } from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import S from "../../functions/select";
import validateData from "../../functions/validateData";

// GLOBALS
import GLOBALS from "../../globals/index";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import ListNav from "../../components/navigations/ListNav";
import CarouselNav from "../../components/navigations/CarouselNav";
import Main from "../../components/main/Main";
import DisplayScore from "../../components/display/DisplayScore";
import TogglePrompt from "../../components/prompt/TogglePrompt";
import Overview from "../../components/display/Overview";
import ReviewForm from "../../components/forms/ReviewForm";
import NewButton from "../../components/buttons/NewButton";
import AnimateLayout from "../../components/layout/AnimateLayout";
import Title from "../../components/text/Title";

// DATA MODEL
import caseDataModel from "../../data_model/reviewer/case/case_data";

function Case({ match, info }) {
  const isInitialMount = useRef(false);
  const _id = 789028790372970;
  let scrollTimeout;
  let isFormRenderNotBlocked = false;
  const dispatch = useDispatch();
  const displayScore = useRef();
  let asideMenuContent = null;
  let displayScoreWidth = null;

  const [counterData, setCounterData] = useState();
  const [activeCase, setActiveCase] = useState(null);
  const [isCounterDone, setIsCounterDone] = useState(false);
  const [isExcluded, setIsExcluded] = useState();
  const [isFormBlocked, setIsFormBlocked] = useState(false);
  const [formData, setFormData] = useState();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [savedStatus, setSavedStatus] = useState(false);
  const [rerenderForm, setRerenderForm] = useState(true);
  const reduxState = useSelector(state => state);
  const reviewer = useSelector(state => state.reviewer);
  const sizeOfTheScreen = useSelector(state => state.app.sizeOfTheScreen);

  const init = useCallback(() => {
    dispatch({ type: TYPES.SET_HELPER_NAV_AS_PRESENT });
    getMainData();
  }, [getMainData]);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      GLOBALS.caseNavMounted = false;
      isFormRenderNotBlocked = true;
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  // on unmount
  useEffect(() => {
    return () => {
      dispatch({ type: TYPES.SET_HELPER_NAV_AS_NOT_PRESENT });
    };
  }, []);

  // block and unblock the voating form
  // useEffect(() => {
  //   if (reviewer && reviewer.expiresAt) setIsFormBlocked(!reviewer.expiresAt.active);
  // }, [reviewer]);

  // set the counter with data from judge module
  // useEffect(() => {
  //   if (reviewer && reviewer.expiresAt) {
  //     setCounterData(reviewer.expiresAt);
  //   }
  // }, [reduxState]);

  const getMainData = () => {
    AJAX()
      .getReviewCase(_id, reduxState, match.params.id)
      .then(res => {
        const isDataValid = validateData(
          "Reviewer > Case > AJAX().getReviewCase",
          res,
          caseDataModel
        );

        if (isDataValid) {
          const activeCaseData = res.data.case;

          setActiveCase(activeCaseData);
          setIsExcluded(activeCaseData.excluded);

          if (!formData || isFormRenderNotBlocked) setFormData(activeCaseData.form);

          if (GLOBALS.caseNavMounted) setSavedStatus("saved");
          if (!GLOBALS.caseNavMounted) GLOBALS.caseNavMounted = true;

          dispatch({
            type: TYPES.SET_PAGE_IDS,
            payload: { stage: activeCaseData.stage_id, collection: activeCaseData.collection_id },
          });

          setCounterData({
            active: activeCaseData.in_range == 0 ? true : false,
            end_at: activeCaseData.end_at, //new Date().setSeconds(new Date().getSeconds() + 10), //
            start_at: activeCaseData.start_at,
            toWhat: "Something",
          });
        }
      });
  };

  const toggleAllCases = () => {
    //@todo add mobile and desktop size:mobile in app redux and change this
    if (sizeOfTheScreen && sizeOfTheScreen === "desktop")
      dispatch({ type: TYPES.TOGGLE_HELLPER_SIDE_NAV });
    if (sizeOfTheScreen && sizeOfTheScreen === "mobile")
      dispatch({ type: TYPES.SHOW_HELPER_NAV_ON_TOP });
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  const toggleHelperNav = e => {
    if (e.key === "Enter") {
      if (!reduxState.app.helperSideNavToggle) {
        setTimeout(() => {
          S("#btn-390481094")[0].focus();
        }, 200);
      }
    }
  };

  const handleExcludion = () => {
    AJAX()
      .patch(_id, activeCase.apis.toggle_x.api.url, {})
      .then(res => {
        if (res.data.result.self_excluded) {
          setIsExcluded(true);
        } else if (!res.data.result.self_excluded) {
          setIsExcluded(false);
        }
        getMainData();
      })
      .catch(err => console.log(err));
  };

  const handleFormSave = res => {
    setSavedStatus("saving");
  };

  const handleFormSubmit = res => {
    // on form submit
    getMainData();
    if (res.data.key !== "saved") {
      isFormRenderNotBlocked = true;
    }
  };

  const handleNavItemClick = () => {
    dispatch({ type: TYPES.HIDE_HELPER_NAV_ON_TOP });
  };

  const handleCaroselClick = () => {
    setRerenderForm(Math.random());
    // scroll active manu item into the view
    scrollTimeout = setTimeout(() => {
      const activItem = document.querySelector(".listNav_active");
      activItem.scrollIntoView();
    }, 300);
  };

  const handleCounterEnd = () => {
    setIsCounterDone(true);
  };

  const handleScroll = e => {
    if (!asideMenuContent) {
      displayScore.current = document.getElementById("483864876597484");
      asideMenuContent = document.getElementById("__n_6789689687698");
    } else {
      const asideMenuContentInfo = asideMenuContent.getBoundingClientRect();

      if (asideMenuContentInfo.top < 0) {
        if (displayScoreWidth === null) {
          displayScoreWidth = displayScore.current.getBoundingClientRect().width;
        }
        displayScore.current.style.width = `${displayScoreWidth}px`;
        displayScore.current.style.position = "fixed";
        displayScore.current.style.top = "6rem";
        displayScore.current.style.zIndex = "3";
      } else {
        displayScore.current.style.width = "unset";
        displayScore.current.style.position = "unset";
        displayScore.current.style.top = "unset";
        displayScore.current.style.zIndex = "unset";
      }
    }
  };

  // ON UNMOUNT
  useEffect(() => {
    return () => {
      dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      GLOBALS.caseNavMounted = false;
      clearTimeout(scrollTimeout);
    };
  }, []);

  const { score, overview, top_cases, form, nav } = activeCase || {};

  // @todo: React gives "TypeError: counterData is undefined"
  // Set to true for now so that voting works.
  const counterData_active = counterData ? counterData.active : true;

  return (
    <NewAppHOC>
      <MainAsideNav onScroll={handleScroll}>
        <AnimateLayout animate={activeCase} addClass="__n_bs" id="__n_6789689687698">
          {activeCase && (
            <>
              <div>
                <Title tagName="h1" className="__c_hh">
                  {activeCase.code} - {activeCase.title}
                </Title>
              </div>

              <DisplayScore
                id="483864876597484"
                addClass="c10 bc1"
                score={score}
                savedStatus={savedStatus}
              />

              {!isCounterDone && (
                <TogglePrompt
                  msg={[renderLangString({ id: "$_o" }), renderLangString({ id: "$_p" })]}
                  btnText={[renderLangString({ id: "$_q" }), renderLangString({ id: "$_r" })]}
                  view={isExcluded}
                  callback={handleExcludion}
                />
              )}

              {/* Base formio does not render on routechange */}
              <div className="__c_hb">
                {!isExcluded && formData && (
                  <ReviewForm
                    blockFormSubmission={isFormBlocked || isCounterDone}
                    disableForm={activeCase.confirmed || isCounterDone}
                    info={info}
                    rerender={rerenderForm}
                    form={formData}
                    onSubmission={handleFormSubmit}
                    afterSaved={handleFormSave}
                  />
                )}
              </div>

              {activeCase.coll_stats.stats.todo === 0 &&
                !activeCase.coll_stats.confirmed &&
                reviewer && (
                  <TogglePrompt
                    confirmer
                    title={[renderLangString({ id: "$_t" }), ""]}
                    msg={[renderLangString({ id: "$_u" }), ""]}
                    url={`${Routes.reviewerCollection().path}/${reviewer.collection}`}
                    btnText={[renderLangString({ id: "$_v" }), ""]}
                    iconID={"celebrate"}
                  />
                )}
              {nav && <CarouselNav data={nav} onBtnClick={handleCaroselClick} />}
              <NewButton
                id="btn-19004981"
                clicked={toggleAllCases}
                onKeyDown={toggleHelperNav}
                btnStyle="flat"
                colStyle="gray"
                style={{ width: "100%", textTransform: "uppercase", fontWeight: "bold" }}
                value={
                  reduxState.app.helperSideNavToggle
                    ? renderLangString({ id: "$_z1" })
                    : renderLangString({ id: "$_z" })
                }
              />
              {top_cases.length > 0 && (
                <ListNav
                  type="rating"
                  title={renderLangString({ id: "$_w" })}
                  linkTo={Routes.reviewerCase().path}
                  list={top_cases}
                />
              )}
            </>
          )}
        </AnimateLayout>
      </MainAsideNav>
      <Main counter={counterData} onCounterEnd={handleCounterEnd}>
        <AnimateLayout animate={overview}>
          {activeCase && <Overview overviewData={overview} readonly={true} />}
        </AnimateLayout>
      </Main>
      <HelperAsideNav>
        {nav && (
          <ListNav
            clicked={handleNavItemClick}
            title={renderLangString({ id: "$_a1" })}
            linkTo={Routes.reviewerCase().path}
            type="cases"
            list={nav.cases || []}
          />
        )}
      </HelperAsideNav>
    </NewAppHOC>
  );
}

export default withRouter(Case);
