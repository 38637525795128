import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// COMPONENTS
import ContentContainer from "../components/layout/ContentContainer";
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import ProfileNavigation from "../components/navigations/ProfileNavigation";
import MobileNav from "../components/navigations/MobileNav";
import AlphaForm from "../components/forms/AlphaForm";
import Btn from "../components/buttons/Btn";
import ProfilesCreateEdit from "./ProfilesCreateEdit";

// REDUX
import { connect } from "react-redux";
import { showMobMenu, hideMenu } from "../actions/appActions";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// VENDORS
import getWidth from "../vendors/getPageWidth";
import ContentGenerator from "../components/layout/ContentGenerator";

// FUNCTIONS
import AJAX from "../functions/ajax";

class Profile extends Component {
  _id = Math.random();

  state = {
    change_password: {
      form: {
        errMsg: "",
        successMsg: "",
        components: [
          {
            id: "6925025",
            type: "password",
            name: "old_password",
            tabIndex: "0",
            value: "",
            additional: {
              label: "oldPassword",
            },
            validate: {
              valid: false,
              errMsg: "",
              errMsgs: {
                fieldRequiredOrEmpty: "pleaseFillOut",
              },
            },
          },
          {
            id: "06784f9",
            type: "password",
            name: "password",
            tabIndex: "0",
            value: "",
            repeat: "firstPass",
            additional: {
              label: "newPassword",
            },
            validate: {
              valid: false,
              regex: AA_GLOBAL.userPassRegex,
              errMsg: "",
              errMsgs: {
                fieldRequiredOrEmpty: "enterPassword",
                invalidValue: "userPasswordValidation",
              },
            },
          },
          {
            id: "2030890",
            type: "password",
            name: "password_confirmation",
            tabIndex: "0",
            value: "",
            repeat: "secondPass",
            additional: {
              label: "confirmNewPassword",
            },
            validate: {
              valid: false,
              errMsg: "",
              errMsgs: {
                fieldRequiredOrEmpty: "pleaseFillOut",
                repeatPassword: "repeatPassNotMatch",
              },
            },
          },
        ],
      },
    },
    profile_menu: [
      {
        id: 1,
        fixedMenu: true,
        langId: "edit",
        url: `/ui/${this.props.user.role_selected}/profile/edit`,
        activeClass: "edit",
      },
      {
        id: 2,
        fixedMenu: true,
        langId: "changePassword",
        url: `/ui/${this.props.user.role_selected}/profile/change_password`,
        activeClass: "change_password",
      },
      // {
      //   id: 3,
      //   fixedMenu: true,
      //   langId: 'newsletterSubscriptions',
      //   url: '/ui/${this.props.user.role_selected}/profile/newsletter_subscriptions',
      //   activeClass: 'newsletter_subscriptions'
      // },
      {
        id: 4,
        fixedMenu: true,
        langId: "privacyInfo",
        url: `/ui/${this.props.user.role_selected}/profile/privacy_info`,
        activeClass: "privacy_info",
      },
    ],
    changePassLoader: false,
    profileFormLoader: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // hide menu
    if (getWidth() <= AA_GLOBAL.breakExtraSmall) {
      this.props.hideMenu();
    }
  }

  componentDidUpdate() {
    // remove edit link from profile menu on non submitters
    // if(!this.menuChanged) {
    //   const profilteMenu = this.state.profile_menu;
    //   if(this.props.user.role_selected !== 'submitter') {
    //     this.menuChanged = true;
    //     this.setState({profile_menu: profilteMenu.slice(1)})
    //   }
    // }
  }

  componentWillUnmount() {
    clearTimeout(this.profileFormTimeout);
    this.menuChanged = false;
    AJAX().cancelRequest(this._id);
  }

  changePassword = formData => {
    this.setState({ changePassLoader: true });

    AJAX()
      .changePassword(this._id, this.props, formData)
      .then(res => {
        if (res) {
          // handle errors
          if (res.status === "error") {
            this.setState(prevState => ({
              changePassLoader: false,
              change_password: {
                ...prevState.change_password,
                form: {
                  ...prevState.change_password.form,
                  errMsg: res.key,
                },
              },
            }));

            return false;
          }

          this.setState(
            prevState => ({
              changePassLoader: false,
              change_password: {
                ...prevState.change_password,
                form: {
                  ...prevState.change_password.form,
                  successMsg: res.data.key,
                },
              },
            }),
            () => {
              // clear inputs
              let changePassForm = this.state.change_password;
              changePassForm.form.components.forEach(item => (item.value = ""));

              this.setState({ change_password: changePassForm });
            }
          );
        }
      });
  };

  onChange = schema => {
    this.setState({ schema });
  };

  handleSaveProfile = isProfileSaved => {
    this.setState({ rerenderMainBoxNav: Math.random() });
  };

  render() {
    const pageName = this.props.match.params.page;

    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav rerenderMainBoxNav={this.state.rerenderMainBoxNav} />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain dashboardMain__multiple">
              <MobileNav>
                <AnimateHOC animate={true}>
                  <ContentContainer title={this.props.intl.formatMessage({ id: "users" })} titleType="span">
                    {/* <ProfileNavigation menuData={this.state.serverProfileMenu}/> */}
                    <ProfileNavigation menuData={this.state.profile_menu} />
                  </ContentContainer>
                </AnimateHOC>
              </MobileNav>

              <AnimateHOC animate={true} addClass="column-md">
                {pageName === "edit" && (
                  <ProfilesCreateEdit
                    {...this.props}
                    onSaveCallback={this.handleSaveProfile}
                    titleType="h1"
                  />
                )}

                {pageName === "change_password" && (
                  <ContentContainer
                    title={this.props.intl.formatMessage({ id: "changePassword" })}
                    titleType="h1"
                  >
                    <AlphaForm
                      form={this.state.change_password.form}
                      getData={this.changePassword}
                      formId="change_password"
                    />
                    <Btn
                      type="submit"
                      intlText="save"
                      loading={this.state.changePassLoader}
                      form="change_password"
                    />
                  </ContentContainer>
                )}

                {/* {pageName === 'newsletter_subscriptions' &&
                <ContentContainer
                  title={this.props.intl.formatMessage({id: 'newsletterSubscriptions'})}>
                  <div>Newsletter subscriptions</div>
                </ContentContainer>
              */}

                {pageName === "privacy_info" && (
                  <ContentGenerator url="/api/pages/privacy_info" titleType="h1" />
                )}
              </AnimateHOC>
            </div>
          </div>

          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

Profile.propTypes = {
  showMobMenu: PropTypes.func,
};

const mapStateToProps = state => ({
  firstName: state.user.firstName,
  info: state.app.info,
  user: state.user,
});

export default injectIntl(connect(mapStateToProps, { showMobMenu, hideMenu })(withRouter(Profile)));
