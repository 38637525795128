// Returns a server base URL. Defaults to the host the frontend runs on.
function chooseBackendServerURL(server) {
  if (process.env.NODE_ENV === "production") return "";

  const configs = {
    default: {
      scheme: "",
      // host: window.location.hostname, // E.g.: xyz.staging.alpha-awards.com
      host: "",
      // port: window.location.port
      port: "",
    },
    sprint: {
      scheme: "https",
      host: "sprint.staging.alpha-awards.com",
      port: "",
    },
    edin: {
      scheme: "https",
      host: "edin.staging.alpha-awards.com",
      port: "",
    },
    aziz: {
      scheme: "http",
      host: "192.168.1.23",
      port: "4002",
    },
    mathias: {
      scheme: "http",
      host: "wks.test.alpha-awards.com",
      port: "",
    },
    mathias2: {
      scheme: "http",
      host: "192.168.1.33",
      port: "4000",
    },
    wks: {
      scheme: "https",
      host: "wks.staging.alpha-awards.com",
      port: "",
    },
  };

  let { scheme, host, port } = configs[server] || configs["default"];

  scheme += scheme ? ":" : "";
  port = port ? ":" + port : "";

  return host ? `${scheme}//${host}${port}` : "";
}

const serverBaseURL = () => {
  //console.log("process.env:", JSON.stringify(process.env))

  // Choose REACT_APP_BASE_URL first if defined, otherwise a custom setting.
  return process.env.REACT_APP_BASE_URL || chooseBackendServerURL("default");
};

export default serverBaseURL;
