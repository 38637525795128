import React from "react";
import Icon from "../components/icons/Icon";

const getStateFromStageReviewData = data => {
  let reviewStatus = {
    stageStatusKey: "",
    langId: "",
    dark: { color: "c1", bcColor: "bc1" },
    light: { color: "c6", bcColor: "bc6" },
    icon: null,
  };

  const isConfimed = color => {
    if (!data.confirmed) {
      reviewStatus.stageStatusKey = "inProgress";
      reviewStatus.langId = "$_g";
    } else {
      reviewStatus.stageStatusKey = "done";
      reviewStatus.langId = "$_h";
      reviewStatus.icon = <Icon iconId="checkLine" addClass={color} />;
    }
  };

  // not started yet
  if (data.in_range === -1) {
    reviewStatus.stageStatusKey = "notStarted";
    reviewStatus.status = "upcoming";
    reviewStatus.langId = "$_i";
    reviewStatus.dark = { color: "c16", bcColor: "bc16" };
    reviewStatus.light = { color: "c12", bcColor: "bc12" };
  }

  // active
  if (data.in_range === 0) {
    reviewStatus.status = "active";
    reviewStatus.langId = "$_g";
    reviewStatus.dark = { color: "c1", bcColor: "bc1" };
    reviewStatus.light = { color: "c6", bcColor: "bc6" };
    isConfimed(reviewStatus.dark.color);
  }

  // inactive
  if (data.in_range === 1) {
    reviewStatus.status = "inactive";
    reviewStatus.dark = { color: "c1", bcColor: "bc1" };
    reviewStatus.light = { color: "c12", bcColor: "bc12" };
    reviewStatus.langId = "$_j";
    reviewStatus.stageStatusKey = "expired";
  }

  return reviewStatus;
};

export default getStateFromStageReviewData;
