import React from "react";

// REDUX
import store from "../store";

// COMPONENTS
import Message from "../components/alerts/Message";

class ErrorHOC extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    if (this.props.crashPage) {
      store.dispatch({ type: "ERROR_POPUP" });
    }

    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Message msg="Something went wrong." type="error" />;
    }
    return this.props.children;
  }
}

export default ErrorHOC;
