import React from "react";
import { MdVideocam, MdInsertPhoto } from "react-icons/md";

import IconAria from "../components/icons/IconAria";

const renderIconBasedOnType = (type, icanClass) => {
  if (type === "image") return <IconAria iconId="photo" className={icanClass} />;
  if (type === "video") return <IconAria iconId="videoCam" className={icanClass} />;
  if (type === "audio") return <IconAria iconId="photo" className={icanClass} />;
  if (type === "file") return <IconAria iconId="photo" className={icanClass} />;
  if (type === "text") return <IconAria iconId="photo" className={icanClass} />;
  return <IconAria iconId="file" className={icanClass} />;
};

export default renderIconBasedOnType;
