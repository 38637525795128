import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import _ from "lodash";

// TODO: remove dependency on AnimateHOC.
import AnimateHOC from "../../hoc/AnimateHOC";

// HOOKS
import useMounted from "../../hooks/useMounted";

// COMPONENTS
import UppyComponent from "./UppyFormio";

// FUNCTIONS
import { wrapArray } from "../../functions/utils";

import "./FormioModified.js";
import { Formio } from "formiojs";

const BaseFormio = ({
  id,
  src,
  schema,
  url,
  content,
  options,
  disableForm,
  renderCallback,
  ...props
}) => {
  // TODO: must use axios if src is defined to fetch the schema.
  // const unused = src
  const mounted = useMounted();
  const { onCreate, onChange, onDifferent /*, onUnmount*/ } = props;
  const elemRef = useRef(null);
  const [respAlert, setRespAlert] = useState(null);
  const portalChildren = useRef([]);

  // NB: Need to keep a shallow copy of options, because formio assigns properties to it,
  // such as the special 'events' object, which causes issues if the page has more than one BaseFormio.
  const optionsReference = useRef();

  function setOptionsReference(options) {
    optionsReference.current = copyOptionsWithDefaults(options);
    return optionsReference.current;
  }

  const callbacksRef = useRef({ change: onChange, diff: onDifferent });

  const [formio, setFormio] = useState(null);

  // Create the formio instance immediately so it can build the elements in the meantime.
  const [formioPromise /*, setFormioPromise*/] = useState(() => {
    const elem = document.createElement("div");

    // Need to add these functions to the element, so they can be accessed
    // inside the custom component classes when the form is being built.
    // They have to be created as "portals" and be part of the App tree,
    // otherwise functions like withRouter() will not work.
    elem.addReactChild = child => portalChildren.current.push(child);
    elem.removeReactChild = child =>
      (portalChildren.current = portalChildren.current.filter(x => child !== x));

    // Need to create a copy because Formio modifies it.
    schema = JSON.parse(JSON.stringify(schema));

    const copiedOptions = copyOptionsWithDefaults(options);
    mergeSchemaI18NIntoOptions(copiedOptions, schema);

    return Formio.createForm(elem, schema, copiedOptions).then(newFormio => {
      // Not even one moment alive and already time to die. Such is life.
      if (!mounted()) {
        newFormio.destroy();
        return newFormio;
      }

      // NB: always prevent formio from submitting
      newFormio.nosubmit = true;

      newFormio.initAAProps();

      if (content) newFormio.setData(content);

      // Helps to avoid setting again in the effect hooks.
      newFormio.isInitialSchema = true;
      newFormio.isInitialContent = true;

      setFormio(newFormio);

      return newFormio;
    });
  });

  [schema, content, options] = useDeepCheck([schema, content, options]);
 

  useLayoutEffect(() => {
    formioPromise
      .then(newFormio => {
        if (mounted()) {
          // Simply insert the finished form into the DOM.
          elemRef.current.appendChild(newFormio.element);
          // TODO: maybe pass back in a different way.
          newFormio.setRespAlert = resp => mounted() && setRespAlert(resp);
        }

        return newFormio;
      })
      // TODO: just ignore?
      .catch(_ => _);

    return () => {
      // TODO: onUnmount needed?
      // onUnmount && onUnmount(formioPromise)
      formioPromise.then(formio => formio.destroy());
    };
    // Rebuild Formio instance when these change
  }, [formioPromise, mounted]);

  useLayoutEffect(() => {
    if (formio) {
      if (formio.isInitialSchema) {
        formio.isInitialSchema = false;
      } else {
        portalChildren.current = [];
        // Need to create a copy because Formio modifies it.
        const newSchema = JSON.parse(JSON.stringify(schema));
        formio.setForm(newSchema);
      }
    }
  }, [formio, schema]);

  useLayoutEffect(() => {
    if (formio) {
      if (formio.isInitialContent) {
        formio.isInitialContent = false;
      } else if (!_.isEqual(content, formio.data)) {
        formio.setData(content);
      }
    }
  }, [formio, content]);

  useLayoutEffect(() => {
    if (formio) {
      const copiedOptions = setOptionsReference(options);
      mergeSchemaI18NIntoOptions(copiedOptions, schema);
      Object.assign(formio.options, copiedOptions);
      // FIXME: new options need to somehow take effect in an existing form.
      // NB: cannot call redraw here as it destroys portal children.
      // formio.redraw();
    }
  }, [formio, options, schema]);

  useLayoutEffect(() => {
    if (formio) formio.url = url;
  }, [formio, url]);

  useLayoutEffect(() => {
    if (formio && onChange) {
      // Need to de-register to avoid calling multiple onChange handlers.
      BaseFormio.off_on(formio, "change", callbacksRef, "change", onChange);
    }
  }, [formio, onChange]);

  useLayoutEffect(() => {
    if (formio && onDifferent) {
      BaseFormio.off_on(formio, "change", callbacksRef, "diff", event => {
        // Only fire if the flag actually toggled.
        if (formio.updateHasChanges()) onDifferent(formio._hasChanges);
      });
    }
  }, [formio, onDifferent]);

  useEffect(() => {
    if (formio && onCreate) onCreate(formio);
    if (renderCallback) renderCallback("render");
  }, [formio, onCreate]);

  useEffect(() => {
    if (renderCallback) renderCallback("change");
  }, [onChange]);

  let childrenAbove = [],
    childrenBelow = [];

  React.Children.forEach(props.children, c =>
    (c && c.type === Above ? childrenAbove : childrenBelow).push(c)
  );

  return (
    <div id={id}>
      {/* Give access to the formio instance */}
      <FormioContext.Provider value={formio}>
        {childrenAbove}
        <div id="formioo-form">
          {disableForm && <div id="formioo-form-blocker" className="bc12"></div>}
          <form ref={elemRef} className="formio-root" onSubmit={e => e.preventDefault()} />
        </div>
        {respAlert && <ResponseAlert {...respAlert} {...{ setRespAlert }} />}
        {childrenBelow}
        {portalChildren.current}
      </FormioContext.Provider>
    </div>
  );
};

Formio.registerComponent("uppy", UppyComponent);

export function apiRequest(safeAxios, api, data) {
  const { url, method, headers } = api || {};
  return safeAxios({ url, method, headers, data: { data, api: api.data } });
}

const copyOptionsWithDefaults = options => ({
  saveDraft: false,
  noAlerts: true,
  baseUrl: "/",
  ...JSON.parse(JSON.stringify(options)),
});

const FormioContext = React.createContext();

BaseFormio.apiRequest = apiRequest;
BaseFormio.copyOptionsWithDefaults = copyOptionsWithDefaults;
BaseFormio.Context = FormioContext;

BaseFormio.off_on = (formio, event, callbacksRef, cbName, newCb) => {
  const oldCb = callbacksRef.current[cbName];
  if (oldCb) BaseFormio.off(formio, event, oldCb);
  formio.on(event, newCb);
  callbacksRef.current[cbName] = newCb;
  return formio;
};

// Removes a specific function from the events list. Not implemented in Formio.
BaseFormio.off = (formio, event, fn) => {
  // See: formio.js/src/Element.js:119:off
  const type = `${formio.options.namespace}.${event}`;
  // _events[type] may contain one function or a list of functions.
  formio.events._events[type] = wrapArray(formio.events._events[type] || []).filter(
    fn_ => fn_ !== fn
  );
  return formio;
};

function mergeSchemaI18NIntoOptions(options, schema) {
  if (_.isObject(schema.i18n)) {
    options.i18n = options.i18n || {};
    _.merge(options.i18n, schema.i18n);
  }
}

export function ResponseAlert(props) {
  const { setRespAlert, status } = props;
  const statusClass = 200 <= status && status <= 299 ? "ok" : "err";

  // Just testing with AnimateHOC what the effect feels like here.
  return (
    <AnimateHOC animate={true}>
      <div className={`resp_msg resp_${statusClass}_msg`}>
        {response2Message(props)}
        <span className="resp_close" onClick={() => setRespAlert(null)}>
          {" ⨯ "}
        </span>
      </div>
    </AnimateHOC>
  );
}

const response2Message = ({ data, statusText, status }) =>
  (data && data.message) || `${statusText} (${(data && data.key) || maybeNumber(status)})`;

const maybeNumber = number => (number >= 0 ? number + "" : "");

// Children wrapped with this function will be rendered above the form.
const Above = (BaseFormio.Above = ({ children }) => children);

// TODO
BaseFormio.propTypes = {};

// TODO: move to hooks/
// Use this hook for object dependencies to help avoid unnecessary re-renders.
function useDeepCheck(newList) {
  const ref = useRef(newList);
  return (ref.current = ref.current.map((currentItem, i) =>
    _.isEqual(currentItem, newList[i]) ? currentItem : newList[i]
  ));
}

export { BaseFormio, Above };
export default BaseFormio;
