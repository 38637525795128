import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { MdSave, MdArrowBack, MdArrowForward, MdCheckCircle } from "react-icons/md";
import { classes } from "../../functions/utils";

export class Button extends Component {
  _id = this.props.id || `btn-${Math.random()}`;

  componentDidMount() {
    // add data attibutes to the button, if they exist
    if (this.props.dataAttr && this.props.dataAttr.length > 0) {
      this.props.dataAttr.forEach(attr => {
        if (document.getElementById(this._id))
          document.getElementById(this._id).setAttribute(`data-${attr.name}`, attr.value);
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this._btnClickDelay);
  }

  handleClick = () => this.props.clicked && this.props.clicked(this.props);

  renderIcon = iconID =>
    ({
      save: <MdSave className="button__icon test--icon" />,
      back: <MdArrowBack className="button__icon test--icon" />,
      next: <MdArrowForward className="button__icon test--icon" />,
      check: <MdCheckCircle className="button__icon test--icon" />,
    }[iconID]);

  generateClass = () => {
    const { styleType, pushLeft, pushRight, disabled, active } = this.props;

    let typeClass, colorClass, activeColorClass;

    if (styleType) {
      const { type, color } = styleType;
      typeClass = { primary: "button__primary", box: "button__boxBtn" }[type];

      colorClass =
        {
          primary: "button--primary",
          secondary: "button--secondary",
          transparent: "button--transparent",
        }[color] || "button--primary";

      colorClass = type === "box" ? "button--transparent" : colorClass;
    }

    // if active is not a class make it default style
    if (active === true) {
      activeColorClass =
        {
          secondary: "button--secondary-active",
          transparent: "button--transparent-active",
        }[styleType && styleType.color] || "button--primary-active";
    }

    return classes(
      "button",
      typeClass,
      colorClass || "button__primary button--primary",
      pushLeft && "button--pushLeft",
      pushRight && "button--pushRight",
      disabled && "button--disabled",
      typeof active === "string" && active,
      activeColorClass
    );
  };

  linkBtn = link => (
    <a href={link} className={classes(this.generateClass(), this.props.addClass)}>
      {this.props.label ||
        (this.props.labelID && this.props.intl.formatMessage({ id: this.props.labelID }))}
    </a>
  );

  render() {
    const {
      id,
      type,
      tabIndex,
      formID,
      label,
      link,
      labelID,
      addClass,
      addStyle,
      iconID,
      disabled,
      showLoader,
      loader,
      intl,
    } = this.props;

    if (link) return this.linkBtn(link);

    const btnHide = (this.props.api_url || loader) && showLoader;

    return (
      <button
        id={this._id}
        key={id}
        type={type}
        tabIndex={tabIndex}
        form={formID}
        className={classes(this.generateClass(), addClass)}
        style={addStyle}
        disabled={disabled}
        onClick={this.handleClick}
        data-test="btn"
      >
        <div data-test="loader" className={classes("button__loader", !btnHide && "button-hide")}>
          <div className="circleLoader-small"></div>
        </div>
        <div data-test="text" className={classes("button__text", btnHide && "button-hide")}>
          {iconID && this.renderIcon(iconID)}
          {(label && <span>{label}</span>) || (labelID && intl.formatMessage({ id: labelID }))}
        </div>
      </button>
    );
  }
}

Button.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string, // button text
  labelID: PropTypes.string, // react intl
  api_url: PropTypes.string,
  link: PropTypes.string, // return a link button as an anchor, you need to pass url here   --- not tested yet
  type: PropTypes.string, // difine the type of the button
  iconID: PropTypes.string, // needs to be defined here in the [renderIcon] function
  styleType: PropTypes.object, // can be: {type: 'box', color: 'secondary'} - type: [box] color: [primary, secondary, transparent] --- not tested yet
  addClass: PropTypes.string, // add aditional classes
  addStyle: PropTypes.object, // add aditional style
  goToID: PropTypes.string, // takes the id of the thing that needs to be rendered
  active: PropTypes.any, // can be custom class so you define active style of bool where it will chose default style
  dataAttr: PropTypes.array, // dataAttr: [{name: 'type', value: 'submit'}] - data-type="submit"
  formID: PropTypes.string, // form id for submitting the the form from outside of the form
  clicked: PropTypes.func, // returns a function with parameter that holds the button data
  disabled: PropTypes.bool,
  pushLeft: PropTypes.bool, // for this prop to work the wrapper div needs to have display: flex
  pushRight: PropTypes.bool, // for this prop to work the wrapper div needs to have display: flex
  loader: PropTypes.bool, // allowe componen to use loader if [api_url] property is not set
  showLoader: PropTypes.bool, // toggle loader on button
};

export default injectIntl(Button);
