import React, { Fragment } from "react";
import _ from "lodash";

import renderIconBasedOnType from "../../functions/renderIconBasedOnType";
import { classes } from "../../functions/utils";

import Popover from "../../components/popovers/Popover";
import IconAria from "../../components/icons/IconAria";

import validURL, { hasScheme } from "../../vendors/validURL";
import formatBytes from "../../vendors/formatBytes";
import { formatDateTime } from "../../vendors/calTimeZone";

/** Renders the labels and values from a list of Formio fields.
 */
const FieldList = ({ model, prefix = "", addClass, pre_key }) => (
  <div className={classes(`${prefix}__labelInfo`, addClass)}>
    <Fields fields={model.field_list} ctxt={{ pre_key, prefix, version: model.version }} />
  </div>
);

/** Renders a list of fields with unique keys.
 */
const Fields = ({ fields, ctxt }) =>
  _.isArray(fields) &&
  fields.map(field => <FieldLabelValue key={field.key} field={field} ctxt={ctxt} />);

const requiredClass = field => (field.options?.required ? "missing" : "empty");
const isValueEmpty = value => _.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value);
const isRequiredCheckbox = field =>
  field.options?.required && field.type === "checkbox" && !field.value;

/** Renders the label of a form field with a distinguishing icon.
 */
const FieldLabel = ({ field, addClass, ctxt }) => (
  <h4
    className={classes(
      `${ctxt.prefix}__labelInfo__title`,
      (isValueEmpty(field.value) || isRequiredCheckbox(field)) &&
        `${ctxt.prefix}__labelInfo-${requiredClass(field)}`,
      addClass
    )}
  >
    <IconAria iconId="label" className="vicon labelIcon" />
    {/* We can set the innerHTML because the label is not user-provided. */}
    <div dangerouslySetInnerHTML={{ __html: field.label }}></div>
  </h4>
);

/** Renders a field with its label and value.
 */
const FieldLabelValue = ({ field, ctxt }) => (
  <div key={field.key} className={`${ctxt.prefix}__labelInfo__infoBox`}>
    <FieldLabel field={field} ctxt={ctxt} />
    <div className={`${ctxt.prefix}__labelInfo__value`}>{renderField(field, ctxt)}</div>
  </div>
);

/** The dash icon signifies that the field's value is empty (null, "", [], {}).
 */
const EmptyValue = () => <IconAria iconId="dash" className="vicon" />;
/** The field didn't have the expectec value type.
 * @todo pass an intl object within `ctxt` for message translation.
 */
const InvalidValue = ctxt => (
  <IconAria
    iconId="warning"
    className="vicon"
    title={
      ctxt.intl
        ? ctxt.intl.formatMessage({ id: "invalidFieldValueType" })
        : "The value type for this field is incorrect!"
    }
  />
);

/** Renders the prefix/suffix of a field value.
 */
const renderAffix = affix =>
  // We can set the innerHTML because the affix is not user-provided.
  _.isString(affix) && affix && <span dangerouslySetInnerHTML={{ __html: affix }}></span>;

const renderPrefix = field => renderAffix(field.options?.prefix);
const renderSuffix = field => renderAffix(field.options?.suffix);

const formatLineBreaks = (lines, ctxt) =>
  (lines = lines.split("\n")).length == 1
    ? lines[0]
    : lines.map((line, i) => (
        <Fragment key={ctxt.pre_key + i}>
          {i > 0 && <br />}
          {line}
        </Fragment>
      ));

const formatParagraphList = (list, ctxt) =>
  // Avoid the top margin when there's only one paragraph.
  list.length == 1 ? (
    <p className={`${ctxt.prefix}__single`}>
      {renderPrefix(ctxt.field)}
      {formatLineBreaks(list[0], ctxt)}
      {renderSuffix(ctxt.field)}
    </p>
  ) : (
    list.map((p, i) =>
      p === "\n\n" ? null : !p || p.startsWith("\n\n\n") ? (
        // Using a <pre> tag with a newline allows rendering and selecting empty paragraphs.
        <pre key={ctxt.pre_key + i}>{p.substr(2)}</pre>
      ) : (
        <p key={ctxt.pre_key + i} className={`${ctxt.prefix}__txt`}>
          {formatLineBreaks(p, ctxt)}
        </p>
      )
    )
  );

const formatAsParagraphs = (text, ctxt) => formatParagraphList(text.split(/(\n{2,})/), ctxt);

const formatURL = url => (hasScheme(url) ? url : `//${url}`);

const ALink = ({ url, children }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const IconAndLink = ({ txt, url, icon }) => (
  <ALink url={url}>
    {url && <IconAria iconId={icon} className="vicon" />}
    {txt}
  </ALink>
);

const renderStringItem = (v, ctxt) =>
  !_.isString(v) ? (
    <InvalidValue />
  ) : ctxt.field.type === "email" ? (
    <p>
      <IconAndLink icon="email" url={`mailto:${v}`} txt={v} />
    </p>
  ) : validURL(v) || ctxt.field.type === "url" ? (
    <p>
      <IconAndLink icon="xlink" url={validURL(v) ? formatURL(v) : null} txt={v} />
    </p>
  ) : (
    formatAsParagraphs(v, ctxt)
  );

const renderObjectItem = (field, ctxt, idx) => (
  <FieldLabelValue key={field.key + idx} field={field} ctxt={ctxt} />
);

const renderObject = (object_or_fields, ctxt) =>
  ctxt.version >= 2 ? (
    !_.isArray(object_or_fields) ? (
      <InvalidValue />
    ) : (
      object_or_fields.map((field, idx) => renderObjectItem(field, ctxt, idx))
    )
  ) : !_.isObject(object_or_fields) ? (
    <InvalidValue />
  ) : (
    (ctxt.field.subfields || []).map((subfield, idx) =>
      renderObjectItem({ ...subfield, value: object_or_fields[subfield.key] }, ctxt, idx)
    )
  );

const renderContainer = (item, ctxt) =>
  !_.isObject(item) ? (
    <InvalidValue />
  ) : _.isEmpty(item) ? (
    <EmptyValue />
  ) : ctxt.version === 1 ? (
    renderObject(item, ctxt)
  ) : (
    renderObjectItem(item, ctxt)
  );

const RadioButtonLabel = ({ label }) => (
  <>
    <IconAria iconId="radioChecked" className="vicon" />
    {/* We can set the innerHTML because the label is not user-provided. */}
    {/* A radio's value is mapped to its label by the back end, so it cannot contain user data. */}
    <span dangerouslySetInnerHTML={{ __html: label }}></span>
  </>
);

const renderSurveyItem = (item, ctxt) =>
  !_.isObject(item) ? (
    <InvalidValue />
  ) : (
    !_.isEmpty(item) && (
      <>
        {/*We can set the innerHTML because the label is not user-provided.*/}
        <dt dangerouslySetInnerHTML={{ __html: item.label }}></dt>
        <dd>
          <RadioButtonLabel label={item.value} />
        </dd>
      </>
    )
  );

const renderRadioItem = v => (
  <p>
    <RadioButtonLabel label={v} />
  </p>
);

const renderSelectBoxesItem = cb => (
  // We can set the innerHTML because the label is not user-provided.
  <p dangerouslySetInnerHTML={{ __html: (cb.value ? "☑ " : "☐ ") + cb.label }}></p>
);

const renderSelectItem = v =>
  _.isString(v?.label) ? (
    // We can set the innerHTML because the label is sanitized by the back end.
    <p dangerouslySetInnerHTML={{ __html: v.label }}></p>
  ) : _.isString(v) ? (
    <p>{v}</p>
  ) : (
    <InvalidValue />
  );

const renderCheckboxItem = v => <p>{v ? "☑" : "☐"}</p>;

const renderFileTag = (item, mainType, ctxt) => (
  <span>
    {renderIconBasedOnType(mainType, `${ctxt.prefix}__labelInfo__value-i`)}
    <Popover linkInspector type={mainType}>
      <a href={item.url} data-url={`${item.url}/thumbnail`} download={true}>
        {item.originalName}
        <IconAria iconId="download" className={`${ctxt.prefix}__labelInfo__value-icn`} />
      </a>
    </Popover>
    {" | "}
    <span className={`${ctxt.prefix}__labelInfo__value-size`}>{formatBytes(item.size)}</span>
  </span>
);

const renderFileItem = (item, ctxt) =>
  _.isObject(item) ? renderFileTag(item, (item.type + "").split("/")[0], ctxt) : <InvalidValue />;

const renderDateTimeItem = (v, ctxt) =>
  !_.isString(v) ? (
    <InvalidValue />
  ) : (
    <p>
      <IconAria iconId={ctxt.field.type === "time" ? "time" : "date"} className="vicon" />
      {formatDateTime(v, ctxt.field.options?.format)}
    </p>
  );

const renderCommonItem = (v, ctxt) =>
  _.isArray(v) && _.isObject(v) ? (
    <InvalidValue />
  ) : (
    <p>
      {renderPrefix(ctxt.field)}
      {v}
      {renderSuffix(ctxt.field)}
    </p>
  );

const renderSingleItemFuncsMap = {
  date: renderDateTimeItem,
  time: renderDateTimeItem,
  datetime: renderDateTimeItem,
  number: renderCommonItem,
  select: renderSelectItem,
  string: renderStringItem,
  selectboxes: renderSelectBoxesItem,
  checkbox: renderCheckboxItem,
  radio: renderRadioItem,
  // // "container" is handled specially below.
  // container: renderContainer,
  // // NB: Don't use datamap. It's buggy. See: https://github.com/formio/formio.js/issues/4169
  // datamap: renderObject,
  editgrid: renderObject,
  survey: renderSurveyItem,
  datagrid: renderObject,
  file: renderFileItem,
};

const handleSpecialFieldFuncsMap = {
  container: ({ value }, formatItem, ctxt) => (
    <div className={`${ctxt.prefix}__container`}>
      <Fields fields={value} ctxt={ctxt} />
    </div>
  ),
  // Render checkboxes without a dash as a <li>-icon.
  selectboxes: ({ value }, formatItem, ctxt) => (
    <ul className={`${ctxt.prefix}__selboxes`}>
      {value.map((item, i) => (
        <li key={ctxt.pre_key + i}>{formatItem(item, ctxt)}</li>
      ))}
    </ul>
  ),
  survey: ({ value }, formatItem, ctxt) => (
    <dl className={`${ctxt.prefix}__survey labelInfo__dashes`}>
      {value.map((item, i) => (
        <Fragment key={ctxt.pre_key + i}>{formatItem(item, ctxt)}</Fragment>
      ))}
    </dl>
  ),
};

const renderDashList = (value, formatItem, ctxt) => (
  <ul className={`${ctxt.prefix}__dlist labelInfo__dashes`}>
    {value.map((item, i) => (
      <li key={ctxt.pre_key + i}>{formatItem(item, ctxt)}</li>
    ))}
  </ul>
);

const handleCommonField = ({ value }, formatItem, ctxt) =>
  // Some Formio components can be "multiple"-enabled, so they contain an array as a value
  // where each item can be formatted using the `formatItem()` function.
  _.isArray(value) ? (
    value.length > 0 ? (
      renderDashList(value, formatItem, ctxt)
    ) : (
      <EmptyValue />
    )
  ) : (
    formatItem(value, ctxt)
  );

/**
 * Renders a field with its value.
 *
 * @param {Object} field - A simpler version of a Formio input component.
 * @param {string} pre_key - The main React key prefix to use throughout.
 * @param {string} prefix - The CSS class prefix to be used throughout.
 * @param {number} version - An integer defining the version of the back end data structure.
 * @returns {ReactComponent}
 */
const renderField = (field, { pre_key, prefix = "", version = 1 }) => {
  if (isValueEmpty(field.value)) {
    return <EmptyValue />;
  } else {
    const handleField = handleSpecialFieldFuncsMap[field.type] || handleCommonField;
    const formatField = renderSingleItemFuncsMap[field.data_type] || renderCommonItem;
    pre_key = pre_key || Math.random() + "";
    return handleField(field, formatField, { field, prefix, version, pre_key });
  }
};

export default FieldList;
export { renderField };
