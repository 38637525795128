import GLOBALS from "../globals";

const getDateAndTime = ({ date }) => {
  // return a null because if null is passed to the new Date object it will return a date
  if (!date) return null;

  const mainDate = new Date(date);
  //const data = new Date().toLocaleString("en-US", {timeZone: "Australia/Brisbane"});
  let lang = GLOBALS.cookies.get("lang").slice(3, 5).toLowerCase();
  let timeFormat = lang === "us" ? "us" : "eu";

  let [month, day, year] = mainDate.toLocaleDateString().split("/");
  let [hour, minute, second] = mainDate.toLocaleTimeString().slice(0, 7).split(":");

  const parts = () => {
    return {
      month: parseInt(month),
      day: parseInt(day),
      year: parseInt(year),
      hour: timeFormat === "us" ? parseInt(hour) : formatHours(parseInt(hour)),
      minute: parseInt(minute),
      second: parseInt(second),
    };
  };

  const formatTime = () => {
    return {
      hourMin: timeFormat === "us" ? `${hour}:${minute}${ampm()}` : `${hour}:${minute}`,
    };
  };

  const ampm = () => {
    var hours = (hour + 24 - 2) % 24;
    var mid = "am";
    if (hours == 0) {
      //At 00 hours we need to show 12 am
      hours = 12;
    } else if (hours > 12) {
      hours = hours % 12;
      mid = "pm";
    }
    return mid;
  };

  const formatHours = hour => {
    switch (hour) {
      case 1:
        return 13;
      case 2:
        return 14;
      case 3:
        return 15;
      case 4:
        return 16;
      case 5:
        return 17;
      case 6:
        return 18;
      case 7:
        return 19;
      case 8:
        return 20;
      case 9:
        return 21;
      case 10:
        return 22;
      case 11:
        return 23;
      case 12:
        return 24;
    }
  };

  const formats = () => {
    return {
      toDateString: mainDate.toDateString(),
      toLocaleString: mainDate.toLocaleString(),
      toLocaleDateString: mainDate.toLocaleDateString(),
      toGMTString: mainDate.toGMTString(),
      toUTCString: mainDate.toUTCString(),
      toISOString: mainDate.toISOString(),
      myFormat1: timeFormat === "us" ? `${month}/${day}/${year}` : `${day}/${month}/${year}`,
      myFormat2:
        timeFormat === "us"
          ? `${month}/${day}/${year} - ${formatTime().hourMin}`
          : `${day}/${month}/${year} - ${formatTime().hourMin}`,
    };
  };

  const checkIfDateIsDateInPast = () => {
    if (mainDate < new Date()) return true;
    else return false;
  };

  const checkIfDateIsInFuture = () => {
    if (mainDate > new Date()) return true;
    else return false;
  };

  const calculateTimeLeft = expiresAt => {
    //Set expire date
    const expireDate = new Date(expiresAt).getTime();

    // Get today's date and convert it to time
    var now = new Date().getTime();

    // Find the time difference between now and given date
    var timeDifference = expireDate - now;

    // Calculate days, hours, minutes and seconds
    var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      in: days === 0 ? "hours" : "days",
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const calculateDaysLeft = date => {
    // To set two dates to two variables
    const date1 = new Date(date);
    const date2 = new Date();

    // To calculate the time difference of two dates
    const Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    console.log("Difference_In_Days", Difference_In_Days);

    return parseInt((Math.abs(Difference_In_Days) + "").split(".")[0]);
  };

  return {
    timeFormat,
    formats: formats(),
    parts: parts(),
    ampm: ampm(),
    isDateInPast: checkIfDateIsDateInPast(mainDate),
    isDateInFuture: checkIfDateIsInFuture(mainDate),
    timeLeft: checkIfDateIsInFuture(mainDate) ? calculateTimeLeft(mainDate) : null,
    calculateDaysLeft: date => calculateDaysLeft(date),
  };
};

export default getDateAndTime;
