import React from "react";
import Routes from "../../Routes";

// COMPONENTS
import Layout from "../layout/Layout";
import ParseHTML from "../../functions/ParseHTML";

// REDUX
import { useSelector } from "react-redux";

let bannerData = {
  imageBanner: null,
  // {
  //   url: "https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo4.png",
  //   alt: "Company logo",
  //   bgColor: "#fa9c1e",
  //   padding: ".7rem 0 0 1.2rem",
  //   maxWidth: "50%",
  // },
  textBanner: {
    banner: {
      body: "Alpha Awards - Grand Prix 2020",
      format: "text",
    },
    bg: {
      color: "#213148",
      image: null,
    },
    text: {
      color: "#c9af6b",
    },
    logo: {
      alt: "alpha awards GP 2020 Logo",
      href: "//grandprix.alpha-awards.com",
      src: "//minio.staging.alpha-awards.com/public/aagp20/Logo_aaGP.jpg",
      title: "alpha awards GRAND PRIX 2020",
    },
    subtitle: {
      body: "Subtitle text here",
      format: "html",
    },
  },
};

const CompanyBanner = () => {
  const activeModule = useSelector(state => state.user.role_selected);
  const banner = useSelector(
    state => state.app.info.apps[state.app.info.default_app].modules[activeModule].banner
  );

  const renderImageBanner = data => (
    <div
      className="__x __x_io"
      style={{
        background: data.bgColor || "#4e4e4e",
      }}
    >
      <img
        src={data.url}
        alt={data.alt}
        style={{
          padding: data.padding || "",
          width: data.maxWidth || "auto",
        }}
      />
    </div>
  );

  const renderCompanyBanner = data => (
    <div
      className={`__x __x_w ${data.text.color || "c12"}`}
      style={{
        background: data.bg.color || "#4e4e4e",
        color: data.banner.color || "c12",
      }}
    >
      <Layout flex spaceBetween addClass="__x_t">
        <Layout addClass="__x_g">
          <div className="__x_wt">
            {data.banner.format === "html" ? (
              <ParseHTML html={data.banner.body} />
            ) : (
              data.banner.body
            )}
          </div>
          <div className="__x_ws">
            {data.subtitle.format === "html" ? (
              <ParseHTML html={data.subtitle.body} />
            ) : (
              data.subtitle.body
            )}
          </div>
        </Layout>
        {data.logo.src && <img className="__x_wi" src={data.logo.src} alt={data.logo.alt} />}
      </Layout>
    </div>
  );

  if (banner.imageBanner) return renderImageBanner(banner.imageBanner);
  if (banner.textBanner) return renderCompanyBanner(banner.textBanner);
  return null;
};

export default CompanyBanner;
