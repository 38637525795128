import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// globals
import AA_GLOBAL from '../../globals/globals';

class CheckLoader extends Component {

  state = {
    done: false,
    loading: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if(nextProps.done) {
      this.setState({done: true, loading: true})
    }else if(nextProps.done === false) {
      this.setState({loading: false})
      setTimeout(() => {
        this.setState({done: false})
        this.props.loaderDone();
      }, AA_GLOBAL.loaderLoadingDuration)
    }
  }

  render() {
    return (
      <div className={"circle-loader-con " + (this.state.done ? '' : ' u-hide')}>
        <div className="">
          <div className={"circle-loader " + (!this.state.loading ? 'load-complete ' : '')}>
            <div className="checkmark draw" style={this.state.loading ? {display: 'none'} : {display: 'block'}}></div>
          </div>
          <div className={"circle-loader-msg " + (this.state.loading ? 'u-hide' : '')}><FormattedMessage id="successfullyRemoved"/>.</div>
        </div>
      </div>
    );
  }
}

export default CheckLoader;