import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "../Routes";

// REDUX
import { connect } from "react-redux";
import { logout, saveSessionAndInfo } from "../actions/userActions";
import { getInfo, getLoginData } from "../actions/appActions";
import { setUserRole } from "../actions/userActions";

// GLOBALS
import AA_GLOBAL from "../globals/globals";

// FUNCTIONS
import AJAX from "../functions/ajax";
import fetchAndSetFormioLocale from "../functions/fetchAndSetFormioLocale";

export default function (ComposedClass, permission) {
  class AuthHOC extends Component {
    _id = Math.random();
    mounted = true;

    state = {
      loading: true,
    };

    UNSAFE_componentWillMount() {
      const authToken = AA_GLOBAL.cookies.get("auth_token");
      let currentRole;

      if (authToken) {
        // get url page role if it exsits
        const urlRole = this.props.location.pathname.split("/")[2];

        // add info and session and set the user role on page load
        if (this.props.user) {
          // if session and info are not set
          if (!this.props.user.role_selected) {
            let data = {};

            // NB: 'props.match.params.module' is only available on "/ui/auth/:module?".
            const m_props = { match: { params: { module: urlRole } } };

            AJAX()
              .getSession(this._id, m_props)
              .then(res => {
                if (!res || !res.data) {
                  AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
                  this.props.history.push("/ui/auth");
                  return;
                }

                data.session = res.data;

                AJAX()
                  .getAppInfo(this._id)
                  .then(res => {
                    if (!res || !res.data) return;

                    const apiInfo = res.data;
                    this.setLanuage(apiInfo)

                    currentRole = data.session.data.current_role;

                    apiInfo.default_formio_options = {
                      ...AA_GLOBAL.globalFormioOpts,
                      ...apiInfo.default_formio_options,
                    };

                    fetchAndSetFormioLocale(apiInfo.default_formio_options);

                    let activeModule = {},
                      defaultApp = apiInfo.apps[apiInfo.default_app];

                    // if url page role is included in the user roles
                    if (data.session.data.roles.includes(urlRole)) {
                      // set app module based on url page name
                      activeModule = defaultApp.modules[urlRole];
                      // set role_selected to this tole
                      data.session.data.role_selected = urlRole;
                    } else {
                      activeModule = defaultApp.modules[currentRole];
                      data.session.data.role_selected = data.session.data.current_role;
                    }

                    data.info = apiInfo;
                    data.appModule = activeModule;

                    this.props.saveSessionAndInfo(data);
                    // userRoleValidation(currentRole, this.props);
                    this.setLoaded();

                    if (this.props.location.pathname === "/ui/auth") this.props.history.push("/ui");
                  });
              });
          }
          // on route cahnge
          else {
            // if url page and role and not the same
            if (urlRole !== this.props.user.role_selected) {
              // take role from url and check if user has it in its roles
              // if it has it add that role as selected role and set up the module
              if (this.props.user.roles.includes(urlRole)) {
                const role = {
                  role_selected: urlRole,
                  appModule: AJAX().defaultApp(this.props).modules[urlRole],
                };
                this.props.setUserRole(role);
              }
              // if it doesnt have it add a current role and setup the module
              else {
                const role = {
                  role_selected: this.props.user.current_role,
                  appModule: AJAX().defaultApp(this.props).modules[this.props.user.current_role],
                };
                this.props.setUserRole(role);
              }
            }

            // userRoleValidation(this.props.user.role_selected, this.props);
            this.setLoaded();
          }
        }
      }
      // if not loged in
      else {
        AJAX()
          .getAppInfo(this._id)
          .then(res => {
            const info = res.data;

            this.setLanuage(info)

            this.props.getInfo(info);
            const moduleKind = this.props.match.params.module;

            // if your not on /ui/auth page redirect to it
            if (
              !moduleKind &&
              this.props.location.pathname !== "/ui/auth" &&
              !this.props.location.pathname.startsWith("/ui/user/reset_password")
            ) {
              this.props.history.push("/ui/auth");
            }

            // get login data if its not in redux state
            if (!this.props.loginData) {
              AJAX()
                .getLoginData(this._id, this.props)
                .then(res => {
                  if (!res) return;
                  this.props.getLoginData(res.data);
                  this.setLoaded();
                });
            } else {
              this.setLoaded();
            }
          });
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      // if logged in and users tries to go to auth page redirect to another page
      const authToken = AA_GLOBAL.cookies.get("auth_token");
      const urlPage = this.props.location.pathname.split("/")[2];
      if (authToken && urlPage === "auth") {
        this.props.history.push(`/ui`);
      }

      // if entry counter is finished and you try to go to /new-entry page
      // redirect back to /dashboard page
      if (
        nextProps.location.pathname !== "/ui/" &&
        nextProps.location.pathname === "/ui/new-entry" &&
        !nextProps.user.appModule.active
      ) {
        this.redirectToDashboard(nextProps);
      }
    }

    componentWillUnmount() {
      this.mounted = false;
      AJAX().cancelRequest(this._id);
    }

    setLanuage = (info) => {
      let isLangCookieSet = AA_GLOBAL.cookies.get("lang")
      const lang = info.apps[info.default_app].chosen_locale || info.apps[info.default_app].locales[0]
      AA_GLOBAL.cookies.set("lang", lang, { path: "/" });
      if(!isLangCookieSet) window.location.reload();
    }

    redirectToDashboard = () => {
      //const module = Routes.getData().module !== 'auth' ? Routes.getData().module :
      this.props.history.push(`/ui/${Routes.getData().module}/dashboard`);
    };

    setLoaded = () => this.mounted && this.setState({ loading: false });

    render() {
      const { info } = this.props;
      document.title = info ? info.apps[info.default_app].name : document.title;

      return this.state.loading ? <div className="loader" /> : <ComposedClass {...this.props} />;
    }
  }

  const mapStateToProps = store => ({
    user: store.user,
    info: store.app.info,
    loginData: store.app.loginData,
  });

  return withRouter(
    connect(mapStateToProps, { logout, saveSessionAndInfo, getInfo, setUserRole, getLoginData })(
      AuthHOC
    )
  );
}
