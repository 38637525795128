import { useRef, useEffect, useCallback } from "react";

import axios from "axios";
import { between, showBackendErrorPage } from "../functions/utils";

export default function useSafeAxios(axiosInstance = axios, mounted) {
  const requestTokens = useRef([]);

  useEffect(() => () => requestTokens.current.forEach(t => t.cancel()), []);

  return useCallback(
    function callAxios(config) {
      const token = axios.CancelToken.source();
      requestTokens.current = [token, ...requestTokens.current];

      const removeToken = () =>
        (requestTokens.current = requestTokens.current.filter(t => t !== token));

      return axiosInstance({ ...config, cancelToken: token.token })
        .then(resp => (mounted() ? addFlags(resp) : throwValue("not mounted")))
        .catch(e => {
          addFlags(e.response || {});
          e.unmounted = !mounted();
          e.cancelled = axios.isCancel(e);
          throw e;
        })
        .catch(e => {
          showBackendErrorPage(e);
          throw e;
        })
        .finally(removeToken);
    },
    [axiosInstance, mounted]
  );
}

const addFlags = resp =>
  Object.assign(resp, {
    isOK: between(resp.status, 200, 299),
    is3xx: between(resp.status, 300, 399),
    is4xx: between(resp.status, 400, 499),
    is5xx: between(resp.status, 500, 599),
    changed: !(resp.data || {}).unchanged,
  });

const throwValue = val => {
  throw val;
};
