import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

// COMPONENTS
import MainBtn from "../buttons/MainBtn";
import ContentGenerator from "../layout/ContentGenerator";

export const AuthInfo = props => {
  const { company_info, forms } = props.data || {};

  return (
    <div>
      {/* FIXME: commented out because LoginPage already renders LoginCompanyInfo. */}
      {company_info.logo && company_info.logo.url && (
        <img
          src={company_info.logo.url}
          data-test="companyImage"
          alt="Company Logo"
          className="loginMain__conpanyInfo-logo"
        />
      )}
      {company_info && <ContentGenerator data={company_info.body} />}
      <MainBtn clicked={() => props.changeAuth("login")} addClass="loginMain__conpanyInfo-btn">
        <FormattedMessage id="login" />
      </MainBtn>
      {forms && !forms.hide_registration && (
        <MainBtn clicked={() => props.changeAuth("register")} addClass="loginMain__conpanyInfo-btn">
          <FormattedMessage id="register" />
        </MainBtn>
      )}
    </div>
  );
};

AuthInfo.propTypes = {
  changeAuth: PropTypes.func,
};

export default AuthInfo;
