import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";

// FUNCTIONS
import { classes } from "../../functions/utils";

// COMPONENTS
import ContentContainer from "../layout/ContentContainer";
import ContentLoader from "../loaders/ContentLoader";
import OperationsNav from "../navigations/OperationsNav";
import ScrollableContent from "../layout/ScrollableContent";
import Popover from "../popovers/Popover";
import ProgressBar from "../statistics/ProgressBar";
import IconAria from "../icons/IconAria";

// REDUX
import { connect } from "react-redux";

class MainSideNav extends Component {
  entryListRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = this.propsToState();
    this.changeActiveStep();
  }

  componentDidUpdate(prevProps) {
    if (this.props.menu !== prevProps.menu) this.setState(this.propsToState());

    this.changeActiveStep();

    if (this.props.sticky !== prevProps.sticky) {
      // set the height for scrollable content container
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      if (this.entryListRef.current) {
        let listDivHeight = this.entryListRef.current.clientHeight;
        if (this.props.sticky) listDivHeight = listDivHeight > windowHeight - 70 ? "75vh" : null;
        else listDivHeight = null;
        this.setState({ listDivHeight });
      }
    }
  }

  propsToState() {
    const menuList = this.makeMenuList();

    return {
      menuList,
      menu: this.props.menu,
      stepIDMap: new Map(menuList.map(step => [step.id, step])),
    };
  }

  editEntryStepPath = stepID =>
    `/ui/${this.props.user.role_selected}/edit-entry/${this.props.match.params.id}/${stepID}`;

  editOrViewEntryStepPath = stepID =>
    this.props.linksOnly
      ? `/ui/${this.props.user.role_selected}/project-overview/${this.props.match.params.id}#title-${stepID}`
      : this.editEntryStepPath(stepID);

  flattenSteps(steps) {
    return (steps || []).flatMap(step => this.flattenSteps(step.requirement_steps).concat(step));
  }

  makeMenuList = () => {
    const steps = (this.props.menu || {}).requirement_steps || [];
    // if item is last child add last property to it
    (steps.slice(-1)[0] || {}).is_last = true;
    return this.flattenSteps(steps);
  };

  findActiveStep = () => this.state.stepIDMap.get(this.props.match.params.displayId);

  changeActiveStep = () => {
    const activeStep = this.findActiveStep();
    if (activeStep && this.props.onStepChange) this.props.onStepChange(activeStep);
  };

  onStepClick = (e, step) => {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.onStepClick) this.props.onStepClick();

    if (step.id === this.props.match.params.displayId) return;

    if (this.props.linksOnly) window.location.hash = `#title-${step.id}`;
    else this.props.history.push(this.editEntryStepPath(step.id));
  };

  // RENDER
  renderStepList = () => {
    return (
      <>
        <div id="entryTitle" className="entryListNav__title">
          {this.state.menu.title}
        </div>
        {this.state.menuList?.map(this.renderStep)}
      </>
    );
  };

  renderStep = step => {
    function StepIcon() {
      let icon;
      if (step.is_last) {
        icon = (
          <div>
            <IconAria
              iconId="addBox"
              className={classes(
                "entryListNav__item-icon entryListNav__item-icon-show",
                step.confirmed && "entryListNav__item-icon-submited"
              )}
            />
          </div>
        );
      } else {
        icon = step.confirmed ? (
          <IconAria
            iconId="checkCircle"
            className={
              "entryListNav__item-icon entryListNav__item-icon-saved entryListNav__item-icon-show"
            }
          />
        ) : (
          <IconAria
            iconId="circle"
            className={"entryListNav__item-icon entryListNav__item-icon-show"}
          />
        );
      }
      return icon;
    }

    const { linksOnly, match } = this.props;
    const isCurrent = match.params.displayId === step.id;

    return (
      <Popover msg={step.label} key={step.id}>
        <div
          className={classes(
            "entryListNav__item",
            isCurrent && !linksOnly && "entryListNav__item-active",
            !step.confirmed && linksOnly && "entryListNav__item-unactive",
            linksOnly && "entryListNav__item-link"
          )}
          onClick={e => this.onStepClick(e, step)}
        >
          <div
            className={classes(
              "entryListNav__item-iconCon",
              linksOnly && "entryListNav__item-iconCon-hideIcon"
            )}
          >
            <StepIcon />
          </div>
          <div
            className={classes(
              "entryListNav__item-title",
              linksOnly && "entryListNav__item-title-link"
            )}
          >
            {linksOnly && !this.state.menu.locked && (
              <Link
                aria-label={step.label}
                className="entryListNav__item-title-icon"
                onClick={e => e.stopPropagation()}
                to={this.editEntryStepPath(step.id)}
              >
                <IconAria iconId="edit" />
              </Link>
            )}
            <Link
              className={classes(
                "entryListNav__item-title-text",
                step.confirmed && linksOnly && "entryListNav__item-title-text-a",
                ((!step.confirmed && linksOnly) || step.active === false) &&
                  "entryListNav__item-title-text-disabled"
              )}
              onClick={e => this.onStepClick(e, step)}
              aria-current={isCurrent ? "step" : null}
              to={this.editOrViewEntryStepPath(step.id)}
            >
              {step.label}
            </Link>
          </div>
        </div>
      </Popover>
    );
  };

  render = () => {
    const { status_label, status, code, progress, locked } = this.state.menu || {};

    return _isEmpty(this.state.menu) ? (
      <ContentLoader full />
    ) : (
      <ContentContainer
        titleType={this.props.titleType || "h2"}
        title={code}
        status={{ code: status, label: status_label }}
        removePadding={this.props.removePadding || this.props.operationsNav}
      >
        <div style={{ display: "flex" }}>
          {this.props.operationsNav && (
            <OperationsNav
              data={this.state.menu}
              print={this.props.onPrintClick}
              disableEdit={locked}
              disableDelete={locked}
              removeBtnClick={this.props.onRemoveClick}
            />
          )}

          <ScrollableContent height={this.state.listDivHeight}>
            {progress && <ProgressBar value={progress.value} ttip={progress.ttip} />}
            <nav
              className="entryListNav entryListNav__menu"
              tabIndex="0"
              ref={this.entryListRef}
              aria-labelledby="entryTitle"
            >
              {this.renderStepList()}
            </nav>
          </ScrollableContent>
        </div>
      </ContentContainer>
    );
  };
}

MainSideNav.propTypes = {
  menu: PropTypes.object,
  sticky: PropTypes.bool,
  operationsNav: PropTypes.bool,
  removePadding: PropTypes.bool,
  linksOnly: PropTypes.bool,
  onPrintClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onStepChange: PropTypes.func,
  onStepClick: PropTypes.func,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps)(MainSideNav));
