//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";
import { renderLangString } from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";
import ErrorHOC from "../../hoc/ErrorHOC";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import Main from "../../components/main/Main";
import MainNav from "../../components/navigations/MainNav";
import AlphaTable from "../../components/tables/AlphaTable";
import AnimateLayout from "../../components/layout/AnimateLayout";
import TogglePrompt from "../../components/prompt/TogglePrompt";
import Title from "../../components/text/Title";
import ExpandableDivAnimation from "../../components/features/ExpandableDivAnimation";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import ParseHTML from "../../functions/ParseHTML";
import validateData from "../../functions/validateData";

// DATA MODEL
import collectionDataModel from "../../data_model/reviewer/collection/collection";
import stageCollectionsDataModel from "../../data_model/reviewer/collection/all_collections";

function Collection({ match }) {
  const isInitialMount = useRef(false);
  const _id = 4353458974568;
  const dispatch = useDispatch();

  const [counterData, setCounterData] = useState();
  const [collectionData, setCollectionData] = useState({});
  const [isCounterDone, setIsCounterDone] = useState(false);
  const [tableData, setTableData] = useState();
  const [reviewStageCollections, setReviewStageCollections] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const reduxState = useSelector(state => state);

  const init = useCallback(() => {
    getMainData();
  }, [getMainData]);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  const getMainData = () => {
    AJAX()
      .getReviewCollection(_id, reduxState, match.params.id)
      .then(res => {
        const isDataValid = validateData(
          "Reviewer > Collection > AJAX().getReviewCollection",
          res,
          collectionDataModel
        );

        if (isDataValid) {
          const data = res.data.collection;
          getMenuData(data.stage_id);

          setCollectionData(data);
          setTableData(data.cases_table);
          setIsConfirmed(data.confirmed);
          dispatch({
            type: TYPES.SET_PAGE_IDS,
            payload: { stage: data.stage_id, collection: data.id },
          });
          setCounterData({
            active: data.in_range == 0 ? true : false,
            end_at: data.end_at, //new Date().setSeconds(new Date().getSeconds() + 10), //
            start_at: data.start_at,
            toWhat: "Something",
          });
        }
      });
  };

  const getMenuData = id => {
    AJAX()
      .getReviewStage(_id, reduxState, id)
      .then(res => {
        const isDataValid = validateData(
          "Reviewer > Collection > AJAX().getReviewStage",
          res,
          stageCollectionsDataModel
        );

        if (isDataValid) {
          setReviewStageCollections(res.data.stage.collections);
        }
      });
  };

  const handleConfirm = () => {
    const confirmData = collectionData.apis.toggle_c;

    AJAX()
      .patch(_id, confirmData.api.url, {})
      .then(res => {
        if (res.data.collection.confirmed) {
          setIsConfirmed(true);
        } else if (!res.data.collection.confirmed) {
          setIsConfirmed(false);
        }

        // update the menu
        getMenuData(collectionData.stage_id);
      });
  };

  const handleNavItemClick = () => {
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  const handleCounterEnd = () => {
    setIsCounterDone(true);
  };

  // run on unmount
  useEffect(() => {
    return () => {
      AJAX().cancelRequest(_id);
    };
  }, [_id]);

  // @todo: React gives "TypeError: counterData is undefined"
  // Set to true for now so that voting works.
  const counterData_active = counterData ? counterData.active : true;

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={collectionData} addClass="__n_bs">
          <ExpandableDivAnimation>
            <Title tagName="h1" className="__c_hh">
              {collectionData.title}
            </Title>

            <ParseHTML html={collectionData.description} />
          </ExpandableDivAnimation>
          <MainNav
            clicked={handleNavItemClick}
            title={renderLangString({ id: "$_m" })}
            data={reviewStageCollections}
            linkTo={Routes.reviewerCollection().path}
            activeItem={match.params.id}
          />
        </AnimateLayout>
      </MainAsideNav>
      <Main counter={counterData} onCounterEnd={handleCounterEnd}>
        <AnimateLayout animate={collectionData}>
          {collectionData &&
            collectionData.stats &&
            collectionData.stats.todo === 0 &&
            !isCounterDone &&
            counterData_active && (
              <TogglePrompt
                confirmer
                style={{ marginBottom: "2rem" }}
                title={[renderLangString({ id: "$_a2" }), renderLangString({ id: "$_a3" })]}
                msg={[renderLangString({ id: "$_a4" }), renderLangString({ id: "$_a5" })]}
                btnText={[renderLangString({ id: "$_a6" }), renderLangString({ id: "$_s" })]}
                view={isConfirmed}
                callback={handleConfirm}
              />
            )}
          {tableData && (
            <ErrorHOC crashPage>
              <AlphaTable data={tableData} />
            </ErrorHOC>
          )}
        </AnimateLayout>
      </Main>
      <HelperAsideNav />
    </NewAppHOC>
  );
}

export default injectIntl(withRouter(Collection));
