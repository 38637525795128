/*jshint esversion: 6 */
import * as Sentry from "@sentry/browser";
import "flatpickr";
import { German } from "flatpickr/dist/l10n/de";
import { Russian } from "flatpickr/dist/l10n/ru";
import { Portuguese } from "flatpickr/dist/l10n/pt";
import { French } from "flatpickr/dist/l10n/fr";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./main.css";
import "./main.dashboard.css";
import { addLocaleData } from "react-intl";
import ReduxWrapper from "./hoc/ReduxWrapper"; // REDUX
import "promise-polyfill/src/polyfill";
import "./polyfills/flatMap";

// define languages you what to use
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import pt from "react-intl/locale-data/pt";
import ru from "react-intl/locale-data/ru";
import fr from "react-intl/locale-data/fr";
addLocaleData([...en, ...de, ...ru, ...pt, ...fr]);

window["flatpickr-de"] = German;
window["flatpickr-ru"] = Russian;
window["flatpickr-pt"] = Portuguese;
window["flatpickr-fr"] = French;

if (process.env.NODE_ENV === "production")
  Sentry.init({
    dsn: "https://dca51e91c57d4f43a109694ebf3da650@o262.ingest.sentry.io/6078676",
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });

// if our app supports the browser, render it
if (!localStorage.getItem("browserSupport")) {
  const root = React.createElement(ReduxWrapper, {}, React.createElement(App));
  ReactDOM.render(root, document.getElementById("root"));
}
