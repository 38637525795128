import {
  ADD_ENTRIES,
  GET_INFO,
  CHECK_LIST_STEP,
  GET_PROJECT_OVERVIEW_DATA,
  CLEAR_ENTRY_STEPS,
  CLEAR_ENTRY_STEP_DATA,
  GET_CATEGORIES,
  SAVE_UPLOAD_DATA,
  CLEAR_UPLOAD_DATA,
  FORM_SUBMITED,
  DISPLAY_ENTRY_STEP,
  CLEAR_ACTIVE_ENTRY_STEP,
  SAVE_FORM_SUBMISSION,
  GET_ENTRY_STEPS,
  GET_OPENED_ENTRY_REQUIREMENTS,
  SUBMISSION_DEADLINE_EXPIRED,
} from "../actions/types";

const initialState = {
  projectOverview: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INFO:
      return {
        ...state,
        submissionDeadline: {
          ...state.submissionDeadline,
          loader: false,
          end_at: action.payload.apps[action.payload.default_app].modules.submitter.end_at,
          active: action.payload.apps[action.payload.default_app].modules.submitter.active,
        },
      };
    case GET_OPENED_ENTRY_REQUIREMENTS:
      return {
        ...state,
        openedEntryRequiremets: action.payload,
      };
    case CHECK_LIST_STEP:
      return {
        ...state,
        openedEntryRequiremets: action.payload,
      };
    case DISPLAY_ENTRY_STEP:
      return {
        ...state,
        stepActive: action.payload,
      };
    case SAVE_FORM_SUBMISSION:
      return {
        ...state,
        formSubmission: action.payload,
      };
    case GET_ENTRY_STEPS:
      return {
        ...state,
        steps: action.payload,
      };
    case CLEAR_ENTRY_STEPS:
      return {
        ...state,
        steps: {},
      };
    case FORM_SUBMITED:
      return {
        ...state,
        submitedFormioResponseData: action.payload,
      };
    // case 'FORM SUBMITED ERROR':
    //   return {
    //     ...state,
    //     submitedFormioResponseErr: action.payload
    //   }
    // case 'SET_ACTIVE_ENTRY_STEP':
    //   return {
    //     ...state,
    //     entryStepData: action.payload
    //   }
    case CLEAR_ACTIVE_ENTRY_STEP:
      return {
        ...state,
        stepActive: null,
      };
    case CLEAR_ENTRY_STEP_DATA:
      return {
        ...state,
        entryStepData: {},
      };
    case SAVE_UPLOAD_DATA:
      return {
        ...state,
        upload: {
          [action.payload.id]: action.payload.data,
        },
      };
    case CLEAR_UPLOAD_DATA:
      return {
        ...state,
        upload: null,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_PROJECT_OVERVIEW_DATA:
      return {
        ...state,
        projectOverview: action.payload,
      };
    case SUBMISSION_DEADLINE_EXPIRED:
      return {
        ...state,
        submissionDeadline: { ...state.submissionDeadline, active: false },
      };
    case ADD_ENTRIES:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          [action.payload.tableId]: action.payload.data,
        },
      };
    case "UPPY_STATE":
      return {
        ...state,
        uppyState: action.payload,
      };
    case "SAVE_UPPY_TRANSLATIONS":
      return {
        ...state,
        translations: action.payload,
      };
    // case SEARCH:
    //   let search = state.operations.search;
    //   search.loader = true;
    //   search.value = action.payload
    //   return {
    //     ...state,
    //     operations: {
    //       ...state.operations,
    //       search
    //     }
    //   }

    default:
      return state;
  }
}
