import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { injectIntl } from "react-intl";
// import { MdVideocam, MdLibraryMusic, MdInsertDriveFile, MdInsertPhoto } from 'react-icons/md';
import Sticky from "react-stickynode";
import { FaEdit } from "react-icons/fa";
import _ from "lodash";

// FUNCTION
import AJAX from "../functions/ajax";

// COMPONENTS
import { StepsOverview } from "../components/display/Overview";
import ContentContainer from "../components/layout/ContentContainer";
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import MobileNav from "../components/navigations/MobileNav";
import BigGallery from "../components/gallery/BigGallery";
import MainSideNav from "../components/navigations/MainSideNav";
import Modal from "../components/modals/Modal";
import Popover from "../components/popovers/Popover";
import ContentGenerator from "../components/layout/ContentGenerator";
import FieldList from "../components/layout/FieldList";
import ProgressBar from "../components/statistics/ProgressBar";
import IconAria from "../components/icons/IconAria";

// REDUX
import { connect } from "react-redux";
import { getEntrySteps, getProjectOverview } from "../actions/entryActions";
import { hideMenu } from "../actions/appActions";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// VENDORS
import fadeInOut from "../vendors/fadeInOut";
// import {isHidden} from '../vendors/checkForVisiblity';
import getElPosition from "../vendors/getElPosition";

export class ProjectOverview extends Component {
  _id = Math.random();

  state = {
    animationDone: false,
  };

  UNSAFE_componentWillMount() {
    // if(this.props.entryStepData && !_isEmpty(this.props.entryStepData)) {
    //   this.setState({menuData: this.props.entryStepData})
    //   this.getProjectOverviewData(this.props.entryStepData)
    // }else {

    if (this.props.info) {
      AJAX()
        .getEntryStepList(this._id, this.props, this.props.match.params.id)
        .then(res => {
          if (res) {
            this.setState({ menuData: res.data });
            this.props.getEntrySteps(res.data);
            this.getProjectOverviewData(res.data);
          }
        });
    }
    // }

    window.addEventListener("click", this.scroll);
  }

  componentWillUnmount() {
    clearTimeout(this.animationDoneTimeout);
    clearTimeout(this.waitForAnimation);
    clearTimeout(this.removeHashTimeout);
    window.removeEventListener("click", this.scroll);
    window.removeEventListener("wheel", this.onWheel);
    AJAX().cancelRequest(this._id);
  }

  componentDidMount() {
    // scroll page to top on load
    window.scrollTo(0, 0);

    if (window.location.hash === "#print") {
      //this.props.history.push(window.location.href.substr(0, window.location.href.indexOf('#')))
      this.props.history.push(
        `/ui/${this.props.user.role_selected}/project-overview/${this.props.match.params.id}/${this.props.match.params.displayId}`
      );
      this.waitForAnimation = setTimeout(() => {
        if (window.print) window.print();
      }, 1000);
    }

    this.animationDoneTimeout = setTimeout(() => {
      this.setState({ animationDone: true });
    }, 100);

    window.addEventListener("wheel", this.onWheel);
  }

  // FUNCTIONS
  getProjectOverviewData = list => {
    AJAX()
      .get(this._id, list.data_url)
      .then(res => {
        if (res) {
          this.setState({ projectOverviewData: res.data });
          this.props.getProjectOverview(res.data);
        }
      });
  };

  scroll = e => {
    let target = e.target;

    if (target.tagName === "svg") {
      target = e.target.parentNode;
    } else if (target.tagName === "path") {
      target = e.target.parentNode.parentNode;
    }

    if (
      (e.target.tagName.toLowerCase() === "a" &&
        e.target.classList.contains("entryListNav__item-title-text")) ||
      target.classList.contains("bigGallery__DOptions-eye")
    ) {
      const id = window.location.hash.split("#")[1];
      const el = document.querySelector("#link-" + id);
      var title = document.getElementById("title-" + id);

      // mark title
      if (title) {
        title.classList.add("projectOverview__title-mark");
      }

      if (el) {
        const elOffsetTop = getElPosition(el).y;

        window.scrollTo({
          top: elOffsetTop,
          behavior: "smooth",
        });
      }
    }
  };

  onWheel = () => {
    const markedTitles = document.querySelectorAll(".projectOverview__title-mark");

    if (markedTitles) {
      markedTitles.forEach(title => {
        title.classList.remove("projectOverview__title-mark");
      });
    }

    const slideHash = window.location.hash.slice(0, 7);

    if (slideHash === "#slide-") {
      this.props.history.push(
        `/ui/project-overview/${this.props.match.params.id}/${this.props.match.params.displayId}`
      );
    }
  };

  printEntry = () => {
    if (window.print) window.print();
  };

  showRemoveEntryModal = () => {
    this.props.hideMenu();

    const el = document.getElementById("fjse09fa0j92f0039jf0932");
    if (el) {
      fadeInOut(el, "500", true);
      setTimeout(() => {
        document.getElementById("yesfjse09fa0j92f0039jf0932").focus();
      }, 700);
    } else {
      console.error("Modal id does not exist");
    }
  };

  confirmDelete = () => {
    // show modal loader
    this.setState({ deleteModalLoader: true });

    // set end point url
    const id = encodeURI(`?ids=["${this.props.match.params.id}"]`);

    AJAX()
      .deleteEntry(this._id, this.props, id)
      .then(res => {
        if (res) {
          // hide loader
          this.setState({ deleteModalLoader: false });
        }
      });
  };

  loaderDone = () => {
    // hide modal
    const el = document.getElementById("fjse09fa0j92f0039jf0932");
    if (el) {
      fadeInOut(el, "500", false);
    }
    this.setState({ deleteModalLoader: undefined });

    // this.props.history.goBack()
    this.props.history.push(`/ui/${this.props.user.role_selected}/dashboard`);
  };

  projectOverviewDataWasRedered = () => {
    // scroll on page load
    if (window.location.hash.slice(0, 7) === "#slide-") {
      this.waitForAnimation = setTimeout(() => {
        const id = window.location.hash.slice(7);
        const el = document.querySelector("#link-" + id);
        const title = document.getElementById("title-" + id);

        // mark title
        if (title) {
          title.classList.add("projectOverview__title-mark");
        }

        if (el) {
          const elOffsetTop = getElPosition(el).y;

          window.scrollTo({
            top: elOffsetTop,
            behavior: "smooth",
          });
        }
      }, 300);
    }
  };

  viewUser = user => {
    this.props.history.push(`/ui/${this.props.user.role_selected}/user/${user.id}`);
  };

  render() {
    const { alerts, progress } = this.state.projectOverviewData || {};

    return (
      <AppHOC>
        <div className="page" id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin" id="main_content">
            <div className="dashboardMain dashboardMain__multiple ">
              <AnimateHOC
                animate={this.state.projectOverviewData}
                test="aniOverview"
                addClass="column-md u-order-2"
              >
                {this.state.projectOverviewData && (
                  <ContentContainer
                    titleType="h1"
                    test="overviewCon"
                    contentRedered={this.projectOverviewDataWasRedered}
                    title={this.props.intl.formatMessage({ id: "projectdetails" })}
                    alerterData={alerts || []}
                  >
                    <div className="projectOverview__header">
                      <span
                        className={`projectOverview__idMarker`}
                        id={`link-${this.props.match.params.id}`}
                      ></span>
                      <h2
                        className="projectOverview__title"
                        id={`title-${this.props.match.params.id}`}
                      >
                        {this.state.projectOverviewData.title}
                        {!this.state.projectOverviewData.locked ? (
                          <Popover
                            msg={
                              this.props.intl.formatMessage({ id: "edit" }) +
                              " " +
                              this.state.projectOverviewData.title
                            }
                          >
                            <Link
                              aria-label={this.state.projectOverviewData.title}
                              to={`/ui/${this.props.user.role_selected}/edit-entry/${this.props.match.params.id}/${this.props.match.params.id}`}
                            >
                              <IconAria iconId="edit" className="projectOverview__title-icon" />
                            </Link>
                          </Popover>
                        ) : null}
                      </h2>
                      <h3 className="projectOverview__subTitle">
                        {this.state.projectOverviewData.categories
                          ? this.state.projectOverviewData.categories.join(", ")
                          : ""}
                      </h3>
                    </div>

                    {progress && (
                      <ProgressBar
                        value={progress.value}
                        text={progress.text}
                        style={{ margin: "-1em 0 0.75em" }}
                        ttip={progress.ttip}
                      />
                    )}

                    {this.state.projectOverviewData &&
                    this.state.projectOverviewData.gallery &&
                    this.state.projectOverviewData.gallery.length !== 0 ? (
                      <BigGallery
                        blockEditing={this.state.projectOverviewData.locked}
                        title={
                          this.props.entryStepData.title + " - " + this.props.entryStepData.code
                        }
                        data={this.state.projectOverviewData.gallery}
                      />
                    ) : null}

                    {this.state.projectOverviewData && (
                      <div>
                        <div className="printHeaderData">
                          <img
                            src={this.props.info.apps[this.props.info.default_app].logo.src}
                            className="projectOverview__printLogo"
                            alt="Company Logo"
                          />
                          <span className="projectOverview__titleCode">
                            {this.state.projectOverviewData.code}
                          </span>
                          <h2 className="projectOverview__printTitle">
                            {this.state.projectOverviewData.title}
                            <hr style={{ margin: "2rem 0 3rem" }} />
                          </h2>
                        </div>

                        <StepsOverview
                          steps={this.state.projectOverviewData?.data}
                          opts={this.props}
                        />
                      </div>
                    )}
                  </ContentContainer>
                )}
              </AnimateHOC>

              <MobileNav addClass="u-order-1">
                <Sticky>
                  <AnimateHOC animate={this.state.menuData} test="animateMenu">
                    <MainSideNav
                      menu={this.state.menuData}
                      titleType="h1"
                      onStepClick={this.props.hideMenu}
                      onPrintClick={this.printEntry}
                      onRemoveClick={this.showRemoveEntryModal}
                      operationsNav
                      linksOnly
                    />

                    {this.state.menuData && this.props.user.role_selected === "submission_admin" ? (
                      <ContentContainer
                        title={this.state.menuData.created_by.user.title}
                        btn
                        btnText={"view"}
                        btnOnClick={() => this.viewUser(this.state.menuData.created_by.user)}
                      >
                        <FieldList
                          model={this.state.menuData.created_by.user}
                          // @todo add proper CSS class (added projectOverview as a temp solution.)
                          prefix="projectOverview"
                          addClass="projectOverview"
                        />
                      </ContentContainer>
                    ) : null}
                  </AnimateHOC>
                </Sticky>
              </MobileNav>
            </div>
          </div>
          <FooterMain />
        </div>

        <Modal
          id="fjse09fa0j92f0039jf0932"
          loaderDone={this.loaderDone}
          loader={this.state.deleteModalLoader}
          promptModal
          intl
          yesMsg="yes"
          noMsg="no"
          yes={this.confirmDelete}
          msg="areYouSureDeleteEntries"
        />
      </AppHOC>
    );
  }
}

ProjectOverview.propTypes = {};

const mapStateToProps = store => ({
  entryStepData: store.entry.steps,
  info: store.app.info,
  user: store.user,
});

export default injectIntl(
  connect(mapStateToProps, { getEntrySteps, getProjectOverview, hideMenu })(
    withRouter(ProjectOverview)
  )
);
