//** NEW TEMPLATE
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import renderLangString from "../../languageStrings";

// COMPONENT
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import Prompter from "../prompt/Prompter";
import ToggleVisibility from "../features/ToggleVisibility";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// GLOBALS
import menuData from "../../globals/globalNavData";
import AA_GLOBAL from "../../globals/globals";
import Routes from "../../Routes";

// FUNCTIONS
import S from "../../functions/select";

const GlobalNav = ({ history }) => {
  const reduxStateReviewer = useSelector(state => state.reviewer);
  const [activeMenuPosition, setActiveMenuPosition] = useState(null);
  const [bodyMainMenu, setBodyMainMenu] = useState(null);
  const [bodySubMenu, setBodySubMenu] = useState(null);
  const [footerMenu, setFooterMenu] = useState(null);
  const [isFooterMenuVisible, setIsFooterMenuVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const module = Routes.getData().module;
    const page = Routes.getData().page;

    const bodyMainMenuData = menuData[module][page].body.main;
    const bodySubMenuData = menuData[module][page].body.subMenu;
    const footerMenuData = menuData[module][page].footer;
    const activeItem = menuData[module][page].activeItem;

    setBodyMainMenu(bodyMainMenuData);
    setBodySubMenu(bodySubMenuData);
    setFooterMenu(footerMenuData);
    setActiveMenuPosition(activeItem);
  }, [
    history,
    bodyMainMenu,
    bodySubMenu,
    footerMenu,
    setBodyMainMenu,
    setBodySubMenu,
    setFooterMenu,
  ]);

  const navigateTo = (e, btn) => {
    e.preventDefault();
    const url =
      btn.urlIdKey && reduxStateReviewer[btn.urlIdKey]
        ? `${btn.url}/${reduxStateReviewer[btn.urlIdKey]}`
        : btn.url;

    history.push(url);
  };

  const navigateToNewTab = (e, btn) => {
    e.preventDefault();
    const url =
      btn.urlIdKey && reduxStateReviewer[btn.urlIdKey]
        ? `${btn.url}/${reduxStateReviewer[btn.urlIdKey]}`
        : btn.url;
    // do the same on middle mouse button click
    if (e.button === 1) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  };

  const showLogoutMenu = e => {
    setIsFooterMenuVisible(!isFooterMenuVisible);
  };

  const handleLogoutKeypress = e => {
    if (e.key === "Enter") {
      setTimeout(() => {
        if (S("#x_309258290482390483029")[0]) S("#x_309258290482390483029")[0].focus();
      }, 100);
    }
  };

  const handlePromperEvents = which => {
    if (which === "close") {
      setIsFooterMenuVisible(false);
    } else if (which === "confirm") {
      logoutUser();
    }
  };

  const logoutUser = () => {
    AA_GLOBAL.cookies.remove("auth_token", { path: "/" });
    dispatch({ type: TYPES.LOGOUT });
    history.push(Routes.getReviewerLogin().route);
    document.querySelector("body").classList.remove("theme-1");
  };

  const renderMenu = menuData =>
    menuData &&
    menuData.map(btn => {
      if (btn.url) {
        return (
          <li key={btn.id}>
            <Link
              id={btn.elID}
              className={btn.active && `__g_bl--a`}
              aria-label={renderLangString({ id: btn.ariaLabel })}
              to={btn.url}
              onClick={e => navigateTo(e, btn)}
              onMouseDown={e => navigateToNewTab(e, btn)}
            >
              <Icon {...btn.iconProps} />
            </Link>
          </li>
        );
      } else if (btn.button === "logout") {
        return (
          <li key={btn.id}>
            <button
              id={btn.elID}
              tabIndex="0"
              className={`__b __g_fb ${btn.active ? `__g_bl--a` : ""}`}
              aria-label={renderLangString({ id: btn.ariaLabel })}
              onClick={e => showLogoutMenu(e)}
              onKeyPress={handleLogoutKeypress}
            >
              <Icon {...btn.iconProps} />
            </button>
          </li>
        );
      } else {
        return <li></li>;
      }
    });

  const homeLink = history.location.pathname.split("/").slice(0, 3).join("/") + "/dashboard";

  return (
    <div className="__g bc1 c12">
      <Layout flex flexColumn screenHeight lastItemBottom>
        <div className="__g_h">
          <div>
            <Link aria-label={renderLangString({ id: "$_0" })} to={homeLink}>
              <Icon iconId="globalNavLogo" addClass="c12" />
            </Link>
          </div>
        </div>
        <div className="__g_b">
          {activeMenuPosition && (
            <Icon
              iconId="globalNavActiveBack"
              addClass="ic-ac"
              style={{ top: activeMenuPosition }}
            />
          )}
          <nav aria-label={renderLangString({ id: "$_a7" })}>
            <ul>{renderMenu(bodyMainMenu)}</ul>
            <ul>{renderMenu(bodySubMenu)}</ul>
          </nav>
        </div>
        <div className="__g_f">
          <nav aria-label={renderLangString({ id: "$_a8" })}>
            <ul>{renderMenu(footerMenu)}</ul>
          </nav>
          <ToggleVisibility toggle={isFooterMenuVisible}>
            <div className={`__g_fm c16`}>
              <Prompter
                id="309258290482390483029"
                title={renderLangString({ id: "$_b5" })}
                msg={renderLangString({ id: "$_b6" })}
                handleEvents={handlePromperEvents}
              />
            </div>
          </ToggleVisibility>
        </div>
      </Layout>
    </div>
  );
};

export default withRouter(GlobalNav);
