/**
 * Central collection of location path building functions.
 */

const modules = {
  ui: "ui",
  auth: "auth",
  reviewer: "reviewer",
  reviewAdmin: "review_admin",
};

const getAllUrlData = (module = "", page = "", ids = "") => {
  const urlArr = window.location.href
    .split("/")
    .splice(window.location.href.split("/").indexOf(modules.ui));

  return {
    activeUrl: window.location.href,
    route: `/${modules.ui}/${module}/${page}/${ids}`,
    path: `/${modules.ui}/${module}/${page}`,
    module: module || urlArr[1],
    page: page || urlArr[2],
    ui: modules.ui,
  };
};

const UIPath = {
  ui: "/ui",
  info: "/ui/info",
  c403: "/ui/403",
  resetPassword: "/ui/user/reset_password",
  editEntry: "/ui/edit-entry",
  newEntry: "/ui/new-entry",
  projectOverview: "/ui/project-overview",
  userNewEntry: "/ui/submitter/new-entry",
  smAdminNewEntry: "/ui/submission_admin/dashboard/new-entry",
  smAdminModerate: "/ui/submission_admin/moderate",
  userReviewStage: "/ui/judge/review_stage",
  adminReviewStage: "/ui/judge_admin/review_stage",
};

const opt = x => (x ? "/" + x : "");

const Routes = {
  getData: () => getAllUrlData(),
  // Authentication
  getReviewerLogin: () => getAllUrlData(modules.auth, "judge"),
  // Reviewer
  reviewerDashboard: () => getAllUrlData(modules.reviewer, "dashboard"),
  reviewerStage: () => getAllUrlData(modules.reviewer, "stage", ":id"),
  reviewerCollection: () => getAllUrlData(modules.reviewer, "collection", ":id"),
  reviewerCase: () => getAllUrlData(modules.reviewer, "case", ":id"),
  // Reviewer Admin
  reviewAdminDashboard: () => getAllUrlData(modules.reviewAdmin, "dashboard"),
  reviewAdminStage: () => getAllUrlData(modules.reviewAdmin, "stage", ":id"),
  reviewAdminCollection: () => getAllUrlData(modules.reviewAdmin, "collection", ":id"),
  reviewAdminCase: () => getAllUrlData(modules.reviewAdmin, "case", ":id"),
  reviewAdminReviewer: () => getAllUrlData(modules.reviewAdmin, "reviewer", ":id"),

  ui: () => UIPath.ui,
  info: () => UIPath.info,
  c403: () => UIPath.c403,
  urlRole: ({ location }) => location.pathname.split("/")[2],
  auth: module_role => `/ui/auth${opt(module_role)}`,
  dashboard: role => `/ui/${role}/dashboard`,
  dashboardPage: (role, page) => `/ui/${role}/dashboard${opt(page)}`,
  projectOverview: (role, id, stepID) => `/ui/${role}/project-overview/${id}${opt(stepID)}`,
  editEntryStep: (role, entryID, stepID) => `/ui/${role}/edit-entry/${entryID}/${stepID}`,
  viewUser: (role, id) => `/ui/${role}/user/${id}`,
  page: (role, page, id) => `/ui/${role}/${page}${opt(id)}`,
  privacyInfo: role => `/ui/${role}/profile/privacy_info`,
  changePassword: role => `/ui/${role}/profile/change_password`,
  profileEdit: (role, id) => `/ui/${role}/profile/edit${opt(id)}`,
  userReviewCase: id => `/ui/judge/review_case${opt(id)}`,
  userReviewCollection: id => `/ui/judge/review_collection${opt(id)}`,
  reviewCase: (role, id) => `/ui/${role}/review_case${opt(id)}`,
  reviewCollection: (role, id) => `/ui/${role}/review_collection${opt(id)}`,
};

export { modules, getAllUrlData };
export default Routes;
