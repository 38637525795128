import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { MdAccessTime } from "react-icons/md";

// REDUX
import { connect } from "react-redux";
import { setDeadlineExpired } from "../../actions/entryActions";
import { formatDateTime } from "../../vendors/calTimeZone";

// COMPONENTS
import IconAria from "../icons/IconAria";

export class Counter extends Component {
  state = {
    display: "counter", // loader, expired, counter
  };

  UNSAFE_componentWillMount() {
    this.startCounter(this.props.submissionDeadline);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.submissionDeadline) {
      clearInterval(this.interval);
      this.startCounter(nextProps.submissionDeadline);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  startCounter = submissionDeadline => {
    submissionDeadline.end_at = new Date(submissionDeadline.end_at);

    // if submission deadline has expired
    // display expired message
    if (!submissionDeadline.active) {
      this.setState({ display: "expired" });
    }
    // if submission deadline has not expired
    // show the counter
    else {
      //set the date and time
      const day = new Date(submissionDeadline.end_at).getDate();
      const month = new Date(submissionDeadline.end_at).getMonth() + 1;
      const year = new Date(submissionDeadline.end_at).getFullYear();

      const time = this.calculateTime(submissionDeadline.end_at);

      // run once before interval
      this.tick(submissionDeadline.end_at);

      this.setState(
        {
          counterInit: true,
          active: submissionDeadline.active,
          end_at: submissionDeadline.end_at,
          display: !submissionDeadline.active ? "expired" : "counter",
          day,
          month,
          year,
          ...time,
        },
        () => {
          // run the counter in entry did not expired
          if (this.state.display === "counter") {
            this.interval = setInterval(this.tick, 1000);
          }
        }
      );
    }
  };

  calculateTime = expiresAt => {
    //Set expire date
    const expireDate = new Date(expiresAt).getTime();

    // Get today's date and convert it to time
    var now = new Date().getTime();

    // Find the time difference between now and given date
    var timeDifference = expireDate - now;

    // Calculate days, hours, minutes and seconds
    var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  tick = expiresAt => {
    // get expire date from get request
    let expiresDate = expiresAt;
    let time = this.calculateTime(expiresDate);

    // get expire date from state
    if (!expiresAt) {
      expiresDate = this.state.end_at;
      time = this.calculateTime(expiresDate);
    }

    // If time has expired render expired message, stop and hide the counter
    this.setState(
      () => ({
        days: time.days,
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds,
      }),
      () => {
        const { days, hours, minutes, seconds } = this.state;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
          if (this.props.submissionDeadline.active) {
            this.setState({ display: "expired" });
            this.props.setDeadlineExpired();
            clearInterval(this.interval);
          }
        }
      }
    );
  };

  // Add a 0 in front of the number, if the value is just one number - 01
  formatNumber = num => {
    if (!isNaN(num) && num.toString().length === 1) {
      return "0" + num;
    } else {
      return num;
    }
  };

  renderCounter = isTicketTool => (
    <div>
      <div className="counter__title " data-title="couterTitle">
        {/* <span data-test="just">
          <FormattedMessage id="just" />
        </span> */}
        <span
          data-test="days"
          className={"counter__time " + (this.state.days <= 0 ? " u-hide " : " ")}
        >
          {" "}
          {this.state.days}{" "}
          {this.state.days === 1
            ? this.props.intl.formatMessage({ id: "day" })
            : this.props.intl.formatMessage({ id: "days" })}
        </span>
        <span className={"counter__time " + (this.state.days > 0 ? " u-hide " : " ")}>
          <span
            className={this.state.days === 0 && this.state.hours <= 23 ? " counter__alert " : " "}
          >
            &nbsp;
            <span data-test="hours" className={this.state.hours <= 0 ? " u-hide " : " "}>
              {this.state.hours}:
            </span>
            <span data-test="minutes">{this.formatNumber(this.state.minutes)}:</span>
            <span data-test="seconds">{this.formatNumber(this.state.seconds)}</span>
            &nbsp;
          </span>
        </span>
        <FormattedMessage id="to" />
      </div>
      <div>
        <FormattedMessage id={isTicketTool ? "registrationDeadline" : "submissionDeadline"} />
      </div>
      <div className="counter__subtitle">
        {formatDateTime(this.state.end_at, "DD.MM.YYYY - HH:mm")}
      </div>
    </div>
  );

  renderExpired = () => (
    <div className="counter__expired" data-test="couterExpiredDisplay">
      <FormattedMessage id="deadlineExpired" />
    </div>
  );

  render() {
    const isTicketTool = this.props.info.apps[this.props.info.default_app].slug === "aat2020";

    return (
      <div
        className={"counter " + (this.props.addClass ? this.props.addClass : "")}
        data-test="counter"
      >
        <div className={this.state.display === "counter" ? "" : "u-hide"}>
          <IconAria iconId="clock" className="counter__icon" data-test="clockIcon" />
        </div>
        {this.state.display === "counter" && this.renderCounter(isTicketTool)}
        {this.state.display === "expired" ? this.renderExpired() : null}
      </div>
    );
  }
}

Counter.propTypes = {
  applicationID: PropTypes.string.isRequired,
  submissionDeadline: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  applicationID: state.app.info.context.application_id,
});

export default injectIntl(connect(mapStateToProps, { setDeadlineExpired })(Counter));
