const caseDataModel = {
  case: {
    type: "object",
    contains: {
      id: { type: "string" },
      assignments_url: { type: "string" },
      form: {
        type: "object",
        contains: {
          schema: { type: "object" },
        },
      },
      nav: {
        type: "object",
        contains: {
          cases: {
            type: "array",
            contains: {
              id: { type: "string" },
              confirmed: { type: "boolean" },
              title: { type: "string" },
            },
          },
        },
      },
    },
  },
};

export default caseDataModel;
