//** NEW TEMPLATE
import React, { useEffect, useState, useCallback, useRef } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";
import renderLangString from "../../languageStrings";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// HOC
import NewAppHOC from "../../hoc/NewAppHOC";

// COMPONENTS
import MainAsideNav from "../../components/navigations/MainAsideNav";
import HelperAsideNav from "../../components/navigations/HelperAsideNav";
import Main from "../../components/main/Main";
import ReviewCard from "../../components/display/ReviewCard";
import UserNav from "../../components/navigations/UserNav";
import MainNav from "../../components/navigations/MainNav";
import ToggleLayout from "../../components/layout/ToggleLayout";
import AnimateLayout from "../../components/layout/AnimateLayout";
import Title from "../../components/text/Title";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import ParseHTML from "../../functions/ParseHTML";
import validateData from "../../functions/validateData";

// DATA MODEL
import stageDataModel from "../../data_model/review_admin/stage/stage_data";
import stagesDataModel from "../../data_model/review_admin/dashboard/stages";

function Stage({ match }) {
  const isInitialMount = useRef(false);
  const _id = 43892048293043;
  const dispatch = useDispatch();

  // const [counterData, setCounterData] = useState();
  const [allStages, setAllStages] = useState([]);
  const [activeReviewStage, setReviewStageActive] = useState({});
  const [reviewers, setReviewers] = useState();
  const reduxState = useSelector(state => state);

  const init = useCallback(() => {
    dispatch({ type: TYPES.SET_HELPER_NAV_AS_PRESENT });
    dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
    getMenuData();
    getMainData();
    // getHelperNavData();
  }, [getMenuData, getMainData]);

  // run once on page load
  useEffect(init, []);

  // on route change
  useEffect(() => {
    if (isInitialMount.current) {
      getMainData();
    } else {
      isInitialMount.current = true;
    }
  }, [match]);

  // set the counter
  // useEffect(() => {
  //   if (reduxState.reviewer && reduxState.reviewer.expiresAt) {
  //     setCounterData(reduxState.reviewer.expiresAt);
  //   }
  // }, [reduxState]);

  const getMenuData = () => {
    AJAX()
      .getReviewStages(_id, reduxState)
      .then(res => {
        const isMenuDataValid = validateData(
          "ReviewAdmin > Stage > AJAX().getReviewStages",
          res,
          stagesDataModel
        );
        if (isMenuDataValid) {
          removeUpcomingStages(res.data);
          setAllStages(res.data.stages);
        }
      });
  };

  // const getHelperNavData = () => {
  //   dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
  // };

  const getMainData = () => {
    AJAX()
      .getReviewStage(_id, reduxState, match.params.id)
      .then(res => {
        // validate the stage data
        const isStageDataValid = validateData(
          "ReviewAdmin > Stage > AJAX().getReviewStage",
          res,
          stageDataModel
        );
        if (isStageDataValid) {
          setReviewStageActive(res.data.stage);
          dispatch({
            type: TYPES.SET_PAGE_IDS,
            payload: { stage: res.data.stage.id },
          });
        }

        // get reviewers
        AJAX()
          .get(_id, res.data.stage.assignments_url)
          .then(res => {
            if (res.data.data.length !== 0) {
              if (reduxState.app.sizeOfTheScreen === "desktop") {
                dispatch({ type: TYPES.SHOW_HELLPER_SIDE_NAV });
              }
              setReviewers(res.data.data);
            } else {
              dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
            }
          });
      });
  };

  const removeUpcomingStages = stagesData => {
    const stageData = JSON.parse(JSON.stringify(stagesData));
    stageData.stages = stageData.stages.filter(i => i.in_range != "-1");
    return stageData;
  };

  const handleNavItemClick = () => {
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  // run on unmount
  useEffect(() => {
    return () => {
      AJAX().cancelRequest(_id);
      dispatch({ type: TYPES.HIDE_HELLPER_SIDE_NAV });
      dispatch({ type: TYPES.SET_HELPER_NAV_AS_NOT_PRESENT });
    };
  }, [_id]);

  return (
    <NewAppHOC>
      <MainAsideNav>
        <AnimateLayout animate={activeReviewStage && allStages} addClass="__n_bs">
          <h1 className="__c_hh __h1">{activeReviewStage.title}</h1>
          <ParseHTML html={activeReviewStage.description} />
          {allStages && (
            <MainNav
              clicked={handleNavItemClick}
              title={renderLangString({ id: "$_b8" })}
              data={allStages}
              linkTo={Routes.reviewAdminStage().path}
              activeItem={match.params.id}
            />
          )}
        </AnimateLayout>
      </MainAsideNav>
      <Main>
        <AnimateLayout animate={activeReviewStage}>
          <ToggleLayout id="986436795436" title={activeReviewStage.title}>
            {activeReviewStage.collections &&
              activeReviewStage.collections.map(stage => {
                // set the background color of the collection based on parent status
                stage.in_range = activeReviewStage.in_range;

                return (
                  <ReviewCard
                    downloadLocation={`/api/app/aa2020/module/judging_admin/review/admin/export/xlsx?collection_id=${stage.id}`}
                    popoverStatsFor="judges"
                    footerTwo
                    key={stage.id}
                    data={stage}
                    clickTarget={Routes.reviewAdminCollection().path}
                  />
                );
              })}
          </ToggleLayout>
        </AnimateLayout>
      </Main>
      <HelperAsideNav preventFromClosing>
        <AnimateLayout animate={activeReviewStage}>
          <Title style={{ marginBottom: "3.5rem" }}>{renderLangString({ id: "$_c2" })}</Title>
          {reviewers && <UserNav list={reviewers} popoverStatsFor="judges" />}
        </AnimateLayout>
      </HelperAsideNav>
    </NewAppHOC>
  );
}

export default injectIntl(withRouter(Stage));
