//** NEW TEMPLATE
import React, { useEffect, useState } from "react";
import { FormattedTime, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import renderLangString from "../../languageStrings";

// FEATURES
import ToggleVisibility from "../features/ToggleVisibility";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import ProgressBar from "./ProgressBar";
import NewButton from "../buttons/NewButton";
import AlphaTable from "../tables/AlphaTable";
import Popover from "../popovers/Popover";

// FUNCTIONS
import AJAX from "../../functions/ajax";
import calculateDaysLeft from "../../functions/calculateDaysLeft";
import ParseHTML from "../../functions/ParseHTML";
import getStateFromStageReviewData from "../../functions/getStateFromStageReviewData";
import slideToggle from "../../functions/slideToggle";
import generateUniqueID from "../../functions/generateUniqueID";
import getDateAndTime from "../../functions/getDateAndTime";
import formatWord from "../../functions/formatWord";

const ReviewCard = ({
  data,
  history,
  clickTarget,
  hideFooter,
  footerTwo,
  downloadLocation,
  onError,
  popoverStatsFor,
}) => {
  const _id = generateUniqueID();
  const stageStatus = getStateFromStageReviewData(data);
  const [toggle, setToggle] = useState(false);
  const [footerMenuData, setFooterMenuData] = useState(false);
  const [loader, setLoader] = useState(false);
  let timer = null;

  const handleReviewClick = id => {
    if (stageStatus.stageStatusKey === "notStarted") return false;
    history.push(`${clickTarget}/${id}`);
  };
  // table data: https://alphaawards-aca8b.firebaseio.com/app/table_data.json

  const renderFooter = data => {
    // const pageUrlArr = history.location.pathname.split("/");
    // const whichPage = pageUrlArr[3];

    // display toggole content div below
    // if (whichPage === "stage") {
    //   data.toggleBtnText = "Judges";
    //   data.judgesDataID = "https://alphaawards-aca8b.firebaseio.com/app/table_data.json";
    // }

    // if (data.judgesDataID) {
    //   return toggleFooter(data);
    // }

    if (footerTwo) {
      return renderFooterTwo(data);
    } else {
      return defaultFooter(data);
    }
  };

  const toggleFooterData = data => {
    setLoader(true);
    if (!footerMenuData) {
      timer = setTimeout(() => {
        setLoader(false);
        AJAX()
          .get(_id, data.judgesDataID)
          .then(res => {
            res.data.filters = [];
            setToggle(true);
            setFooterMenuData(res.data);
          });
      }, 500);
    } else {
      setToggle(!toggle);
      setLoader(false);
    }
  };

  const renderStatPopoverMsg = stats => {
    if (popoverStatsFor === "collection")
      return `${stats.done}/${stats.total} ` + renderLangString({ id: "$_c6" });
    if (popoverStatsFor === "judges")
      return `${stats.done}/${stats.total} ` + renderLangString({ id: "$_c7" });
    return " ";
  };

  useEffect(() => {}, [toggle]);

  useEffect(() => {
    const el = document.querySelector(`.__c_f`);

    if (el) {
      if (toggle) {
        el.classList.add("__c_fa");
      } else {
        el.classList.remove("__c_fa");
      }
    }
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const toggleFooter = data => {
    return (
      <div>
        <div className={`__c_f ${stageStatus.dark.bcColor} c10`}>
          <Layout flex spaceBetween>
            <div></div>
            <NewButton
              iconRight={{
                icon: toggle ? (
                  <Icon iconId="arrowLeft" style={{ height: "13px" }} />
                ) : (
                  <Icon iconId="arrowDown" style={{ height: "13px" }} />
                ),
              }}
              iconLeft={{
                icon: loader && (
                  <Icon iconId="spinnerIcon" addClass="sp_f" style={{ height: "13px" }} />
                ),
              }}
              colStyle="noColor"
              style={{ textTransform: "uppercase", fontWeight: "bold", padding: ".5rem 1rem" }}
              clicked={() => toggleFooterData(data)}
              btnStyle="flat"
            >
              {data.toggleBtnText}
            </NewButton>
          </Layout>
        </div>

        <ToggleVisibility toggle={toggle && footerMenuData} animationTime={500} slide>
          {footerMenuData && (
            <div className={`__c_fm `} id={`footerMenu${_id}`}>
              <AlphaTable data={footerMenuData} />
            </div>
          )}
        </ToggleVisibility>
      </div>
    );
  };

  const defaultFooter = data => {
    return (
      <div className={`__c_f ${stageStatus.dark.bcColor} c10`}>
        <div className="__ly_f __ly_f-sb">
          <div className="__c_fl">
            <p className="__h-up">
              <strong>
                {renderLangString({ id: "$_c" })}
                :&nbsp;
              </strong>
              <FormattedTime day="numeric" month="numeric" year="numeric" value={data.start_at} />
            </p>
            <p className="__h-up">
              <strong>
                {renderLangString({ id: "$_d" })}
                :&nbsp;
              </strong>
              <FormattedTime day="numeric" month="numeric" year="numeric" value={data.end_at} />
            </p>
          </div>
          <div className="__c_fr">
            <p className="__h-up">
              <strong>
                {renderLangString({
                  id: "$_e",
                  variables: {
                    num: calculateDaysLeft(data.start_at, data.end_at),
                    pluralizationIndex: calculateDaysLeft(data.start_at, data.end_at) === 1 ? 1 : 2,
                  },
                })}
              </strong>
            </p>
            {data.stats && (
              <p>
                {renderLangString({
                  id: "$_f",
                  variables: {
                    num: data.stats.collections || data.stats.total,
                    pluralizationIndex:
                      data.stats.collections === 1 || data.stats.total === 1 ? 1 : 2,
                  },
                })}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderFooterTwo = data => {
    return (
      <div className={`__c_f ${stageStatus.dark.bcColor} c10`}>
        <div className="__ly_f __ly_f-sb">
          <div className="__c_fl">
            <a href={downloadLocation} className="c10" style={{ textDecoration: "underline" }}>
              {renderLangString({ id: "$_d4" })}
            </a>
          </div>
          <div className="__c_fr">
            <p className="__h-up">
              <strong>
                {data.stats.cases}{" "}
                {renderLangString({
                  id: "$_c5",
                  variables: {
                    pluralizationIndex: data.stats.cases === 1 ? 1 : 2,
                  },
                })}
              </strong>
            </p>
          </div>
        </div>
      </div>
    );
  };

  //@todo on longpress cancel mouse click so user can copy the text from the card
  return (
    <div data-name="ReviewCard" className={`__c ${stageStatus.light.bcColor}`}>
      <Layout flex spaceBetween flexColumn fullHeight>
        <div
          className="__c_m"
          onClick={() => handleReviewClick(data.id)}
          onKeyDown={e => e.key === "Enter" && handleReviewClick(data.id)}
          tabIndex="0"
          role="link"
          style={
            stageStatus.stageStatusKey === "notStarted"
              ? { cursor: "default" }
              : { cursor: "pointer" }
          }
          aria-label={data.title}
        >
          <div className="__c_h">
            <div>
              <div className="__c_hw">
                <Layout flex lastItemRight>
                  {stageStatus.icon}
                  <h3 className="__c_hh __h3">{data.title}</h3>
                  <div className="__c_hd">
                    <Popover msg={renderStatPopoverMsg(data.stats)}>
                      <strong>
                        {data.stats.done}/{data.stats.total}
                      </strong>
                    </Popover>
                  </div>
                </Layout>
              </div>
            </div>
            {data.stats && (
              <ProgressBar
                color={stageStatus.dark.bcColor}
                textStats={stageStatus.langId}
                width={(data.stats.done / data.stats.total) * 100 + "%"}
              />
            )}
          </div>
          <div className="__c_b">
            <div className="__t">
              <ParseHTML html={data.description} />
            </div>
          </div>
        </div>

        {!hideFooter && renderFooter(data)}
      </Layout>
    </div>
  );
};

export default withRouter(ReviewCard);
