export default function fadeInOut(el, fadeOutTime, bool) {
  if (!el) return;

  if (bool) {
    el.style.display = "block";
    setTimeout(() => (el.style.opacity = "1"), 100);
  } else {
    el.style.opacity = "0";
    setTimeout(() => (el.style.display = "none"), fadeOutTime);
  }
}
