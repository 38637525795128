import { Formio } from "formiojs";
import Webform from "formiojs/Webform";
import _isEqual from "lodash/isEqual";
import Cookies from "universal-cookie";
import Choices from "@formio/choices.js";

import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js";

// NB: only works for scrolling down for some reason.
Sortable.mount(new AutoScroll());

/// Utility functions:
const findChild = (elem, tagName) => Array.from(elem.children).find(x => x.matches(tagName));

const $q = (e, s) => Array.from(e.querySelectorAll(s));
const $$q = (e, s) => e.querySelector(s);

/// Patch Formio

const { htmlelement, file, editgrid, select } = Formio.Components.components;

// Enable inlineEdit by default which is more sensible.
const origEditGridDefaultSchema = editgrid.schema;
editgrid.schema = (...args) => ({ ...origEditGridDefaultSchema(...args), inlineEdit: true });

// NB: this helps to prevent Formio from including the button's value in the form data json.
// E.g.: { ..., submit: true }
// The advantage is that we don't have to modify the schema using a recursive function,
// and we especially don't have to search for it in deeper, nested levels.
// The disadvantage may be that we can't use Formio's own submit button if we ever need to,
// but that can be remedied by monkey-patching the button component to make it give no value.
Formio.Components.setComponent("button", htmlelement);

const monkeyPatch = ({ prototype }, mapper) => Object.assign(prototype, mapper(prototype));

// Patch Choices.js Dropdown component.

const getDropdownPrototype = () => {
  const div = document.createElement("div");
  div.innerHTML = "<select/>";
  return { prototype: Object.getPrototypeOf(new Choices(div.firstChild).dropdown) };
};

monkeyPatch(getDropdownPrototype(), _ => ({
  toggle(b) {
    this.element.classList[b ? "add" : "remove"](this.classNames.activeState);
    this.isActive = b;
    return this;
  },
  show() {
    return this.toggle(true);
  },
  hide() {
    return this.toggle(false);
  },
}));

// Patch the file component to support setting a default upload URL.
monkeyPatch(file, ({ init, attach }) => ({
  init() {
    init.call(this);
    // Take the upload URL from the root form schema.
    if (!this.component.url && this.root && this.root._form)
      this.component.url = this.root._form.filesUrl;
  },
  attach(element) {
    const thisComp = this,
      attached = attach.call(this, element);

    const sortMe = ["ul", "div"].reduce((acc, name) => acc || findChild(element, name), 0),
      isDiv = sortMe.tagName === "DIV",
      opts = isDiv ? {} : { draggable: ".list-group-item" };

    if (sortMe)
      new Sortable(sortMe, {
        filter: ".list-group-header",
        // Prevent dropping above the list-group-header.
        onMove: (evt, _oevt) => (isDiv ? null : evt.newIndex === 0 ? false : 1),
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) return;
          const offset = isDiv ? 0 : 1;
          const [to, from] = [newIndex - offset, oldIndex - offset];
          thisComp.dataValue.splice(to, 0, thisComp.dataValue.splice(from, 1)[0]);
          thisComp.triggerChange();
        },
        ...opts,
      });

    return attached;
  },
}));

monkeyPatch(editgrid, ({ attach }) => ({
  attach(element) {
    const thisComp = this,
      attached = attach.call(this, element),
      dragRowClass = "dragRow",
      // NB: need to use id selector or buttons are added multiple times for nested editgrids.
      rowPath = `#${thisComp.id}>ul>li>.row`;

    // NB: the aria labels have been fixed in a newer version of Formio.
    // TODO: remove some parts here after an update.

    // The template of the editgrid itself could be modified, but this works reliably now.
    $q(element.parentNode, `${rowPath} button.editRow, ${rowPath} button.removeRow`).forEach(b => {
      const isEditRow = /\beditRow\b/.test(b.className);

      if (isEditRow && thisComp.component.reorder === true) {
        const handle = document.createElement("span");
        handle.style = "cursor:move";
        handle.className = b.className.replace(/\beditRow\b/, dragRowClass);
        handle.innerHTML = "<i class='fa fa-arrows' aria-hidden='true'></i>";
        b.parentNode.insertBefore(handle, b);
      }

      // For accessibility.
      b.setAttribute("aria-label", isEditRow ? this.t("Edit row") : this.t("Remove row"));
    });

    // if (!this.options.readOnly && !this.disabled) {
    //   console.log("$q(rowPath)", $q(element, rowPath + ">.col-sm-2>.btn-group"));
    //   $q(element, rowPath + ">.col-sm-2>.btn-group").forEach(group => {
    //     // Possible to edit each button group here.
    //   });
    // }

    const sortMe = findChild(element, "ul");

    if (sortMe)
      new Sortable(sortMe, {
        filter: ".list-group-header",
        draggable: ".list-group-item",
        handle: "." + dragRowClass,
        // Prevent dropping above the list-group-header.
        onMove: (evt, _oevt) => (evt.newIndex === 0 ? false : 1),
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) return;
          const offset = 1;
          const [to, from] = [newIndex - offset, oldIndex - offset];
          thisComp.moveRow(from, to);
        },
      });

    return attached;
  },

  /**
   * Nested components can interfere with other children with the same key name.
   * We have to override this function so the "tableView" prop from one component
   * does not interfere with the setting of a deeper nested component.
   *
   * This is a bug which should be reported to Formio.js.
   *
   * Example:
   *
   * Both are called "fieldname" and they have different "tableView" settings.
   *
   * {
   *   type: "editgrid",
   *   components: [
   *     { key: "fieldname", tableView: true },
   *     { components: [{key: "fieldname", tableView: false}], type: "datagrid" }
   *   ]
   * }
   */
  flattenComponents(rowIndex) {
    const result = {};

    const keyPath = c => {
      if (c.component.input === true && c !== this) {
        const parentPath = keyPath(c.parent);
        return parentPath ? parentPath + "." + c.key : c.key;
      } else return "";
    };

    this.everyComponent(component => {
      const path = component.component.flattenAs || keyPath(component) || component.key;
      result[path] = component;
    }, rowIndex);

    return result;
  },

  moveRow(from, to, modified) {
    if (this.options.readOnly) return;

    this.clearErrors(from);

    this.dataValue.splice(to, 0, this.dataValue.splice(from, 1)[0]);
    this.editRows.splice(to, 0, this.editRows.splice(from, 1)[0]);
    this.updateRowsComponents(from < to ? from : to);

    this.emit("editGridMoveRow", { index: from, toIndex: to });
    this.updateValue();

    this.triggerChange({
      modified,
      noPristineChangeOnModified: modified && this.component.rowDrafts,
      isolateRow: true,
    });

    this.checkValidity(null, true);
    this.checkData();
    this.redraw();
  },
}));

monkeyPatch(select, ({ init, attach }) => ({
  init() {
    if (this.component.dataSrc === "url") {
      const { data } = this.component;
      const l = window.location;
      const origin = `${l.protocol}//${l.hostname}${l.port ? ":" + l.port : ""}`;
      const auth_token = new Cookies().get("auth_token");
      // TODO: rather use post method?
      data.url += (!data.url.includes("?") ? "?" : "&") + "auth_token=" + auth_token;
      // Prepend origin location to root paths, otherwise Formio will add baseUrl() or similar.
      if (/^\/([^/]|$)/.test(data.url)) data.url = origin + "/" + data.url;
    }

    init.call(this);
  },
  attach(element) {
    // Need to let Formio create the contents first.
    const attached = attach.call(this, element);

    const autoInput = $$q(element, "input[ref=autocompleteInput]");
    // For accessibility.
    if (autoInput) {
      autoInput.setAttribute("role", "none");
      autoInput.setAttribute("aria-hidden", "true");
    }

    const choicesList = $$q(element, ".choices__list--dropdown > div.choices__list");
    const rmAriaExpanded = tag => tag && tag.removeAttribute("aria-expanded");

    if (choicesList) {
      choicesList.setAttribute("role", "listbox");
      choicesList.setAttribute("tabindex", "0");
      choicesList.setAttribute("aria-label", this.component.label);
      rmAriaExpanded(choicesList);
      rmAriaExpanded(choicesList.parentNode);
    }

    const choicesDiv = $$q(element, "div.formio-choices");

    choicesDiv && choicesDiv.setAttribute("aria-label", this.component.label);
    // NB: Axe complains that the attribute is required but Access Assistant says the opposite. ¯\_(ツ)_/¯
    rmAriaExpanded(choicesDiv);

    if (choicesDiv && choicesDiv.getAttribute("role") === "listbox")
      // Choices.js adds role="listbox" to this div, which is incorrect.
      choicesDiv.removeAttribute("role");

    // NB: it's re-added afterwards every time. Only an issue when component.dataSrc == "json".
    // const choiceItem = $$q(element, ".choices__item--selectable");
    // if (choiceItem) choiceItem.removeAttribute("aria-selected");

    return attached;
  },
}));

// Extend the Webform class with necessary functionality.
monkeyPatch(Webform, _proto => ({
  initAAProps() {
    this._hasChanges = false;
    this._originalData = {};
  },
  // Call when the form has been submitted successfully.
  getHasChanges() {
    return this._hasChanges;
  },
  clearHasChanges() {
    this._hasChanges = false;
    this._originalData = JSON.parse(JSON.stringify(this._data));
  },
  updateHasChanges() {
    const changed = !_isEqual(this._originalData, this._data);

    const hasToggled = this._hasChanges !== changed;

    if (hasToggled) this._hasChanges = changed;

    return hasToggled;
  },
  getOriginalData() {
    return this._originalData;
  },
  setData(data, noCopy) {
    data = noCopy ? data : JSON.parse(JSON.stringify(data));
    return this.setSubmission({ data }, { noValidate: true }).then(() => this.clearHasChanges());
  },
  getData() {
    return this._data;
  },
  clearData() {
    return this.setData({});
  },
  resetData() {
    return this.setData(this._originalData, true);
  },
  checkAndShowErrors() {
    this.checkValidity(/*data=*/ null, /*dirty=*/ true);
  },
}));
