import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const ColorAlerter = (props) => {

  return (
    <div
      data-test="colorAlerter"
      className={"colorAlerter " + (props.warning ? ' colorAlerter--warning ' : '') +
      (props.important ? ' colorAlerter--important ' : '') +
      (props.addClass ? props.addClass : '') +
      (props.toggle ? '' : ' u-hide')}>
      {props.text}
      {props.intlMsg ? props.intl.formatMessage({id: props.intlMsg}) : null}
      {props.list ?
        <ul className="colorAlerter__list">
          {!props.list[0].id ? props.list.map((item, i) => ( <li key={i}>{item.label}</li> )) : null}
          {props.list[0].id ? props.list.map((item, i) => ( <li key={i}><Link to={item.id}>{item.label}</Link></li> )) : null}
        </ul>
        : null}
    </div>
  )
}

ColorAlerter.propTypes = {
  text: PropTypes.string,
  list: PropTypes.array,
  warning: PropTypes.bool,
  alert: PropTypes.bool
}

export default injectIntl(ColorAlerter);