import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// COMPONENTS
import Button from '../buttons/Button';


class ContentTopNav extends Component {
  render() {
    return (
      <div className="contentTopNav">
        {this.props.buttons.map(btn => (
          <Button
            {...btn}
            clicked={() => this.props.butnsClick(btn)}
            key={btn.id}/>
        ))}
      </div>
    );
  }
}

ContentTopNav.propTypes = {
  buttons: PropTypes.array.isRequired
};

export default withRouter(ContentTopNav);