import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import Sticky from "react-stickynode";
import { MdCheckCircle, MdSave } from "react-icons/md";
import { FiCheckSquare, FiExternalLink, FiEdit, FiGrid } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import _map from "lodash/map";
import { classes } from "../../functions/utils";

// COMPONENTS
import ToggleContainerBtn from "../buttons/ToggleContainerBtn";
import Status from "../display/Status";
import Alerter from "../alerts/Alerter";
import ToggleRenderHOC from "../../hoc/ToggleRenderHOC";
import Popover from "../popovers/Popover";
import IconAria from "../icons/IconAria";

export class ContentContainer extends Component {
  state = {
    contentVisibility: true,
    toggleVisiblitiBtn: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({ id: Math.random() });
  }

  componentDidMount() {
    if (this.props.contentRedered) {
      this.props.contentRedered();
    }
    // hide content if component has [close] prop
    if (this.props.close) {
      this.setState({ contentVisibility: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ contentVisibility: !nextProps.close });
  }

  componentWillUnmount() {
    if (this.props.destroyFormioOnUnmount) {
      delete window.Formio;
      delete window.FormioUtils;
    }
  }

  // FUNCTIONS

  toggleContentVisibility = () => {
    this.setState({ contentVisibility: !this.state.contentVisibility });
  };

  // RENDER

  renderBtn = () => (
    <span className="contentContainer__title-status" data-test="titleBtn">
      <div className="statusDisplay" onClick={this.props.btnOnClick}>
        <div className="statusDisplay__name button button__smallTitleButtons">
          {this.props.btnText || "Button"}
        </div>
      </div>
    </span>
  );

  renderToggleBtn = () => (
    <ToggleContainerBtn
      toggleContent={this.toggleContentVisibility}
      closed={this.state.contentVisibility}
    />
  );

  renderStatus = () => (
    <span className="contentContainer__title-status">
      <Status status={this.props.status} />
    </span>
  );

  renderCheckIcon = checked => (
    <ToggleRenderHOC showOnRoutes={["/ui/edit-entry"]}>
      <div className="contentContainer__title-check">
        <IconAria
          iconId="checkCircle"
          className={classes(checked && "contentContainer__title-check-ed")}
        />
      </div>
    </ToggleRenderHOC>
  );

  renderAlerter = alerterData => <Alerter list={alerterData} />;

  renderStats = stats => {
    const icons = {
      drafts: <IconAria iconId="drafts" />,
      published: <IconAria iconId="published" />,
      submitted: <IconAria iconId="submitted" />,
      total: <IconAria iconId="total" />,
    };

    return (
      <div className="contentContainer__stats">
        {_map(stats.entries, (v, k) => {
          const icon = icons[k];
          if (icon) {
            return (
              <Popover intl msg={k} key={k}>
                <span className={"contentContainer__stats-box contentContainer__stats-box-" + k}>
                  {icon} {v}
                </span>
              </Popover>
            );
          }
        })}
      </div>
    );
  };

  static SideNav = props =>
    props.children.some(c => !!c) && (
      <div className="contentContainer__sideNav">{props.children}</div>
    );

  titleArea = () => {
    const CustomTag = this.props.titleType ? this.props.titleType : "h2";

    return (
      <div>
        <div className="contentContainer__title" data-test="contentTitleArea">
          <div className="contentContainer__title-txt">
            {this.renderCheckIcon(this.props.checked)}
            <CustomTag className="title title--medium">{this.props.title}</CustomTag>
          </div>
          <ContentContainer.SideNav>
            {this.props.previewBtnClick && (
              <Popover msg={this.props.intl.formatMessage({ id: "projectOverview" })}>
                <FaEye
                  onClick={this.props.previewBtnClick}
                  className="contentContainer__title-eye"
                />
              </Popover>
            )}
            {this.props.toggle && this.renderToggleBtn()}
            {this.props.btn && this.renderBtn()}
            {this.props.hasUnsavedData && (
              <div className="contentContainer__title-save">
                <Popover
                  textMedium
                  showAs="error"
                  msg={this.props.intl.formatMessage({ id: "unsavedMsg" })}
                >
                  <IconAria iconId="save" />
                  <span className="r-hideOnExtraSmall">
                    <FormattedMessage id="unsavedChanges" />
                  </span>
                </Popover>
              </div>
            )}
            {this.props.stats && this.renderStats(this.props.stats)}
            {this.props.status && this.renderStatus()}
          </ContentContainer.SideNav>
        </div>
        {this.renderAlerter(this.props.alerterData)}
      </div>
    );
  };

  renderTitle = () =>
    this.props.sticky ? (
      <Sticky innerZ="3" activeClass="sticky_fixed">
        {this.titleArea()}
      </Sticky>
    ) : (
      this.titleArea()
    );

  render() {
    return (
      <div
        id={this.props.id ? this.props.id : this.state.id}
        style={this.props.style}
        className={classes("contentContainer", this.props.sizeClass, this.props.addClass)}
        data-test="container"
      >
        {this.props.title && this.renderTitle()}
        <div
          className={classes(
            this.props.removePadding || "contentContainer__content",
            { success: "BLSuccess", important: "BLDanger", dark: "BLDark" }[
              this.props.contentAlerter
            ]
          )}
          data-test="content"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ContentContainer.propTypes = {
  id: PropTypes.string, // shows the titel in the top title area
  title: PropTypes.string, // shows the titel in the top title area
  addClass: PropTypes.string, // add a class to the container
  style: PropTypes.object, // add a custom style
  toggle: PropTypes.bool, // adds a toggle feature on the content and the toggle state icons, by clicking on the icons you can toggle the container
  titleType: PropTypes.string, // change title from h1 to something elese
  close: PropTypes.bool, // close the content on load
  btn: PropTypes.bool, // add a small button in the right side of the title area
  btnText: PropTypes.string, // add text to the button
  btnOnClick: PropTypes.func, // add a function to your button
  status: PropTypes.object, // add a status text display
  hasUnsavedData: PropTypes.bool, // add as Unsaved message status, display: none by default
  sizeClass: PropTypes.string, // set size of the container with size classes
  removePadding: PropTypes.bool, // remove padding on container
  contentAlerter: PropTypes.string, // add styles on content
};

export default injectIntl(ContentContainer);
