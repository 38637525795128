import React, { useEffect, useState } from "react";

// components
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import Popover from "../popovers/Popover";

// hooks
import useScroll from "../../hooks/useScroll";

const ScrollBtn = ({ style, text }) => {
  const [isVisible, setIsVisible] = useState(true);
  const scrollData = useScroll();

  useEffect(() => {
    setIsVisible(true);
    if (scrollData) {
      const htmlHeight =
        document.getElementsByTagName("html")[0].getBoundingClientRect().height - 100;

      if (scrollData.bottom > htmlHeight) {
        setIsVisible(false);
      }
    }
  }, [scrollData]);

  const handleClick = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  if (!isVisible) return false;
  return (
    <Popover msg={text}>
      <Layout addClass="__3" clicked={handleClick}>
        <Icon iconId="arrowDown" />
      </Layout>
    </Popover>
  );
};

export default ScrollBtn;
