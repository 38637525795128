import React from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import { hideMenu, showMobMenu } from '../../actions/appActions';

export const MobileNav = (props) => (
  <div
    id="mobileNav"
    className={'mobileNav '
      + (props.display ? ' a-slideMenuRight ' : ' ')
      + (props.addClass ? props.addClass : ' ')}
    data-test="mobileNav">
    <div>{props.children}</div>
  </div>
);

MobileNav.propTypes = {
  display: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  display: state.app.mobMenuDisplay
})

export default connect(mapStateToProps, { hideMenu, showMobMenu })(MobileNav);