import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {MdFileDownload} from 'react-icons/md';

// COMPONENTS
import MainHeaderNav from '../components/navigations/MainHeaderNav';
import MainBoxNav from '../components/navigations/MainBoxNav';
import FooterMain from '../components/footers/FooterMain';
import TableBtn from '../components/buttons/TableBtn';
import SearchInput from '../components/inputs/SearchInput';
import ContentContainer from '../components/layout/ContentContainer';
import Table from '../components/tables/Table';
import ContentLoader from '../components/loaders/ContentLoader';

// HOC
import AppHOC from '../hoc/AppHOC';

// REDUX
import { connect } from 'react-redux';

export class Payments extends Component {

  UNSAFE_componentWillMount() {

  }

  renderEntryTable = () => {
    let hideTable = false;
    if(this.props.allInvoices) {
      return this.props.allInvoices.map((item, index) => {
        if(index >= 3) { hideTable = true; }
          return (
            <ContentContainer
              title={item.title}
              key={item.id}
              toggle
              close={hideTable}>
              <Table data={item.entries}/>
            </ContentContainer>
          )
      })
    }
  }

  search = (e) => {

  }

  render() {
    return (
      <AppHOC>
      <div id="page">
        <MainHeaderNav/>
        <MainBoxNav/>
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain">
              <div className="contentContainer containerNav">
                <TableBtn text={this.props.intl.formatMessage({id: 'download'}) + ' ' + this.props.intl.formatMessage({id: 'invoice'})}><MdFileDownload/></TableBtn>
                <SearchInput width={'30%'} handleChange={this.search}/>
              </div>

              {!this.props.allInvoices  ?
                <ContentLoader/>
              : this.renderEntryTable()}
            </div>
          </div>
          <FooterMain/>
      </div>
      </AppHOC>
    );
  }
};

const mapStateToProps = (store) => ({

})

export default injectIntl( connect(mapStateToProps)(Payments));