import React, { Component } from "react";
import { injectIntl } from "react-intl";
import axios from "axios";

// HOC
// import AppHOC from '../hoc/AppHOC';
import ErrorHOC from "../hoc/ErrorHOC";

// REDUX
import { connect } from "react-redux";
// import { clearEntrySteps, clearEntryStepActive } from '../actions/entryActions';

// COMPONENTS
// import MainHeaderNav from '../components/navigations/MainHeaderNav';
// import MainBoxNav from '../components/navigations/MainBoxNav';
// import FooterMain from '../components/footers/FooterMain';
import MainNav from "../components/navigations/MainNav";
import ReviewForm from "../components/forms/ReviewForm";
import BaseFormio from "../components/forms/BaseFormio";
// import ContentContainer from "../components/layout/ContentContainer";

export class Testing extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    if (!this.state.formioAllData) {
      axios.get("json/all_in_one_formio.json").then(res => console.log("res", res));
    }
  }

  render() {
    return (
      <div id="page">
        <div className="rowCon rowCon__withMargin">
          <div className="dashboardMain">
            wfewf
            {/* {this.state.formioAllData && (
              <BaseFormio
                schema={{
                  components: this.state.formioAllData.components,
                  filesUrl:
                    "/files/multipart/draft_data_set/21cb84d9-6a1c-463f-8dde-6406bdcf1d49/key/{{self.component.key}}/fpath/{{self.path}}",
                  uppyConfig: { tus: {}, uppy: {} },
                }}
                content={{}}
                options={{
                  language: "de",
                  noAlerts: true,
                  i18n: {
                    de: {
                      "Add Another": "Mehr hinzufügen",
                    },
                  },
                }}
                onCreate={() => {}}
                onDifferent={() => {}}
              />
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  info: state.app.info,
});

export default injectIntl(connect(mapStateToProps)(Testing));
