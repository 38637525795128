import moment from "moment-timezone";
require("moment/locale/de");

moment.locale("de");

const userTimezone = moment.tz.guess();

export function formatDateTime(date, format, tz) {
  return moment
    .utc(date)
    .tz(tz || userTimezone)
    .format(format || "DD.MM.YY - HH:mm");
}

const calTimeZone = date => formatDateTime(date);

export default calTimeZone;
