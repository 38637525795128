import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {MdClear, MdCheck} from 'react-icons/md';

class PaymentTable extends Component {

  price = () => {
    let price = 0;
    this.props.data.forEach(item => {
      price += item.price
    })
    return price;
  }

  renderFooterLink = () => {
    if(this.props.paymentOverview) {
      return <div><span onClick={this.props.changeTable} className="button button__textBtn"><FormattedMessage id="paymentOverview"/></span></div>
    }else if(this.props.payEntries) {
      return <div><span onClick={this.props.changeTable} className="button button__textBtn"><FormattedMessage id="payEntries"/></span></div>
    }else {
      return <div></div>
    }
  }

  render() {
    return (
      <div>
        <table cellSpacing="0" className="myTable" data-table="table">
          <thead>
          <tr>
            <th className="myTable__title-left myTable__headTitle-small"><FormattedMessage id="payed"/></th>
            <th className="myTable__title-left  myTable__headTitle-small"><FormattedMessage id="id"/></th>
            <th className="myTable__title-right  myTable__headTitle-small"><FormattedMessage id="price"/></th>
          </tr>
          </thead>
          <tbody>
          {this.props.data.map(item => (
            <tr className="myTable__tr" key={item.id}>
              <td className="myTable__sml">
                {item.payed ? null : <MdClear className="myTable--red"/>}
                {item.payed ? <MdCheck className="myTable--green"/> : null}
              </td>
              <td className="myTable__sml">{item.entryId}</td>
              <td className="myTable__sml myTable__title-right">{item.price} $</td>
            </tr>
          ))}
          </tbody>
        </table>
        <div className="myTable__footer">
          {this.renderFooterLink()}
          <div>{this.price()} $</div>
        </div>
      </div>
    );
  }
}

export default PaymentTable;