import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

// REDUX
import { connect } from "react-redux";

// COMPONENTS
import CheckBox from "../inputs/CheckBox";
import Select from "../inputs/Select";

// GLOBAL
import AA_GLOBAL from "../../globals/globals";

export class AlphaForm extends Component {
  state = {
    serverErrMsgVisibility: false,
    serverSuccessMsgVisibility: false,
  };

  UNSAFE_componentWillMount() {
    // get the input values from localStorage
    if (this.props.form) {
      this.props.form.components.forEach(item => {
        if (localStorage.getItem(item.id)) {
          item.value = localStorage.getItem(item.id);
        }
      });

      this.setState({ form: this.props.form, formCopy: this.props.form });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if form errMsg exsits, show it to the user
    if (nextProps.form) {
      if (nextProps.form.errMsg && nextProps.form.errMsg.length > 0) {
        this.setState({ form: nextProps.form, serverErrMsgVisibility: true });
      } else if (nextProps.form.successMsg) {
        this.setState({ form: nextProps.form, serverSuccessMsgVisibility: true });
      }
    }
  }

  // HANDLE INPUTS
  handleInputValue = (e, item, index) => {
    this.hideFormErrMessage();
    const inputValue = e.currentTarget.value;
    const inputName = e.currentTarget.name;
    let form = this.state.form;

    form.components.forEach(item => {
      if (item.name === inputName) {
        if (
          item.type === "text" ||
          item.type === "email" ||
          item.type === "password" ||
          item.type === "textarea"
        ) {
          item.value = inputValue;
          if (item.rememberMe) {
            this.remeberValues(item);
          }
        }
      }
    });

    this.setState({ form: form }, () => {
      this.validate(item, index);
    });
  };

  handleSelect = (val, item, index) => {
    this.hideFormErrMessage();
    let inputValue;
    for (let key in val) {
      inputValue = val[key];
    }

    let form = this.state.form;
    form.components.forEach(component => {
      if (component.id === item.id) {
        component.value = inputValue;
        if (component.rememberMe) {
          this.remeberValues(component);
        }
      }
    });

    this.setState({ form: form }, () => {
      this.validate(item, index);
    });
  };

  handleCheckbox = (item, index) => {
    this.hideFormErrMessage();
    let form = this.state.form;
    form.components.forEach((input, i) => {
      if (i === index) {
        input.checked = !input.checked;
        if (item.rememberMe) {
          this.remeberValues(item);
        }
      }
    });

    this.setState({ form: form }, () => {
      this.validate(item, index);
    });
  };

  hideFormErrMessage = () => {
    this.setState({ serverErrMsgVisibility: false, serverSuccessMsgVisibility: false });
  };

  // REMEMBER VALUES
  remeberValues = item => {
    localStorage.setItem(item.id, item.value);
  };

  // VALIDATION

  validate = (item, index) => {
    let form = this.state.form;

    function resetInputValidation() {
      form.components[index].errMsg = "";
      form.components[index].validate.valid = true;
    }

    function setInputInvalid(validation) {
      form.components[index].errMsg = form.components[index].validate.errMsgs[validation];
      form.components[index].validate.valid = false;
    }

    if (item.validate) {
      if (
        item.type === "text" ||
        item.type === "email" ||
        item.type === "password" ||
        item.type === "textarea"
      ) {
        if (item.value.length === 0) {
          setInputInvalid("fieldRequiredOrEmpty");
        } else if (item.validate.regex) {
          if (!item.validate.regex.test(item.value)) {
            setInputInvalid("invalidValue");
          } else {
            resetInputValidation();
          }
        } else if (item.validate.minLength || item.validate.maxLength) {
          if (item.value.length < item.validate.minLength) {
            setInputInvalid("minLength");
          } else if (item.value.length > item.validate.maxLength) {
            setInputInvalid("maxLength");
          } else {
            resetInputValidation();
          }
        } else if (item.repeat) {
          let password = "";
          let repeatPassword = "";
          form.components.forEach(item => {
            if (item.repeat === "firstPass") {
              password = item.value;
            } else if (item.repeat === "secondPass") {
              repeatPassword = item.value;
            }
          });
          if (password !== repeatPassword) {
            setInputInvalid("repeatPassword");
          } else {
            resetInputValidation();
          }
        } else {
          resetInputValidation();
        }
      } else if (item.type === "checkbox") {
        if (!item.checked) {
          setInputInvalid("fieldRequiredOrEmpty");
        } else {
          resetInputValidation();
        }
      } else if (item.type === "select") {
        if (item.value.length === 0) {
          setInputInvalid("fieldRequiredOrEmpty");
        } else {
          resetInputValidation();
        }
      }
    }
    this.setState({ form: form });
  };

  // SUBMIT
  submit = e => {
    e.preventDefault();

    // iterate trought component and validate them
    this.state.form.components.forEach((item, index) => {
      // validate elements
      this.validate(item, index);
    });

    // validate form
    const invalid = this.state.form.components.find(
      item => item.validate && item.validate.valid === false
    );

    // if form is valid call the passed function [getData] and pass to it form values
    if (!invalid) {
      let formData = {};
      this.state.form.components.forEach(item => {
        if (
          item.type === "text" ||
          item.type === "email" ||
          item.type === "password" ||
          item.type === "textarea"
        ) {
          if (item.value.length !== 0) {
            formData[item.name] = item.value;
          }
        } else if (item.type === "checkbox" && item.checked === true) {
          formData[item.name] = item.checked;
        }
      });

      //this.setState({formLoader: false})
      // if info exists and [url] is set
      // use them to construct request url
      if (this.props.info && this.props.url) {
        // const { context } = this.props.info;
        // axiosWithAuth.post(`${AA_GLOBAL.serverDomain.name}/api/org/${context.organization_id}${this.props.url}`, formData)
        //   .then(res => {
        //     this.setState({formLoader: false})
        //     onSuccess(res)
        //   }).catch(err => {
        //     this.setState({formLoader: false})
        //     onError(err)
        //   })
      }
      // if [submitUrl] is set, post on this url
      else {
        // axiosWithAuth.post(this.props.submitUrl)
        //   .then(res => {
        //     this.setState({formLoader: false})
        //     onSuccess(res)
        //   }).catch(err => {
        //     this.setState({formLoader: false})
        //     onError(err)
        //   })
      }

      // const onSuccess = (res) => {
      //   const status = res.data.status;
      //   if(status === 'success') {

      //   }
      // }

      // const onError = (err) => {
      //   const response = err.response.data;
      // }

      this.props.getData(formData);
    }
  };

  // RENDER ELEMENTS

    renderLabel = (item, handleCheckbox) => {

    if (item.additional) {
      if (item.additional.label) {
        return (
          <label htmlFor={item.id} className="form__label" onClick={handleCheckbox}>
                <div dangerouslySetInnerHTML= {{ __html: this.props.intl.formatMessage({ id: item.additional.label })
              ? this.props.intl.formatMessage({ id: item.additional.label })
                                                          : item.additional.label }}>
                </div>
            {item.validate && !item.hideStar ? <span>*</span> : null}
          </label>
        );
      }
      if (item.additional.urlLabel) {
        return (
          <a
            id={item.id}
            tabIndex={item.tabindex}
            href={item.additional.urlLabel.url}
            className={"form__label " + (item.validate && !item.hideStar ? "required" : "")}
            target="_blank"
            rel="noopener noreferrer"
          >

            {item.additional.urlLabel.name}
            <span style={{ position: "absolute", left: "-1500%" }}>opens in new window</span>
          </a>
        );
      }
    }
  };

  renderErrMsg = item => (
    <div className="inputErrorMsg"> {console.log(item.errMsg)}
      {item.errMsg ? this.props.intl.formatMessage({ id: item.errMsg }) : null}
    </div>
  );

  renderInput = (item, index) => {
    return (
      <div key={item.id}>
        {this.renderLabel(item)}
        <div className="form__InputWrapper">
          {item.icon ? <span className="form__InputIcon">{item.icon}</span> : null}
          <input
            className={"form__textInput " + (item.icon ? "form__withIcons" : "")}
            tabIndex={0}
            type={item.type}
            id={item.id}
            value={item.value}
            autoFocus={item.autofocus}
            repeat={item.repeat}
            placeholder={
              item.additional && item.additional.placeholder
                ? this.props.intl.formatMessage({ id: item.additional.placeholder })
                : ""
            }
            aria-label={
              item.additional && item.additional.placeholder
                ? this.props.intl.formatMessage({ id: item.additional.placeholder })
                : ""
            }
            name={item.name}
            autoComplete={
              item.additional && item.additional.autocomplete ? item.additional.autocomplete : "off"
            }
            onChange={e => this.handleInputValue(e, item, index)}
            onBlur={() => this.validate(item, index)}
          />
          {this.renderErrMsg(item)}
        </div>
      </div>
    );
  };

  renderTextarea = (item, index) => (
    <div className="inputBox" key={item.id}>
      {this.renderLabel(item)}
      <textarea
        id={item.id}
        rows="6"
        className={`form__textInput form__textarea`}
        data-el="textarea"
        placeholder={
          item.additional && item.additional.placeholder
            ? this.props.intl.formatMessage({ id: item.additional.placeholder })
            : ""
        }
        name={item.name}
        autoComplete={item.additional ? item.additional.autocomplete : ""}
        onChange={e => this.handleInputValue(e, item, index)}
        onBlur={() => this.validate(item, index)}
      ></textarea>
      {this.renderErrMsg(item)}
    </div>
  );

  renderCheckbox = (item, index) => {
    return (
      <div className="form__checkboxWrap" key={item.id}>
        <div className="form__checkboxAndLabel">
          <CheckBox
            labeldBy={item.id}
            tabindex={item.tabindex}
            name={item.name}
            checked={item.checked}
            addClass="form__checkbox"
            toggleIcons={() => this.handleCheckbox(item, index)}
          />
          {this.renderLabel(item, () => this.handleCheckbox(item, index))}
        </div>
        {this.renderErrMsg(item)}
      </div>
    );
  };

  renderSelect = (item, index) => {
    return (
      <div key={item.id}>
        {this.renderLabel(item)}
        <div className="form__InputWrapper">
          <Select
            placeholder={item.additional.placeholder}
            propertyName={item.name}
            firstOption={item.firstOption}
            options={item.options}
            getValue={val => this.handleSelect(val, item, index)}
          />
          {this.renderErrMsg(item)}
        </div>
      </div>
    );
  };

  renderSubmitBtn = () => {
    return (
      <button type="submit" className="button button__main-form">
        <div
          className={
            "circleLoader-small button__main-form-loader " + (this.state.formLoader ? "" : "u-hide")
          }
        ></div>
        {/* this.props.submitBtnText - needs to be name from messagess.js so the traslation would work */}
        <div className={this.state.formLoader ? "u-invisible" : ""}>
          {this.props.submitBtnText
            ? this.props.intl.formatMessage({ id: this.props.submitBtnText })
            : this.props.intl.formatMessage({ id: "submit" })}
        </div>
      </button>
    );
  };

  renderHeaderAlertMsg = form => {
    const headerAlert = form.headerAlert;
    return (
      <div
        data-test="headerAlerter"
        className={
          "colorAlerter " +
          (headerAlert.type === "warning" ? "colorAlerter--warning " : "") +
          (headerAlert.type === "important" ? "colorAlerter--important " : "")
        }
      >
        {this.props.intl.formatMessage({ id: headerAlert.msg })}
      </div>
    );
  };

  renderFormErrMsg = () => {
    if (this.props.form.errMsg && this.props.form.errMsg.length > 0) {
      return (
        <span
          className={"form__inputErrorMsg " + (this.state.serverErrMsgVisibility ? "" : "u-hide")}
        >
          {this.props.intl.formatMessage({ id: this.props.form.errMsg })}.
        </span>
      );
    }
  };

  renderSuccessMsg = () => {
    if (this.props.form.successMsg) {
      return (
        <span
          className={
            "form__inputSuccessMsg " + (this.state.serverSuccessMsgVisibility ? "" : "u-hide")
          }
        >
          {this.props.intl.formatMessage({ id: this.props.form.successMsg })}.
        </span>
      );
    }
  };

  render() {
    const form = this.state.form;
    if (form) {
      if (form.components.length === 0) {
        return (
          <div style={{ color: "red" }} data-test="componentsEmptyErrMsg">
            [AlphaForm]: Plase add components to your form!
          </div>
        );
      }
      return (
        <div>
          {this.state.form.headerAlert ? this.renderHeaderAlertMsg(form) : null}
          <form onSubmit={this.submit} id={this.props.formId ? this.props.formId : ""} noValidate>
            {form.components.map((item, index) => {
              if (item.type === "text" || item.type === "email" || item.type === "password") {
                return this.renderInput(item, index);
              } else if (item.type === "textarea") {
                return this.renderTextarea(item, index);
              } else if (item.type === "checkbox") {
                return this.renderCheckbox(item, index);
              } else if (item.type === "select") {
                return this.renderSelect(item, index);
              } else {
                return "";
              }
            })}
            {this.renderFormErrMsg()}
            {this.renderSuccessMsg()}
            {this.props.formId ? null : this.renderSubmitBtn()}
          </form>
        </div>
      );
    } else {
      return (
        <div style={{ color: "red" }} data-test="formPropMissingErrMsg">
          [AlphaForm]: Please add form object with 'form' prop.
          <br />
          <code>Form object: {"form = { components: []}"}</code>
          <br />
          <code>Form prop: {"<AlphaForm form={form}/>"}</code>
        </div>
      );
    }
  }
}

AlphaForm.propTypes = {
  // takes form object with form props
  form: PropTypes.object,
  // http request will use info data to make a url
  url: PropTypes.string,
  // http request will not use info data to make url, it will user just url
  submitUrl: PropTypes.string,
  // get to form data with function argument
  getData: PropTypes.func,
  // set form id so form can be submited from outside
  // to submit form form outside add form attibute on the button with formId value
  // also when you add formId props Form defauld button will not be renderd
  formId: PropTypes.string,
  // change form submit button text
  submitBtnText: PropTypes.string,
};

const mapStateToProps = state => ({
  info: state.app.info,
});

export default injectIntl(connect(mapStateToProps)(AlphaForm));

// form: {
// formId="login" // set the form id to submit the form from outside, btn needs to have form="login"
// errMsg: // adds server error message
// headerAlert: { // add alerter
//   msg: 'completeSubmission', // alert message
//   type: 'important'          // alert color
// },
//   components: [
//     {
//       id: 'ife03kf',
//       ...
//     }
//   ]
// }

// COMPONENT
// {
//   id*:string:number
//   type*:string - text, email, password, textarea, checkbox, select
//   name*:string - needs to be unique
//   repeat:"firstPass || secondPass" to compare pass add repeat="firstPass" on first pass and repeat="secondPass" on secound
//   value*:string - required on text, select and textarea, holds the value
//   icon: 'email' - display incon in input
//   checked: bool - is required if input type is checkbox
//   hideStar: bool - hides * on the end of the label
//   firstOption:string - goes with select - defines first option in select
//   options:array - goes with select - ['A', 'B', 'C', 'D', 'E', 'F']
//   autofocus: bool - auto focu input on load
//   additional: {  - additional option to add to your inputs
//     label:string - renders the label with the value as text
//     placeholder:string - add placeholder
//     autocomplete:string - is on 'off' by default, add on to see it
//     urlLabel: {  - adds a label as <a href={urlLabel.url}>{urlLabel.name}</a>
//       url: '#empty',
//       name: 'terms'
//     }
//   }
//   validate: {
//     valid:bool - stores validation state
//     minLength:number - sets min length of input value
//     maxLength:number - sets max length of input vlaue
//     errMsg:string - displays the error message
//     regex - validates this regex
//     errMsgs: {
//       fieldRequiredOrEmpty:string -
//       invalidValue:string - its required if regex is added to object
//       repeatPassword:string - its required if name: 'repeatPassword'
//       minLength:string - its required if input has minLength prop
//       maxLength:string - its required if input has maxLength prop
//     }
//   }
// }
