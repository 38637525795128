import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { axiosWithAuth } from "../axiosSetup";
import axios from "axios";

// REDUX
import { connect } from "react-redux";
import { getEntrySteps, setEntryStepActive } from "../actions/entryActions";
import { hideMenu } from "../actions/appActions";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import Modal from "../components/modals/Modal";
import AlphaTable from "../components/tables/AlphaTable";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// VENDORS
import fadeInOut from "../vendors/fadeInOut";
import { isHidden } from "../vendors/checkForVisiblity";

// this was used for uppy list
// var items = [
//   {id: 1, name: '125-downalod-f032f0f30th0323323f2f23f2f3f2f0f30th0323323f2f23f323f2f23f', state: 'transferred', position: 1},
//   {id: 2, name: 'Crimson', state: 'new', position: 2},
//   {id: 3, name: 'Hotpink', state: 'transferred', position: 3},
//   {id: 4, name: 'Blueviolet', state: 'new', position: 4},
//   {id: 5, name: 'Cornflowerblue', state: 'transferred', position: 5},
//   {id: 6, name: 'Skyblue', state: 'new', position: 6},
//   {id: 7, name: 'GoLightblueld', state: 'new', position: 7}
// ]

export class Pay extends Component {
  signal = axiosWithAuth.CancelToken.source();

  state = {
    rerenderTable: 1,
  };

  UNSAFE_componentWillMount() {
    axios.get("https://alphaawards-aca8b.firebaseio.com/app/pay_entries.json").then(res => {
      if (res.status === 200) {
        this.setState({ tableData: res.data });
      }
    });
  }

  componentWillUnmount() {
    this.signal.cancel();
  }

  getActiveStep = activeStep => {
    this.props.setEntryStepActive(activeStep);
  };

  showRemoveEntryModal = () => {
    const el = document.getElementById("dw302lf04kf23t405240f05");

    // hide mob menu if visible
    if (isHidden(document.getElementById("mobileNav"))) {
      this.props.hideMenu();
    }

    if (el) {
      fadeInOut(el, "500", true);
    } else {
      console.error("Modal id does not exist");
    }
  };

  confirmDelete = () => {
    // show modal loader
    this.setState({ deleteModalLoader: true });

    // set end point url
    const id = encodeURI(`?ids=["${this.props.match.params.id}"]`);
    const url =
      this.props.info.apps[this.props.info.default_app].modules.submitter_dashboard.submissions
        .index + id;

    axiosWithAuth
      .delete(url, { cancelToken: this.signal.token })
      .then(res => {
        const status = res.data.status;

        if (status === "success") {
          // hide loader
          this.setState({ deleteModalLoader: false, deletionConfirmed: true });
        }
      })
      .catch(err => axiosWithAuth.isCancel(err));
  };

  loaderDone = () => {
    // hide modal
    const el = document.getElementById("dw302lf04kf23t405240f05");
    if (el) {
      fadeInOut(el, "500", false);
    }
    this.setState({ deleteModalLoader: undefined });
    this.props.history.push("/ui/");
  };

  stepClicked = (listID, entryID) => {
    // hide the menu on mobile sizes if visible
    if (document.getElementById("mobileNav")) this.props.hideMenu();
  };

  handleFilterClick = url => {
    this.props.history.push(url);
  };

  render() {
    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain">
              <div className="">
                <AnimateHOC animate={this.state.tableData}>
                  {this.state.tableData && this.state.rerenderTable ? (
                    <AlphaTable data={this.state.tableData} />
                  ) : null}
                </AnimateHOC>
              </div>
            </div>
          </div>
          <FooterMain />
        </div>
        <Modal
          id="dw302lf04kf23t405240f05"
          loaderDone={this.loaderDone}
          loader={this.state.deleteModalLoader}
          promptModal
          intl
          yesMsg="yes"
          noMsg="no"
          yes={this.confirmDelete}
          msg="areYouSureDeleteEntries"
        />
      </AppHOC>
    );
  }
}

Pay.propTypes = {};

const mapStateToProps = store => ({
  entryStepData: store.entry.steps,
  info: store.app.info,
});

export default injectIntl(
  connect(mapStateToProps, { getEntrySteps, setEntryStepActive, hideMenu })(withRouter(Pay))
);
