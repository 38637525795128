import React, { Component } from "react";
import PropTypes from "prop-types";

// COMPONENTS
import Uppyy from "../forms/Uppyy";

class AlphaUppy extends Component {
  _id = this.props.id || Math.random();

  state = { formData: this.props.formData };

  onListChanged = uppyInfo => {
    let { formData } = this.state;
    formData[uppyInfo.id] = uppyInfo.list;
    this.props.uppyChanged({ content: formData, changed: uppyInfo.changed });
  };

  render() {
    const { uppyConfig, components } = this.props.schema;

    return (
      <div>
        {(components || [])
          .filter(c => c.type === "uppy")
          .map(uppySchema => {
            console.log('uppySchema', uppySchema)

            return (
              <Uppyy
                id={`${this._id}_${uppySchema.key}`}
                key={uppySchema.key} 
                uploads={this.state.formData[uppySchema.key]}
                rawData={this.props.formData}
                schema={addErrors(uppySchema)}
                config={uppyConfig}
                onListChanged={this.onListChanged}
              />
            )
          })}
      </div>
    );
  }
}

const addErrors = (errors, schema) => {
  // add error messages to the uppy components
  if (errors && Array.isArray(errors.upload)) {
    errors.upload.forEach(err => {
      // FIXME: there can be multiple errors associated with a component.
      if (err.form_key === schema.key) schema.error = err;
    });
  }
};

AlphaUppy.propTypes = {
  schema: PropTypes.object.isRequired,
  getListState: PropTypes.object,
  url: PropTypes.string.isRequired,
};

export default AlphaUppy;
