import React from 'react';

const Title = ({children, addClass, style, tagName, small}) => {
  const defaultSize = '__t_d';

  const CustomTag = tagName || 'h2'
  return (
    <CustomTag style={style} className={`__t ${addClass ? addClass : ''} ${small ? '__t_s' : defaultSize}`}>
      {children}
    </CustomTag>
  );
};

export default Title;