import React, { useEffect, useRef, useState } from "react";

// COMPONENTS
import Layout from "../layout/Layout";
// HOKS
import useResizeScreen from "../../hooks/useResizeScreen";

const NavSeperator = ({
  header,
  footer,
  children,
  style,
  styleOnOverflow,
  addClassOnOverfow,
  onScroll,
}) => {
  const screenSize = useResizeScreen(); // detect screen resize to triger useEffect
  const [isBodyOverflown, setIsBodyOverflown] = useState({ top: false, bottom: false });
  const [isElOverflown, setIsElOverflown] = useState(false);
  const wrapperDiv = useRef();
  const wasScrolled = useRef(false);

  // on mount
  useEffect(() => {
    addShadowsOnScroll(wrapperDiv);
  }, []);

  // on browser resize
  useEffect(() => {
    setTimeout(() => {
      toggleScroller();
    }, 100);
  });

  const handleScroll = e => {
    wasScrolled.current = true;
    var element = e.target;
    addShadowsOnScroll(element);
    if (onScroll) onScroll(e);
  };

  const addShadowsOnScroll = element => {
    // if scrollbar is in the bottom
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      overflowCallback(true, false);
    }
    // if scrollbar in on the top
    else if (element.scrollTop === 0) {
      overflowCallback(false, true);
    }
    // if scrollbar is in the middle
    else if (element.scrollTop > 0) {
      overflowCallback(true, true);
    }
  };

  const overflowCallback = (top, bottom) => {
    setIsBodyOverflown({ top, bottom });
  };

  const toggleScroller = () => {
    if (wrapperDiv.current) {
      if (isInViewport(wrapperDiv.current)) {
        if (!isOverflown(wrapperDiv.current)) {
          setIsElOverflown(false);
        }
      } else {
        setIsElOverflown(true);

        // on load
        if (!wasScrolled.current) {
          overflowCallback(false, true);
        }
      }
    }
  };

  const isOverflown = element => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  };

  const isInViewport = elem => {
    let bounding = elem.getBoundingClientRect();
    if (!bounding) return false;

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // on unmount
  useEffect(() => {
    return () => {
      wasScrolled.current = false;
    };
  });

  return (
    <Layout flex flexColumn fullHeight lastItemBottom>
      {(header && (
        <div className={`__n_h ${isBodyOverflown.top ? "__n_hs" : ""}`}>{header}</div>
      )) || <div className={`__n_h ${isBodyOverflown.top ? "__n_hs" : ""}`}></div>}
      <div
        ref={wrapperDiv}
        onScroll={handleScroll}
        className={`__q __n_b  __q-js __sc ${isElOverflown ? addClassOnOverfow : ""}`}
        style={isElOverflown ? { overflowY: "scroll", ...style, ...styleOnOverflow } : { style }}
      >
        {children}
      </div>
      {(footer && (
        <div className={`__n_f ${isBodyOverflown.bottom ? "__n_fs" : ""}`}>{footer}</div>
      )) || <div className={`__n_f ${isBodyOverflown.bottom ? "__n_fs" : ""}`}></div>}
    </Layout>
  );
};

export default NavSeperator;
