import React, {Component} from 'react';
import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import { resetStoreProps } from '../../actions/appActions';

// VENDORS
import fadeInOut from '../../vendors/fadeInOut';

const fadeIn = { opacity: '1' };
const fadeOut = { opacity: '0' };

export class ModalBack extends Component {

  componentDidUpdate() {
    const el = document.getElementById('modalBack635');
    fadeInOut(el, '500', this.props.modalBackVisibility);
  }

  render() {
    return (
      <div
        id="modalBack635"
        style={ this.props.modalBackVisibility ? fadeIn : fadeOut }
        className={'modal__Modalback modal--defaultCol '}
        onClick={this.props.resetStoreProps}
        data-test="modalBack"
        ></div>
    );
  }
}

ModalBack.propTypes = {
  resetStoreProps: PropTypes.func
}

const mapStateToProps = (state) => ({
  modalBackVisibility: state.app.modalBackVisibility
})

export default connect(mapStateToProps, { resetStoreProps })(ModalBack);