import React from "react";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";

const Message = ({ msg, type, color, shadow, close, icon, fontWeight }) => {
  const colorType = {
    default: "bc12",
    success: "c7",
    warning: "bc2 c2",
    error: "c4",
  }[color];

  const alerterType = {
    default: "bc12",
    success: "bc12 c1",
    warning: "bc12 c2",
    error: "bc5 c4",
    info: "c4",
  }[type || "default"];

  return (
    <Layout flex addClass={`__m ${alerterType} ${shadow && "sh1"}`}>
      <Layout flex alignItemsCenter>
        <Icon iconId="infoFull" />
      </Layout>
      <Layout
        flex
        flex1
        alignItemsCenter
        addClass={`__m_t`}
        styles={{ fontWeight: fontWeight || "bold" }}
      >
        {msg}
      </Layout>
      <Layout flex alignItemsCenter>
        {close && <Icon iconId="x" />}
      </Layout>
    </Layout>
  );
};

export default Message;
