import React from "react";
import Cookies from "universal-cookie";

const renderLangString = ({ id, variables, html }) => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  let string = languageStrings[lang][id];

  // if translation id exists
  if (string) {
    // if string contains variables
    if (variables) {
      // add variables
      Object.keys(variables).forEach(key => {
        string = string.replace(`{${key}}`, variables[key]);
      });

      // add pluralization 1 = singular, 2 plural, 0 hide value
      if ("pluralizationIndex" in variables) {
        const insideOfTheArray = string.slice(string.indexOf("[") + 1, string.indexOf("]"));
        const arr = insideOfTheArray.split(", ");
        const word = arr[variables.pluralizationIndex - 1];

        if (variables.pluralizationIndex === 0) {
          string = string.replace(`[${insideOfTheArray}]`, "");
        } else {
          string = string.replace(`[${insideOfTheArray}]`, word);
        }
      }

      // if some of the variables are not defined render empty string
      string = string.replace(/\{(.+?)\}/g, "");
    }

    // render html
    if (html) return <span dangerouslySetInnerHTML={{ __html: string }}></span>;
    return string;
  } else {
    console.error(`String id [${id}] does not exist!`);
    return languageStrings[lang]["default"];
  }
};

// call it like this
// renderLangString({
//   id: "$_l",  // sting id
//   variables: {
//     pluralizationIndex: 1,  // can be 1 || 2 || 0
//     num: 3,
//     toWhat: "Something",
//     date: "(19.10.2020 - 01:59)",
//   },
//   html: true,  // render as html
// }

// prettier-ignore
const languageStrings = {
  "en-US": {
    "default": " - standardtext - ",
    "$_0": "Application Logo",
    "$_1": "Icon",
    "$_2": "Dashboard",
    "$_3": "Log out",
    "$_4": "Stage",
    "$_5": "Collection",
    "$_6": "Active Stages",
    "$_7": "No active stages!",
    "$_8": "Incoming Stages",
    "$_9": "No incoming stages!",
    "$_a": "Expired Stages",
    "$_b": "No expired stages!",
    "$_c": "Starts",
    "$_d": "Ends",
    "$_e": "{num} [day, days] left",
    "$_f": "{num} [collection, collections]",
    "$_g": "in progress",
    "$_h": "done",
    "$_i": "Not yet started",
    "$_j": "Expired",
    "$_k": "Evaluation rounds",
    "$_l": "Just <strong>{num} [Day, Days]</strong> until {toWhat} {date}",
    "$_m": "Evaluation collection",
    "$_o": "If you should not vote, please confirm by clicking on the button below.",
    "$_p": "If you should vote, please confirm by clicking on the button below.",
    "$_q": "Exclude me",
    "$_r": "Include me",
    "$_s": "Take it back",
    "$_t": "Almost Done!",
    "$_u": "All cases in this collection are confirmed! Plese review your votes and confirm the collection.",
    "$_v": "Review Votes",
    "$_w": "My top cases",
    "$_x": "back",
    "$_y": "next",
    "$_z": "show all cases",
    "$_z1": "hide all cases",
    "$_a1": "All cases in this collection",
    "$_a2": "Congratulation!",
    "$_a3": "Collection confirmed!",
    "$_a4": "Please review your votes and confirm the results.",
    "$_a5": "Please click on the button below to unconfirm.",
    "$_a6": "Confirm",
    "$_a7": "Page menu",
    "$_a8": "Footer menu",
    "$_a9": "close",
    "$_b1": "Oops! Something went wrong.",
    "$_b2": "Our technicians are aware of the problem and are working to resolve it. We ask for your understanding!",
    "$_b3": "Evaluation Categories",
    "$_b4": "Reviewers in this collection",
    "$_b5": "Logout",
    "$_b6": "Are you sure you want to log out?",
    "$_b7": "Cancle",
    "$_b8": "Evaluation Rounds",
    "$_b9": "Stage voting has expired",
    "$_c1": "Header navigation",
    "$_c2": "Reviewers in this stage",
    "$_c3": "All cases in this collection",
    "$_c4": "Total",
    "$_c5": "[Case, Cases]",
    "$_c6": "Categories done",
    "$_c7": "Judges done",
    "$_c8": "Connection lost",
    "$_c9": "Please check your connection and reload the page.",
    "$_d1": "Reload the page",
    "$_d2": "Sparen...",
    "$_d3": "Saved",
    "$_d4": "Download",
  },
  "de-DE": {
    "default": " - default text - ",
    "$_0": "Anwendungslogo",
    "$_1": "das Ikon",
    "$_2": "Instrumententafel",
    "$_3": "Ausloggen",
    "$_4": "Stufe",
    "$_5": "Sammlung",
    "$_6": "Aktive Etappen",
    "$_7": "Keine aktiven Etappen!",
    "$_8": "Eingehende Etappen",
    "$_9": "Keine eingehenden Etappen!",
    "$_a": "Abgelaufene Etappen",
    "$_b": "Keine abgelaufenen Etappen!",
    "$_c": "Beginnt",
    "$_d": "Endet",
    "$_e": "{num} verbleibende [Tag, Tage]",
    "$_f": "{num} [Sammlung, Sammlungen]",
    "$_g": "in Bearbeitung",
    "$_h": "erledigt",
    "$_i": "Noch nicht begonnen",
    "$_j": "Abgelaufen",
    "$_k": "Bewertungsrunden",
    "$_l": "Nur noch <strong>{num} [Tag, Tags]</strong> bis {toWhat} {date}",
    "$_m": "Sammlung von Auswertungen",
    "$_o": "Falls Sie befangen sind, und sich von der Bewertung dieses Projekts ausschließen möchten, klicken Sie hier.",
    "$_p": "Falls Sie abstimmen sollten, bestätigen Sie bitte, indem Sie auf die Schaltfläche unten klicken.",
    "$_q": "Mich ausschließen",
    "$_r": "Mich einbeziehen",
    "$_s": "Zurücknehmen",
    "$_t": "Fast fertig!",
    "$_u": "Alle Fälle in dieser Sammlung sind bestätigt! Bitte überprüfen Sie Ihre Stimmen und bestätigen Sie die Sammlung.",
    "$_v": "Rückblick Abstimmungen",
    "$_w": "Meine Top-Fälle",
    "$_x": "zurück",
    "$_y": "nächste",
    "$_z": "alle Fälle anzeigen",
    "$_z1": "alle Fälle ausblenden",
    "$_a1": "Alle Fälle in dieser Sammlung",
    "$_a2": "Herzlichen Glückwunsch!",
    "$_a3": "Abholung bestätigt!",
    "$_a4": "Bitte überprüfen Sie Ihre Stimmen und bestätigen Sie die Ergebnisse.",
    "$_a5": "Bitte klicken Sie zum Bestätigen auf die Schaltfläche unten.",
    "$_a6": "Bestätigen Sie",
    "$_a7": "Menü Seite",
    "$_a8": "Fußzeilen-Menü",
    "$_a9": "close",
    "$_b1": "Hoppla! Etwas ist schief gelaufen.",
    "$_b2": "Unsere Techniker wissen Bescheid und arbeiten an der Behebung des Problems. Wir bitten um Verständnis!",
    "$_b3": "Bewertungs-Kategorien",
    "$_b4": "Rezensenten in dieser Sammlung",
    "$_b5": "Abmeldung",
    "$_b6": "Sind Sie sicher, dass Sie sich abmelden möchten?",
    "$_b7": "Abbrechen",
    "$_b8": "Bewertungsrunden",
    "$_b9": "Etappenwahl ist abgelaufen",
    "$_c1": "Kopfzeilen-Navigation",
    "$_c2": "Gutachter in dieser Phase",
    "$_c3": "Alle Fälle in dieser Sammlung",
    "$_c4": "Gesamt",
    "$_c5": "[Gehäuse, Fälle]",
    "$_c6": "Kategorien erledigt",
    "$_c7": "Richter fertig",
    "$_c8": "Verbindung verloren",
    "$_c9": "Bitte überprüfen Sie Ihre Verbindung und laden Sie die Seite neu.",
    "$_d1": "Laden Sie die Seite neu",
    "$_d2": "Sparen...",
    "$_d3": "Gespeicherte",
    "$_d4": "Herunterladen",
  },
  "pt-PT": {
    "default": " - standardtext - ",
    "$_0": "Application Logo",
    "$_1": "Icon",
    "$_2": "Dashboard",
    "$_3": "Log out",
    "$_4": "Stage",
    "$_5": "Collection",
    "$_6": "Active Stages",
    "$_7": "No active stages!",
    "$_8": "Incoming Stages",
    "$_9": "No incoming stages!",
    "$_a": "Expired Stages",
    "$_b": "No expired stages!",
    "$_c": "Starts",
    "$_d": "Ends",
    "$_e": "{num} [day, days] left",
    "$_f": "{num} [collection, collections]",
    "$_g": "in progress",
    "$_h": "done",
    "$_i": "Not yet started",
    "$_j": "Expired",
    "$_k": "Evaluation rounds",
    "$_l": "Just <strong>{num} [Day, Days]</strong> until {toWhat} {date}",
    "$_m": "Evaluation collection",
    "$_o": "If you should not vote, please confirm by clicking on the button below.",
    "$_p": "If you should vote, please confirm by clicking on the button below.",
    "$_q": "Exclude me",
    "$_r": "Include me",
    "$_s": "Take it back",
    "$_t": "Almost Done!",
    "$_u": "All cases in this collection are confirmed! Plese review your votes and confirm the collection.",
    "$_v": "Review Votes",
    "$_w": "My top cases",
    "$_x": "back",
    "$_y": "next",
    "$_z": "show all cases",
    "$_z1": "hide all cases",
    "$_a1": "All cases in this collection",
    "$_a2": "Congratulation!",
    "$_a3": "Collection confirmed!",
    "$_a4": "Please review your votes and confirm the results.",
    "$_a5": "Please click on the button below to unconfirm.",
    "$_a6": "Confirm",
    "$_a7": "Page menu",
    "$_a8": "Footer menu",
    "$_a9": "close",
    "$_b1": "Oops! Something went wrong.",
    "$_b2": "Error msg dolor sit amet consectetur adipisicing elit. Voluptates eligendi...",
    "$_b3": "Evaluation Categories",
    "$_b4": "Reviewers in this collection",
    "$_b5": "Logout",
    "$_b6": "Are you sure you want to log out?",
    "$_b7": "Cancle",
    "$_b8": "Evaluation Rounds",
    "$_b9": "Stage voting has expired",
    "$_c1": "Header navigation",
    "$_c2": "Reviewers in this stage",
    "$_c3": "All cases in this collection",
    "$_c4": "Total",
    "$_c5": "[Case, Cases]",
    "$_c6": "Categories done",
    "$_c7": "Judges done",
    "$_c8": "Connection lost",
    "$_c9": "Please check your connection and reload the page.",
    "$_d1": "Reload the page",
    "$_d2": "Sparen...",
    "$_d3": "Saved",
    "$_d4": "Download",
  },
  "fr-FR": {
    "default": " - standardtext - ",
    "$_0": "Application Logo",
    "$_1": "Icon",
    "$_2": "Dashboard",
    "$_3": "Log out",
    "$_4": "Stage",
    "$_5": "Collection",
    "$_6": "Active Stages",
    "$_7": "No active stages!",
    "$_8": "Incoming Stages",
    "$_9": "No incoming stages!",
    "$_a": "Expired Stages",
    "$_b": "No expired stages!",
    "$_c": "Starts",
    "$_d": "Ends",
    "$_e": "{num} [day, days] left",
    "$_f": "{num} [collection, collections]",
    "$_g": "in progress",
    "$_h": "done",
    "$_i": "Not yet started",
    "$_j": "Expired",
    "$_k": "Evaluation rounds",
    "$_l": "Just <strong>{num} [Day, Days]</strong> until {toWhat} {date}",
    "$_m": "Evaluation collection",
    "$_o": "If you should not vote, please confirm by clicking on the button below.",
    "$_p": "If you should vote, please confirm by clicking on the button below.",
    "$_q": "Exclude me",
    "$_r": "Include me",
    "$_s": "Take it back",
    "$_t": "Almost Done!",
    "$_u": "All cases in this collection are confirmed! Plese review your votes and confirm the collection.",
    "$_v": "Review Votes",
    "$_w": "My top cases",
    "$_x": "back",
    "$_y": "next",
    "$_z": "show all cases",
    "$_z1": "hide all cases",
    "$_a1": "All cases in this collection",
    "$_a2": "Congratulation!",
    "$_a3": "Collection confirmed!",
    "$_a4": "Please review your votes and confirm the results.",
    "$_a5": "Please click on the button below to unconfirm.",
    "$_a6": "Confirm",
    "$_a7": "Page menu",
    "$_a8": "Footer menu",
    "$_a9": "close",
    "$_b1": "Oops! Something went wrong.",
    "$_b2": "Error msg dolor sit amet consectetur adipisicing elit. Voluptates eligendi...",
    "$_b3": "Evaluation Categories",
    "$_b4": "Reviewers in this collection",
    "$_b5": "Logout",
    "$_b6": "Are you sure you want to log out?",
    "$_b7": "Cancle",
    "$_b8": "Evaluation Rounds",
    "$_b9": "Stage voting has expired",
    "$_c1": "Header navigation",
    "$_c2": "Reviewers in this stage",
    "$_c3": "All cases in this collection",
    "$_c4": "Total",
    "$_c5": "[Case, Cases]",
    "$_c6": "Categories done",
    "$_c7": "Judges done",
    "$_c8": "Connection lost",
    "$_c9": "Please check your connection and reload the page.",
    "$_d1": "Reload the page",
    "$_d2": "Sparen...",
    "$_d3": "Saved",
    "$_d4": "Download",
  }

};

export { renderLangString };
export default renderLangString;
