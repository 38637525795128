import React, { Component } from "react";
import PropTypes from "prop-types";
import { MdAddCircle, MdInfo, MdWarning /*, MdCheckCircle*/ } from "react-icons/md";
// import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import { classes, setHTMLWithScripts } from "../../functions/utils";
import Markdown from "react-markdown";
import IconAria from "../icons/IconAria";

class Alerter extends Component {
  state = {};

  static alertLevelMap = {
    error: {
      classes: "alerter--err",
      icon: <IconAria iconId="error" className="u-rotate135 err--icon" />, //<MdAddCircle className="u-rotate135 err--icon" />,
    },
    warning: {
      classes: "alerter--war",
      icon: <IconAria iconId="warning" className="war--icon" />, // <MdWarning className="war--icon" />,
    },
    info: {
      classes: "alerter--info",
      icon: <IconAria iconId="info" className="info--icon" />, //<MdInfo className="info--icon" />,
    },
    // success: {
    //   classes: "alerter--suc",
    //   icon: <IconAria iconId="checkCircle" addClass="suc--icon" /> //<MdCheckCircle className="suc--icon"/>,
    // }
  };

  constructor(props) {
    super(props);
    this.state.alertLists = this.toList(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) this.setState({ alertLists: this.toList(this.props) });
  }

  toList = props =>
    (props.list || [])
      .filter(({ items }) => items && items.length !== 0)
      .map(alert => ({ ...Alerter.alertLevelMap[alert.level], ...alert }));

  static profileRoute = profileID => `/ui/submitter/profile/edit/${profileID}`;

  static renderItemFuncs = {
    requirement: item => (item.req_id ? <Link to={item.req_id}>{item.msg}</Link> : item.msg),
    profile: item =>
      item.profile_id ? (
        <Link to={Alerter.profileRoute(item.profile_id)}>{item.msg}</Link>
      ) : (
        item.msg
      ),
    form: item => item.msg && <b>{item.msg}</b>,
    html: item => <div ref={el => setHTMLWithScripts(el, item.msg)}></div>,
    md: item => item.msg && <Markdown source={item.msg} />,
  };

  renderAlertGroupItems = (group, key) => {
    const renderItem = Alerter.renderItemFuncs[group.kind] || (item => item.msg);
    return group.list.map((item, i) => item && <li key={key + i}>{renderItem(item)}</li>);
  };

  renderAlertGroups = (alertGroups, randomKey) =>
    alertGroups.map(group => {
      // Pick the group itself as a msg item structure?
      group.list = !group.list && group.msg ? [group] : group.list;

      return (
        (group.list || []).length !== 0 && (
          <div key={randomKey}>
            {group.header && <p>{group.header}</p>}
            <ul className="alerter__list">{this.renderAlertGroupItems(group, randomKey)}</ul>
          </div>
        )
      );
    });

  render() {
    const randomKey = Math.random();

    return (this.state.alertLists || []).map(
      (alertList, idx) =>
        (alertList.items || []).length !== 0 && (
          <div
            key={alertList.id || randomKey + idx}
            className={classes("alerter", alertList.classes)}
          >
            <div className="alerter__display">
              <div className="alerter__title">
                {alertList.icon} <span className="alerter-title">{alertList.title}</span>
              </div>
              {this.renderAlertGroups(alertList.items, randomKey)}
            </div>
          </div>
        )
    );
  }
}

Alerter.propTypes = {
  addClass: PropTypes.string,
  list: PropTypes.array,
};

export default Alerter;
