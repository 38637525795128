import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

// HOC
import AppHOC from '../hoc/AppHOC';

// REDUX
import { connect } from 'react-redux';
import { clearEntrySteps, clearEntryStepActive } from '../actions/entryActions';

// COMPONENTS
import MainHeaderNav from '../components/navigations/MainHeaderNav';
import MainBoxNav from '../components/navigations/MainBoxNav';
import FooterMain from '../components/footers/FooterMain';
import ContentContainer from '../components/layout/ContentContainer';

// HOC
import AnimateHOC from '../hoc/AnimateHOC';


export class App403 extends Component {



  render() {
    return (
      <AppHOC>
      <div id="page">
        <MainHeaderNav/>
        <MainBoxNav/>
        <div className="rowCon rowCon__withMargin">
          <div className="dashboardMain">

          <AnimateHOC animate={true}>
            <ContentContainer title='403'>
            Unberechtigter Zugriff.
            </ContentContainer>
          </AnimateHOC>

          </div>
        </div>
        <FooterMain/>
      </div>
      </AppHOC>
    );
  }
};

const mapStateToProps = (state) => ({
  info: state.app.info
})

export default injectIntl( connect(mapStateToProps, {clearEntrySteps, clearEntryStepActive})(App403) );
