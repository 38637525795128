import React, { useState } from "react";
import { render, unmountComponentAtNode, createPortal } from "react-dom";
import { classes } from "../../functions/utils";

import Uppyy from "./Uppyy";

import { Formio } from "formiojs";

import { ReactComponent } from "@formio/react";
import store from "../../store";
import AA_GLOBAL from "../../globals/globals";
import { IntlProvider } from "react-intl";

// const Base = Formio.Components.components.base;

class UppyComponent extends ReactComponent {
  // The react "portal" element to be added to <BaseFormio/>.
  reactPortalElement = null;
  // Will receive callback functions to update the internal react state.
  uppyShimCallbacks = {};

  static schema() {
    return ReactComponent.schema({
      // TODO: add default fields
      type: "uppy",
    });
  }

  static builderInfo = {
    title: "Big Files (Uppy Uploader)",
    group: "advanced",
    icon: "uppy",
    weight: 70,
    documentation: "http://help.form.io/userguide/#table",
    schema: UppyComponent.schema(),
  };

  /**
   * @param children
   * @returns {string}
   */
  // render(children, topLevel) {
  //   // Need to pass empty string or else "Unknown component: uppy" is shown.
  //   return super.render("", topLevel);
  // }

  onReactUppyValueChanged(uploadList) {
    this.dataValue = uploadList;
    this.triggerChange();
  }

  /**
   * @param element
   * @returns {Promise}
   */
  attachReact(element) {
    // NB: this.root is the Formio instance and _form is the schema.
    const { uppyConfig } = this.root._form;
    const schema = this.component;

    schema.fpath = this.path;

    const state = store.getState();

    const lang = state.app.lang || AA_GLOBAL.cookies.get("lang");

    // Must be created as a portal to be inserted into the App hierarchy.
    // Cannot just use React.render(), because functions like withRouter() or injectIntl()
    // will not work, since the element would be rendered independently of the root App.
    const portal = createPortal(
      <IntlProvider locale={lang} messages={state.persist.messages}>
        <UppyReactShim
          id={this.id}
          callbacks={this.uppyShimCallbacks}
          uppyConfig={uppyConfig}
          schema={schema}
          addClass={classes(
            "form-group has-feedback formio-component formio-component-uppy",
            schema.key && `formio-component-${schema.key}`,
            schema.validate?.required && "required"
          )}
          uploads={[]} // Uploads list will be set via setValue() after mounting.
          onListChanged={uploadList => this.onReactUppyValueChanged(uploadList)}
        />
      </IntlProvider>,
      element
    );

    render(portal, element);

    // this.root.element.addReactChild(this.reactPortalElement);

    // Allow basic component functionality to attach like field logic and tooltips.
    // return super.attach(element).then(res => {
    //   // Move the error "messageContainer" tag below the Uppy field.
    //   element.appendChild(element.removeChild(element.firstElementChild));
    //   return res;
    // });
  }

  detachReact(element) {
    // this.root.element.removeReactChild(this.reactPortalElement);
    // delete this.reactPortalElement;
    // return super.detach(element);
    if (element) {
      unmountComponentAtNode(element);
    }
  }

  get emptyValue() {
    return [];
  }

  get defaultValue() {
    return [];
  }

  /**
   * @returns {Array}
   */
  getValue() {
    return this.dataValue;
  }

  /**
   * @param value
   * @returns {boolean}
   */
  setValue(value, flags = {}) {
    super.setValue(value, flags);
    this.uppyShimCallbacks.setValue && this.uppyShimCallbacks.setValue(value);
  }
}

// A component that acts as a shim between the Formio UppyComponent class and the react Uppyy class.
function UppyReactShim(props) {
  const { id, schema, uppyConfig, onListChanged } = props;

  const [uploads, setUploads] = useState(props.uploads);

  props.callbacks.setValue = setUploads;

  return (
    <Uppyy
      id={`${id}${id && "-"}uppy-${schema.key}`}
      uploads={uploads}
      schema={schema}
      config={uppyConfig}
      onListChanged={info => onListChanged(info.list)}
    />
  );
}

export default UppyComponent;
