import React, { useState, useEffect } from "react";

// COMPONENTS
import Layout from "../layout/Layout";
import ToggleVisibility from "../features/ToggleVisibility";
import Title from "../text/Title";
import Icon from "../icons/Icon";

const PriviewOverview = ({ children, data }) => {
  const [contentVisible, setContentVisible] = useState(false);

  const showContent = () => {
    setContentVisible(true);
  };

  useEffect(() => {
    setContentVisible(false);
  }, [data]);

  return (
    <div className={`__2 `}>
      <div className={`${contentVisible ? "__2o" : ""}`}>
        <Layout flex addClass="bc12">
          <div
            className="__2b"
            style={{
              backgroundImage: `url("https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")`,
            }}
          ></div>
          <div className="__2a">
            <Title addClass="">
              {data.code ? `${data.code} - ` : ""} {data.title}
            </Title>
            <p>{data.categories[0]}</p>
          </div>
        </Layout>
        <Layout flex justifyContentCenter alignItemsCenter addClass="__2c" clicked={showContent}>
          <Icon iconId="arrowDown" />
        </Layout>
      </div>
      <ToggleVisibility toggle={contentVisible} animationTime={1000} slide>
        <div>{children}</div>
      </ToggleVisibility>
    </div>
  );
};

export default PriviewOverview;
