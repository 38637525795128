import React from "react";
import { withRouter } from "react-router-dom";
import userPlaceholderImg from "../../img/placeholders/user-placeholder.png";
import renderLangString from "../../languageStrings";

//  COMPONENT
import Layout from "../layout/Layout";
import ProgressBar from "../display/ProgressBar";
import Icon from "../icons/Icon";
import Popover from "../popovers/Popover";

const UserNav = ({ history, list, popoverStatsFor }) => {
  const handleReviewerClick = () => {
    return false;
    history.push("/ui/review_admin/reviewer/384f44dd-d2d0-44c6-b00f-8fd85814d7bb");
  };

  return (
    <div className="__un">
      {list.map(reviewer => (
        <div
          style={{ cursor: "default" }}
          key={reviewer.user.id}
          className="__un_o"
          onClick={handleReviewerClick}
        >
          <Layout flex lastItemRight addClass="__un_s">
            <div>
              <img className="__un_i" src={userPlaceholderImg} alt="Naomi Fuller" />
            </div>
            <div className="__un_t">
              <div className="__un_n">{reviewer.user.name}</div>
              <div className={`__un_e ${!reviewer.user.name && "__un_em"}`}>
                {reviewer.user.email}
              </div>
            </div>
            <div className="__un_s">
              <Popover
                msg={
                  popoverStatsFor === "judges"
                    ? `${reviewer.done}/${reviewer.total} ${renderLangString({ id: "$_c7" })}`
                    : ""
                }
              >
                {reviewer.done}/{reviewer.total}
              </Popover>
            </div>
          </Layout>
          <Layout flex addClass="__un_pc">
            {reviewer.confirmed && <Icon iconId="checkLine" addClass="__un_pi" />}
          </Layout>
          <ProgressBar
            addClass="__un_p"
            color="bc1"
            width={(reviewer.done / reviewer.total) * 100 + "%"}
          />
        </div>
      ))}
    </div>
  );
};

export default withRouter(UserNav);
