const caseDataModel = {
  stages: {
    type: "array",
    contains: {
      confirmed: { type: "boolean" },
      end_at: { type: "string" },
      id: { type: "string" },
      in_range: { type: "number" },
      start_at: { type: "string" },
      title: { type: "string" },
      stats: {
        type: "object",
        contains: {
          done: { type: "number" },
          todo: { type: "number" },
          total: { type: "number" },
        },
      },
    },
  },
  // confirmed: false
  // description: "<p><b>Voting zu:</b></p>↵<p>Alle Kategorien im alpha awards GRAND PRIX 2020</p>↵<br>↵<p><b>Beschreibung:</b></p>↵<ul>↵  <li>Geben Sie Ihre Bewertungen ab bevor die Frist abgelaufen ist.</li>↵  <li>Entscheiden Sie welche Einreichungen am besten sind.</li>↵  <li>Ihre Expertise ist gefragt.</li>↵  <li>Ihr Urteil ist endgültig.</li>↵</ul>"
  // end_at: "2020-10-18T23:59:59Z"
  // id: "c6e1130e-8026-35e9-96eb-ba88ef9d92f4"
  // in_range: 0
  // parent_id: null
  // start_at: "2020-07-05T00:00:00Z"
  // stats: {confirmed: 1, done: 1, todo: 9, total: 10}
  // time_left_msg: "TODO"
  // title: "Voting"
};

export default caseDataModel;
