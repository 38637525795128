
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

class ProfileNavigation extends Component {

  render() {

    return (
      <div>
        {this.props.menuData.map(item => {

          return (
            <NavLink
            className={"stepCheckBtn " + ( this.props.match.params.page === item.activeClass ? 'stepCheckBtn--active' : '')}
            to={item.url}
            key={item.id}>
            <div className="stepCheckBtn__left">
              {item.fixedMenu ?
                <div className="stepCheckBtn__text"><FormattedMessage id={item.langId}/></div>
                :
                <div className="stepCheckBtn__text">{item.title} {item.submited ? <span>✓</span> : <span>X</span>}</div>
              }

            </div>
            <div className="stepCheckBtn__right"></div>
          </NavLink>
          )
        })}
      </div>
    );
  }
}

export default withRouter(ProfileNavigation);


