import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
// import messages from "./messages";
import Routes from "./Routes";

// HOC
import AuthHOC from "./hoc/AuthHOC";

// VIEWS
import LoginPage from "./views/LoginPage";
import Dashboard from "./views/Dashboard";
import EditEntry from "./views/EditEntry";
import CopyEntry from "./views/CopyEntry";
import NewEntry from "./views/NewEntry";
import Cart from "./views/Cart";
import Pay from "./views/Pay";
import Profile from "./views/Profile";
import ProjectOverview from "./views/ProjectOverview";
import User from "./views/User";
import Payments from "./views/Payments";
import ResetPassword from "./views/ResetPassword";
import Info from "./views/Info";
import Admin from "./views/Admin";
import Testing from "./views/Testing";
import AdminModerate from "./views/AdminModerate";
import App403 from "./views/App403.js";
import App404 from "./views/App404.js";
import PublicPage from "./views/PublicPage";
import ContentPage from "./views/ContentPage";

// Reviewer
import JudgeDashboard from "./views/Reviewer/Dashboard";
import ReviewStage from "./views/Reviewer/Stage";
import ReviewCollection from "./views/Reviewer/Collection";
import ReviewCase from "./views/Reviewer/Case";

// judge admin
import JudgeAdminDashboard from "./views/ReviewAdmin/Dashboard";
import JudgeAdminStage from "./views/ReviewAdmin/Stage";
import JudgeAdminCollection from "./views/ReviewAdmin/Collection";
import JudgeAdminCase from "./views/ReviewAdmin/Case";
import JudgeAdminReviewer from "./views/ReviewAdmin/Reviewer";

// GLOBALS
import AA_GLOBAL from "./globals/globals";

// REDUX
import { connect } from "react-redux";
import { setLanguage, setMessages } from "./actions/appActions";
import { axiosWithAuth } from "./axiosSetup.js";

// window.onerror = function (error) {
//   // do something clever here
//   alert(error); // do NOT do this for real!
// };

// function deleteAllCookies() {
//   var cookies = document.cookie.split(";");

//   for (var i = 0; i < cookies.length; i++) {
//     var cookie = cookies[i];
//     console.log("cookie", cookie, i);
//     var eqPos = cookie.indexOf("=");
//     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//   }
// }

// deleteAllCookies();

export class App extends Component {
  _lang = AA_GLOBAL.cookies.get("lang");

  state = {
    lang: "de-DE",
    languages: ["en-US", "de-DE", "ru-RU", "pt-PT", "fr-FR"],
  };

  getMessages = () => {
    axiosWithAuth.get("/api/messages").then(res => {
      this.props.setMessages(res.data);
    });
  };

  UNSAFE_componentWillMount() {
    this.getMessages();
    // set the language
    // if (!this._lang) {
    //   AA_GLOBAL.cookies.set("lang", "de-DE", { path: "/" });
    // }
    // make intl methods public
    // const { intl } = new IntlProvider(
    //   { locale: this._lang || this.props.lang, messages: messages[this._lang || this.props.lang] },
    //   {}
    // ).getChildContext();
    // AA_GLOBAL.intl = intl;
    // DETECT LANGUAGE FROM BROWSER
    // check for browser language by looking for navigator object
    // if navigator object does not exsits, set the language to default
    // let browserLang =
    //   (navigator.languages && navigator.languages[0])
    //   || navigator.language
    //   || navigator.userLanguage
    //   || this.state.lang;
    // set the language from localStorage
    //const lang = localStorage.getItem('lang');
    // if(lang) {
    //   this.props.setLanguage(lang);
    // }else {
    //   // localStorage lang dosn't exists get browser language
    //   // and check if we have it in our languages list
    //   this.state.languages.forEach(lang => {
    //     if(lang === browserLang) {
    //       this.setState({lang: browserLang})
    //       this.props.setLanguage(lang);
    //     }
    //   })
    // }
  }

  render() {
    // prettier-ignore
    return (
      <IntlProvider locale={this.props.lang || this._lang} messages={this.props.messages}>
        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path="/ui/" test={"/"} render={() => (
                <Redirect to="/ui/submitter/dashboard" />
              )} />
              {/* <Route path="/" component={AuthHOC(Home)} exact={true}/> */}
              <Route path="/ui/submitter/dashboard" component={AuthHOC(Dashboard, 'user')} exact={true} />
              {/* <Route path="/ui/auth" component={AuthHOC(LoginPage)} exact={true}/> */}
              <Route path="/ui/auth/:module?" component={AuthHOC(LoginPage)} exact={true} />
              <Route path="/ui/submitter/new-entry" component={AuthHOC(NewEntry)} exact={true} />
              <Route path="/ui/submitter/copy-entry/:id" component={AuthHOC(CopyEntry)} exact={true} />
              <Route path="/ui/:role/edit-entry/:id/:displayId?" component={AuthHOC(EditEntry)} exact={true} />
              <Route path="/ui/:role/project-overview/:id/:displayId?" component={AuthHOC(ProjectOverview)} exact={true} />
              <Route path="/ui/cart" component={AuthHOC(Cart)} exact={true} />
              <Route path="/ui/:role/profile/:page/:id?" component={AuthHOC(Profile)} exact={true} />
              <Route path="/ui/payments" component={AuthHOC(Payments)} exact={true} />
              <Route path="/ui/submission_admin/copy-entry/:id" component={AuthHOC(CopyEntry)} exact={true} />
              <Route path="/ui/submission_admin/dashboard/:catID?" component={AuthHOC(Admin)} exact={true} />
              {/* <Route path="/ui/judge_admin/dashboard/:catID?" component={AuthHOC(Admin)} exact={true}/> */}
              {/* <Route path="/ui/submission_admin/project-overview/:id?" component={AuthHOC(AdminProjectOverview)} exact={true}/> */}
              <Route path="/ui/submission_admin/user/:id?" component={AuthHOC(User)} exact={true} />
              <Route path="/ui/submission_admin/pay" component={AuthHOC(Pay)} exact={true} />
              <Route path="/ui/submission_admin/moderate" component={AuthHOC(AdminModerate)} exact={true} />
              {/* REVIEWER */}
              <Route path={Routes.reviewerDashboard().route} component={AuthHOC(JudgeDashboard)} exact={true} />
              <Route path={Routes.reviewerStage().route} component={AuthHOC(ReviewStage)} exact={true} />
              <Route path={Routes.reviewerCollection().route} component={AuthHOC(ReviewCollection)} exact={true} />
              <Route path={Routes.reviewerCase().route} component={AuthHOC(ReviewCase)} exact={true} />
              {/* REVIEWER ADMIN */}
              <Route path={Routes.reviewAdminDashboard().route} component={AuthHOC(JudgeAdminDashboard)} exact={true} />
              <Route path={Routes.reviewAdminStage().route} component={AuthHOC(JudgeAdminStage)} exact={true} />
              <Route path={Routes.reviewAdminCollection().route} component={AuthHOC(JudgeAdminCollection)} exact={true} />
              <Route path={Routes.reviewAdminCase().route} component={AuthHOC(JudgeAdminCase)} exact={true} />
              <Route path={Routes.reviewAdminReviewer().route} component={AuthHOC(JudgeAdminReviewer)} exact={true} />

              <Route path="/ui/403" component={AuthHOC(App403)} exact={true} />
              <Route path="/ui/user/reset_password/:id" component={AuthHOC(ResetPassword)} exact={true} />
              <Route path="/ui/info" component={AuthHOC(Info)} exact={true} />
              <Route path="/ui/pages/:slug" component={PublicPage} exact={true} />
              <Route path="/ui/content/:slug" component={AuthHOC(ContentPage)} exact={true} />
              <Route path="/ui/testing" component={Testing} exact={true} />
              <Route path="/*" component={AuthHOC(App404)} exact={true} />
            </Switch>
          </div>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.app.lang,
  messages: state.persist.messages,
});

export default connect(mapStateToProps, { setLanguage, setMessages })(App);
