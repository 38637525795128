import React, { Component } from "react";
import { useRef } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

// FUNCTIONS
import AJAX from "../functions/ajax";
import { axiosWithAuth } from "../axiosSetup";

// REDUX
import { connect } from "react-redux";
import { getEntrySteps, setEntryStepActive } from "../actions/entryActions";
import { hideMenu } from "../actions/appActions";

// COMPONENTS
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import MobileNav from "../components/navigations/MobileNav";
import ScrollableContent from "../components/layout/ScrollableContent";
import AlphaTable from "../components/tables/AlphaTable";
import FieldList from "../components/layout/FieldList";

import BaseFormio from "../components/forms/BaseFormio";
import { APIButtons } from "../components/forms/APIButton";

// HOOKS
import useMounted from "../hooks/useMounted";
import useSafeAxios from "../hooks/useSafeAxios";
import useSafeDelay from "../hooks/useSafeDelay";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

// VENDORS
import { ContentContainer } from "../components/layout/ContentContainer";

export class User extends Component {
  _id = Math.random();

  state = {};

  UNSAFE_componentWillMount() {
    this.init(this.props);
  }

  init = props => {
    this.getProfileData(props);
  };

  getProfileData = props => {
    AJAX()
      .getUserData(this._id, props)
      .then(res => {
        if (!res || !res.data) return;

        const { profiles, user, submissions } = res.data;
        const indexAPI = submissions.index_api;

        this.resetRecord(user, profiles);

        AJAX()
          .send(this._id, indexAPI.method, indexAPI.url, indexAPI.data)
          .then(res => this.setState({ tableData: res.data, submissions, profiles, user }));
      });
  };

  resetRecord = (user, profiles) => {
    if (this.state.record) {
      const currentRecordID = this.state.record.id;
      const updatedRecord = [user, ...profiles].find(rec => rec.id === currentRecordID);
      this.setState({ record: updatedRecord });
    }
  };

  onEditUserOrProfile = record => {
    this.setState({ record: null, animateEditProfile: false }, () => {
      this.setState({ record, animateEditProfile: true });
    });
  };

  hideForm = () => {
    this.setState({ record: null });
  };

  onEditFormSubmitted = resp => {
    if (resp.isOK && resp.changed) this.getProfileData(this.props);
  };

  render() {
    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain dashboardMain__multiple ">
              <div className="column-md u-order-1">
                {this.state.record && (
                  <AnimateHOC animate={this.state.animateEditProfile}>
                    <ContentContainer
                      titleType="h1"
                      title={this.state.record.edit.title}
                      btn
                      btnText="close"
                      btnOnClick={this.hideForm}
                    >
                      <EditRecordForm
                        info={this.props.info}
                        record={this.state.record}
                        onSubmitted={this.onEditFormSubmitted}
                      />
                    </ContentContainer>
                  </AnimateHOC>
                )}
                {this.state.tableData && (
                  <AnimateHOC animate={true}>
                    <AlphaTable titleType="h1" data={this.state.tableData} />
                  </AnimateHOC>
                )}
              </div>

              <MobileNav>
                <AnimateHOC animate={true}>
                  <ScrollableContent height={this.props.mobMenuDisplay ? "100vh" : null}>
                    {this.state.user && (
                      <ContentContainer
                        title={this.state.user.display.title}
                        titleType="span"
                        btn
                        btnText="edit"
                        btnOnClick={() => this.onEditUserOrProfile(this.state.user)}
                      >
                        {/* @todo add proper CSS class (added projectOverview as a temp solution.) */}
                        <FieldList
                          model={this.state.user.display}
                          prefix="projectOverview"
                          addClass="projectOverview"
                        />
                      </ContentContainer>
                    )}

                    {(this.state.profiles || []).map(profile => {
                      return (
                        <ContentContainer
                          key={profile.id}
                          title={profile.display.title}
                          titleType="span"
                          btn
                          btnText="edit"
                          btnOnClick={() => this.onEditUserOrProfile(profile)}
                        >
                          {/* @todo add proper CSS class (added projectOverview as a temp solution.) */}
                          <FieldList
                            model={profile.display}
                            prefix="projectOverview"
                            addClass="projectOverview"
                          />
                        </ContentContainer>
                      );
                    })}
                  </ScrollableContent>
                </AnimateHOC>
              </MobileNav>
            </div>
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

function EditRecordForm({ info, onSubmitted, record: { edit } }) {
  const mounted = useMounted();
  const safeAxios = useSafeAxios(axiosWithAuth, mounted);
  const safeDelay = useSafeDelay(mounted);

  const formOptions = useRef({ ...info.default_formio_options }).current;

  function onFormButton({ formio, action }) {
    const sendAndReloadOnSuccess = () => {
      return safeDelay(250)
        .then(_ => BaseFormio.apiRequest(safeAxios, action.api, formio.data))
        .then(r => {
          formio.setRespAlert(r);
          onSubmitted && safeDelay(500).then(_ => onSubmitted(r));
          return r.isOK;
        })
        .catch(error => {
          mounted() && formio.setRespAlert(error.response);
          throw error;
        });
    };

    const runAction = {
      reset: () => formio.resetData(),
      delete: sendAndReloadOnSuccess,
      save: sendAndReloadOnSuccess,
    }[action.key];

    return runAction && runAction();
  }

  return (
    <BaseFormio schema={edit.schema} content={edit.content} options={formOptions}>
      <APIButtons list={[edit.actions, ctxt => onFormButton(ctxt)]} />
    </BaseFormio>
  );
}

User.propTypes = {};

const mapStateToProps = store => ({
  entryStepData: store.entry.steps,
  mobMenuDisplay: store.app.mobMenuDisplay,
  info: store.app.info,
  user: store.user,
});

export default injectIntl(
  connect(mapStateToProps, { getEntrySteps, setEntryStepActive, hideMenu })(withRouter(User))
);
