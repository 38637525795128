import React from "react";
import Routes from "../../Routes";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import TYPES from "../../actions/types";

// COMPONENTS
// import CounterWithCompanyInfo from "../display/CounterWithCompanyInfo";
import Layout from "../layout/Layout";
import Footer from "../footers/Footer";
import HeaderNav from "../navigations/HeaderNav";
import CompanyBanner from "../banners/CompanyBanner";
// import NewCounter from "../display/NewCounter";
import StageCounter from "../display/StageCounter";
import MobToggleMenuBtn from "../buttons/MobToggleMenuBtn";

const Main = ({ children, counter, onCounterEnd }) => {
  const dispatch = useDispatch();
  const appStore = useSelector(state => state.app);
  // const user = useSelector(state => state.user);
  // const reviewer = useSelector(state => state.reviewer);
  const isMainMenuVisible = useSelector(store => store.app.isMainMenuVisible);
  const isMainMenuVisibleOnTop = useSelector(store => store.app.isMainMenuVisibleOnTop);
  const isHelperMenuVisibleOnTop = useSelector(store => store.app.isHelperMenuVisibleOnTop);
  const isHelperMenuPresent = useSelector(store => store.app.isHelperMenuPresent);
  const helperSideNavToggle = useSelector(store => store.app.helperSideNavToggle);

  // useEffect(() => {
  //   console.log("isMainMenuVisibleOnTop", isMainMenuVisibleOnTop);
  // }, [isMainMenuVisibleOnTop]);

  const handleMainMenuMobButtonClick = () => {
    if (!isMainMenuVisibleOnTop) {
      dispatch({ type: TYPES.SHOW_MAIN_MENU_ON_TOP });
    } else {
      dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
    }
    dispatch({ type: TYPES.HIDE_HELPER_NAV_ON_TOP });
  };

  const handleHelperMenuMobButtonClick = () => {
    if (!isHelperMenuVisibleOnTop) {
      dispatch({ type: TYPES.SHOW_HELPER_NAV_ON_TOP });
    } else {
      dispatch({ type: TYPES.HIDE_HELPER_NAV_ON_TOP });
    }
    dispatch({ type: TYPES.HIDE_MAIN_MENU_ON_TOP });
  };

  return (
    <div
      data-name="Main"
      className={`__mc ${
        appStore.helperSideNavToggle && !appStore.helperSideNavOnTop && "__mc--r"
      } ${isMainMenuVisible ? " __mc--l" : ""}`}
    >
      <div className="__ly_f __ly_f-dc __ly_f-mb __ly_fh">
        <header className="__he">
          <HeaderNav />

          <div className="__he_s">
            <CompanyBanner />
            {/* {counter && (
              <NewCounter
                setDeadlineExpired={setDeadlineExpired}
                toWhat={counter.toWhat}
                submissionDeadline={counter}
              />
            )} */}

            {counter && <StageCounter data={counter} callback={onCounterEnd} />}
            {/* <CounterWithCompanyInfo /> */}
          </div>
        </header>

        <main className="__ma">
          <div className="">
            {appStore && appStore.appTitle && <h2 className="__h2 __mat">{appStore.appTitle}</h2>}
            <div>{children}</div>
          </div>
        </main>
        <Footer addClass="__fo" />
        {
          <Layout addClass="__mb_c" flex spaceBetween>
            <MobToggleMenuBtn
              toggle={isMainMenuVisibleOnTop}
              clicked={handleMainMenuMobButtonClick}
              iconID={
                Routes.getData().module === "reviewer" && Routes.getData().page === "case"
                  ? "hammer"
                  : "menuBtn"
              }
            />
            {isHelperMenuPresent && (
              <MobToggleMenuBtn
                toggle={appStore.isHelperMenuVisibleOnTop}
                clicked={handleHelperMenuMobButtonClick}
                iconID="listLine"
              />
            )}
          </Layout>
        }
      </div>
    </div>
  );
};

export default Main;
