import React, { Component } from "react";
import PropTypes from "prop-types";
// import { MdVideocam, MdLibraryMusic, MdInsertDriveFile, MdInsertPhoto } from 'react-icons/md';
// import { FaFileAlt } from 'react-icons/fa';
import { FormattedMessage } from "react-intl";

// FUNCTIONS
import renderIconBasedOnType from "../../functions/renderIconBasedOnType";

class Popover extends Component {
  _id = Math.random();

  state = {
    style: {},
  };

  onMouseEnter = e => {
    if (this.props.linkInspector && e.target.attributes.getNamedItem("data-url"))
      this.setState({ linkUrl: e.target.attributes.getNamedItem("data-url").value });
    if (this.props.imageInspector && e.target.attributes.getNamedItem("img-url"))
      this.setState({ imgUrl: e.target.attributes.getNamedItem("img-url").value });

    let mouseLeft = e.clientX;
    let mouseTop = e.clientY + 20;
    const windowWidth = window.innerWidth;
    const windowBottomLine = window.innerHeight;

    this.setState(
      {
        style: {
          ...this.state.style,
          left: mouseLeft,
          top: mouseTop,
          windowWidth,
          windowBottomLine,
          position: "fixed",
          display: "inline-block",
          zIndex: 7,
        },
      },
      () => {
        const elWidht = document.getElementById(this._id).offsetWidth;
        const popoverWidth = document.getElementById("popover" + this._id).offsetWidth;
        const popoverCordinates = document
          .getElementById("popover" + this._id)
          .getBoundingClientRect();

        // if mouse is to close to right side of the screen
        // move popover to the left
        if (mouseLeft > windowWidth - 320) {
          mouseLeft = mouseLeft - (popoverWidth + 10);
        }

        // if element is shown belowe the sceen, move it up
        if (windowBottomLine <= mouseTop + popoverCordinates.height) {
          mouseTop = mouseTop - popoverCordinates.height - 25;
        }

        this.setState({
          style: { ...this.state.style, left: mouseLeft, top: mouseTop, elWidht, opacity: 1 },
        });
      }
    );
  };

  onMouseLeave = e => {
    this.setState({ style: {} });
  };

  formatMsg = (text, condition) => {
    // make array of words
    var wordsArr = text.split(" ");
    var newWordsArr = [];
    var wordLengthLimit = 26;
    var slicedWord = "";
    var minusSimbol = condition ? "" : "-";

    wordsArr.forEach(word => {
      // if the word is to long
      if (word.length > wordLengthLimit) {
        [...word].forEach((l, i) => {
          slicedWord += l;
          if (i + 1 > wordLengthLimit) {
            newWordsArr.push(slicedWord + minusSimbol);
            slicedWord = "";
            wordLengthLimit += wordLengthLimit;
          } else {
            if (i + 1 === word.length) {
              const lastWord = slicedWord.length === 1 ? " " + slicedWord : slicedWord;
              newWordsArr.push(lastWord);
            }
          }
        });
      } else {
        newWordsArr.push(word);
      }
    });

    return newWordsArr.join(" ");
  };

  render() {
    return (
      <span
        className={
          "popover__container " +
          (this.props.msg && this.props.msg.length === 0 ? " u-hide " : "") +
          (this.props.addClass ? this.props.addClass : "")
        }
        id={this._id}
        style={this.props.style || {}}
        onMouseOver={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.props.msg ? (
          <span
            className={
              "popover " +
              (this.props.textMedium ? "popover__textMedium " : "") +
              (this.props.showAs === "error" ? "popover--error " : "") +
              (this.props.showAs === "success" ? "popover--success " : "")
            }
            id={"popover" + this._id}
            style={{ ...this.state.style, maxWidth: "200px" }}
          >
            {this.props.msg && !this.props.intl
              ? this.formatMsg(this.props.msg, this.props.removeMinusOnBreak)
              : null}
            {this.props.msg && this.props.intl ? <FormattedMessage id={this.props.msg} /> : null}
          </span>
        ) : null}
        {this.props.linkInspector ? (
          <span
            className={"popover "}
            id={"popover" + this._id}
            style={{ ...this.state.style, width: "200px" }}
          >
            {/* <img className="popover__img" src={this.state.linkUrl}/> */}
            <div
              className="popover__img"
              style={{
                backgroundImage:
                  this.props.type === "video" || this.props.type === "image"
                    ? `url(${this.state.linkUrl})`
                    : "",
                width: "200px",
                height: "125px",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              {renderIconBasedOnType(this.props.type, "popover-Icon")}
            </div>
          </span>
        ) : null}
        {this.props.imageInspector ? (
          <span
            className={"popover "}
            id={"popover" + this._id}
            style={{ ...this.state.style, width: "200px" }}
          >
            <img className="popover__img" alt="popover" src={this.state.imgUrl} />
            {/* <div
                className="popover__img"
                style={{
                  backgroundImage: `url(${this.state.imgUrl})`,
                  width: '300px',
                  height: '225px',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'}}>
                  </div> */}
          </span>
        ) : null}
        {this.props.children}
      </span>
    );
  }
}

Popover.propTypes = {
  msg: PropTypes.string,
  type: PropTypes.string,
  addClass: PropTypes.string,
  linkInspector: PropTypes.bool, // add [data-url] on dom el
  imageInspector: PropTypes.bool, // add [img-url] on dom el
  textMedium: PropTypes.bool, // used for bigger text
  important: PropTypes.bool, // shows a red border line on the top of the popover
};

export default Popover;
