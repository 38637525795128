const collectionDataModel = {
  collection: {
    type: "object",
    contains: {
      id: { type: "string" },
      stage_id: { type: "string" },
      confirmed: { type: "boolean" },
      cases_table: { type: "object" },
      apis: {
        type: "object",
        contains: {
          toggle_c: {
            type: "object",
            contains: {
              api: {
                type: "object",
                contains: {
                  method: { type: "string" },
                  url: { type: "string" },
                },
              },
              id: { type: "string" },
              key: { type: "string" },
              label: { type: "string" },
            },
          },
        },
      },
    },
  },
};

export default collectionDataModel;
