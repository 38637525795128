import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

// COMPONENTS
import Layout from "../layout/Layout";
import Icon from "../icons/Icon";
import Popover from "../popovers/Popover";
import Title from "../text/Title";

// FUNCTIONS
import generateUniqueID from "../../functions/generateUniqueID";

const ListNav = ({ type, title, list, linkTo, match, role, clicked }) => {
  const id = generateUniqueID();

  // useEffect(() => {
  //   if (scrollInView) {
  //     const activItem = document.querySelector("#activeCase452");
  //     console.log("activItem", activItem);
  //     if (activItem) activItem.scrollIntoView();
  //   }
  // }, []);

  const handleLinkClick = () => {
    if (clicked) clicked();
  };

  const generateRatingList = () =>
    list.map(item => (
      <li key={item.id}>
        <Link to={`${linkTo}/${item.id}`} onClick={handleLinkClick} className="__il c16 bc15">
          <Layout flex spaceBetween alignItemsCenter>
            <p className="__ip">{item.title}</p>
            <Icon iconId="cup" addClass="__ii c17" />
          </Layout>
        </Link>
      </li>
    ));

  const generateCaseList = () =>
    list.map(item => (
      <li key={item.id}>
        <Popover msg={item.title}>
          <Link
            id={`${item.id}`}
            onClick={handleLinkClick}
            to={`${linkTo}/${item.id}`}
            className={`__ir ${
              item.id === match.params.id ? "c10 bc1 listNav_active" : "c16 bc11"
            }`}
          >
            <Layout flex alignItemsCenter>
              <Icon
                iconId="checkLine"
                addClass={`__ii __if
              ${item.confirmed && item.id !== match.params.id ? "c1" : ""}
              ${item.id === match.params.id ? "c10" : "c17"}`}
                style={!item.confirmed && { opacity: ".4" }}
              />
              <img className="__ia" src="https://bit.ly/3hNvw0Z" alt="Placeholder" />
              <p className="__ip">
                {item.code ? `${item.code} - ` : ""}
                {item.title}
              </p>
            </Layout>
          </Link>
        </Popover>
      </li>
    ));

  return (
    <div className="__i" data-name="ListNav">
      <Title addClass="__it" id={id}>
        {title}
      </Title>
      <div className="__in">
        <ul>
          {type === "rating" && generateRatingList()}
          {type === "cases" && generateCaseList()}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(ListNav);
