import React, { Component } from "react";
import { useRef, useState, useLayoutEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

// FUNCTIONS
import { axiosWithAuth } from "../axiosSetup";

// REDUX
import { connect } from "react-redux";

// COMPONENTS
import ContentContainer from "../components/layout/ContentContainer";
import MainHeaderNav from "../components/navigations/MainHeaderNav";
import MainBoxNav from "../components/navigations/MainBoxNav";
import FooterMain from "../components/footers/FooterMain";
import ContentLoader from "../components/loaders/ContentLoader";

import { BaseFormio, ResponseAlert } from "../components/forms/BaseFormio";
import { APIButtons } from "../components/forms/APIButton";

// HOOKS
import useMounted from "../hooks/useMounted";
import useSafeAxios from "../hooks/useSafeAxios";
import useSafeDelay from "../hooks/useSafeDelay";

// HOC
import AppHOC from "../hoc/AppHOC";
import AnimateHOC from "../hoc/AnimateHOC";

export class NewEntry extends Component {
  render() {
    const { user } = this.props;

    return (
      <AppHOC>
        <div id="page">
          <MainHeaderNav />
          <MainBoxNav />
          <div className="rowCon rowCon__withMargin">
            <div className="dashboardMain">
              <AnimateHOC animate={user}>
                <NewEntryForm
                  {...this.props}
                  url={user.appModule.submissions.new}
                  onSuccess={res => onNewEntryAdd(res, this.props)}
                />
              </AnimateHOC>
            </div>
          </div>
          <FooterMain />
        </div>
      </AppHOC>
    );
  }
}

export function onNewEntryAdd({ data }, props) {
  props.history.push(`/ui/${props.user.role_selected}/edit-entry/${data.draft.id}`);
}

export function NewEntryForm({ url, info, history, onSuccess }) {
  const mounted = useMounted();
  const safeAxios = useSafeAxios(axiosWithAuth, mounted);
  const safeDelay = useSafeDelay(mounted);
  const [form, setForm] = useState();
  const [portalNode, setPortalNode] = useState();
  const [respAlert, setRespAlert] = useState();

  const formioOptions = useRef({ ...info.default_formio_options }).current;

  useLayoutEffect(() => {
    safeAxios({ url })
      .then(({ data: { body: form } }) => setForm(form))
      .catch(e => mounted() && setForm(serverErrorForm));
  }, [url, safeAxios, mounted]);

  function onCreateClick({ formio, action }) {
    if (action.key !== "create") return;

    return safeDelay(250)
      .then(_ => BaseFormio.apiRequest(safeAxios, action.api, formio.data))
      .then(r => {
        formio.setRespAlert(r);
        if (r.isOK) safeDelay(750).then(_ => onSuccess(r));
        return r.isOK;
      })
      .catch(error => {
        if (!mounted()) return;
        formio.checkAndShowErrors();
        formio.setRespAlert(error.response || {});
        throw error;
      });
  }

  const onCreate = useCallback(
    formio => {
      // @todo Remove when this is solved by a higher order component or similar.
      formio.element.querySelectorAll("a[href^='/ui/'], a[href='/ui']").forEach(a =>
        a.addEventListener("click", evt => {
          // Prevent reload if it's a ui link.
          evt.preventDefault();
          history.push(a.pathname);
        })
      );

      const portalNode = formio.element.querySelector("div[ref=portal]");
      if (portalNode) {
        portalNode.className = "portal";
        // Override setRespAlert so we can render it inside the portal together with the create button.
        formio.setRespAlert = resp => mounted() && setRespAlert(resp);
        setPortalNode(portalNode);
      }
    },
    [history, mounted]
  );

  return (
    <ContentContainer title={form ? form.title : "..."} titleType="h1">
      {form ? (
        <BaseFormio schema={form.schema} options={formioOptions} onCreate={onCreate}>
          {portalNode ? (
            <Portal node={portalNode}>
              <div style={{ marginTop: "2rem" }}>
                {respAlert && <ResponseAlert {...respAlert} {...{ setRespAlert }} />}
                <APIButtons list={[form.actions, ctxt => onCreateClick(ctxt)]} />
              </div>
            </Portal>
          ) : (
            <APIButtons list={[form.actions, ctxt => onCreateClick(ctxt)]} />
          )}
        </BaseFormio>
      ) : (
        <ContentLoader />
      )}
    </ContentContainer>
  );
}

const Portal = props => ReactDOM.createPortal(props.children, props.node);

const serverErrorForm = {
  title: "Error",
  schema: { components: [{ type: "content", html: "Please, try again later." }] },
  actions: [],
};

NewEntry.propTypes = {
  user: PropTypes.object,
  info: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.user,
  info: state.app.info,
});

export default injectIntl(connect(mapStateToProps)(NewEntry));
